import axios from '../../../constants/ApiInterceptor';
import { getHeaders } from '../../../constants/Common';
import {
  ANALYTIC_FILTER_API,
  EFFIENCY_API,
  ENERGY_COST_SAVING_API,
  IMPLEMENT_TYPE_EFFIENCY_API,
  OPERATION_HOURS_AND_COVERAGE_API,
  TRACTOR_ANALYTICS_DETAILS_API,
  TRACTOR_IMEPLEMENT_API,
  ANALYTIC_RESULTS_API,
  OPERATOR_HOURS_AND_COVERAGE_API,
} from './endpoints';
import { operationalAnalyticsResults } from './db';

const BASE_URL = process.env.REACT_APP_BASE_URL;

// const { REACT_APP_COGNIITO_ACCESS_TOKEN_URL } = process.env;

const isEmpty = (value: any) => {
  if (typeof value === 'undefined') return true;
  if (typeof value === 'string' && value.trim() === '') return true;
  if (typeof value === 'object' && value === null) return true;
  if (typeof value === 'number' && value === 0) return true;
  return false;
};

const emptyFilter = (data: any) => (key: string) => !isEmpty(data[key]);

const getBaseURL = (url: string, id?: number): string =>
  `${url}organization${id ? `/${id}` : ''}`;

const getURL = (url: string): string => `${url.slice(0, -1)}`;

// const getFarmURL = (url: string, id?: number): string =>
//   `${url}farm${id ? `/${id}` : ''}`;

// const getFleetURL = (url: string, id?: number): string =>
//   `${url}fleet${id ? `/${id}` : ''}`;

// const getConverseURL = (url: string, id: number): string =>
//   `${url}converse/organization/${id}`;

export const fetchCall =
  (ENDPOINT: string) =>
  async (orgURL: string, orgId: number): Promise<any> => {
    const url = getBaseURL(orgURL, orgId) + ENDPOINT;
    const response = await getAPI(url);
    return response.data;
  };

export const fetchPaginateCall =
  (ENDPOINT: string) =>
  async (
    orgURL: string,
    orgId: number,
    page_number: number,
    page_size: number,
    search?: string,
  ): Promise<any> => {
    let url = getBaseURL(orgURL, orgId) + ENDPOINT;
    url += prepareQuery({
      page_number,
      page_size,
      q: search && encodeURI(search),
    });
    const response = await getAPI(url);
    return response.data;
  };

export const postCall =
  (ENDPOINT: string) =>
  async (orgURL: string, orgId: number, data: any): Promise<any> => {
    const url = getBaseURL(orgURL, orgId) + ENDPOINT;
    const response = await postAPI(url, data);
    return response.data;
  };

const prepareURL = (url: string, data: any = {}) =>
  Object.keys(data).length
    ? Object.keys(data).reduce(
        (a, c) => (a = a.replace(`:${c}`, data[c])) && a,
        url,
      )
    : url;

const prepareQuery = (data: any) =>
  Object.keys(data)
    .filter(emptyFilter(data))
    .reduce(
      (a, c, i, arr) =>
        (a += `${i === 0 ? '?' : ''}${c}=${data[c]}${
          arr.length - 1 !== i ? '&' : ''
        }`) && a,
      '',
    );

const postAPI = async (url: string, data: any) =>
  axios.post(url, data, {
    headers: await getHeaders(),
  });

const putAPI = async (url: string, data: any) =>
  axios.put(url, data, {
    headers: await getHeaders(),
  });

const getAPI = async (url: string) =>
  axios.get(url, {
    headers: await getHeaders(),
  });

const deleteAPI = async (url: string) =>
  axios.delete(url, {
    headers: await getHeaders(),
  });

export const getTractorAnalyticDetails = async (
  from_date: string,
  to_date: string,
  imp_name: string,
  tractor_id: string,
): Promise<any> => {
  let url = BASE_URL + TRACTOR_ANALYTICS_DETAILS_API;
  url += prepareQuery({
    from_date,
    to_date,
    imp_name, //'DECTEST',
    tractor_id,
  });
  const response = await getAPI(url);
  return response.data;
};

export const getEfficiencyData = async (
  operation_type: string,
  from_date_time?: string,
  to_date_time?: string,
  operator_id?: string,
): Promise<any> => {
  let url = BASE_URL + EFFIENCY_API;
  url += prepareQuery({
    operation_type,
    from_date_time,
    to_date_time,
    operator_id,
  });
  const response = await getAPI(url);
  return response.data;
};

export const getImplementTypeEfficiencyData = async (
  from_date: string,
  to_date: string,
  imp_type: string,
): Promise<any> => {
  let url = BASE_URL + IMPLEMENT_TYPE_EFFIENCY_API;
  url += prepareQuery({
    from_date,
    to_date,
    imp_type,
  });
  const response = await getAPI(url);
  return response.data;
};

export const getEnerySavingCostData = async (
  from_date: string,
  to_date: string,
): Promise<any> => {
  let url = BASE_URL + ENERGY_COST_SAVING_API;
  url += prepareQuery({
    from_date,
    to_date,
  });
  const response = await getAPI(url);
  return response.data;
};

export const getOperationalHoursAndCoverage = async (
  from_date?: string,
  to_date?: string,
  imp_type?: string,
): Promise<any> => {
  let url = BASE_URL + OPERATION_HOURS_AND_COVERAGE_API;
  url += prepareQuery({
    from_date,
    to_date,
    imp_type,
  });
  const response = await getAPI(url);
  return response.data;
};

export const getOperations = async (
  orgURL: string,
  from_date_time?: number,
  to_date_time?: number,
  operationType?: string,
  operatorId?: string,
): Promise<any> => {
  let URL = `${orgURL}analytics/operational-coverages?`;
  if (to_date_time && from_date_time) {
    URL = URL + `from_date_time=${from_date_time}&to_date_time=${to_date_time}`;
  }
  if (operationType) {
    URL = URL + `&operation_type=${operationType}`;
  }
  if (operatorId) {
    URL = URL + `&operator_id=${operatorId}`;
  }
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};
export const getOperationVideos = async (
  orgURL: string,
  from_date_time: number,
  to_date_time: number,
  operationType: string,
  tractorId: number,
  driveActionUuid: string,
): Promise<any> => {
  let URL = `${orgURL}analytics/operations/video-urls?`;
  if (to_date_time && from_date_time) {
    URL = URL + `from_date_time=${from_date_time}&to_date_time=${to_date_time}`;
  }
  if (operationType) {
    URL = URL + `&implement_type=${operationType}`;
  }
  if (tractorId) {
    URL = URL + `&tractor_id=${tractorId}`;
  }

  if (driveActionUuid) {
    URL = URL + `&drive_action_uuid=${driveActionUuid}`;
  }

  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};
export const getOperationsWithIds = async (
  orgURL: string,
  from_date_time: number,
  to_date_time: number,
  operationType: string,
  tractorId: number,
): Promise<any> => {
  let URL = `${orgURL}analytics/operations/drive-actions/list?`;
  if (to_date_time && from_date_time) {
    URL = URL + `from_date_time=${from_date_time}&to_date_time=${to_date_time}`;
  }
  if (operationType) {
    URL = URL + `&implement_name=${operationType}`;
  }

  if (tractorId) {
    URL = URL + `&tractor_id=${tractorId}`;
  }

  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });

  return Response.data;
};

export const getSavingsBasedOnOperation = async (
  from_date?: string,
  to_date?: string,
  operation_type?: string,
): Promise<any> => {
  let url = BASE_URL + ENERGY_COST_SAVING_API;
  url += prepareQuery({
    from_date,
    to_date,
    operation_type,
  });
  const response = await getAPI(url);
  return response.data;
};

export const getTicketDetailsById = async (
  orgURL: string,
  ticketId: number,
): Promise<any> => {
  const URL = `${orgURL}analytics/tickets/${ticketId}`;
  const Response = await axios.get(URL, {
    headers: await getHeaders(),
  });
  return Response.data;
};

export const getTractorImplements = async (
  from_date_time: string,
  to_date_time: string,
  tractor_id: string,
  operator_id?: string,
): Promise<any> => {
  let url = BASE_URL + TRACTOR_IMEPLEMENT_API;
  url += prepareQuery({
    from_date_time,
    to_date_time,
    tractor_id,
    operator_id,
  });
  const response = await getAPI(url);
  return response.data;
};

export const getAnalyticFilters = async (
  from_date_time: number,
  to_date_time: number,
  operation_type: string[],
  tractor_id: string[],
): Promise<any> => {
  let url = BASE_URL + ANALYTIC_FILTER_API;
  url += prepareQuery({
    from_date_time,
    to_date_time,
    operation_type: operation_type?.length ? operation_type : undefined,
    tractor_id: tractor_id?.length ? tractor_id : undefined,
  });
  const response = await getAPI(url);
  return response.data;
};

export const getAnalyticResults = async (
  from_date_time: number,
  to_date_time: number,
  operation_type: string[],
  tractor_id: string[],
  operator_id: string[],
  page_number: number,
  page_size: number,
): Promise<any> => {
  let url = BASE_URL + ANALYTIC_RESULTS_API;
  url += prepareQuery({
    from_date_time,
    to_date_time,
    operation_type,
    tractor_id,
    operator_id,
    page_number,
    page_size,
  });
  const response = await getAPI(url);
  return response.data;
};

export const getOperatorHoursAndCoverage = async (
  from_date: number,
  to_date: number,
  operator_id: string,
): Promise<any> => {
  let url = BASE_URL + OPERATOR_HOURS_AND_COVERAGE_API;
  url += prepareQuery({
    from_date,
    to_date,
    operator_id,
  });
  const response = await getAPI(url);
  return response.data;
};

/* eslint-disable no-console */
/* eslint-disable react/jsx-key */
import { Button, Spin, TimePicker, notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { getInstallList, getSummary } from '../../constants/types';
import { ClockCircleOutlined, LeftOutlined } from '@ant-design/icons';
import { ConfirmSchedule } from './ConfirmSchedule';
import scheduled from '../../assets/images/scheduled.svg';
import neutral_tractor from '../../assets/images/neutral_tractor.svg';
import up_alert_circle from '../../assets/images/up_alert_circle.svg';
import {
  Datepicker,
  DatepickerEvent,
} from '@meinefinsternis/react-horizontal-date-picker';
import { enGB } from 'date-fns/locale';
import { getSelectedTractors } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';

interface Props {
  closeModal: () => void;
  closeAndUpdate: () => void;
  loader: boolean;
  setLoader: (data: boolean) => void;
  tractorIds: any;
  summaryList: getSummary;
  confirmSchedule: boolean;
  setConfirmSchedule: (data: boolean) => void;
}
export const ScheduleLater: React.FC<Props> = ({
  closeModal,
  closeAndUpdate,
  loader,
  setLoader,
  tractorIds,
  summaryList,
  confirmSchedule,
  setConfirmSchedule,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const [disable, setDisable] = useState<boolean>(true);
  const [date, setDate] = React.useState<{
    endValue: Date | null;
    startValue: Date | null;
    rangeDates: Date[] | null;
  }>({
    startValue: new Date(),
    endValue: new Date(),
    rangeDates: [],
  });

  const [defaultTime] = useState<any>('4:30 PM');
  const [selectedTime, setSelectedTime] = useState<any>(
    moment(defaultTime, 'h:mm A'),
  );
  const [tractorsList, setTractorsList] = useState<getInstallList[]>([]);
  const [selectedIds, setSelectedIds] = useState<any[]>([]);
  const [isToday, setIsToday] = useState(false);

  useEffect(() => {
    const nextDayDate = moment(date.startValue).add(1, 'day');
    if (moment().isAfter(moment(defaultTime, 'h:mm A'))) {
      setDate({
        ...date,
        startValue: nextDayDate.toDate(),
        endValue: nextDayDate.toDate(),
        rangeDates: [nextDayDate.toDate()],
      });
    } else {
      setDate({
        ...date,
        startValue: new Date(),
        endValue: new Date(),
        rangeDates: [],
      });
    }
  }, []);

  useEffect(() => {
    if (date.startValue !== null && selectedTime !== null) {
      setDisable(false);
    } else setDisable(true);
  }, [date.startValue, selectedTime]);

  useEffect(() => {
    if (userDetails && userDetails.organization && tractorIds)
      getTractorsList();
  }, [userDetails, tractorIds]);

  const getTractorsList = async () => {
    try {
      setLoader(true);
      const records = await getSelectedTractors(
        userDetails.organization.api_url,
        tractorIds,
      );
      const ids =
        records &&
        records.length > 0 &&
        records.map((d: getInstallList) => d.id);
      setSelectedIds(ids);
      setTractorsList(records);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleTimeChange = (e: any, dateString: any) => {
    const formattedTime = moment(dateString, 'h:mm a');
    const amPm = formattedTime.format('a');
    const currentDate = moment().format();
    if (
      selectedTime &&
      amPm === 'am' &&
      moment(currentDate).isSame(date.startValue, 'day')
    ) {
      setSelectedTime(moment());
    } else {
      setSelectedTime(e);
    }
  };

  const removeSchItems = (id: number) => {
    const updatedSelectedIds = [...tractorIds];
    updatedSelectedIds.splice(updatedSelectedIds.indexOf(id), 1);
    setSelectedIds(updatedSelectedIds);
    const filterList = tractorsList.filter((d: any) => d.id !== id);
    setTractorsList(filterList);
  };

  const getDisabledHours: any = () => {
    const currentHour = moment().hour();
    const currentDate = moment().format();

    if (moment(currentDate).isSame(date.startValue, 'day')) {
      return Array.from({ length: currentHour }, (_, i) => i);
    }

    return [];
  };

  const getDisabledMinutes = (selectedHour: any) => {
    const currentHour = moment().hour();
    const currentDate = moment().format();

    if (
      moment(currentDate).isSame(date.startValue, 'day') &&
      selectedHour === currentHour
    ) {
      const currentMinute = moment().minute();
      return Array.from({ length: currentMinute }, (_, i) => i);
    }

    return [];
  };

  const handleChange = (d: DatepickerEvent) => {
    setIsToday(false);
    const [startValue, endValue, rangeDates] = d;
    setDate((prev) => ({ ...prev, endValue, startValue, rangeDates }));
    setSelectedTime(null);
  };

  const handleGoToToday = () => {
    setIsToday(true);
    setTimeout(() => {
      const today = new Date();
      setDate({
        startValue: today,
        endValue: today,
        rangeDates: [today],
      });
      setIsToday(false);
    }, 100);
  };

  return (
    <>
      {!confirmSchedule ? (
        <>
          <div className="scheduleFleet">
            <div className="updateArrow" onClick={closeModal}>
              <LeftOutlined />
            </div>

            <div className="otaHead mb0">Schedule your Fleet</div>
          </div>
          <div className="updateVersionTxt">
            Select a date and time to automatically update these tractors.
          </div>

          <div className="scheduleGrid">
            <div className="schUpdateGridBg1">
              <div>
                <div className="tractorContainer borderBtm">
                  <div>
                    <img src={neutral_tractor} alt="" />
                    <span className="schUpTxt mL10">Selected Tractors</span>
                  </div>
                  <div className="tractorsLength">{tractorsList.length}</div>
                </div>

                <div className="swVersionTxt">
                  {tractorsList &&
                    tractorsList.length > 0 &&
                    tractorsList?.map((item: any) => (
                      <div className="serialContainer plr15">
                        <div className="straName">{item?.name}</div>
                        <div
                          className="removeTxt"
                          onClick={() => removeSchItems(item.id)}
                        >
                          Remove
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div className="installRight schRelNotes">
              <div>
                <div className="scheduleFleet mb15">
                  <img src={scheduled} alt="" height="24px" />
                  <div className="otaHead ml5">Select Date</div>
                </div>
                <div className="selectDateSec">
                  <div className="selectedDateRow">
                    <div className="schDateStyle">
                      {moment(date.startValue).format('dddd, MMM D')}
                    </div>
                    <div className="etaTxt">
                      <img src={up_alert_circle} alt="" /> We recommend updating
                      within 2 weeks of release.
                    </div>
                  </div>
                  {isToday ? (
                    <div style={{ height: '145px' }} />
                  ) : (
                    <Datepicker
                      onChange={handleChange}
                      locale={enGB}
                      startValue={date.startValue}
                      endValue={date.startValue}
                    />
                  )}
                </div>

                <div className="goToToday" onClick={handleGoToToday}>
                  Go To Today
                </div>

                <div className="otaHead mb15 mt36">
                  <ClockCircleOutlined className="mr5" /> Select Time
                </div>
                <div className="selectedDateRow1">
                  <TimePicker
                    value={selectedTime}
                    disabledHours={getDisabledHours}
                    disabledMinutes={getDisabledMinutes}
                    format="h:mm A"
                    onChange={(e: any, dateString: any) =>
                      handleTimeChange(e, dateString)
                    }
                  />
                </div>
              </div>
              <div style={{ textAlign: 'right' }}>
                <Button
                  className="nextBtn nextBtnW"
                  onClick={() => setConfirmSchedule(true)}
                  disabled={disable}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>

          <div className="edit-loader">
            {loader && (
              <div className="loader">
                <Spin size="large" />
              </div>
            )}
          </div>
        </>
      ) : (
        <ConfirmSchedule
          closeModal={() => setConfirmSchedule(false)}
          closeAndUpdate={closeAndUpdate}
          tractorsList={tractorsList}
          scheduleDate={date.startValue}
          tractorIds={selectedIds}
          selectedTime={selectedTime}
          summaryList={summaryList as any}
          setTractorsList={setTractorsList}
          setSelectedIds={setSelectedIds}
        />
      )}
    </>
  );
};

export const camerasVideos: string[] = [
  'FrontCamera',
  'front_left',
  'front_right',
  'RearCamera',
  'PTOCamera',
];
export const cameraName: any = {
  FrontCamera: 'Front',
  front_left: 'Left',
  front_right: 'Right',
  RearCamera: 'Rear',
  PTOCamera: 'Hitch',
};
export const cameras: any = {
  FrontCamera:
    'https://monarch-tractor-org-0002-dev-data-qubes.s3.amazonaws.com/VideoLogs/PS1066/FrontCamera/2022-11-22/front-2022-11-22-19-20-00.mp4?AWSAccessKeyId=ASIA6RGY6XLLGQYBQ2JH&Signature=IKOKJia9cvVpCLZBa84sngZb88k%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEIL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLXdlc3QtMiJHMEUCIDnfmG5Zw87bwj4by91iai8Ifya9SeUPygA3uVuHD5pdAiEA8CC%2F%2BvWx64Cm0t3kddOgivqHGROrM6WzaLowVfNWxwcqiQMIu%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgw5OTkwMzQ4OTUwNjIiDFT6ovT0pNPuIxg%2FRCrdArx0FOKFwKec2OsOrua6Ig4d1XXdSyXCCJEMpDbf8UTzdSeB72M3jydTdlOqDPbxuTzTBQ454vLfLjAckeelevTtX8wCfOT7JSwr2TEurMT7yLESBdegIUhJKK9hNCcBpigYLFBYej5a5IWWEA1YAK4PHT2WRifu57tnYeP5Ish0P%2F2jbL2%2BMNJ3dm%2BNEofSMsRb2IWMdx1ZRp54whmEjLfQswmDcnSoGU2umcBW1%2Bqpq8yVvypgu7royHiujfryqQgRCwcCdM3AS7uVSbvgOB2dJhsZg4gRNyA53tFJ3rlVZ8yY2gwRn19qNfWB5AVa4PlwW1VWXenVZwntZo3Wt1kf%2Fn0P9tTKsVGJfI4dw4LjqJNbiNAILRwzuywKYu3QGZ4SBCv8EvocA4E6SnwMizabnuUkNNldKN0mbQWhTI%2BX7HHKtpyR55guCt4TKCyqtX523HEW2Kc6y%2BP9bPEw9pL5sAY6ngEgNHb36r5S0oC5N40L%2B5u3WvF8PbI9MmjhmKYvYOwT4z8N%2B5KlM8RJ08Bho1KJspFdzskp%2BbnnnImw1YD434aWKsGF7iC2yMlkM4HxHU3C21Po6ngrkiSyi3G6rl5VMoju814ck11TxerW2cytxymjJkPW5wuUOTnadlY7neGY9aenqrhmGy4%2B%2BIL%2FZ6Bc20fUuxQInRXCF5EAIX3sYA%3D%3D&Expires=1713271406',
  PTOCamera:
    'https://monarch-tractor-org-0002-dev-data-qubes.s3.amazonaws.com/VideoLogs/PS1066/PTOCamera/2022-11-22/pto-2022-11-22-19-20-00.mp4?AWSAccessKeyId=ASIA6RGY6XLLGQYBQ2JH&Signature=bREg4U4QPooM1qLd0X1FJqipr7Y%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEIL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLXdlc3QtMiJHMEUCIDnfmG5Zw87bwj4by91iai8Ifya9SeUPygA3uVuHD5pdAiEA8CC%2F%2BvWx64Cm0t3kddOgivqHGROrM6WzaLowVfNWxwcqiQMIu%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgw5OTkwMzQ4OTUwNjIiDFT6ovT0pNPuIxg%2FRCrdArx0FOKFwKec2OsOrua6Ig4d1XXdSyXCCJEMpDbf8UTzdSeB72M3jydTdlOqDPbxuTzTBQ454vLfLjAckeelevTtX8wCfOT7JSwr2TEurMT7yLESBdegIUhJKK9hNCcBpigYLFBYej5a5IWWEA1YAK4PHT2WRifu57tnYeP5Ish0P%2F2jbL2%2BMNJ3dm%2BNEofSMsRb2IWMdx1ZRp54whmEjLfQswmDcnSoGU2umcBW1%2Bqpq8yVvypgu7royHiujfryqQgRCwcCdM3AS7uVSbvgOB2dJhsZg4gRNyA53tFJ3rlVZ8yY2gwRn19qNfWB5AVa4PlwW1VWXenVZwntZo3Wt1kf%2Fn0P9tTKsVGJfI4dw4LjqJNbiNAILRwzuywKYu3QGZ4SBCv8EvocA4E6SnwMizabnuUkNNldKN0mbQWhTI%2BX7HHKtpyR55guCt4TKCyqtX523HEW2Kc6y%2BP9bPEw9pL5sAY6ngEgNHb36r5S0oC5N40L%2B5u3WvF8PbI9MmjhmKYvYOwT4z8N%2B5KlM8RJ08Bho1KJspFdzskp%2BbnnnImw1YD434aWKsGF7iC2yMlkM4HxHU3C21Po6ngrkiSyi3G6rl5VMoju814ck11TxerW2cytxymjJkPW5wuUOTnadlY7neGY9aenqrhmGy4%2B%2BIL%2FZ6Bc20fUuxQInRXCF5EAIX3sYA%3D%3D&Expires=1713271406',
  RearCamera:
    'https://monarch-tractor-org-0002-dev-data-qubes.s3.amazonaws.com/VideoLogs/PS1066/RearCamera/2022-11-22/rear-2022-11-22-19-20-00.mp4?AWSAccessKeyId=ASIA6RGY6XLLGQYBQ2JH&Signature=Wi5OZtdXBCsJcnQ3g7AOcHRZJYE%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEIL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLXdlc3QtMiJHMEUCIDnfmG5Zw87bwj4by91iai8Ifya9SeUPygA3uVuHD5pdAiEA8CC%2F%2BvWx64Cm0t3kddOgivqHGROrM6WzaLowVfNWxwcqiQMIu%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgw5OTkwMzQ4OTUwNjIiDFT6ovT0pNPuIxg%2FRCrdArx0FOKFwKec2OsOrua6Ig4d1XXdSyXCCJEMpDbf8UTzdSeB72M3jydTdlOqDPbxuTzTBQ454vLfLjAckeelevTtX8wCfOT7JSwr2TEurMT7yLESBdegIUhJKK9hNCcBpigYLFBYej5a5IWWEA1YAK4PHT2WRifu57tnYeP5Ish0P%2F2jbL2%2BMNJ3dm%2BNEofSMsRb2IWMdx1ZRp54whmEjLfQswmDcnSoGU2umcBW1%2Bqpq8yVvypgu7royHiujfryqQgRCwcCdM3AS7uVSbvgOB2dJhsZg4gRNyA53tFJ3rlVZ8yY2gwRn19qNfWB5AVa4PlwW1VWXenVZwntZo3Wt1kf%2Fn0P9tTKsVGJfI4dw4LjqJNbiNAILRwzuywKYu3QGZ4SBCv8EvocA4E6SnwMizabnuUkNNldKN0mbQWhTI%2BX7HHKtpyR55guCt4TKCyqtX523HEW2Kc6y%2BP9bPEw9pL5sAY6ngEgNHb36r5S0oC5N40L%2B5u3WvF8PbI9MmjhmKYvYOwT4z8N%2B5KlM8RJ08Bho1KJspFdzskp%2BbnnnImw1YD434aWKsGF7iC2yMlkM4HxHU3C21Po6ngrkiSyi3G6rl5VMoju814ck11TxerW2cytxymjJkPW5wuUOTnadlY7neGY9aenqrhmGy4%2B%2BIL%2FZ6Bc20fUuxQInRXCF5EAIX3sYA%3D%3D&Expires=1713271406',
  front_left:
    'https://monarch-tractor-org-0002-dev-data-qubes.s3.amazonaws.com/VideoLogs/PS1066/front_left/2022-11-22/frontleft-2022-11-22-19-20-00.mp4?AWSAccessKeyId=ASIA6RGY6XLLGQYBQ2JH&Signature=RexgK7Et%2FI0rSKwGoBXtzDx4RmM%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEIL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLXdlc3QtMiJHMEUCIDnfmG5Zw87bwj4by91iai8Ifya9SeUPygA3uVuHD5pdAiEA8CC%2F%2BvWx64Cm0t3kddOgivqHGROrM6WzaLowVfNWxwcqiQMIu%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgw5OTkwMzQ4OTUwNjIiDFT6ovT0pNPuIxg%2FRCrdArx0FOKFwKec2OsOrua6Ig4d1XXdSyXCCJEMpDbf8UTzdSeB72M3jydTdlOqDPbxuTzTBQ454vLfLjAckeelevTtX8wCfOT7JSwr2TEurMT7yLESBdegIUhJKK9hNCcBpigYLFBYej5a5IWWEA1YAK4PHT2WRifu57tnYeP5Ish0P%2F2jbL2%2BMNJ3dm%2BNEofSMsRb2IWMdx1ZRp54whmEjLfQswmDcnSoGU2umcBW1%2Bqpq8yVvypgu7royHiujfryqQgRCwcCdM3AS7uVSbvgOB2dJhsZg4gRNyA53tFJ3rlVZ8yY2gwRn19qNfWB5AVa4PlwW1VWXenVZwntZo3Wt1kf%2Fn0P9tTKsVGJfI4dw4LjqJNbiNAILRwzuywKYu3QGZ4SBCv8EvocA4E6SnwMizabnuUkNNldKN0mbQWhTI%2BX7HHKtpyR55guCt4TKCyqtX523HEW2Kc6y%2BP9bPEw9pL5sAY6ngEgNHb36r5S0oC5N40L%2B5u3WvF8PbI9MmjhmKYvYOwT4z8N%2B5KlM8RJ08Bho1KJspFdzskp%2BbnnnImw1YD434aWKsGF7iC2yMlkM4HxHU3C21Po6ngrkiSyi3G6rl5VMoju814ck11TxerW2cytxymjJkPW5wuUOTnadlY7neGY9aenqrhmGy4%2B%2BIL%2FZ6Bc20fUuxQInRXCF5EAIX3sYA%3D%3D&Expires=1713271406',
  front_right:
    'https://monarch-tractor-org-0002-dev-data-qubes.s3.amazonaws.com/VideoLogs/PS1066/front_right/2022-11-22/frontright-2022-11-22-19-20-00.mp4?AWSAccessKeyId=ASIA6RGY6XLLGQYBQ2JH&Signature=0qThAFuAOVMgzVqWOvVZ9fyVHrk%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEIL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLXdlc3QtMiJHMEUCIDnfmG5Zw87bwj4by91iai8Ifya9SeUPygA3uVuHD5pdAiEA8CC%2F%2BvWx64Cm0t3kddOgivqHGROrM6WzaLowVfNWxwcqiQMIu%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgw5OTkwMzQ4OTUwNjIiDFT6ovT0pNPuIxg%2FRCrdArx0FOKFwKec2OsOrua6Ig4d1XXdSyXCCJEMpDbf8UTzdSeB72M3jydTdlOqDPbxuTzTBQ454vLfLjAckeelevTtX8wCfOT7JSwr2TEurMT7yLESBdegIUhJKK9hNCcBpigYLFBYej5a5IWWEA1YAK4PHT2WRifu57tnYeP5Ish0P%2F2jbL2%2BMNJ3dm%2BNEofSMsRb2IWMdx1ZRp54whmEjLfQswmDcnSoGU2umcBW1%2Bqpq8yVvypgu7royHiujfryqQgRCwcCdM3AS7uVSbvgOB2dJhsZg4gRNyA53tFJ3rlVZ8yY2gwRn19qNfWB5AVa4PlwW1VWXenVZwntZo3Wt1kf%2Fn0P9tTKsVGJfI4dw4LjqJNbiNAILRwzuywKYu3QGZ4SBCv8EvocA4E6SnwMizabnuUkNNldKN0mbQWhTI%2BX7HHKtpyR55guCt4TKCyqtX523HEW2Kc6y%2BP9bPEw9pL5sAY6ngEgNHb36r5S0oC5N40L%2B5u3WvF8PbI9MmjhmKYvYOwT4z8N%2B5KlM8RJ08Bho1KJspFdzskp%2BbnnnImw1YD434aWKsGF7iC2yMlkM4HxHU3C21Po6ngrkiSyi3G6rl5VMoju814ck11TxerW2cytxymjJkPW5wuUOTnadlY7neGY9aenqrhmGy4%2B%2BIL%2FZ6Bc20fUuxQInRXCF5EAIX3sYA%3D%3D&Expires=1713271406',
  rear_left:
    'https://monarch-tractor-org-0002-dev-data-qubes.s3.amazonaws.com/VideoLogs/PS1066/rear_left/2022-11-22/rearleft-2022-11-22-19-20-00.mp4?AWSAccessKeyId=ASIA6RGY6XLLGQYBQ2JH&Signature=V57viXRxiszlEmnK%2BcGFLl9GjZ4%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEIL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLXdlc3QtMiJHMEUCIDnfmG5Zw87bwj4by91iai8Ifya9SeUPygA3uVuHD5pdAiEA8CC%2F%2BvWx64Cm0t3kddOgivqHGROrM6WzaLowVfNWxwcqiQMIu%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgw5OTkwMzQ4OTUwNjIiDFT6ovT0pNPuIxg%2FRCrdArx0FOKFwKec2OsOrua6Ig4d1XXdSyXCCJEMpDbf8UTzdSeB72M3jydTdlOqDPbxuTzTBQ454vLfLjAckeelevTtX8wCfOT7JSwr2TEurMT7yLESBdegIUhJKK9hNCcBpigYLFBYej5a5IWWEA1YAK4PHT2WRifu57tnYeP5Ish0P%2F2jbL2%2BMNJ3dm%2BNEofSMsRb2IWMdx1ZRp54whmEjLfQswmDcnSoGU2umcBW1%2Bqpq8yVvypgu7royHiujfryqQgRCwcCdM3AS7uVSbvgOB2dJhsZg4gRNyA53tFJ3rlVZ8yY2gwRn19qNfWB5AVa4PlwW1VWXenVZwntZo3Wt1kf%2Fn0P9tTKsVGJfI4dw4LjqJNbiNAILRwzuywKYu3QGZ4SBCv8EvocA4E6SnwMizabnuUkNNldKN0mbQWhTI%2BX7HHKtpyR55guCt4TKCyqtX523HEW2Kc6y%2BP9bPEw9pL5sAY6ngEgNHb36r5S0oC5N40L%2B5u3WvF8PbI9MmjhmKYvYOwT4z8N%2B5KlM8RJ08Bho1KJspFdzskp%2BbnnnImw1YD434aWKsGF7iC2yMlkM4HxHU3C21Po6ngrkiSyi3G6rl5VMoju814ck11TxerW2cytxymjJkPW5wuUOTnadlY7neGY9aenqrhmGy4%2B%2BIL%2FZ6Bc20fUuxQInRXCF5EAIX3sYA%3D%3D&Expires=1713271406',
  rear_right:
    'https://monarch-tractor-org-0002-dev-data-qubes.s3.amazonaws.com/VideoLogs/PS1066/rear_right/2022-11-22/rearright-2022-11-22-19-20-00.mp4?AWSAccessKeyId=ASIA6RGY6XLLGQYBQ2JH&Signature=dmUM1KGXKkYEi7nxaV5vbJ6As%2Fg%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEIL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLXdlc3QtMiJHMEUCIDnfmG5Zw87bwj4by91iai8Ifya9SeUPygA3uVuHD5pdAiEA8CC%2F%2BvWx64Cm0t3kddOgivqHGROrM6WzaLowVfNWxwcqiQMIu%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgw5OTkwMzQ4OTUwNjIiDFT6ovT0pNPuIxg%2FRCrdArx0FOKFwKec2OsOrua6Ig4d1XXdSyXCCJEMpDbf8UTzdSeB72M3jydTdlOqDPbxuTzTBQ454vLfLjAckeelevTtX8wCfOT7JSwr2TEurMT7yLESBdegIUhJKK9hNCcBpigYLFBYej5a5IWWEA1YAK4PHT2WRifu57tnYeP5Ish0P%2F2jbL2%2BMNJ3dm%2BNEofSMsRb2IWMdx1ZRp54whmEjLfQswmDcnSoGU2umcBW1%2Bqpq8yVvypgu7royHiujfryqQgRCwcCdM3AS7uVSbvgOB2dJhsZg4gRNyA53tFJ3rlVZ8yY2gwRn19qNfWB5AVa4PlwW1VWXenVZwntZo3Wt1kf%2Fn0P9tTKsVGJfI4dw4LjqJNbiNAILRwzuywKYu3QGZ4SBCv8EvocA4E6SnwMizabnuUkNNldKN0mbQWhTI%2BX7HHKtpyR55guCt4TKCyqtX523HEW2Kc6y%2BP9bPEw9pL5sAY6ngEgNHb36r5S0oC5N40L%2B5u3WvF8PbI9MmjhmKYvYOwT4z8N%2B5KlM8RJ08Bho1KJspFdzskp%2BbnnnImw1YD434aWKsGF7iC2yMlkM4HxHU3C21Po6ngrkiSyi3G6rl5VMoju814ck11TxerW2cytxymjJkPW5wuUOTnadlY7neGY9aenqrhmGy4%2B%2BIL%2FZ6Bc20fUuxQInRXCF5EAIX3sYA%3D%3D&Expires=1713271406',
};

export const indicator = [
  {
    drive_action_uuid: '17123432918690',
    indicators_data: [
      {
        created_date_time: 1712344446379,
        description: 'Gear shifting stuck',
        error_code: '20607',
        explanation: '',
        fleet_id: 18,
        geostamp:
          '-58933.96986815152|90995.7484461071|-907.0006227187645|12.8164|0.5901',
        id: 258244,
        indicator_history_uuid: '1712344446398',
        indicator_level: 3,
        indicator_name: 'Tractor Drivability',
        indicator_uuid: '1709575600821',
        is_acknowledged: true,
        latitude: 38.46855014009075,
        longitude: -122.38999622813618,
        modified_date_time: 1712344504220,
        organization_id: 20,
        resolved_date_time: null,
        sync_flag: true,
        tractor_id: 146,
      },
      {
        created_date_time: 1712344441188,
        description: '',
        error_code: '20610',
        explanation: '',
        fleet_id: 18,
        geostamp:
          '-58934.064852453455|90995.72303980698|-907.0338800243472|13.5404|0.5831',
        id: 258243,
        indicator_history_uuid: '1712344441193',
        indicator_level: 3,
        indicator_name: 'Tractor Drivability',
        indicator_uuid: '1709575600821',
        is_acknowledged: true,
        latitude: 38.46854990923883,
        longitude: -122.38999731790533,
        modified_date_time: 1712344506334,
        organization_id: 20,
        resolved_date_time: null,
        sync_flag: true,
        tractor_id: 146,
      },
    ],
    tractor: {
      connection: 'online',
      created_date_time: 1710160471238,
      id: 146,
      name: 'W-1022511sa',
      serial_number: 'PVO126',
      tractor_model: 'PV-O',
    },
  },
];

import { Button, notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { createSupportRoom } from '../../constants/Api';
import { v4 as uuidv4 } from 'uuid';
import { ApplicationContext } from '../../context/AppContext';
import AppLoader from '../common/AppLoader';
import CustomTextArea from '../common/CustomTextArea';
import './style.css';

interface Props {
  getSupportRooms: () => void;
}

const SupportRequest: React.FC<Props> = ({ getSupportRooms }) => {
  const { userDetails } = useContext(ApplicationContext);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [message, setMessage] = useState<string>('');
  const [messageCount, setMessageCount] = useState<number>(0);

  useEffect(() => {
    if (message && message.trim().length > 1) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [message]);

  useEffect(() => {
    if (message !== undefined) setMessageCount(message.length);
  }, [message]);

  const createNewRoom = async () => {
    try {
      setShowLoader(true);
      const payload = {
        message: message,
        messageId: uuidv4(),
        user_info: {
          user_id: userDetails.id,
          first_name: userDetails.first_name,
          last_name: userDetails.last_name,
        },
      };
      await createSupportRoom(
        userDetails.organization.api_url,
        userDetails.organization.id,
        userDetails.id,
        payload,
      );

      getSupportRooms();
    } catch (error: any) {
      if (error?.message) {
        notification.error({
          message: error?.message,
          duration: 2,
        });
      } else {
        notification.error({ message: 'Unable to create Room', duration: 2 });
      }
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <div className="requestContainer">
      <div className="conTxt">Start a new conversation with support team.</div>
      {/* <TextArea
        placeholder="Message"
        autoSize={{ minRows: 5, maxRows: 8 }}
        defaultValue={message}
        maxLength={500}
        onChange={(e: any) => setMessage(e.target.value)}
      /> */}
      <CustomTextArea
        label="Message"
        value={message}
        setValue={setMessage}
        required={true}
        description={message}
        descriptionCount={messageCount}
      />
      <div className="wdtCenter">
        <Button
          className="conStartBtn"
          disabled={isDisabled}
          onClick={createNewRoom}
        >
          Start
        </Button>
      </div>
      <AppLoader loader={showLoader} />
    </div>
  );
};

export default SupportRequest;

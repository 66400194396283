/* eslint-disable no-console */
import { notification } from 'antd';
import { Feature, Overlay, View } from 'ol';
import OLMap from 'ol/Map';
import { ScaleLine } from 'ol/control';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import * as proj from 'ol/proj';
import * as am4core from '@amcharts/amcharts4/core';
import OSM from 'ol/source/OSM';
import VectorSource from 'ol/source/Vector';
import XYZ from 'ol/source/XYZ';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import {
  getAllTractorSnapShot,
  getDriveActionPath,
  getMapFleetIndicators,
  getPolygonsData,
} from '../../../constants/Api';
import {
  calPtsDistance,
  centerTheLocation,
  delay,
  drawPoly,
  getDateTime,
  getDateTimes,
  getIndInfo,
  getPathBorderColor,
  getPathColor,
  getPathTimeInfo,
  getSnapInfo,
  getTicketInfo,
  polygonHoverLabel,
  priority,
} from '../../../constants/Common';
import {
  ATOM,
  GILES,
  GUARD_RAILS,
  JUMP_AND_GO,
  rangeForAnalytics,
} from '../../../constants/constant';
import { MapFeature } from '../../../constants/types';
import { ApplicationContext } from '../../../context/AppContext';
import { SET_POLYGONS_LIST } from '../../../context/actions';
import { drawLayerWithOutCluster } from '../../../hooks/useDrawLayer';
import tractorAnalyticDetailsStore from './../store/tractorAnalyticDetails';

import autoDrive from './../images/path-8134-1.svg';
import operatorAssistant from './../images/path-8134-2.svg';
import idle from './../images/path-8134-3.svg';
import manualDrive from './../images/path-8134.svg';

import guardRails from '../../../assets/images/guardRails.svg';
import human_pt from '../../../assets/images/human_pt.svg';
import JumpnGoIcon from '../../../assets/images/m_jngo.svg';
import SnapIcon from '../../../assets/images/m_sshot.svg';
import operationPath from '../../../assets/images/operationPath.svg';
import path_pt from '../../../assets/images/path_pt.svg';
import pto_pt from '../../../assets/images/pto_pt.svg';
import human from './../images/human.svg';
import vehicle from './../images/vehicle.svg';
import implementCollision from './../images/implement-collision-oa.svg';
import cameraClarity from './../images/camera_poor_oa.svg';
import ptoProtected from './../images/pto-oa.svg';
// import tractor__normal from './../../assets/images/tractor-icons/tractor__normal.svg';
// import selected_tractor_user_manual from './../../../assets/images/tractor-icons/selected_tractor_user_manual.svg';
import { getVectorContext } from 'ol/render';
import DiagnosticWidget from '../../fleethealth/DiagnosticWidget';
import { getSeverity, getTags } from '../../fleethealth/Diagnostics';
import SnapView from '../../snapshot/SnapView';
import OperationalAnalyticsTicketDetails from '../OperationalAnalyticsTicketDetails';
import useAnalyticsStore from './../store/operationAnalytics';

import {
  changeTimeFormat,
  fetchName,
  findNearestTimestamp,
  getColorCodeByOpType,
  handleSameLocationChangeFeatures,
  handleSameLocationFeatures,
  mapClickType,
  selectedPath,
} from '../common';
import { roundStyle } from '../constants';
import './styles.scss';
import moment from 'moment';

const API_COUNT = 10;
let gSelectedTractor: any = null;
let toggleForGuardRails = false;
let toggleForTicket = false;
let toggleForIndicator = false;
let globalGuardrailData: any = null;
let distance = 0;
let lastTime: any;
let currentPath = 0;
let allFeatures: any = [];
let mapClick: mapClickType = 'default';
let operatorName: any;
let startMarkerPosition: any;
let clickedFeature: any;
let gMouseEntered = false;
let gTriggeredToReArrange = false;
let gHasZoomed = false;
interface Props {
  operations?: any;
  selectedCamera: string;
  setSnapLoader: (state: boolean) => void;
  setPathLoader: (state: boolean) => void;
  setIndLoader: (state: boolean) => void;
  setDriveActionAndTime: (state: selectedPath) => void;
  driveActionAndTime: selectedPath;
  setProgressData: (data: any) => void;
  hasZoomed: boolean;
}

const OperationTractorDetailsMap: React.FC<Props> = ({
  operations,
  selectedCamera,
  setDriveActionAndTime,
  setSnapLoader,
  setPathLoader,
  setIndLoader,
  driveActionAndTime,
  setProgressData,
  hasZoomed,
}: Props) => {
  const { selectedDate } = useAnalyticsStore();

  const { selectedTractor, ticketsData } = tractorAnalyticDetailsStore();

  const { userDetails, APPReducer, user } = useContext(ApplicationContext);
  const [appState, appDispatch] = APPReducer;
  const { polygonsList, groundZero, tractorsMap } = appState;
  const [mapInitialized, setMapInitialized] = useState(false);
  const [base, setBase] = useState<[number, number]>([0, 0]);
  const [guardrailData, setGuardrailData] = useState<any>({});
  const [showGuardrailModel, setShowGuardrailModel] = useState(false);
  const [errorDetails, setErrorDetails] = useState<any>();
  const [showIndicatorModel, setShowIndicatorModel] = useState(false);
  const [showTicketPopup, setShowTicketPopup] = useState<boolean>(false);
  const [toggleTicketWidget, setToggleTicketWidget] = useState<boolean>(false);
  const [ticketId, setTicketId] = useState<any>();
  const [btnClick, setBtnClick] = useState(false);
  const [mouseEntered, setMouseEntered] = useState(false);
  const [triggeredToReArrange, setTriggeredToReArrange] = useState(false);

  const mapElement: any = useRef();
  const container: any = useRef();
  const content: any = useRef();
  const mapRef = useRef<OLMap | null>(null);
  const vectorTileLayerRef = useRef<TileLayer<any>>();
  const satelliteTileLayerRef = useRef<TileLayer<any>>();
  const polygonsLayerRef = useRef<VectorLayer<any>>();
  const tractorPathLayerRef = useRef<VectorLayer<any>>();
  const hoverPointLayerRef = useRef<VectorLayer<any>>();
  const mapTicketsRefs = useRef<VectorLayer<any>>();
  const mapIndicatorRefs = useRef<VectorLayer<any>>();
  const snapshotRefs = useRef<VectorLayer<any>>();
  useEffect(() => {
    gHasZoomed = hasZoomed;
  }, [hasZoomed]);
  useEffect(() => {
    gMouseEntered = mouseEntered;
  }, [mouseEntered]);
  useEffect(() => {
    gSelectedTractor = selectedTractor;
  }, [selectedTractor]);
  useEffect(() => {
    mapInitialized && selectedCamera && mapRef.current?.updateSize();
  }, [selectedCamera, mapInitialized]);

  useEffect(() => {
    if (user && groundZero) {
      initializeMap();
    }
  }, [user, groundZero]);

  useEffect(() => {
    if (groundZero) {
      const { latitude, longitude } = groundZero;
      setBase([latitude, longitude]);
    }
  }, [groundZero]);
  useEffect(() => {
    selectedDate &&
      mapInitialized &&
      base &&
      centerTheLocation([base[1], base[0]], mapRef.current, 15);
  }, [selectedDate, base, mapInitialized]);

  useEffect(() => {
    gTriggeredToReArrange = triggeredToReArrange;
    let list: any = [];
    const list1 = snapshotRefs.current?.getSource()?.getFeatures();
    const list2 = mapTicketsRefs.current?.getSource()?.getFeatures();
    const list3 = mapIndicatorRefs.current?.getSource()?.getFeatures();
    if (list1 && list1.length > 0) list = list1;
    if (list2 && list2.length > 0) list = [...list, ...list2];
    if (list3 && list3.length > 0) list = [...list, ...list3];
    list && list.length > 0 && handleSameLocationChangeFeatures(list, 5);
  }, [triggeredToReArrange]);

  //  mapInitialization start here
  const initializeMap = async () => {
    try {
      const { latitude, longitude } = groundZero;

      const vectorLayer = new TileLayer({
        source: new OSM(),
        visible: true,
      });

      const satelliteLayer = new TileLayer({
        source: new XYZ({
          url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga',
          cacheSize: 1000,
        }),
        visible: false,
      });
      const initialPathLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
        zIndex: 0,
      });

      // polygons list layar
      const polygonsFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
      });
      const tractorPathFeaturesLayer = new VectorLayer({
        source: new VectorSource(),
        zIndex: priority.PATH,
      });
      const mapSnaps = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        zIndex: priority.SNAP,

        visible: true,
      });
      // create map
      const initialMap = new OLMap({
        target: mapElement.current,
        layers: [
          satelliteLayer,
          vectorLayer,
          initialPathLayer,
          polygonsFeaturesLayer,
          tractorPathFeaturesLayer,
          mapSnaps,
        ],
        controls: [scaleControl()],
        view: new View({
          projection: 'EPSG:3857',
          center: proj.transform([0, 0], 'EPSG:4326', 'EPSG:3857'),
          zoom: 2,
          maxZoom: 25,
        }),
      });

      // set flag for map initialization
      setMapInitialized(true);
      centerTheLocation([longitude, latitude], initialMap, 15);
      mapRef.current = initialMap;
      // initialize safelight layer
      satelliteTileLayerRef.current = satelliteLayer;
      // initialize vector layer
      vectorTileLayerRef.current = vectorLayer;
      polygonsLayerRef.current = polygonsFeaturesLayer;

      tractorPathLayerRef.current = tractorPathFeaturesLayer;
      snapshotRefs.current = mapSnaps;
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };
  //  mapInitialization ends here

  const scaleControl = () => {
    const control = new ScaleLine({
      units: 'metric',
      className: 'positionMap',
    });
    return control;
  };

  useEffect(() => {
    const init = async () => {
      polygonsLayerRef.current?.getSource().clear();
      try {
        const { organization } = userDetails;
        let recordsList = [];
        if (polygonsList && polygonsList.length > 0) {
          recordsList = polygonsList;
        } else {
          const response = await getPolygonsData(
            organization.api_url,
            organization.farm.id,
          );
          const { records } = response;
          recordsList =
            records && records.length && records.length > 0 ? records : [];
        }
        drawPolygon(
          recordsList,
          polygonsList && polygonsList.length > 0 ? false : true,
        );
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };
    if (userDetails && userDetails.organization && mapInitialized && base) {
      init();
    }
  }, [userDetails, mapInitialized, base]);

  const drawPolygon = async (polyList: any, save: boolean) => {
    try {
      const records = polyList && polyList.length > 0 ? polyList : [];
      const listItems: MapFeature[] = [];
      records.map(async (record: any, index: number) => {
        const { polygonItem } = await drawPoly(
          userDetails,
          base,
          polygonsLayerRef.current,
          1,
          1,
          record,
          false, // suggested as false
          !save, // call api to get vertices -> false call api , -> true dont call api
        );
        if (polygonItem && polygonItem.vertices) {
          record.vertices = polygonItem.vertices;
        }
        // polygons added to maintain app context start
        listItems.push(polygonItem as MapFeature);
        if (records && records.length - 1 === index) {
          save &&
            appDispatch({
              type: SET_POLYGONS_LIST,
              payload: records,
            });
        }
        // polygons added to maintain app context end
      });
      polygonHoverLabel(mapRef.current);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  useEffect(() => {
    const init = async () => {
      try {
        setBtnClick(false);
        tractorPathLayerRef?.current?.getSource()?.clear();
        if (operations && operations.length > 0) {
          const listItem: any = operations;
          // if (listItem.length > 99) listItem.length = 25;
          setPathLoader(true);

          const list =
            listItem.length > API_COUNT
              ? Array.from(
                  {
                    length: listItem.length / API_COUNT,
                  },
                  (_, i) => i,
                )
              : [0];
          let index = 0;
          const allResponses: any[] = [];
          for (const i of list) {
            const promises = listItem
              .slice(API_COUNT * i, API_COUNT * (i + 1))
              .map(async (jng: any) => {
                return await getAllPoints(jng);
              });
            await delay(5000);
            const response: any = await Promise.all(promises);
            allResponses.push(...[].concat(...response));
            const list = allResponses.filter((item: any) => item !== false);
            setProgressData(list);
            if (list.length == index + 1) {
              setBtnClick(true);
              setPathLoader(false);
            }
            index += 1;
          }
          // const data =
          //   allResponses?.length > 5
          //     ? allResponses?.filter((item: any) => item.duration > 5)
          //     : allResponses;
          // setProgressData(data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setPathLoader(false);
      }
    };
    mapInitialized && selectedTractor && selectedTractor.tractorId && init();
  }, [operations]);

  useEffect(() => {
    if (!showTicketPopup) {
      toggleForTicket = false;
    } else {
      setShowIndicatorModel(false);
      setShowGuardrailModel(false);
    }
  }, [showTicketPopup]);

  useEffect(() => {
    if (!showIndicatorModel) {
      toggleForIndicator = false;
    } else {
      setShowTicketPopup(false);
      setShowGuardrailModel(false);
    }
  }, [showIndicatorModel]);

  useEffect(() => {
    if (!showGuardrailModel) {
      toggleForGuardRails = false;
    } else {
      setShowTicketPopup(false);
      setShowIndicatorModel(false);
    }
  }, [showGuardrailModel]);

  useEffect(() => {
    if (mapIndicatorRefs.current) {
      mapRef.current?.removeLayer(mapIndicatorRefs.current);
    }
    const initIndicatorLayer = (data: any[]) => {
      const layer = drawLayerWithOutCluster(data, 'OPERATION_INDICATOR', 8);
      mapIndicatorRefs.current = layer;
      mapIndicatorRefs.current?.setVisible(true);
      if (mapIndicatorRefs.current)
        mapRef.current?.addLayer(mapIndicatorRefs.current);
      setTriggeredToReArrange(!gTriggeredToReArrange);
    };

    const initIndicatorData = async () => {
      try {
        let list: any = [];
        setIndLoader(true);
        // const opsList = [...operations, { drive_action_uuid: 17132762545590 }];
        const opsList = operations;
        opsList.map(async (operation: any, index: number) => {
          const data = await getMapFleetIndicators(
            userDetails.organization.api_url,
            userDetails.organization.id,
            operation.drive_action_uuid,
          );
          if (data && data.length > 0) {
            const [{ indicators_data }] = data;
            if (indicators_data && indicators_data.length > 0) {
              list = [...list, ...indicators_data];
            }
          }
          index + 1 == opsList.length && setIndLoader(false);
          index + 1 == opsList.length && initIndicatorLayer(list);
        });
      } catch (error) {
        console.error(error);
      } finally {
        setIndLoader(false);
      }
    };
    mapInitialized &&
      userDetails &&
      userDetails.organization &&
      operations &&
      operations.length > 0 &&
      initIndicatorData();
  }, [mapInitialized, operations, userDetails]);

  useEffect(() => {
    if (mapTicketsRefs.current) {
      mapRef.current?.removeLayer(mapTicketsRefs.current);
    }
    const initTicketLayer = (data: any[]) => {
      const layer = drawLayerWithOutCluster(data, 'Operation_Ticket', 3);
      mapTicketsRefs.current = layer;
      mapTicketsRefs.current?.setVisible(true);
      if (mapTicketsRefs.current)
        mapRef.current?.addLayer(mapTicketsRefs.current);
      setTriggeredToReArrange(!gTriggeredToReArrange);
    };
    mapInitialized &&
      userDetails &&
      userDetails.organization &&
      ticketsData &&
      ticketsData.length > 0 &&
      initTicketLayer(ticketsData);
  }, [mapInitialized, userDetails, ticketsData]);

  const initSnapLayer = (data: any) => {
    const defaultLayer = snapshotRefs.current;

    defaultLayer?.getSource().clear();

    if (data) {
      data?.map((snapshot: any, index: number) => {
        const { latitude, longitude, created_at, guard_rail_type } = snapshot;
        if (latitude && longitude) {
          const coordinates = [latitude, longitude];
          // snapshot icons
          let icon = SnapIcon;
          icon = operationPath;

          let name = 'SNAPSHOT';
          let scale = 1.5;
          if (created_at === 'GUARD_RAILS') {
            name = 'GUARD_RAILS';
            // icon = GuardRailIcon;
            // icon = guardRails;
            name = 'Guard Rails';
            if (guard_rail_type === 'human_protection') {
              icon = human;
              name = 'Human Detected';
            } else if (guard_rail_type === 'path_protection') {
              icon = operationPath;
              name = 'Obstacle Detected';
            } else if (guard_rail_type === 'pto_protection') {
              icon = ptoProtected;
              name = 'Pto Protection';
            } else if (guard_rail_type === 'vehicle_presence') {
              icon = vehicle;
              name = 'Vehicle Detected';
            } else if (guard_rail_type === 'camera_clarity') {
              icon = cameraClarity;
              name = 'Poor camera clarity';
            } else if (guard_rail_type === 'implement_guardrail') {
              icon = implementCollision;
              name = 'Implement Protection';
            }
            // scale = 0.7;
          } else if (created_at === 'JUMP_AND_GO') {
            // icon = JumpnGoIcon;
            name = 'JUMP_AND_GO';
          }
          scale = 1.5;
          const style = new Style({
            image: new Icon({
              src: icon,
              scale,
            }),
            zIndex: 100,
          });
          const hoverStyle = new Style({
            image: new Icon({
              src: icon,
              scale: scale * 1.2,
            }),
            zIndex: 100,
          });

          const feature = new Feature({
            geometry: new Point(
              proj.fromLonLat([coordinates[1], coordinates[0]]),
            ),
            name: name,
            pointType: 'SNAP',
            content: { ...snapshot, created_type_text: name },
            style,
            point: [coordinates[1], coordinates[0]],
          });
          feature.setStyle(style);
          feature.set('style', style);
          feature.set('hoverStyle', hoverStyle);
          if (created_at === 'GUARD_RAILS') {
            // guardRailsfeatures.push(feature);
            defaultLayer?.getSource().addFeature(feature);
          } else if (created_at === 'JUMP_AND_GO') {
            // jumpAndGofeatures.push(feature);
          } else {
            defaultLayer?.getSource().addFeature(feature);
          }
          if (index + 1 === data.length) {
            setTriggeredToReArrange(!gTriggeredToReArrange);
            handleSameLocationFeatures(
              defaultLayer?.getSource()?.getFeatures(),
              5,
            );
          }
        }
      });
    }
  };
  useEffect(() => {
    const initSnapData = async () => {
      try {
        let list: any = [];
        setSnapLoader(true);

        operations.map(async (operation: any, index: number) => {
          const { organization } = userDetails;
          const data = await getAllTractorSnapShot(
            organization.api_url,
            organization.farm.id,
            selectedTractor.tractorId,
            1,
            300,
            'completed',
            '',
            operation.drive_action_start_date_time,
            operation.drive_action_finish_date_time,
          );
          if (data && data.records && data.records.length > 0) {
            list = [...list, ...data.records];
          }
          index + 1 == operations.length && setSnapLoader(false);
          index + 1 == operations.length && initSnapLayer(list);
        });
      } catch (error) {
        console.error(error);
      } finally {
        setSnapLoader(false);
      }
    };
    mapInitialized &&
      userDetails &&
      userDetails.organization &&
      operations &&
      operations.length > 0 &&
      selectedTractor &&
      selectedTractor.tractor_id;
    initSnapData();
  }, [mapInitialized, operations, userDetails, selectedTractor]);

  const getAllPoints = async (jumpAndGo: any) => {
    try {
      const { drive_action_uuid: selectedDriveAction } = jumpAndGo;
      if (
        userDetails &&
        userDetails.organization &&
        selectedDriveAction &&
        selectedDriveAction.length > 0
      ) {
        const { organization } = userDetails;
        const response = await getDriveActionPath(
          organization.api_url,
          selectedDriveAction,
        );
        response['jng'] = jumpAndGo;
        drawJAG(
          selectedDriveAction,
          response,
          true,
          selectedTractor.implementName,
        );
        const driveUuidData = response.records
          ?.filter(
            (item: any) =>
              item.planner !== 'standby' &&
              item?.path?.length > 1 &&
              item.planner !== 'copycat',
          )
          ?.map((item: any) => {
            const lastRecord = item?.path[item?.path.length - 1];
            const firstRecord = item?.path[0];
            return {
              category: '',
              drive_action_uuid: selectedDriveAction,
              op_type: item.planner,
              from: changeTimeFormat(firstRecord?.created_date_time),
              to: changeTimeFormat(lastRecord?.created_date_time),
              color: am4core.color(getColorCodeByOpType(item.planner)),
              // defualtColor: am4core.color('#BBCBCF'),
              duration: moment(lastRecord?.created_date_time).diff(
                firstRecord?.created_date_time,
                'minute',
              ),
              operator: fetchName(
                jumpAndGo.operator.first_name,
                jumpAndGo.operator.last_name,
              ),
              opName: `${jumpAndGo.operator.first_name}
                ${jumpAndGo.operator.last_name}`,
            };
          });
        return driveUuidData;
      }
      // return true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return false;
    }
  };
  const drawJAG = (
    id: string,
    jumpAndGo: any,
    all = false,
    keyItem: string,
  ) => {
    const {
      tractor_id: tractorId,
      records: points,
      tractor_name = '',
      operator_name = '',
      jng,
    } = jumpAndGo;
    const defaultLayer = tractorPathLayerRef.current;
    let oarr: any = [];
    let lastIndexPoint: any = null;
    points
      .filter(
        (point: any) =>
          point.planner !== 'standby' &&
          point.planner !== 'copycat' &&
          point?.path?.length > 1,
      ) // filter standby
      .map((point: any) => {
        // slice points
        if (lastIndexPoint) point.path = [lastIndexPoint, ...point.path];

        const result = point.path.reduce(
          (
            a: { data: any[][]; i: number | undefined; last_item: any },
            path_pt: {
              longitude: number;
              latitude: number;
              created_date_time: number;
              dTime: string;
              accuracy: number;
            },
            i: number,
          ) => {
            const ar = point.path;

            let distance = 0;

            if (i < ar.length) {
              distance = ar[i + 1] ? calPtsDistance([path_pt, ar[i + 1]]) : 1;

              if (distance > rangeForAnalytics) {
                a.data.push(ar.slice(a.i, i + 1));
                a.i = i + 1;
                if (ar[i + 1]) a.data.push([ar[i], ar[i + 1]]);
              }
            }

            if (ar.length - 1 == i && a.data.length == 0) {
              a.data.push(ar);
              lastIndexPoint = ar[ar.length - 1];
              return a.data;
            }
            if (ar.length - 1 == i && a.data.length > 0) {
              lastIndexPoint = ar[ar.length - 1];
              return a.data;
            }
            return a;
          },
          { i: 0, data: [], last_item: {} },
        );

        result
          .filter((item: any, key: number) => !(key % 2))
          .map((item: any, key: number) => {
            const arr: any[] = [];
            item.map(
              (path_pt: {
                longitude: number;
                latitude: number;
                created_date_time: number;
                dTime: string;
                accuracy: number;
              }) => {
                path_pt.dTime = getDateTime(path_pt.created_date_time);
                arr?.push(
                  proj.fromLonLat([path_pt.longitude, path_pt.latitude]),
                );
              },
            );

            let tractorLocation: Feature<any> = new Feature();
            if (defaultLayer) {
              tractorLocation = defaultLayer?.getSource().getFeatureById(id);
            }
            if (!tractorLocation) {
              tractorLocation = new Feature();
            }
            oarr = [...oarr, ...arr];
            tractorLocation.setGeometry(new LineString(arr));
            let color = getPathColor(point.planner);
            const borderColor = getPathBorderColor(point.planner);
            if (key % 2) color = 'grey';
            const style = [
              new Style({
                stroke: new Stroke({
                  color: borderColor,
                  width: 8,
                }),
                zIndex: 0,
              }),
              new Style({
                stroke: new Stroke({
                  color: color,
                  width: 6,
                }),
                zIndex: 1,
              }),
            ];
            const opacityStyle = [
              new Style({
                stroke: new Stroke({
                  color: borderColor + '80',
                  width: 8,
                }),
                zIndex: 0,
              }),
              new Style({
                stroke: new Stroke({
                  color: color + '80',
                  width: 6,
                }),
                zIndex: 1,
              }),
            ];
            const pathHover = [
              new Style({
                stroke: new Stroke({
                  color: borderColor,
                  width: 10,
                }),
                zIndex: 1,
              }),
              new Style({
                stroke: new Stroke({
                  color,
                  width: 6,
                }),
                zIndex: 1,
              }),
            ];
            tractorLocation.setStyle(style);
            tractorLocation.set('style', style);
            tractorLocation.set('opacityStyle', opacityStyle);
            tractorLocation.set('hoverStyle', pathHover);
            // tractorLocation.set('name', selectedDriveActionUser);
            tractorLocation.set('name', '');
            tractorLocation.set('pointType', 'PATH');
            tractorLocation.set('operationType', keyItem);

            tractorLocation.set('drawPointsText', point);
            // tractorLocation.set('tractorId', tractorId);
            tractorLocation.set('tractor_name', tractor_name);
            tractorLocation.set('operator_name', operator_name);
            tractorLocation.set('jng', jng);
            tractorLocation.set('operationInfo', jumpAndGo);
            tractorLocation.set('drive_action_uuid', id);

            defaultLayer?.getSource().addFeature(tractorLocation);
          });
      });

    if (!all && oarr && oarr.length > 0 && mapRef.current) {
      const view = mapRef.current?.getView();
      const zoom = view.getZoom();
      if (zoom) {
        mapRef.current?.getView().setCenter(oarr[0]);
        mapRef.current?.getView().setZoom(17);
      }
    }
  };

  useEffect(() => {
    if (mapInitialized) {
      handleClick();
      handlePointerMove();
      const pointLocation: Feature<any> = new Feature();
      pointLocation.setId(999);
      const selectPointFeatureLayer = new VectorLayer({
        source: new VectorSource({
          features: [pointLocation],
        }),
        visible: true,
        style: new Style({}),
        zIndex: 111,
      });
      mapRef.current?.addLayer(selectPointFeatureLayer);
      hoverPointLayerRef.current = selectPointFeatureLayer;
    }
  }, [mapInitialized]);
  const handleOperationIndClick = (content: any) => {
    const { subsystem = '', functionality_affected = '' } =
      content.error_code_data ? content.error_code_data : {};
    content.functionality_affected = functionality_affected;
    content.subsystem = subsystem;
    content.severity = content.indicator_level;
    content.start_time = content.created_date_time
      ? getDateTimes(content.created_date_time)
      : '';
    (content.counter_measure = content?.error_code_data?.counter_measure
      ? content?.error_code_data?.counter_measure?.split(';')
      : ''),
      setErrorDetails(content);
    setShowIndicatorModel(true);
    setShowGuardrailModel(false);
    setShowTicketPopup(false);
    toggleForIndicator = true;
  };
  const handleOperationSnapClick = (content: any) => {
    if ([GUARD_RAILS, GILES, ATOM, JUMP_AND_GO].includes(content?.created_at)) {
      setGuardrailData({
        guard_rail_triggered_uuid: content.transaction_id,
        id: content.tractor_id.id,
        tractor_id: content.tractor_id.id,
        title: getDateTime(content.created_date_time),
        created_date_time: content.created_date_time,
        guard_rail_type: content.guard_rail_type,
        created_at: content.created_at,
        created_by: content.created_by,
        tractorName: content?.tractor_id?.name,
        created_type_text: content?.created_type_text,
      });
      setShowGuardrailModel(true);

      setShowIndicatorModel(false);
      setShowTicketPopup(false);
      globalGuardrailData = content;
    } else {
      // setSelectedMapSnapshot(content);
    }
    toggleForGuardRails = true;
  };
  const handleOperationTicketClick = (content: any) => {
    if (!toggleForTicket) {
      ticketDetailsById(content.ticketId);

      setShowTicketPopup(true);

      setShowIndicatorModel(false);
      setShowGuardrailModel(false);
    } else {
      setShowTicketPopup(false);
      toggleForTicket = false;
    }
  };
  const handleClick = () => {
    let click = false;
    mapRef.current?.on('click', (evt) => {
      mapRef.current?.forEachFeatureAtPixel(evt.pixel, function (feature: any) {
        const pointType = feature.get('pointType');
        if (pointType == 'SNAP') {
          if (!toggleForGuardRails) {
            const point = feature.get('point');
            const content = feature.get('content');
            handleOperationSnapClick(content);

            const view = mapRef.current?.getView();
            if (view) {
              mapRef.current?.getView().setCenter(proj.fromLonLat(point));
            }
          } else {
            // setGuardrailData(null);
            if (content?.id === globalGuardrailData?.id)
              setShowGuardrailModel(false);
            toggleForGuardRails = false;
          }
        } else if (pointType && pointType === 'OPERATION_INDICATOR') {
          if (!toggleForIndicator) {
            const point = feature.get('point');
            const content = feature.get('content');
            handleOperationIndClick(content);
            mapRef.current?.getView().setCenter(proj.fromLonLat(point));
          } else {
            setShowIndicatorModel(false);
            toggleForIndicator = false;
          }
        } else if (pointType && pointType === 'Operation_Ticket') {
          const content = feature.get('content');
          handleOperationTicketClick(content);
        } else if (pointType === 'PATH') {
          const geometry = feature.getGeometry();
          const closePoint = geometry.getClosestPoint(evt.coordinate);
          const coordinates = geometry.getCoordinates();

          for (let element = 0; element < coordinates.length - 1; element++) {
            const lineString = new LineString([
              coordinates[element],
              coordinates[element + 1],
            ]);

            if (lineString.intersectsCoordinate(closePoint)) {
              const drawPointsText = feature.get('drawPointsText');
              if (drawPointsText.path && drawPointsText.path[element]) {
                mapClick = 'map';
                const arr: any[] = [];
                const item = drawPointsText.path.slice(
                  element,
                  drawPointsText.path.length - 1,
                );
                item.map((path_pt: { longitude: number; latitude: number }) => {
                  arr?.push(
                    proj.fromLonLat([path_pt.longitude, path_pt.latitude]),
                  );
                });
                clickedFeature = new Feature();
                clickedFeature.setGeometry(new LineString(arr));
                const jng = feature.get('jng');
                setBtnClick(false);
                delay(500);
                setBtnClick(true);
                if (!click) {
                  click = true;
                  operatorName = jng.operator;
                  setDriveActionAndTime &&
                    setDriveActionAndTime({
                      fromClick: 'map',
                      created_date_time:
                        drawPointsText.path[element].created_date_time,
                      drive_action_uuid: jng.drive_action_uuid,
                    });
                  setTimeout(() => {
                    click = false;
                  }, 100);
                }
              }
            }
          }
        }
      });
    });
  };
  const handlePointerMove = () => {
    const overlay = new Overlay({
      element: container.current,
      autoPan: false,
      autoPanAnimation: {
        duration: 10,
      },
    });
    let selected: any = null;
    const handleClick = (event: any, pointType: string, contenta: any) => {
      if (pointType === 'OPERATION_INDICATOR') {
        handleOperationIndClick(contenta);
      } else if (pointType === 'Operation_Ticket') {
        handleOperationTicketClick(contenta);
      } else if (pointType === 'SNAP') {
        handleOperationSnapClick(contenta);
      }
      content.current.removeEventListener('click', handleClick);
    };
    mapRef.current?.on('pointermove', function (evt) {
      const pointLayer = hoverPointLayerRef.current;
      if (selected !== null) {
        const feat = pointLayer?.getSource().getFeatureById(999);
        feat?.setStyle(new Style({}));
        selected?.setStyle(selected?.get('style'));
        selected = null;
      }
      const hit = mapRef.current?.forEachFeatureAtPixel(
        evt.pixel,
        function (feature: any) {
          const pointType = feature.get('pointType');
          if (gMouseEntered) return false;
          const geometry = feature.getGeometry();
          const coordinates = geometry.getCoordinates();
          if (pointType === 'OPERATION_INDICATOR') {
            const contenta = feature.get('content');

            const innerHTML = getIndInfo(contenta);
            content.current.innerHTML = innerHTML;
            overlay.setPosition(coordinates);
            content &&
              content.current &&
              content.current.addEventListener('click', (e: any) =>
                handleClick(e, pointType, contenta),
              );
            if (
              !overlay.getElement()?.classList.contains('tooltip-container')
            ) {
              overlay.getElement()?.classList.add('tooltip-container');
            }
            mapRef.current?.addOverlay(overlay);
            return true;
          } else if (pointType === 'Operation_Ticket') {
            const contenta = feature.get('content');
            const innerHTML = getTicketInfo(contenta);
            content.current.innerHTML = innerHTML;
            overlay.setPosition(coordinates);
            content &&
              content.current &&
              content.current.addEventListener('click', (e: any) =>
                handleClick(e, pointType, contenta),
              );
            if (
              !overlay.getElement()?.classList.contains('tooltip-container')
            ) {
              overlay.getElement()?.classList.add('tooltip-container');
            }
            mapRef.current?.addOverlay(overlay);
            return true;
          } else if (pointType === 'SNAP') {
            const contenta = feature.get('content');
            const nameText = feature.get('name');
            const innerHTML = getSnapInfo({ ...contenta, nameText });
            content.current.innerHTML = innerHTML;
            overlay.setPosition(coordinates);

            content &&
              content.current &&
              content.current.addEventListener('click', (e: any) =>
                handleClick(e, pointType, contenta),
              );
            // overlay.setPosition(evt.coordinate);
            if (
              !overlay.getElement()?.classList.contains('tooltip-container')
            ) {
              overlay.getElement()?.classList.add('tooltip-container');
            }
            mapRef.current?.addOverlay(overlay);
            return true;
          } else if (pointType === 'PATH' && !gHasZoomed) {
            const feat = pointLayer?.getSource().getFeatureById(999);
            if (feat) {
              feat.setGeometry(
                new Point(
                  proj.fromLonLat(
                    proj.transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326'),
                  ),
                ),
              );
              feat.setStyle(
                new Style({
                  image: new CircleStyle({
                    radius: 5,
                    stroke: new Stroke({
                      color: 'blue',
                    }),
                    fill: new Fill({
                      color: '#3399CC',
                    }),
                  }),
                  zIndex: 10,
                }),
              );
              selected = feature;

              selected?.setStyle(feature.get('hoverStyle'));
              const geometry = feature.getGeometry();
              const point = geometry.getClosestPoint(evt.coordinate);
              const coordinates = geometry.getCoordinates();

              for (
                let element = 0;
                element < coordinates.length - 1;
                element++
              ) {
                if (
                  new LineString([
                    coordinates[element],
                    coordinates[element + 1],
                  ]).intersectsCoordinate(point)
                ) {
                  const drawPointsText = selected.get('drawPointsText');
                  if (drawPointsText.path && drawPointsText.path[element]) {
                    drawPointsText.path[element]['tractor_name'] =
                      selected.get('tractor_name');
                    drawPointsText.path[element]['operator_name'] =
                      selected.get('operator_name');
                    const innerHTML = getPathTimeInfo(
                      drawPointsText.path[element],
                      gSelectedTractor?.implementName,
                    );
                    selected.set('name', innerHTML);
                    content.current.innerHTML = innerHTML;
                    if (
                      !overlay
                        .getElement()
                        ?.classList.contains('tooltip-container')
                    ) {
                      overlay.getElement()?.classList.add('tooltip-container');
                    }
                    overlay.setPosition(evt.coordinate);
                    mapRef.current?.addOverlay(overlay);
                    return true;
                  }
                }
              }
            }
          }
          const coordinate = evt.coordinate;
          const name = feature.get('name');
          const dataType = feature.get('pointType');
          if (name) {
            content.current.innerHTML = '<p>' + name + '</p>';
            if (dataType !== 'PATH') {
              overlay.getElement()?.classList.remove('ol-details-popup');
            }
            overlay.setPosition(coordinate);
            mapRef.current?.addOverlay(overlay);
            return true;
          }

          return false;
        },
      );
      if (hit) {
        evt.map.getTargetElement().style.cursor = 'pointer';
      } else {
        if (!gMouseEntered) {
          overlay.setPosition(undefined);
          mapRef.current?.addOverlay(overlay);
          evt.map.getTargetElement().style.cursor = '';
        }
      }
    });
  };
  const ticketDetailsById = (ticketId: any) => {
    setToggleTicketWidget(true);
    setTicketId(ticketId);
  };
  const onCloseTicketWidget = () => {
    setToggleTicketWidget(false);
  };

  useEffect(() => {
    const init = () => {
      lastTime = Date.now();
      distance = 0;
      tractorPathLayerRef?.current?.un('postrender', moveFeature);
      allFeatures = tractorPathLayerRef?.current?.getSource().getFeatures();
      currentPath = 0;
      const startMarker = new Feature({
        type: 'icon',
        geometry: new Point([0, 0]),
      });
      startMarkerPosition = startMarker.getGeometry()?.clone();
      tractorPathLayerRef?.current?.on('postrender', moveFeature);
    };
    const init1 = () => {
      currentPath = 0;
      tractorPathLayerRef?.current?.un('postrender', moveFeature);
    };

    mapInitialized && btnClick && init();
    mapInitialized && !btnClick && init1();
    return () => {
      tractorPathLayerRef?.current?.un('postrender', moveFeature);
    };
  }, [mapInitialized, btnClick]);
  const moveFeature = (event: any) => {
    if (allFeatures && allFeatures[currentPath]) {
      if (mapClick === 'default') {
        const feature = allFeatures[currentPath];
        const geometry = feature.getGeometry();
        const speed = Number(0.1);
        const time = event.frameState.time;
        const elapsedTime = time - lastTime;
        distance = (distance + (speed * elapsedTime) / 1e6) % 2;
        lastTime = time;
        const currentCoordinate = geometry.getCoordinateAt(
          distance > 1 ? 2 - distance : distance,
        );
        startMarkerPosition?.setCoordinates(currentCoordinate);
        const vectorContext = getVectorContext(event);
        const jng = feature.get('jng');
        vectorContext.setStyle(roundStyle(jng.operator));
        startMarkerPosition && vectorContext.drawGeometry(startMarkerPosition);
        mapRef?.current?.render();
        if (distance > 1 || geometry.getCoordinates().length === 1)
          currentPath = currentPath + 1;
        if (distance > 1 && allFeatures.length - 1 === currentPath)
          tractorPathLayerRef?.current?.un('postrender', moveFeature);
      } else if (mapClick === 'map' || mapClick === 'chart') {
        const geometry = clickedFeature.getGeometry();
        const speed = Number(0.3);
        const time = event.frameState.time;
        const elapsedTime = time - lastTime;
        distance = (distance + (speed * elapsedTime) / 1e6) % 2;
        lastTime = time;
        const currentCoordinate = geometry.getCoordinateAt(
          distance > 1 ? 2 - distance : distance,
        );
        startMarkerPosition?.setCoordinates(currentCoordinate);
        const vectorContext = getVectorContext(event);

        vectorContext.setStyle(roundStyle(operatorName));
        startMarkerPosition && vectorContext.drawGeometry(startMarkerPosition);
        mapRef?.current?.render();
        if (distance > 1 || geometry.getCoordinates().length === 1) {
          currentPath = 0;
          mapClick = 'default';
        }
      }
    }
  };
  useEffect(() => {
    if (
      driveActionAndTime &&
      driveActionAndTime.drive_action_uuid &&
      driveActionAndTime.fromClick === 'chart' &&
      allFeatures &&
      allFeatures.length > 0
    ) {
      allFeatures.map((feature: any, index: number) => {
        const jng = feature.get('jng');
        const drawPointsText = feature.get('drawPointsText');

        const startTime = drawPointsText.path[0].created_date_time;
        const endTime =
          drawPointsText.path[drawPointsText.path.length - 1].created_date_time;

        if (
          driveActionAndTime.created_date_time > startTime &&
          endTime > driveActionAndTime.created_date_time
        ) {
          const startIndex = findNearestTimestamp(
            drawPointsText.path,
            driveActionAndTime.created_date_time,
          );
          mapClick = 'chart';
          const arr: any[] = [];
          const item = drawPointsText.path.slice(
            startIndex,
            drawPointsText.path.length - 1,
          );
          item.map((path_pt: { longitude: number; latitude: number }) => {
            arr?.push(proj.fromLonLat([path_pt.longitude, path_pt.latitude]));
          });
          clickedFeature = new Feature();
          clickedFeature.setGeometry(new LineString(arr));
          operatorName = jng.operator;
          setBtnClick(false);
          delay(500);
          setBtnClick(true);
        }
      });
    }
  }, [driveActionAndTime]);
  useEffect(() => {
    if (
      hasZoomed &&
      driveActionAndTime &&
      driveActionAndTime.created_date_time
    ) {
      tractorPathLayerRef.current
        ?.getSource()
        .getFeatures()
        .forEach((feature: any) => {
          if (
            feature.get('drive_action_uuid') ==
            driveActionAndTime.drive_action_uuid
          ) {
            feature.setStyle(feature.get('style'));
          } else {
            feature.setStyle(feature.get('opacityStyle'));
          }
        });
    } else {
      tractorPathLayerRef.current
        ?.getSource()
        .getFeatures()
        .forEach((feature: any) => {
          if (feature.get('drive_action_uuid')) {
            feature.setStyle(feature.get('style'));
          }
        });
    }
  }, [driveActionAndTime, hasZoomed]);
  return (
    <>
      <div className="coverage-map-video">
        <div
          ref={mapElement}
          style={{
            position: 'relative',
            // height: '100vh',
            width: '100%',
            height: '100%',
            // marginTop: 46,
          }}
          //to align right bottom of map distance
          className="posHd"
        >
          {selectedCamera === 'map' && (
            <div className="legend">
              <div className="autonomous">Key</div>
              <div className="version-vitality">
                <div className="history-hero">
                  <img
                    className="path-8134-icon"
                    loading="lazy"
                    alt=""
                    src={manualDrive}
                  />

                  <div className="autonomous1">Manual drive</div>
                </div>
                <div className="history-hero1">
                  <img className="path-8134-icon1" alt="" src={autoDrive} />

                  <div className="autonomous">Autodrive</div>
                </div>
                <div className="history-hero2">
                  <img
                    className="path-8134-icon2"
                    alt=""
                    src={operatorAssistant}
                  />

                  <div className="autonomous">Operator Assist</div>
                </div>
                <div className="history-hero3">
                  <img className="path-8134-icon3" alt="" src={idle} />

                  <div className="autonomous">Idle</div>
                </div>
              </div>
            </div>
          )}
        </div>
        {mapInitialized && (
          <div
            ref={container}
            className="ol-popup"
            style={{ display: 'block' }}
            onMouseEnter={() => setMouseEntered(true)}
            onMouseLeave={() => setMouseEntered(false)}
          >
            <div ref={content}></div>
          </div>
        )}
        {showGuardrailModel && (
          <SnapView
            snapshotFlag={showGuardrailModel}
            guardrailsList={guardrailData}
            Close={() => setShowGuardrailModel(false)}
            selectedJumpnGo={{ tractor: { name: guardrailData?.tractorName } }}
          />
        )}
        {toggleTicketWidget && (
          <OperationalAnalyticsTicketDetails
            ticketId={ticketId}
            onCloseTicketWidget={onCloseTicketWidget}
            toggleTicketWidget={toggleTicketWidget}
          />
        )}
        {showIndicatorModel && (
          <DiagnosticWidget
            isEdit={false}
            details={errorDetails}
            setIsEdit={() => console.log('1')}
            setToggleWidget={setShowIndicatorModel}
            getSeverity={getSeverity}
            getTags={getTags}
            onCreateTicket={() => console.log('2')}
            toggleWidget={showIndicatorModel}
            tractor={tractorsMap?.get(errorDetails?.tractor_id) || ''}
          />
        )}
        {/* <Button
          style={{ position: 'fixed' }}
          onClick={() => setBtnClick(!btnClick)}
        >
          {!btnClick ? 'start' : 'stop'} animation
        </Button> */}
      </div>
    </>
  );
};

export default memo(OperationTractorDetailsMap);

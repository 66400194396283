import React, { useContext, useEffect, useState } from 'react';
import Layout from 'antd/lib/layout/layout';
import { notification, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { ApplicationContext } from '../../context/AppContext';
import { getjumpandgoview, getTractorsListData } from '../../constants/Api';
import { Actions, MyJumpGoList, TractorsList } from '../../constants/types';
import {
  getDateTimes,
  getTime,
  fromToDateWrapper,
  tractorSort,
  getTractorAvailableState,
} from '../../constants/Common';

import translate from '../../locale/en_translate.json';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import Jumpandgoctions from './Jumpandgoctions';
import CustomSelect from '../common/CustomSelect2';
import CustomDate from '../common/CustomDate';
import usePaginate from '../../hooks/usePaginate';

import 'react-datepicker/dist/react-datepicker.css';
import './style.css';
import { GILES } from '../../constants/constant';

interface TractorResponseType {
  label: string;
  value: number | string | boolean;
  disabled: boolean;
  index: number | string;
  isAvailable?: string;
}

const Jumpandgo: React.FC = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize] = useState<any>(25);
  const [tractors, setTractorsData] = useState<TractorResponseType[]>([]);
  const [fromDate, setFromDate] = useState<any>(
    moment().subtract(1, 'day').toDate(),
  );
  const [toDate, setToDate] = useState<any>(moment().toDate());
  const [tractorId, setTractorId] = useState<string>();
  const [actionmodel, setactionmodel] = useState(false);
  const [actiondata, setactiondata] = useState<Actions>();
  const [loader, setLoader] = useState<boolean>(false);
  const [totalcount, settotalcount] = useState<any>();

  const { filterData, setData, activity, hasMore } = usePaginate();

  const columns = [
    {
      title: `${t(translate.baseStation.tractorName)}`,
      dataIndex: 'name',
      sorter: (a: { name: string }, b: { name: string }) =>
        a.name.localeCompare(b.name),
      // width: '12%',
      showSorterTooltip: false,
    },
    {
      title: `${t(translate.Jumpandgo.operator)}`,
      dataIndex: 'fullname',
      // width: '20%',
    },
    {
      title: `${t(translate.MyJumpAndGo.createdAt)}`,
      dataIndex: 'createdAt',
      // width: '15%',
    },
    {
      title: `${t(translate.taskmaster.starttime)}`,
      dataIndex: 'created_date_time',
      // width: '240px',
    },
    {
      title: `${t(translate.Jumpandgo.finishTime)}`,
      dataIndex: 'modified_date_time',
      // width: '240px',
    },
    {
      title: `${t(translate.MyJumpAndGo.duration)}`,
      dataIndex: 'duration',
    },
  ];

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      const init = async () => {
        await getjumpandgolist();
      };
      init();
    }
  }, [userDetails, pageNumber, pageSize, tractorId, fromDate, toDate]);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getTractorsList();
    }
  }, [userDetails]);

  const getjumpandgolist = async () => {
    try {
      setLoader(true);
      const response: any = await getjumpandgoview(
        userDetails.organization.api_url,
        pageNumber,
        pageSize,
        tractorId ? tractorId : '',
        fromDate ? fromDate.getTime() : '',
        toDate ? toDate.getTime() : '',
      );
      settotalcount(response._metadata.total_records_count);
      const { records } = response;
      let data = Array.isArray(records) ? records : [];

      data = data.map((record: MyJumpGoList) =>
        Object.assign({
          id: Math.random(),
          createdAt:
            record.drive_action_created_at === GILES
              ? 'SMARTSCREEN'
              : record.drive_action_created_at,
          operatorid: record.operator ? record.operator.id : '',
          name: record.tractor.name ? record.tractor.name : '-',
          fullname: record.operator
            ? record.operator.first_name + ' ' + record.operator.last_name
            : '-',
          created_date_time: record.created_date_time
            ? getDateTimes(record.created_date_time)
            : '-',
          modified_date_time: record.modified_date_time
            ? getDateTimes(record.modified_date_time)
            : '-',
          duration: record.duration_in_seconds
            ? getTime(record.duration_in_seconds)
            : '-',
          data: record,
        }),
      );
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleLoadMore = () => {
    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const getTractorsList = async () => {
    try {
      const tractor: { records: TractorsList[] } = await getTractorsListData(
        userDetails.organization.api_url,
        userDetails.organization_id,
        pageNumber,
      );
      const data =
        tractor && tractor.records && tractor.records.length > 0
          ? tractor.records
          : [];
      const tractorData: TractorResponseType[] = data.map((d: TractorsList) => {
        return {
          label: d.name,
          value: d.id,
          disabled: false,
          index: d.id,
          id: d.id,
          connection: d.connection,
          isOnline: d.connection === 'online' ? true : false,
          isAvailable: getTractorAvailableState(d.connection, d?.heartbeat),
        };
      });
      const arr = tractorSort(tractorData, 'label');
      setTractorsData(arr);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const handleSelect = async (e: any) => {
    activity.current = 'filter';
    setPageNumber(1);
    setTractorId(e);
  };

  const onDateRangeChange = (dates: any) => {
    activity.current = 'filter';
    if (dates) {
      setPageNumber(1);
      const [from, to] = fromToDateWrapper(dates);
      setFromDate(from.toDate());
      setToDate(to.toDate());
    } else {
      setFromDate(null);
      setFromDate(null);
    }
  };

  return (
    <Layout>
      <div className="mainContent">
        <div className="tblContainer viewportContainer">
          <Row>
            <div className="common_wrapper wWidget_wrapper ddddd">
              <div className="filters_card personnelCard">
                <CustomSelect
                  label="Tractor"
                  cssClass="min_width"
                  value={tractorId}
                  setValue={(id: any) => {
                    handleSelect(id);
                  }}
                  options={tractors.map((item) =>
                    Object.assign({
                      id: item.index,
                      name: item.label,
                      isAvailable: item.isAvailable,
                    }),
                  )}
                  optionKey="id"
                  optionDisplay="name"
                  isAll={true}
                />
                <CustomDate
                  label="Select Date"
                  cssClass="min_width"
                  value={[moment(fromDate), moment(toDate)]}
                  onChange={(dates: any) => {
                    onDateRangeChange(dates);
                  }}
                  format="YYYY-MM-DD"
                />
              </div>
              <div>
                <div className="common_table">
                  <InfiniteScrollTable
                    columns={columns}
                    loading={loader}
                    hasMore={hasMore}
                    filterData={filterData}
                    totalcount={totalcount}
                    handleLoadMore={handleLoadMore}
                    filename="Jump&Go"
                    onRowClick={(record: any) => {
                      setactiondata(record.data);
                      setactionmodel(true);
                    }}
                  />
                </div>
              </div>
              <div>
                {actionmodel && (
                  <Jumpandgoctions
                    showModel={actionmodel}
                    closeModel={() => setactionmodel(false)}
                    actiondata={actiondata as Actions}
                  />
                )}
              </div>
            </div>
          </Row>
        </div>
      </div>
    </Layout>
  );
};

export default Jumpandgo;

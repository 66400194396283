/* eslint-disable no-console */
import React, { memo, useState, useRef, useEffect, useContext } from 'react';
import { Card, Row, Space, Button, Modal, Menu } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import './style.css';
import {
  CaretRightOutlined,
  PauseOutlined,
  MoreOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import { getDateTime, sortCameraViews } from '../../constants/Common';
import { getVideoURL } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import RoutesConstants from '../../routes/RoutesConstant';
// import { track } from '../../util/logger';
import AppLoader from '../common/AppLoader';
import { CamTypes } from '../../constants/types';
import { TOGGLE_VIDEO_WIDGET } from '../../context/actions';
import CameraGrid4 from '../map/CameraGrid4';
import BarHandler from './BarHandler';

const VideoGridModal: React.FC = () => {
  const { userDetails, APPReducer } = useContext(ApplicationContext);
  const [state, dispatch] = APPReducer;
  const { videoWidgetData, tractorsMap } = state;
  const { tractorId, videoTime } = videoWidgetData;
  const tractor = tractorsMap.get(tractorId);
  const ini = useRef(false);
  const iv = useRef<any>();
  const [videos, setVideos] = useState<any>([]);
  const [playState, setPlayState] = useState(true);
  const [vLength, setVLength] = useState<number>(300);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [speedMenu, setSpeedMenu] = useState(false);
  const [current, setCurrent] = useState('1');
  const [cameras, setCameras] = useState<string[]>([]);

  useEffect(() => {
    return () => {
      setVideos([]);
      setPlayState(true);
      setVLength(0);
      setRefresh(false);
      setShowLoader(false);
      clearInterval(iv.current);
    };
  }, []);

  const errorRef = useRef<any>({
    front: false,
    rear: false,
    pto: false,
    front_left: false,
    front_right: false,
  });

  const videoRef = useRef<CamTypes>({
    front: undefined,
    rear: undefined,
    pto: undefined,
    front_left: undefined,
    front_right: undefined,
  });

  const checkVideos = () => {
    return Object.values(videoRef.current).map((item) => item !== undefined);
  };

  const loadDuration = () => {
    if (checkVideos().includes(true) && ini.current) {
      const durations = Object.values(videoRef.current)
        .filter((item) => item)
        .map((item: any) => {
          const { player } = item?.getState();
          return Math.round(player.duration);
        });
      setVLength(Math.max(...durations));
    }
  };

  const play = () => {
    setPlayState(false);
    Object.values(videoRef.current).map((item: any) => {
      if (item) {
        item.play();
      }
    });
  };

  const pause = () => {
    setPlayState(true);
    Object.values(videoRef.current).map((item: any) => {
      if (item) {
        item.pause();
      }
    });
  };

  const playbackRate = (steps: number) => {
    setPlayState(true);
    Object.values(videoRef.current).map((item: any) => {
      if (item) {
        item.video.video.playbackRate = steps;
        // const { player } = item.getState();
        // player.playbackRate = player.playbackRate + steps;
        // item.pause();
      }
    });
  };

  const delay = (ms: number) =>
    new Promise<void>((resolve) => setTimeout(() => resolve(), ms));

  const seek = async (seconds: number) => {
    pause();
    Object.values(videoRef.current).map((item: any) => {
      if (item) {
        item.seek(seconds);
        item.pause();
      }
    });
    await delay(3000);
    play();
  };

  const getVideoData = async () => {
    try {
      pause();
      ini.current = false;
      setShowLoader(true);
      let response = await getVideoURL(
        userDetails.organization.api_url,
        userDetails.organization.farm.id,
        tractorId,
        videoTime,
      );
      response = sortCameraViews(response, 'direction', 'videos');
      const cams = response.reduce(
        (a: any, data: any) => [...a, data.direction],
        [],
      );
      setCameras(cams);
      response = response
        .map((item: any) => {
          item.direction = item.direction.replace('-', '_');
          return item;
        })
        .filter((item: any) =>
          ['front', 'rear', 'pto', 'front_left', 'front_right'].includes(
            item.direction,
          ),
        );
      setVideos(response);
    } finally {
      setShowLoader(false);
      setRefresh(!refresh);
    }
  };

  const load = () => {
    Object.values(videoRef.current).map((item: any) => {
      if (item) {
        item.load();
      }
    });
  };

  const forward = (seconds: number) => {
    Object.values(videoRef.current).map((item: any) => {
      if (item) {
        item.video.video.currentTime = item.video.video.currentTime + seconds;
        item.seek(item.video.video.currentTime);
      }
    });
  };

  const openFullscreen = (videoUrl: any) => {
    videoUrl &&
      window.open(
        `${RoutesConstants.VideoPop}?videoUrl=${videoUrl}`,
        `Video URL`,
        'max-width: 100%',
      );
  };

  // const onError = (face: string) => {
  //   if (videoRef.current[face]) {
  //     const { player } = videoRef.current[face]?.getState();
  //     errorRef.current[face] = player.error ? true : false;
  //   }
  // };

  // const setMuted = (muted) => {
  //   Object.values(videoRef.current).map((item) => {
  //     if (item instanceof Player) {
  //       item.muted = muted;
  //     }
  //   });
  // };

  // const changeVolume = (steps:) => {
  //   Object.values(videoRef.current).map((item: Player) => {
  //     if (item instanceof Player) {
  //       item.volume = item.volume + steps;
  //     }
  //   });
  // };

  useEffect(() => {
    if (userDetails && userDetails.organization && videoTime && tractorId) {
      getVideoData();
    }
  }, [userDetails, videoTime]);

  const checkReadyState = () => {
    ini.current = false;
    if (!checkVideos().includes(true)) return;
    if (videoTime === 1653514589176) seek(45);
    const readyStates = Object.values(videoRef.current)
      .filter((item) => item)
      .every((item: any) => {
        if (item?.getState()) {
          const { player } = item?.getState();
          errorRef.current[item.direction] = Boolean(player?.error);
          return player.readyState === 4;
        }
        return false;
      });
    if (readyStates) {
      ini.current = true;
      setRefresh(!refresh);
      clearInterval(iv.current);
    }
  };

  useEffect(() => {
    if (videos.length)
      iv.current = setInterval(() => {
        checkReadyState();
      }, 1000);
    return () => clearInterval(iv.current);
  }, [videos]);

  useEffect(() => {
    loadDuration();
    return () => {
      setRefresh(false);
    };
  }, [refresh]);

  const Cancel = () => {
    dispatch({
      type: TOGGLE_VIDEO_WIDGET,
      payload: {
        toggle: false,
      },
    });
  };

  const moreOptions = () => {
    setOpenMenu(true);
  };
  const onClickSpeedMenu = () => {
    setOpenMenu(false);
    setSpeedMenu(true);
  };

  const selectSpeed = (speed: number, e: any) => {
    setCurrent(e.key);
    playbackRate(speed);
    setSpeedMenu(false);
  };

  return (
    <>
      <Modal
        title={''}
        visible={videoWidgetData?.toggle}
        className="thubnailVideosFull"
        onCancel={Cancel}
        footer={null}
        width="95vw"
      >
        <Content>
          <Row>
            <Card
              title={tractor?.name}
              bordered={true}
              className="dbWidget videoDbCon"
              extra={
                <Space
                  className="mt0"
                  style={{ marginBottom: 0, position: 'relative' }}
                >
                  <p style={{ fontFamily: 'Montserrat-Medium' }}>
                    <span>{videoTime && getDateTime(videoTime)}</span>
                  </p>
                </Space>
              }
            >
              <CameraGrid4
                videos={videos}
                // handleProgress={handleProgress}
                videoRef={videoRef}
                errorRef={errorRef}
                showLoader={showLoader}
                cameras={cameras}
                playState={playState}
                play={play}
              />
              {videos.length > 0 && (
                <div className="barDisplay">
                  <>
                    <Button
                      style={{ display: 'block' }}
                      className="okBtn"
                      onClick={() => (playState ? play() : pause())}
                    >
                      {playState ? <CaretRightOutlined /> : <PauseOutlined />}
                    </Button>
                    <div className="video_progress">
                      <div className="video_progress__filled"></div>
                    </div>
                    <div
                      style={{
                        width: '140px',
                        fontFamily: 'Montserrat-SemiBold',
                      }}
                    >
                      {document.getElementsByClassName('video_progress')
                        .length && (
                        <BarHandler
                          videoRef={videoRef}
                          seek={seek}
                          vLength={vLength}
                        />
                      )}
                    </div>
                    <div className="moreDiv">
                      <div onClick={moreOptions}>
                        <MoreOutlined />
                      </div>
                      {openMenu && (
                        <Menu>
                          <Menu.Item
                            key="playback speed"
                            onClick={onClickSpeedMenu}
                          >
                            Playback Speed
                          </Menu.Item>
                        </Menu>
                      )}
                      {speedMenu && (
                        <Menu className="">
                          <Menu.Item
                            key="0.25"
                            onClick={(e) => selectSpeed(0.25, e)}
                          >
                            0.25
                            {current === '0.25' && (
                              <CheckOutlined className="checkmark" />
                            )}
                          </Menu.Item>
                          <Menu.Item
                            key="0.5"
                            onClick={(e) => selectSpeed(0.5, e)}
                          >
                            0.5
                            {current === '0.5' && (
                              <CheckOutlined className="checkmark" />
                            )}
                          </Menu.Item>
                          <Menu.Item
                            key="0.75"
                            onClick={(e) => selectSpeed(0.75, e)}
                          >
                            0.75
                            {current === '0.75' && (
                              <CheckOutlined className="checkmark" />
                            )}
                          </Menu.Item>
                          <Menu.Item key="1" onClick={(e) => selectSpeed(1, e)}>
                            Normal
                            {current === '1' && (
                              <CheckOutlined className="checkmark" />
                            )}
                          </Menu.Item>
                          <Menu.Item
                            key="1.25"
                            onClick={(e) => selectSpeed(1.25, e)}
                          >
                            1.25
                            {current === '1.25' && (
                              <CheckOutlined className="checkmark" />
                            )}
                          </Menu.Item>
                          <Menu.Item
                            key="1.5"
                            onClick={(e) => selectSpeed(1.5, e)}
                          >
                            1.5
                            {current === '1.5' && (
                              <CheckOutlined className="checkmark" />
                            )}
                          </Menu.Item>
                          <Menu.Item
                            key="1.75"
                            onClick={(e) => selectSpeed(1.75, e)}
                          >
                            1.75
                            {current === '1.75' && (
                              <CheckOutlined className="checkmark" />
                            )}
                          </Menu.Item>
                          <Menu.Item key="2" onClick={(e) => selectSpeed(2, e)}>
                            2
                            {current === '2' && (
                              <CheckOutlined className="checkmark" />
                            )}
                          </Menu.Item>
                        </Menu>
                      )}
                    </div>
                  </>
                </div>
              )}
              <AppLoader loader={showLoader} />
            </Card>
          </Row>
        </Content>
      </Modal>
    </>
  );
};

export default VideoGridModal;

/* eslint-disable no-console */
import { LeftOutlined, SearchOutlined } from '@ant-design/icons';
import { Collapse, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import locate_icon from './../../../assets/images/locate_icon.svg';

import './ADashboard.css';

import {
  Organization,
  Tractor,
  dairy,
  filterByValue,
  getFirstChars,
  vineYard,
} from '../Common';
import { AdminHeartBeat, OrgTractor } from '../types';
import ImpactDashboard from './ImpactDashboard';
import TractorDetails from './TractorDetails';
import AdminLoader from '../AdminLoader';

const { Panel } = Collapse;
interface Props {
  loader: boolean;
  showError: boolean;
  orgList: OrgTractor[];
  selectedOrg: number;
  setSelectedOrg: (orgId: number) => void;
  setSelectedTractorId: (tractorId: number) => void;
  setRecenterOrgId: (orgId: number) => void;
  setRecenterTractorId: (tractorId: number) => void;
  setRecenterTrigger: (trigger: boolean) => void;
  recenterTrigger: boolean;
  tractorList: OrgTractor[];
  selectedTractorId: number;
  setHeartbeat: (hb: AdminHeartBeat) => void;
}
const orgList: React.FC<Props> = ({
  loader,
  showError,
  orgList,
  selectedOrg,
  setSelectedOrg,
  setSelectedTractorId,
  setRecenterOrgId,
  setRecenterTractorId,
  setRecenterTrigger,
  recenterTrigger,
  tractorList,
  selectedTractorId,
  setHeartbeat,
}: Props) => {
  const [filterList, setFilterList] = useState<OrgTractor[]>([]);
  useEffect(() => {
    setFilterList([...orgList, ...tractorList]);
  }, [orgList, tractorList]);
  const handleOrgSelect = (id: number, category: string, orgId?: number) => {
    if (category === Organization) {
      setSelectedTractorId(0);
      if (selectedOrg === 0 || selectedOrg !== id) {
        setSelectedOrg(id);
        setRecenterOrgId(id);
      } else {
        setSelectedOrg(0);
        setRecenterOrgId(0);
      }
    } else {
      if (selectedTractorId === 0 || selectedTractorId !== id) {
        setSelectedTractorId(id);
        setRecenterTractorId(id);
        orgId && setSelectedOrg(orgId);
      } else {
        setSelectedTractorId(0);
        setRecenterTractorId(0);
        setSelectedOrg(0);
      }
    }
  };
  const onSearch = (searchText: string) => {
    if (searchText) {
      const list = filterByValue(
        [...orgList, ...tractorList],
        searchText.toLowerCase(),
      );
      setFilterList(list);
    } else {
      setFilterList([...orgList, ...tractorList]);
    }
  };

  return (
    <div className="ad_cards_Blk">
      <div className="ad_searchMapBlk">
        <SearchOutlined />
        <Input
          placeholder="Search organization or tractor"
          autoComplete="off"
          className="fListSearch"
          onChange={(e) => onSearch(e.target.value)}
        />
      </div>
      <div className="ad_operationsBlk">
        <Collapse
          expandIconPosition="right"
          defaultActiveKey={['1']}
          className="ad_parentAccordion parentIcon"
          expandIcon={({ isActive }) => (
            <LeftOutlined rotate={isActive ? 360 : 270} />
          )}
        >
          <Panel
            key="1"
            header="Organizations And Tractors"
            className="operationsTitle"
          >
            <div className="ad_cardBlk_cont">
              <div>
                {filterList &&
                  filterList.map((orgTractor: OrgTractor) => (
                    <>
                      <div
                        className={`ad_cardBlk ${
                          selectedOrg === orgTractor.id ||
                          selectedTractorId === orgTractor.id
                            ? 'selected'
                            : ''
                        }`}
                        onClick={() => {
                          handleOrgSelect(
                            orgTractor.id,
                            orgTractor.category,
                            orgTractor?.organization_id,
                          );
                        }}
                        key={orgTractor.id}
                      >
                        <div className="ad_card_row">
                          <div
                            className={`ad_org_title ${
                              orgTractor.category === Organization &&
                              orgTractor.crop_type.toLocaleLowerCase() ===
                                vineYard
                                ? 'vineyardColor'
                                : ''
                            }
                            ${
                              orgTractor.category === Organization &&
                              orgTractor.crop_type.toLocaleLowerCase() === dairy
                                ? 'dairyColor'
                                : ''
                            }`}
                          >
                            {getFirstChars(orgTractor.name)}
                          </div>
                          <div className="ad_org_details">
                            <h2 className="ad_org_name">{orgTractor.name}</h2>
                            <div className="ad_org_location">
                              {orgTractor.city}
                            </div>
                            <div className="ad_org_country">
                              {' '}
                              {orgTractor.country}
                            </div>
                          </div>
                          <div className="ad_locate_icon">
                            <img
                              src={locate_icon}
                              alt=""
                              onClick={(e: any) => {
                                e.stopPropagation();
                                // setRecenterTrigger(!recenterTrigger);
                                if (orgTractor.category === Organization) {
                                  setRecenterOrgId(0);
                                  setTimeout(() => {
                                    setRecenterOrgId(orgTractor.id);
                                  }, 100);
                                }
                                if (orgTractor.category === Tractor) {
                                  setRecenterTractorId(0);
                                  setTimeout(() => {
                                    setRecenterTractorId(orgTractor.id);
                                  }, 100);
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                {!loader && showError && filterList.length === 0 && (
                  <div className="no-inuse-tractors">No data found</div>
                )}
              </div>
              <AdminLoader loader={loader} />
            </div>
          </Panel>
        </Collapse>
        {selectedOrg !== 0 && (
          <ImpactDashboard
            orgList={orgList}
            selectedOrg={selectedOrg}
            setSelectedTractorId={setSelectedTractorId}
            setSelectedOrg={setSelectedOrg}
            setRecenterOrgId={setRecenterOrgId}
            setRecenterTractorId={setRecenterTractorId}
          />
        )}
        {selectedOrg !== 0 && selectedTractorId !== 0 && (
          <TractorDetails
            selectedTractorId={selectedTractorId as number}
            setSelectedTractorId={setSelectedTractorId}
            selectedOrg={selectedOrg}
            setHeartbeat={setHeartbeat}
          />
        )}
      </div>
    </div>
  );
};

export default orgList;

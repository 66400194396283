// Import necessary packages
import React, { useEffect, useMemo, useRef } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import AppLoader from '../common/AppLoader';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { getCurrentResolution } from '../operationalAnalytics/common';
import loading_logo from '../../assets/images/loading_logo.gif';

// Apply amCharts themes
am4core.useTheme(am4themes_animated);

const dataTypes: any = {
  time: 'hours',
  area: 'acres',
};
interface Props {
  data: any;
  loader: boolean;
  chartId: string;
  labels: string[];
  barColors: string[];
  yTitle: string;
  onClick?: (operator: string, data?: any) => void;
  height?: string;
  dataType?: string;
  className?: string;
  type?: string;
}

const StackedBarChartWithSlider: React.FC<Props> = ({
  data,
  loader,
  chartId,
  labels,
  barColors,
  yTitle,
  onClick,
  height,
  dataType,
  className,
  type,
}) => {
  // UseEffect to create and destroy the chart
  const screens = useBreakpoint();
  const resolution = getCurrentResolution(screens);

  // const getDataType = useMemo(() => {
  //   return dataType ? dataTypes[dataType] : 'hours';
  // }, [dataType]);

  useEffect(() => {
    // Create chart instance

    const chart: am4charts.XYChart = am4core.create(chartId, am4charts.XYChart);
    chart.logo.disabled = true;
    chart.paddingTop = 44;
    if (data?.length > 0) chart.data = data;

    // Create axes
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'category';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.disabled = true;
    chart.paddingBottom = 0;
    chart.marginBottom = 0;

    chart.zoomOutButton = new am4core.ZoomOutButton();
    chart.zoomOutButton.hide();

    // chart.scrollbarX = new am4core.Scrollbar();
    if (type === 'operationscoverage' && data.length > 4) {
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.marginBottom = 0; // Set the margin from the top
      chart.scrollbarX.parent = chart.bottomAxesContainer;
      chart.scrollbarX.startGrip.disabled = true;
      chart.scrollbarX.endGrip.disabled = true;
      chart.scrollbarX.minHeight = 3;
      chart.scrollbarX.start = 0;
      chart.scrollbarX.end = 0.75;
    } else if (data?.length > 7 && data?.length <= 12) {
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.marginBottom = 0; // Set the margin from the top
      chart.scrollbarX.parent = chart.bottomAxesContainer;
      chart.scrollbarX.startGrip.disabled = true;
      chart.scrollbarX.endGrip.disabled = true;
      chart.scrollbarX.minHeight = 3;
      chart.scrollbarX.start = 0;
      chart.scrollbarX.end = 0.6;
    } else if (data?.length > 12 && data?.length <= 17) {
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.marginBottom = 0; // Set the margin from the top
      chart.scrollbarX.parent = chart.bottomAxesContainer;
      chart.scrollbarX.startGrip.disabled = true;
      chart.scrollbarX.endGrip.disabled = true;
      chart.scrollbarX.minHeight = 3;
      chart.scrollbarX.start = 0;
      chart.scrollbarX.end = 0.4;
    } else if (data?.length > 17 && data.length <= 21) {
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.marginBottom = 0; // Set the margin from the top
      chart.scrollbarX.parent = chart.bottomAxesContainer;
      chart.scrollbarX.startGrip.disabled = true;
      chart.scrollbarX.endGrip.disabled = true;
      chart.scrollbarX.minHeight = 3;
      chart.scrollbarX.start = 0;
      chart.scrollbarX.end = 0.3;
    } else if (data?.length > 21) {
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.marginBottom = 0; // Set the margin from the top
      chart.scrollbarX.parent = chart.bottomAxesContainer;
      chart.scrollbarX.startGrip.disabled = true;
      chart.scrollbarX.endGrip.disabled = true;
      chart.scrollbarX.minHeight = 3;
      chart.scrollbarX.start = 0;
      chart.scrollbarX.end = 0.2;
    }

    chart.responsive.enabled = true;
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    if (yTitle) valueAxis.title.text = yTitle;
    categoryAxis.renderer.labels.template.fontSize = 10;
    categoryAxis.renderer.labels.template.fill = am4core.color('#1F1F1F');
    categoryAxis.renderer.labels.template.fontFamily = 'Montserrat-Regular';
    categoryAxis.title.fontSize = 10;
    categoryAxis.title.fill = am4core.color('#333333');
    categoryAxis.title.fontFamily = 'Montserrat-Medium';
    categoryAxis.renderer.minGridDistance = 20;
    // categoryAxis.renderer.labels.template.horizontalCenter = 'middle';
    // categoryAxis.renderer.labels.template.verticalCenter = 'middle';
    // categoryAxis.renderer.cellStartLocation = 0.1;
    // categoryAxis.renderer.cellEndLocation = 0.65;

    valueAxis.renderer.labels.template.fontSize = 10;
    valueAxis.renderer.labels.template.fill = am4core.color('#5c5c5c');
    valueAxis.renderer.labels.template.fontFamily = 'Montserrat-Regular';
    valueAxis.title.fontSize = 10;
    valueAxis.title.fill = am4core.color('#333333');
    valueAxis.title.fontFamily = 'Montserrat-Medium';
    valueAxis.renderer.labels.template.fontSize = 10;

    categoryAxis.renderer.grid.template.strokeOpacity = 0;
    valueAxis.renderer.grid.template.strokeOpacity = 0.1;
    categoryAxis.renderer.labels.template.paddingBottom = 0;
    categoryAxis.renderer.labels.template.marginBottom = 0;

    function cornerRadius(radius: any, item: any) {
      const dataItem = item.dataItem;

      // Find the last series in this stack
      let lastSeries;
      chart.series.each(function (series) {
        if (
          series.dataFields.valueY &&
          dataItem.dataContext[series.dataFields.valueY] &&
          !series.isHidden &&
          !series.isHiding
        ) {
          lastSeries = series;
        }
      });

      // If current series is the one, use rounded corner
      return dataItem.component == lastSeries ? 3 : radius;
    }

    const createSeries = (
      field: any,
      name: any,
      color: string,
      borderBottom?: boolean,
      time?: string,
    ) => {
      // Create series
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.columns.template.togglable = true;
      series.columns.template.stroke = am4core.color('#FFFFFF');
      series.columns.template.strokeWidth = 1;
      series.columns.template.column.adapter.add(
        'cornerRadiusTopLeft',
        cornerRadius,
      );
      series.columns.template.column.adapter.add(
        'cornerRadiusTopRight',
        cornerRadius,
      );

      series.dataFields.valueY = field;
      series.dataFields.categoryX = 'category';
      series.name = name;
      series.stacked = true;

      if (onClick) {
        series.cursorOverStyle = am4core.MouseCursorStyle.pointer;
        series.columns.template.events.on('hit', (ev: any) => {
          // eslint-disable-next-line no-console
          onClick(ev.target.dataItem.categoryX, ev.target.dataItem.dataContext);
        });
      }
      if (time) {
        series.columns.template.tooltipHTML = `
        <div style="background-color: #99999C; font-size: 12px; padding: 8px, 12px, 8px, 12px; align-items: center, font-weight: 600, font-family: Montserrat">
        <strong>{${time}}</strong>
        </div>
        `;
      } else {
        series.columns.template.tooltipHTML = `
        <div style="background-color: #99999C; font-size: 12px; padding: 8px, 12px, 8px, 12px; align-items: center, font-weight: 600, font-family: Montserrat">
        <strong>{valueY}</strong>
        </div>
        `;
      }

      if (series.tooltip) {
        series.tooltip.pointerOrientation = 'down';
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fill = am4core.color('#99999C');
        series.tooltip.label.fill = am4core.color('#fff');
        series.columns.template.tooltipPosition = 'pointer';
        series.tooltip.background.stroke = am4core.color('#99999C');
        // series.tooltip.getFillFromObject = false;
        // series.tooltip.background.fill = am4core.color('#fff');
      }
      series.columns.template.tooltipText = '{categoryX}: [bold]{valueY}[/]';
      series.columns.template.fill = am4core.color(color);
      series.strokeWidth = 0;

      if (borderBottom) {
        series.columns.template.column.cornerRadiusBottomLeft = 3;
        series.columns.template.column.cornerRadiusBottomRight = 3;
      }

      // series.columns.template.width = am4core.percent(40);
      series.columns.template.width = 14;
    };
    if (data?.length > 0) {
      chart.events.on('beforedatavalidated', () => {
        // Use adapter to update label text with total hours
        categoryAxis.renderer.labels.template.adapter.add(
          'html',
          (html: any, target: any) => {
            const category = target.dataItem?.category;
            const total = chart.data.find(
              (item) => item.category === target.dataItem.category,
            )?.total;
            if (dataType === 'time') {
              return `<div class="category">${category}<div> <div class="total-hours">${total}</div>`;
            } else {
              return `<div class="category">${category}<div> <div class="total-hours">${total} acres</div>`;
            }
          },
        );
      });
    }

    labels[0] &&
      createSeries(
        'value1',
        labels[0],
        barColors[0],
        true,
        dataType === 'time' ? 'time1' : '',
      );
    labels[1] &&
      createSeries(
        'value2',
        labels[1],
        barColors[1],
        false,
        dataType === 'time' ? 'time2' : '',
      );
    labels[2] &&
      createSeries(
        'value3',
        labels[2],
        barColors[2],
        false,
        dataType === 'time' ? 'time3' : '',
      );
    labels[3] &&
      createSeries(
        'value4',
        labels[3],
        barColors[3],
        false,
        dataType === 'time' ? 'time4' : '',
      );

    // // Legend
    // chart.legend = new am4charts.Legend();
    // chart.legend.useDefaultMarker = true;
    const markerTemplate = chart?.legend?.markers?.template;
    if (markerTemplate) {
      markerTemplate.width = 10;
      markerTemplate.height = 10;
      chart.legend.position = 'top';
      chart.legend.align = 'center';
      chart.legend.fontFamily = 'Montserrat-Regular';
      chart.legend.fontSize = 10;
    }

    let indicator: any;
    let indicatorInterval: any;

    function showIndicator(chart: any) {
      if (!indicator) {
        indicator = chart.tooltipContainer.createChild(am4core.Container);
        indicator.background.fill = am4core.color('#fff');
        indicator.background.fillOpacity = 0.8;
        indicator.width = am4core.percent(100);
        indicator.height = am4core.percent(100);

        // const indicatorLabel = indicator.createChild(am4core.Label);
        // indicatorLabel.text = 'Loading...';
        // indicatorLabel.align = 'center';
        // indicatorLabel.valign = 'middle';
        // indicatorLabel.fontSize = 20;
        // indicatorLabel.dy = 50;

        // eslint-disable-next-line no-var
        var hourglass = indicator.createChild(am4core.Image);
        hourglass.href = loading_logo;
        hourglass.align = 'center';
        hourglass.valign = 'middle';
        hourglass.horizontalCenter = 'middle';
        hourglass.verticalCenter = 'middle';
        hourglass.scale = 0.7;
      }

      indicator.hide(0);
      indicator.show();

      // clearInterval(indicatorInterval);
      // indicatorInterval = setInterval(function () {
      //   hourglass?.animate(
      //     [
      //       {
      //         from: 0,
      //         to: 360,
      //         property: 'rotation',
      //       },
      //     ],
      //     2000,
      //   );
      // }, 3000);
    }

    function hideIndicator() {
      indicator?.hide();
      clearInterval(indicatorInterval);
    }

    loader ? showIndicator(chart) : hideIndicator();

    // Cleanup function to destroy the chart
    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, [data, loader, dataType]);

  return (
    <div>
      <div
        className={className}
        // className="mowing-chart"
        id={chartId}
        style={{
          width: '100%',
          // height: height ?? resolution === 'lg' ? '304px' : '304px',
        }}
      ></div>
    </div>
  );
};

export default StackedBarChartWithSlider;

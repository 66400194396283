/* eslint-disable react/display-name */
import React, { useContext, useState, useEffect } from 'react';
import { Row, notification, Tooltip } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import { ApplicationContext } from '../../../context/AppContext';
import { fleetObject } from '../../../constants/types';
import { fromToDateWrapper, initScroller } from '../../../constants/Common';
import { useTranslation } from 'react-i18next';
import translate from '../../../locale/en_translate.json';
import 'react-datepicker/dist/react-datepicker.css';
import { getIndicatorImages } from '../../basestation/Common';
import InfiniteScrollTable from '../../common/InfiniteScrollTable';
import moment from 'moment';
import CustomSelect from '../../common/CustomSelect2';
import CustomDate from '../../common/CustomDate';
import {
  getDealerIndicatorHistory,
  getOrgDropdown,
  getTractorsList,
} from '../api';
import CustomMultiSelect from '../../common/CustomMultiSelect';
import './style.css';
import usePaginate from '../../../hooks/usePaginate';
import { mapIndicatorsData } from '../Common';
import { getSeverity } from '../../fleethealth/Diagnostics';

interface StatusListType {
  name: string;
  value: string;
}

interface LevelType {
  number: string;
  value: string;
}

interface TractorResponseType {
  label: string;
  value: number | string | boolean;
  disabled: boolean;
  index: number | string;
  isAvailable?: string;
}

const Indicators: React.FC = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPagesize] = useState<any>(25);
  const [indicatorKey, setIndicatorKey] = useState<any>();
  const [fromDateTime, setFromDateTime] = useState<any>(
    moment().subtract(1, 'day').toDate(),
  );
  const [toDateTime, setToDateTime] = useState<any>(moment().toDate());
  const [totalCount, setTotalCount] = useState(0);
  const [loader, setLoader] = useState<boolean>(false);
  const statusList: StatusListType[] = [
    { name: 'Tractor_Communication', value: 'Tractor_Communication' },
    { name: 'Tractor_Automation', value: 'Tractor_Automation' },
    { name: 'Tractor_Drivability', value: 'Tractor_Drivability' },
    { name: 'Tractor_Data', value: 'Tractor_Data' },
    { name: 'Tractor_Energy', value: 'Tractor_Energy' },
    { name: 'Hitch_and_Implement', value: 'Hitch_and_Implement' },
  ];
  const [indicatorLevel, setIndicatorLevel] = useState<any>();
  const level: LevelType[] = [
    { number: '1', value: '1' },
    { number: '2', value: '2' },
    { number: '3', value: '3' },
  ];
  const [selectedOrgId, setSelectedOrgId] = useState<string[]>([]);
  const [organizations, setOrganizations] = useState<string[]>([]);
  const [selectedTractorId, setselectedTractorId] = useState<string[]>([]);
  const [tractors, setTractors] = useState<any[]>([]);
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();

  useEffect(() => {
    if (userDetails && userDetails.organization && selectedOrgId?.length) {
      const init = async () => {
        await loadData();
      };
      init();
    }
  }, [
    userDetails,
    pageNumber,
    pageSize,
    selectedOrgId,
    selectedTractorId,
    indicatorKey,
    indicatorLevel,
    fromDateTime,
    toDateTime,
  ]);

  const getAllOrganizations = async () => {
    try {
      const { api_url } = userDetails?.organization;
      const result = await getOrgDropdown(api_url);
      const orgList = result.map((data: any) => ({
        title: <span className="ellipsis">{data.name}</span>,
        value: `${data.id}`,
      }));
      setOrganizations(orgList);
      const defaultSelectedIds = result?.map((item: any) => String(item.id));
      setSelectedOrgId(defaultSelectedIds);
    } catch (error: any) {
      notification.error({ message: error.message });
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getAllOrganizations();
    }
  }, [userDetails]);
  useEffect(() => {
    if (selectedOrgId?.length) {
      setselectedTractorId([]);
      getTractors();
    } else {
      setData([]);
    }
  }, [selectedOrgId]);

  const loadData = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      // eslint-disable-next-line no-unused-expressions
      pageNumber !== 1 ? setPageNumber(1) : fleetIndicatorHistoryList();
    } else if (activity.current === 'paginate' || activity.current === '') {
      fleetIndicatorHistoryList();
    }
  };

  const wrapResult = async (
    orgId: any,
    tractorId: any,
    indicatorKey: any,
    fromTime: any,
    toTime: any,
    pageNumber: number,
    pageSize: number,
    level: any,
  ) => {
    const { organization } = userDetails;
    const result = await getDealerIndicatorHistory(
      organization.api_url,
      orgId,
      tractorId,
      indicatorKey,
      fromTime,
      toTime,
      pageNumber,
      pageSize,
      level,
    );
    return {
      result,
    };
  };

  const fleetIndicatorHistoryList = async () => {
    try {
      if (checkActivity()) initScroller();
      setLoader(true);
      const { result } = await wrapResult(
        selectedOrgId,
        selectedTractorId,
        indicatorKey ? indicatorKey : '',
        fromDateTime ? fromDateTime.getTime() : '',
        toDateTime ? toDateTime.getTime() : '',
        pageNumber,
        pageSize,
        indicatorLevel ? indicatorLevel : '',
      );
      setTotalCount(result?._metadata.total_records_count);
      const { records } = result;
      const data = mapIndicatorsData(records);
      setData(data);
      setLoader(false);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };
  const columns = [
    {
      title: `${t(translate.baseStation.tractorName)}`,
      dataIndex: 'name',
      key: 'name',
      width: '12%',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: `${t(translate.taskmaster.starttime)}`,
      dataIndex: 'start_time',
      key: 'start_time',
      width: '14%',
    },
    {
      title: `${t(translate.tractorDetails.indicatorName)}`,
      dataIndex: 'indicator_name',
      key: 'indicator_name',
      width: '12%',
      sorter: (a: any, b: any) =>
        a.indicator_name.localeCompare(b.indicator_name),
    },
    {
      title: `${t(translate.tractorDetails.errorcode)}`,
      dataIndex: 'error_code',
      key: 'error_code',
      width: '10%',
      sorter: (a: any, b: any) => a.error_code.localeCompare(b.error_code),
    },
    {
      title: `${t(translate.tractorDetails.level)}`,
      render: (severity: number) => {
        return getSeverity(severity);
      },
      dataIndex: 'level',
      key: 'level',
    },
    {
      title: `${t(translate.myTask.description)}`,
      dataIndex: 'description',
      key: 'description',
      sorter: (a: any, b: any) => a.description.localeCompare(b.description),
      render: (description: string, levelObj: fleetObject) => {
        return (
          <div className="description">
            <img
              width="18px"
              height="18px"
              src={getIndicatorImages(
                levelObj.indicator_name,
                levelObj.level,
                true,
              )}
            />
            <Tooltip title={description}>
              <div className="description-text">{description}</div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: `${t(translate.tractorDetails.ResolvedTime)}`,
      dataIndex: 'resolved_date_time',
      key: 'resolved_date_time',
      width: '14%',
    },
    {
      title: `${t(translate.copycat.duration)}`,
      dataIndex: 'Duration',
      key: 'Duration',
      width: '10%',
    },
  ];

  const getTractors = async () => {
    try {
      const tractor = await getTractorsList(
        userDetails.organization.api_url,
        selectedOrgId,
      );
      const totalTractors = tractor?.map((data: any) => ({
        title: <span className="ellipsis">{data.name}</span>,
        value: `${data.id}`,
      }));
      setTractors(totalTractors);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const statusSelect = (e: string) => {
    activity.current = 'filter';
    filterData.length = 0;
    setPageNumber(1);
    setIndicatorKey(e);
  };

  const levelSelect = (e: string) => {
    activity.current = 'filter';
    filterData.length = 0;
    setPageNumber(1);
    setIndicatorLevel(e);
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const onDateRangeChange = (dates: any) => {
    activity.current = 'filter';
    filterData.length = 0;
    if (dates) {
      setPageNumber(1);
      const [from, to] = fromToDateWrapper(dates);
      setFromDateTime(from.toDate());
      setToDateTime(to.toDate());
    } else {
      setFromDateTime(null);
      setToDateTime(null);
    }
  };
  const handleOrg = (ids: any) => {
    activity.current = 'filter';
    setSelectedOrgId(ids);
  };

  const handleTractor = (ids: any) => {
    activity.current = 'filter';
    setselectedTractorId(ids);
  };

  return (
    <Layout>
      <div className="mainContent">
        <div className="tblContainer viewportContainer">
          <Row>
            <div className="common_wrapper wWidget_wrapper">
              <div className="filters_card personnelCard ad_filters_card">
                <CustomMultiSelect
                  handleTreeSelect={handleOrg}
                  selectedId={selectedOrgId}
                  list={organizations}
                  label="Organizations"
                />
                <CustomMultiSelect
                  handleTreeSelect={handleTractor}
                  selectedId={selectedTractorId}
                  list={tractors}
                  label="Tractors"
                />
                <CustomSelect
                  label="Indicator"
                  cssClass="min_width"
                  value={indicatorKey}
                  setValue={(id: any) => {
                    statusSelect(id);
                  }}
                  options={statusList.map((item: any) =>
                    Object.assign({
                      id: item.index,
                      name: item.name,
                      value: item.value,
                      key: item.value,
                    }),
                  )}
                  optionKey="value"
                  optionDisplay="name"
                  isAll={true}
                />
                <CustomSelect
                  label="Level"
                  cssClass="min_width"
                  value={indicatorLevel}
                  setValue={(id: any) => {
                    levelSelect(id);
                  }}
                  options={level.map((item: any) =>
                    Object.assign({
                      id: item.index,
                      name: item.number,
                      value: item.value,
                      key: item.value,
                    }),
                  )}
                  optionKey="value"
                  optionDisplay="name"
                  isAll={true}
                />
                <CustomDate
                  label="Select Date"
                  cssClass="min_width"
                  value={[moment(fromDateTime), moment(toDateTime)]}
                  onChange={(dates: any) => {
                    onDateRangeChange(dates);
                  }}
                  format="YYYY-MM-DD"
                />
              </div>
              <div className="common_table">
                <InfiniteScrollTable
                  // xScroll={1800}
                  columns={columns}
                  loading={loader}
                  hasMore={hasMore}
                  filterData={filterData}
                  totalcount={totalCount}
                  handleLoadMore={handleLoadMore}
                  filename="Indicators"
                />
              </div>
            </div>
          </Row>
        </div>
      </div>
    </Layout>
  );
};

export default Indicators;

/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import { pagenation } from '../../constants/types';
import './style.css';

interface Props {
  columns: Array<any>;
  dataSource: Array<any>;
  pagination: any;
  removeName?: any;
  handleChange: (page: pagenation) => void;
  loader: boolean;
  onRowClick?: (record: any) => void;
  fileName: any;
  removestorage?: any;
  scroll?: any;
}
const CustomTable: React.FC<Props> = ({
  columns,
  dataSource,
  pagination,
  removeName,
  handleChange,
  loader,
  onRowClick,
  fileName,
  removestorage,
  scroll,
}: Props) => {
  const [headers, setHeaders] = useState();

  useEffect(() => {
    const headers: any = [];
    if (columns) {
      columns.map((column: any) => {
        if (column.title !== removeName && column.title !== removestorage) {
          headers.push({
            label: column.title,
            key: column.dataIndex,
          });
        }
      });
      setHeaders(headers);
    }
  }, [columns]);
  pagination.pageSizeOptions = [25, 50, 100, 150];
  (pagination.showQuickJumper = true),
    (pagination.showTotal = (total: any) => `Total ${total} items`);
  return (
    <Layout>
      <Content>
        <div className="dbWidget empTbl customdbWidget">
          <Table
            className="invOrgTbl"
            columns={columns}
            dataSource={dataSource}
            sticky={true}
            pagination={false}
            loading={loader}
            scroll={{ y: scroll ? scroll : 500 }}
            onChange={(page: any) => {
              handleChange(page);
            }}
            onRow={(record: any) => ({
              onClick: () => onRowClick && onRowClick(record),
            })}
            rowKey={(record: any) => record.id || Math.random()}
          />
        </div>
      </Content>
    </Layout>
  );
};

export default CustomTable;

/* eslint-disable no-console */
import { notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import end_cross_round from '../../assets/images/end_cross_round.svg';

import imp_cam_icon from '../../assets/images/imp_cam_icon.svg';
import { getImplementsData, setImplement } from '../../constants/Api';
import { getImplementType } from '../../constants/constant';
import { ImplementDropDownType } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import AppLoader from '../common/AppLoader';
import CustomSelectMulti from '../common/CustomSelectMulti';
import HitchImage from './HitchImage';
import { RemoteDriveAppCtx } from './RemoteDriveContext';
import {
  SELECTED_IMPLEMENT_NAME,
  SELECTED_IMPLEMENT_TYPE,
  SET_LOADER_FOR_IMPLEMENT_CHANGE,
  SHOW_TRACTOR_COMMAND_LOADER,
} from './actions';

interface Props {
  setSelectedImplement: (implement: string) => void;
  selectedImplement: string;
  setShowEndAutoDrive: (flag: boolean) => void;
  addRoutineHandler: () => void;
}

const ImplementDetail: React.FC<Props> = ({
  setSelectedImplement,
  selectedImplement,
  setShowEndAutoDrive,
  addRoutineHandler,
}) => {
  const [implementsList, setImplementsList] = useState<any>([]);
  const [implementListForDropdown, setImplementListForDropdown] = useState<
    ImplementDropDownType[]
  >([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [hitchImage, setHitchImage] = useState('');
  const { userDetails } = useContext(ApplicationContext);
  const { RDReducer } = useContext(RemoteDriveAppCtx);
  const [state, dispatch] = RDReducer;
  const [implementDetails, setImplementDetails] = useState<any>();

  const { selectedTractor, selectedImplementName, showTractorCommandLoader } =
    state;

  useEffect(() => {
    userDetails && userDetails.organization && getImplementsListData();
  }, [userDetails]);

  const getImplementsListData = async () => {
    try {
      setLoader(true);
      const { records } = await getImplementsData(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
      );
      records && records.length > 0
        ? setImplementsList(records)
        : setImplementsList([]);

      const records1: any[] = records.map((d: any) => {
        return {
          label: d.name,
          value: d.config_implements_uuid,
          index: d.config_implements_uuid,
          type: d.implement_type,
          id: d.config_implements_uuid,
        };
      });
      setImplementListForDropdown([...records1]);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    dispatch({
      type: SELECTED_IMPLEMENT_NAME,
      payload: '',
    });
    dispatch({
      type: SELECTED_IMPLEMENT_TYPE,
      payload: '',
    });
    if (selectedTractor && selectedTractor.id && implementsList.length > 0) {
      implementsList.forEach((implement: any) => {
        if (
          selectedTractor &&
          selectedTractor?.heartbeat &&
          selectedTractor?.heartbeat?.implement &&
          selectedTractor?.heartbeat?.implement?.current_implement &&
          selectedTractor?.heartbeat?.implement?.current_implement.length > 0 &&
          implement.name ===
            selectedTractor?.heartbeat?.implement?.current_implement
        ) {
          dispatch({
            type: SELECTED_IMPLEMENT_NAME,
            payload: implement.name,
          });
          dispatch({
            type: SELECTED_IMPLEMENT_TYPE,
            payload: getImplementType(implement) || '',
          });
          setImplementDetails(implement);
          setSelectedImplement(implement.config_implements_uuid);
        }
      });
    }
  }, [selectedTractor, implementsList]);

  const approveImplementSettings = async () => {
    setLoader(true);
    try {
      const { organization } = userDetails;
      if (organization && organization.api_url) {
        dispatch({
          type: SET_LOADER_FOR_IMPLEMENT_CHANGE,
          payload: selectedImplementName ? selectedImplementName : 'None',
        });
        await setImplement(
          organization.api_url,
          selectedTractor.id,
          selectedImplementName ? selectedImplementName : 'None',
        );
      }
    } catch (err: any) {
      notification.error({
        message: err?.message,
        duration: 1,
      });
    } finally {
      setLoader(false);
    }
  };

  return (
    <div className="rautoDriveRightSec newAutodriveWidget">
      <div className="pd0 adrImpDtl empView personeeltab implementsWidget">
        <div className="proRow mb28">
          <p className="snapshotTxt body1">
            <img src={imp_cam_icon} alt="" />
            <span>Here’s a snapshot from the hitch camera</span>
          </p>
          <div className="implementImage">
            {selectedTractor && (
              <HitchImage
                hitchImage={hitchImage}
                setHitchImage={setHitchImage}
                driveActionUUID={
                  selectedTractor?.heartbeat?.drive_action_details
                    .current_drive_action
                }
              />
            )}
          </div>
          <div className="verifyInfoBlk">
            <h5 className="headline3">
              {implementDetails
                ? 'Verify information below'
                : 'Select implement from list'}
            </h5>
            <CustomSelectMulti
              label="Implement"
              value={
                implementListForDropdown && implementListForDropdown.length > 0
                  ? selectedImplement
                  : ''
              }
              setValue={(selected) => {
                const [implement] = implementsList.filter(
                  (implement: any) =>
                    selected === implement.config_implements_uuid,
                );
                if (implement) {
                  dispatch({
                    type: SELECTED_IMPLEMENT_NAME,
                    payload: implement && implement.name ? implement.name : '',
                  });
                  dispatch({
                    type: SELECTED_IMPLEMENT_TYPE,
                    payload: getImplementType(implement) || '',
                  });
                  setSelectedImplement(selected);
                  setImplementDetails(implement);
                }
              }}
              options={implementListForDropdown}
              optionKey="id"
              optionDisplay="label"
              optionDisplay2="type"
            />
            {implementDetails && implementDetails.id ? (
              <div className="verifyInfoDetailsBlk">
                {implementDetails?.image_url &&
                  implementDetails?.image_url !== '' && (
                    <div className="profileICon">
                      <img src={implementDetails?.image_url} alt="" />
                    </div>
                  )}
                <div className="verifyInfoDetails">
                  <div className="verifyInfoDetailsFlex">
                    <div className="w50per">
                      <span className="overline2">Manufacturer</span>
                      <h6 className="overline1">
                        {implementDetails?.config_imm?.manufacturer}
                      </h6>
                    </div>
                    <div className="w50per">
                      <span className="overline2">Model</span>
                      <h6 className="overline1">
                        {implementDetails?.config_imm?.model}
                      </h6>
                    </div>
                  </div>
                  <div className="verifyInfoDetailsFlex mt15">
                    <div className="w50per">
                      <span className="overline2">Type</span>
                      <h6 className="overline1">
                        {implementDetails?.implement_type}
                      </h6>
                    </div>
                    <div className="w50per">
                      <span className="overline2">Attachment</span>
                      <h6 className="overline1">
                        {implementDetails?.attachment_type}
                      </h6>
                    </div>
                  </div>
                  <h3 className="overline2">Dimensions</h3>
                  <div className="verifyInfoDetailsFlex">
                    <div className="w50per">
                      <span className="overline2">Height</span>
                      <h6 className="overline1">
                        {implementDetails?.height}{' '}
                        <span className="overline2">IN</span>
                      </h6>
                    </div>
                    <div className="w50per">
                      <span className="overline2">Length</span>
                      <h6 className="overline1">
                        {implementDetails?.length}{' '}
                        <span className="overline2">IN</span>
                      </h6>
                    </div>
                  </div>
                  <div className="verifyInfoDetailsFlex mt15">
                    <div className="w50per">
                      <span className="overline2">Width</span>
                      <h6 className="overline1">
                        {implementDetails?.width}{' '}
                        <span className="overline2">IN</span>
                      </h6>
                    </div>
                    <div className="w50per">
                      <span className="overline2">Weight</span>
                      <h6 className="overline1">
                        {implementDetails?.weight}{' '}
                        <span className="overline2">LBS</span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="auto-implement-select">
                Only Monarch verified implements can be used with Autodrive
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="verifyTxt impVerifyBtn">
        <button
          className="Button2 verifyBtn mb10"
          onClick={() => {
            approveImplementSettings();
            showTractorCommandLoader[selectedTractor.id] = false;
            dispatch({
              type: SHOW_TRACTOR_COMMAND_LOADER,
              payload: {
                ...showTractorCommandLoader,
              },
            });
            addRoutineHandler();
          }}
          disabled={selectedImplement === '' ? true : false}
        >
          Confirm
        </button>
        <button
          className="Button btnBgNone"
          onClick={() => setShowEndAutoDrive(true)}
        >
          <span className="endDnone">
            <img src={end_cross_round} alt="" />
          </span>
          <span>End Autodrive</span>
        </button>
      </div>
      <AppLoader loader={loader} />
    </div>
  );
};

export default ImplementDetail;

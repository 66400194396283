import React, { useContext, useEffect, useMemo, useState } from 'react';
import Layout from 'antd/lib/layout/layout';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { Avatar, Dropdown, Menu, notification, Space } from 'antd';
import {
  EyeTwoTone,
  EyeInvisibleOutlined,
  WarningFilled,
  EllipsisOutlined,
} from '@ant-design/icons';

import {
  getEmployeePermissionDetails,
  getUserPermission,
} from '../../constants/Api';
import translate from '../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';
import { privilagesConstants } from '../../constants/Privilages';
import { ApplicationContext } from '../../context/AppContext';

import RemoveFromTeam from './RemoveFromTeam';
import PermissionsDashBoard from './PermissionsDashBoard';
import AppLoader from '../common/AppLoader';

import 'antd/dist/antd.css';
import './style.css';

interface Props {
  closeEmpDetails: () => void;
  empId: number;
  setTeamids: (key: number) => void;
}
const EmployeeDetails: React.FC<Props> = ({
  closeEmpDetails,
  empId,
  setTeamids,
}) => {
  const { t } = useTranslation();
  const { user, privilegeChecker } = useContext(ApplicationContext);
  const [userProfile, setUserProfile] = useState<any>(null);
  const [removeTeam, setRemoveTeam] = useState<boolean>(false);
  const [teamDesc, setTeamDesc] = useState<any>();
  const [userPermission, setuserPermission] = useState<any>([]);
  const [passwordShown, setPasswordShown] = useState(false);
  const [permissionFlag, setPermission] = useState<boolean>(false);
  const [teamId, setTeamId] = useState<number>(0);
  const [appAcess, setAppAcess] = useState<boolean>(false);
  const [seeMenu, setSeeMenu] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);

  const closeModel = (status: any) => {
    setRemoveTeam(status);
    getProfile(empId);
  };

  const getProfile = async (empId: number) => {
    try {
      if (!user) return;
      const { organization } = user;
      setLoader(true);
      const data = await getEmployeePermissionDetails(
        organization.api_url,
        organization.id,
        empId,
      );
      const { name: organization_name } = data.organization || {};
      data.organization_name = organization_name;
      setUserProfile(data);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (user && empId) {
      getProfile(empId);
      getPermissions();
    }
    return () => setUserProfile(null);
  }, [user, empId]);

  useEffect(() => {
    if (userProfile) {
      const [check] = userProfile.teams?.map((team: any) => team.app_access);
      setAppAcess(Boolean(check));
    }
  }, [userProfile]);

  const getPermissions = async () => {
    try {
      if (!user) return;
      const { organization } = user;
      const response = await getUserPermission(
        organization.api_url,
        organization.id,
        empId,
      );
      setuserPermission(response);
    } catch (error: any) {
      notification.error({ message: 'Unable to get Permission data' });
    }
  };

  const menu = (ele: any) => {
    return (
      <Menu>
        <Menu.Item key="see" onClick={() => SeeDetails(ele)}>
          <span className="overline2">
            {t(translate.employeeDetails.seeDetails)}
          </span>
        </Menu.Item>
        {privilegeChecker(privilagesConstants.Edit_Teams) && (
          <Menu.Item key="remove" onClick={() => removeFromTeam(ele)}>
            <span className="overline2">
              {t(translate.employeeDetails.removeFromTeam)}
            </span>
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const SeeDetails = (ele: any) => {
    setTeamDesc(ele);
    setTeamids(ele?.id);
    setTeamId(ele?.id);
    setPermission(true);
    closeEmpDetails();
  };

  const removeFromTeam = (ele: any) => {
    setTeamDesc(ele);
    setRemoveTeam(true);
  };

  const showPassWord = () => {
    setPasswordShown(!passwordShown);
  };

  const checkGroupPrivilege = (groupId: number) => {
    let value = false;
    userPermission?.filter((groupPrivilege: any) => {
      if (groupPrivilege.id === groupId) {
        const check = groupPrivilege?.privileges?.every(
          (subPrivilege: any) => subPrivilege.status === true,
        );
        value = check;
      }
    });
    return value;
  };

  const ViewBlock = useMemo(
    () =>
      userProfile && (
        <div className="see_more_block">
          <div className="employee-profile">
            <div className="profile-details">
              {userProfile?.profile_url !== '' && userProfile?.profile_url ? (
                <Avatar src={userProfile?.profile_url}></Avatar>
              ) : (
                <Avatar>
                  {userProfile?.first_name?.charAt(0)}
                  {userProfile?.last_name?.charAt(0)}
                </Avatar>
              )}
              <h1
                className="headline2 wwp"
                data-testid={`${userProfile?.first_name} ${userProfile?.last_name}-EmployeeDetails`}
              >
                {userProfile?.first_name} {userProfile?.last_name}
              </h1>
              <span className="headline4">
                {t(translate.employeeDetails.id)}: {userProfile?.member_uuid}
              </span>
            </div>
            <div className="profile-contacts">
              <h1 className="headline3 fSbd">Contact</h1>
              <h3 className="body2">{userProfile?.username}</h3>
              <h3 className="body2">{userProfile?.contact_number}</h3>
              {appAcess && !userProfile?.username && (
                <div style={{ display: 'flex' }}>
                  <WarningFilled
                    style={{
                      color: '#fbb313',
                      marginRight: '10px',
                      fontSize: '22px',
                    }}
                  />
                  <span className="warning-msg">
                    {t(translate.requiredMessages.requieredEmail)}
                  </span>
                </div>
              )}
            </div>

            {userProfile?.ops_code && (
              <div className="operator-code">
                <h1 className="headline3 fSbd">
                  {t(translate.employeeDetails.opCode)}
                </h1>
                <div className="proRoleValue flex-1 ">
                  <div
                    className="flex"
                    // style={{ justifyContent: 'flex-start' }}
                  >
                    <div className="flex-1 body1">
                      {privilegeChecker(
                        privilagesConstants.View_Operator_Code,
                      ) && passwordShown
                        ? userProfile?.ops_code
                        : '* * * *'}
                    </div>
                    {privilegeChecker(
                      privilagesConstants.View_Operator_Code,
                    ) && (
                      <div className="flex-1 cursor" onClick={showPassWord}>
                        <>
                          {!passwordShown ? (
                            <EyeTwoTone />
                          ) : (
                            <EyeInvisibleOutlined />
                          )}
                        </>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="employee-permissions teams-permissions">
            <div className="us_section1">
              <div className="permissions-title">
                <h1 className="headline3">
                  {t(translate.employeeDetails.title)}
                </h1>
                <span className="body2">
                  {t(translate.employeeDetails.titleDesc)}
                </span>
              </div>
              <div className="employee-teams">
                <div className="employee-team-sub">
                  {userProfile.teams.map((ele: any) => {
                    return (
                      <div className="empoyee-team" key={ele?.name}>
                        <div className="employee-team-title">
                          <h1 className="headline3 fSbd">{ele?.name}</h1>
                          <span className="body2">{ele?.description}</span>
                        </div>

                        {ele && (
                          <Dropdown overlay={menu(ele)} trigger={['click']}>
                            <a
                              onClick={(e) => {
                                setSeeMenu(!seeMenu);
                                e.preventDefault();
                              }}
                            >
                              <Space>
                                <EllipsisOutlined className="empEllip" />
                              </Space>
                            </a>
                          </Dropdown>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="us_section2">
              <div className="gridBlock">
                {userPermission.length > 0 && (
                  <div className="employee-main-permissions">
                    <h1 className="headline3">
                      {t(translate.employeeDetails.permissions)}
                    </h1>
                    <div className="permission-sub">
                      {userPermission.map((ele: any) => {
                        return (
                          <div className="operation-permissions" key={ele.id}>
                            <div className="operation-title">
                              <h2 className="body2">
                                {ele?.privilege_group_name}
                              </h2>
                              <Checkbox checked={checkGroupPrivilege(ele.id)} />
                            </div>
                            {ele.privileges.map((eledata: any) => {
                              return (
                                <div
                                  className="operation-features"
                                  key={eledata.id}
                                >
                                  <div className="operation-feature">
                                    <h3 className="body2">
                                      {eledata?.privilege_name}
                                    </h3>
                                    <Checkbox checked={eledata.status} />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ),
    [userProfile, passwordShown, userPermission],
  );

  return (
    <Layout className="posRel">
      <div className="common_wrapper hvh1">
        <div className="filters_card spaceBtnAlignCenter">
          <div className="teamarrow mr20" onClick={closeEmpDetails}></div>
        </div>
        <AppLoader loader={loader} />
        {ViewBlock}
      </div>
      {removeTeam && (
        <RemoveFromTeam
          showModal={removeTeam}
          closeModel={closeModel}
          userProfile={userProfile}
          teamDesc={teamDesc}
        />
      )}

      {permissionFlag && (
        <PermissionsDashBoard
          setPermission={setPermission}
          teamId={teamId}
          setTeamId={setTeamId}
        ></PermissionsDashBoard>
      )}
    </Layout>
  );
};

export default EmployeeDetails;

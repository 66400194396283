/* eslint-disable no-console */
import { Button, notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import atArrow from '../../assets/images/atArrow.svg';
import connecting_generating_path from '../../assets/images/connecting_generating_path.gif';
import adrDicBtn from '../../assets/images/cross_black.svg';
import errorIcon from '../../assets/images/errorCode_icon.svg';
import hitch_implement_S from '../../assets/images/hitch_implement_S.svg';
import fleet_implement_new from '../../assets/images/hitch_implement_gray.svg';
import hoursOfOp from '../../assets/images/hours_of_op.svg';
import seeder_refill from '../../assets/images/seeder_refill.svg';
import tCLock from '../../assets/images/tCLock.svg';

import { track } from '../../util/logger';

import './autodrivelist.css';

import {
  addRemoteAVCommand,
  createSnapshot,
  executeCommand,
  getImplementsList1,
  stopDriveTractor,
} from '../../constants/Api';
import {
  batteryValue,
  checkAutoDriveMonitorErrorCodes,
  checkDisconnectCheck,
  checkIsTractorConnected,
  checkTractorDrivingState,
  fleetListTabs,
  geTractorStatusIcon,
  getFNR,
  getImplementName,
  getMinsFromSecs,
  getOperatorNameByHeartBeat,
  getSpeed,
  getSpeedFromVelocity,
  getStatusClass,
  getStatusForTractor,
  getSystemPausedInterrupts,
  getTractorImage,
  getTripMeterValue,
  hideOnTractorConnectCommon,
  isHaveErrors,
  isHaveErrorsForRoutineFlow,
  isPlatFromWeb,
  isVineYardOrDairy,
  loginUserHaveInterruptsOrNot,
  noneAttached,
  secondsToHours,
  showInterruptMsg,
  tractorConstantsNew,
} from '../../constants/Common';
import { TractorHeartBeat, UserDetails } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import { RemoteDriveAppCtx } from './RemoteDriveContext';

import {
  ATOM,
  AutoDriveTabs,
  clearCommandFrom,
  dairyAutoDrive,
  getDriveActionUuid,
  getImplementType,
  handleSeederShow,
  isInRemoteAvRunningState,
  tractorEndPopDefault,
  vineYardAutoDrive,
} from '../../constants/constant';
import { AutoDriveApplicationContext } from '../../context/AutoDriveContext';
import { SET_PINNED, SET_TRACTOR_IMPLEMENT_STATE } from '../../context/actions';
import DriveOptionSettingModal from '../common/DriveOptionSettingModal';
import DairyAutoDrive from '../dairy/DairyAutoDrive';
import AutoDriveFeedBackPop from '../livecommand/AutoDriveFeedBackPop';
import Cameras from '../widget/LiveCamera';
import AutoDriveRoutine from './AutoDriveRoutine';
import CommonPopAutoDrive from './CommonPopAutoDrive';
import { Errorcodepopup } from './Errorcodepopup';
import { GuardrailWidget } from './GuardrailWidget';
import MaintenanceAndStartAutoDrive from './MaintenanceAndStartAutoDrive';
import PinnedTractors from './PinnedTractors';
import RemoteDriveActionsNewDesign from './RemoteDriveActionsNewDesign';
import {
  AUTO_DRIVE_ERROR,
  SELECTED_IMPLEMENT_NAME,
  SELECTED_IMPLEMENT_TYPE,
  SELECTED_ROUTINE,
  SELECTED_TRACTORS,
  SET_ACTIVE_AUTODRIVE_TAB,
  SET_AUTO_DRIVE_FEEDBACK_PAYLOAD,
  SET_AUTO_DRIVE_FEEDBACK_POP,
  SET_AUTO_DRIVE_INTERRUPT_MESSAGES,
  SET_AUTO_DRIVE_POP,
  SET_PINNED_TRACTORS,
  SET_SELECTED_OPENED_FLEET_TAB,
  SET_SHOW_CONNECT_LOADER,
  SET_SHOW_CONNECT_TRY_AGAIN_POP,
  SET_SHOW_PATH_MODAL,
  SET_SHOW_PRESENT_COMMAND,
  SET_SHOW_REMOTE_DRIVE_DETAILS,
  SET_SHOW_SNAPSHOT,
  SHOW_TRACTOR_COMMAND_LOADER,
  SHOW_TRACTOR_START,
  SNAP_SHOT_INITIATED,
} from './actions';
let globalAutoDriveInterruptMsgs: any = {};

interface Props {
  clearPreviewPath: (id: number) => void;
  handleResetCommand: (state: string) => void;
  preCommandState: string;
  addRowFollowRVCommand: () => void;
}
const RemoteDriveDetailsNew: React.FC<Props> = ({
  clearPreviewPath,
  handleResetCommand,
  preCommandState,
  addRowFollowRVCommand,
}: Props) => {
  const { userDetails, APPReducer } = useContext(ApplicationContext);

  const { AutoDriveReducer } = useContext(AutoDriveApplicationContext);
  const [autoDriveState, autoDriveDispatch] = AutoDriveReducer;
  const [appState] = APPReducer;
  const { tractorsMap, tractorsList } = appState;
  const {
    tractorImplementState,
    isPinned,
    remainingTime,
    velocity,
    showSideFeedBack,
  } = autoDriveState;
  const {
    getImplement,
    RDReducer,
    handlePauseResumeStopLiveCom,
    getIndicatorIcons,
    mapPinnedTractors,
    clearAllLocalDBCommands,
    clearCommandApiHandler,
    selectedTractorsIds,
    connectToTractor,
    addImplementSettings,
  } = useContext(RemoteDriveAppCtx);
  const [state, dispatch] = RDReducer;
  const {
    selectedTractor,
    showPathModal,
    autoDriveMonitorErrorCodes,
    autoDriveError,
    pinnedTractors,
    showAutoDrivePop,
    autoDriveInterruptMsgs,
    selectedTractors,
    selectedImplementName,
    showTractorStart,
    showTractorCommandLoader,
    showConnectLoader,
    showConnectTryAgainPop,
    showAutoDrivePoorConnectivityLoader,
  } = state;
  const { heartbeat } = selectedTractor;
  const [, setLoader] = useState<boolean>(false);
  const [errorCodeFlag, setErrorCodeFlag] = useState<boolean>(false);
  const [implementsList, setImplements] = useState<any[]>([]);
  const [selectedImplement, setselectedImplement] = useState('');
  const [showEndAndDisconnect, setShowEndAndDisconnect] =
    useState<number>(tractorEndPopDefault);
  const [showEndAutoDrive, setShowEndAutoDrive] = useState(false);
  const [showDairyEndAutoDrive, setShowDairyEndAutoDrive] = useState(false);
  const [autonomyFlag, setAutonomyFlag] = useState<boolean>(false);
  const [showCameras, setShowCameras] = useState(true);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  useEffect(() => {
    if (
      heartbeat &&
      heartbeat.live_streaming_status !== null &&
      heartbeat.live_streaming_status !== undefined &&
      heartbeat?.drive_action_details?.drive_action_created_at === ATOM
    ) {
      const status = heartbeat.live_streaming_status ? true : false;
      setShowCameras(status);
    } else {
      setShowCameras(true);
    }
  }, [heartbeat]);
  useEffect(() => {
    setselectedImplement('');
    return () => {
      setselectedImplement('');
    };
  }, []);
  useEffect(() => {
    globalAutoDriveInterruptMsgs = autoDriveInterruptMsgs;
  }, [autoDriveInterruptMsgs]);
  const stopAndDisconnectHandler = () => {
    // add this for feedback payload starts here
    const drive_action_uuid = getDriveActionUuid(
      selectedTractors,
      selectedTractor.id,
    );
    const tractor_id = selectedTractor.id;
    dispatch({
      type: SET_AUTO_DRIVE_FEEDBACK_PAYLOAD,
      payload: { drive_action_uuid, tractor_id },
    });
    dispatch({
      type: SET_AUTO_DRIVE_FEEDBACK_POP,
      payload: true,
    });
    // add this for feedback payload ends here

    // stop start here
    clearAllLocalDBCommands(selectedTractor.id);
    dispatch({
      type: SELECTED_TRACTORS,
      payload: [],
    });
    handleResetCommand('');
    tractorImplementState[selectedTractor.id] = false;
    autoDriveDispatch({
      type: SET_TRACTOR_IMPLEMENT_STATE,
      payload: tractorImplementState,
    });
    clearPreviewPath(selectedTractor.id);
    handlePauseResumeStopLiveCom('stop');
    // stop end here
    // disconnect start here
    disConnectConnection(selectedTractor?.heartbeat);
    // disconnect ends here
    // clear error message when click on end operation and disconnect
    // globalAutoDriveInterruptMsgs &&
    //   Object.keys(globalAutoDriveInterruptMsgs).map(
    //     (autoDriveInterruptMsg: string) => {
    //       if (autoDriveInterruptMsg.includes(tractor_id)) {
    //         delete globalAutoDriveInterruptMsgs[autoDriveInterruptMsg];
    //       }
    //     },
    //   );
    globalAutoDriveInterruptMsgs[tractor_id] = {};
    dispatch({
      type: SET_AUTO_DRIVE_INTERRUPT_MESSAGES,
      payload: globalAutoDriveInterruptMsgs,
    });
  };
  useEffect(() => {
    setselectedImplement('');
    dispatch({
      type: SELECTED_IMPLEMENT_NAME,
      payload: '',
    });
    dispatch({
      type: SELECTED_IMPLEMENT_TYPE,
      payload: '',
    });
    if (selectedTractor && selectedTractor.id && implementsList.length > 0) {
      implementsList.forEach((implement: any) => {
        if (
          selectedTractor &&
          selectedTractor?.heartbeat &&
          selectedTractor?.heartbeat?.implement &&
          selectedTractor?.heartbeat?.implement?.current_implement &&
          selectedTractor?.heartbeat?.implement?.current_implement.length > 0 &&
          implement.name ===
            selectedTractor?.heartbeat?.implement?.current_implement
        ) {
          dispatch({
            type: SELECTED_IMPLEMENT_NAME,
            payload: implement.name,
          });
          dispatch({
            type: SELECTED_IMPLEMENT_TYPE,
            payload: getImplementType(implement) || '',
          });
          setselectedImplement(implement.config_implements_uuid);
        }
      });
    }
  }, [selectedTractor, implementsList]);

  useEffect(() => {
    if (selectedTractor && selectedTractor.id) getImplement(selectedTractor.id);
  }, [selectedTractor]);

  const disConnectConnection = async (hb?: TractorHeartBeat) => {
    if (hb && hb?.drive_action_details?.current_drive_action) {
      setLoader(true);
      try {
        const { organization } = userDetails;
        const { msg } = await stopDriveTractor(
          organization.api_url,
          hb?.drive_action_details?.current_drive_action,
          {
            operator_id: userDetails.id,
            action: 'FINISH',
          },
        );
        clearAllLocalDBCommands(hb.tractor_id);
        msg &&
          notification.success({
            message: msg,
            duration: 1,
          });
        setLoader(false);
        dispatch({
          type: SELECTED_TRACTORS,
          payload: [],
        });
        clearPreviewPath(hb.tractor_id);
        // to reset implement start here
        tractorImplementState[selectedTractor.id] = false;
        autoDriveDispatch({
          type: SET_TRACTOR_IMPLEMENT_STATE,
          payload: tractorImplementState,
        });
        dispatch({
          type: SET_SELECTED_OPENED_FLEET_TAB,
          payload: fleetListTabs.fleetListTractorsList,
        });
        return;
      } catch (error: any) {
        notification.error({
          message: error.message,
          duration: 1,
        });
      } finally {
        setLoader(false);
      }
    }
  };

  //clearCommandLocalDb = true => will stop clearing the command from db
  const clearAll = (hb: TractorHeartBeat, clearCommandLocalDb = false) => {
    // !clearCommandLocalDb &&
    clearCommandApiHandler(
      hb.tractor_id,
      clearCommandLocalDb ? clearCommandFrom : '',
    );
    dispatch({
      type: SET_SHOW_PRESENT_COMMAND,
      payload: 'GOTO',
    });
    // set the map click action type
    handleResetCommand('GOTO');
    //  clear the preview path
    clearPreviewPath(hb.tractor_id);
  };

  useEffect(() => {
    autonomyFlag &&
      userDetails &&
      userDetails.organization &&
      getImplementsListData();
  }, [userDetails, autonomyFlag]);

  const getImplementsListData = async () => {
    try {
      setLoader(true);
      const records = await getImplementsList1(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
      );
      records && records.length > 0
        ? setImplements(records)
        : setImplements([]);
    } catch (error: any) {
      notification.error({
        message: error.message,
        duration: 1,
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    handleResetCommand(selectedImplement === '' ? '' : 'GOTO');
  }, [selectedImplement]);
  const handleIgnore = async (action: string) => {
    try {
      const { organization } = userDetails;
      const drive_action_uuid =
        heartbeat?.drive_action_details?.current_drive_action;
      const { msg } = await executeCommand(
        organization.api_url,
        drive_action_uuid,
        {
          action,
          operator_id: userDetails.id,
          planner: 'guardrails',
          tractor_id: selectedTractor.id,
          organization_id: userDetails.organization_id,
          parameters: [],
        },
      );
      notification.success({
        message: msg,
        duration: 1,
      });
      dispatch({ type: SET_SHOW_PATH_MODAL, payload: false });
    } catch (err: any) {
      notification.error({
        message: err.message,
        duration: 1,
      });
    }
  };

  const closeModel = () => {
    setErrorCodeFlag(false);
  };

  useEffect(() => {
    if (
      checkAutoDriveMonitorErrorCodes(
        autoDriveMonitorErrorCodes,
        selectedTractor?.id,
      )
    ) {
      if (isInRemoteAvRunningState(selectedTractor)) {
        handleResetCommand('');
        clearAll(selectedTractor?.heartbeat);
        dispatch({
          type: SET_ACTIVE_AUTODRIVE_TAB,
          payload: AutoDriveTabs.Block,
        });
        showTractorCommandLoader[selectedTractor.id] = false;
        dispatch({
          type: SHOW_TRACTOR_COMMAND_LOADER,
          payload: {
            ...showTractorCommandLoader,
          },
        });
      }
    }
  }, [autoDriveMonitorErrorCodes, selectedTractor]);

  useEffect(() => {
    if (isHaveErrors(autoDriveError)) {
      handleResetCommand('');
      // clearAll(selectedTractor?.heartbeat);
      // dispatch({
      //   type: SET_ACTIVE_AUTODRIVE_TAB,
      //   payload: AutoDriveTabs.Block,
      // });
      // other then 7101 and 7102 error
      if (isHaveErrorsForRoutineFlow(autoDriveError)) {
        showTractorCommandLoader[selectedTractor.id] = false;
        dispatch({
          type: SHOW_TRACTOR_COMMAND_LOADER,
          payload: {
            ...showTractorCommandLoader,
          },
        });
        clearAll(selectedTractor?.heartbeat);
        dispatch({
          type: SET_ACTIVE_AUTODRIVE_TAB,
          payload: AutoDriveTabs.Block,
        });
      } else {
        // true will stop clearing the command from db
        // for 7101 and 7102 error
        clearAll(selectedTractor?.heartbeat, true);
      }
    }
  }, [autoDriveError, selectedTractor]);

  const createSnapshots = async () => {
    setLoader(true);
    try {
      const { organization } = userDetails;
      const response = await createSnapshot(
        organization.api_url,
        organization.farm.id,
        selectedTractor.id,
      );
      if (!response?.id) throw new Error('SnapShot Failed');
      track('Live Map', {
        event: `Fleet List SnapShot successful`,
      });
      response['tractorName'] = tractorsMap.get(response.tractor_id)?.name;
      response['name'] = `${userDetails.first_name} ${userDetails.last_name}`;
      dispatch({
        type: SNAP_SHOT_INITIATED,
        payload: response,
      });
      notification.open({
        message: '',
        key: response.id,
        // duration: 3,
        description: (
          <>
            <p>Snapshot Captured</p>
            <Button
              onClick={() => {
                notification.close(response.id);
                dispatch({
                  type: SET_SHOW_SNAPSHOT,
                  payload: true,
                });
              }}
              className="cursor"
            >
              View
            </Button>
          </>
        ),
      });
    } catch (error: any) {
      track('Live Map', {
        event: `Fleet List SnapShot Failed`,
      });
      notification.error({
        message: error.message,
        duration: 1,
      });
    } finally {
      setLoader(false);
    }
  };

  const clearTheAutoDrive = (isDisconnect: boolean) => {
    // to clear preview path
    clearPreviewPath(selectedTractor.id);
    // call clear command api
    clearCommandApiHandler(selectedTractor.id, 'RDDetails-- clearAutoDrive');
    // clearing the selected Routine
    dispatch({
      type: SELECTED_ROUTINE,
      payload: null,
    });
    dispatch({ type: SET_AUTO_DRIVE_POP, payload: false });
    // clearing interrupt message
    dispatch({
      type: AUTO_DRIVE_ERROR,
      payload: {
        tractor_id: selectedTractor.id,
        message: '',
        errors: [],
      },
    });
    // implement verification set false (default)
    tractorImplementState[selectedTractor.id] = false;
    autoDriveDispatch({
      type: SET_TRACTOR_IMPLEMENT_STATE,
      payload: tractorImplementState,
    });
    // below code run when disconnect button click
    isDisconnect && disConnectConnection(heartbeat);
  };

  const isPinnedFun = (id: number) => pinnedTractors.includes(id);
  const unPinnedTractors = (id: number) => {
    const ttractors = pinnedTractors.filter((item: any) => item !== id);
    dispatch({
      type: SET_PINNED_TRACTORS,
      payload: ttractors,
    });
  };
  const disConnectTractor = (e: any) => {
    e.stopPropagation();
    disConnectConnection(selectedTractor?.heartbeat);
    dispatch({
      type: SET_SELECTED_OPENED_FLEET_TAB,
      payload: fleetListTabs.fleetListTractorsList,
    });
    // stop showing auto drive  start button
    showTractorStart[selectedTractor.id] = false;
    dispatch({
      type: SHOW_TRACTOR_START,
      payload: {
        ...showTractorStart,
      },
    });
    showTractorCommandLoader[selectedTractor.id] = false;
    dispatch({
      type: SHOW_TRACTOR_COMMAND_LOADER,
      payload: {
        ...showTractorCommandLoader,
      },
    });
  };

  const addImplementSettings1 = async (savedImplementDetails: any) => {
    // setShowLoader(true);
    try {
      const { organization } = userDetails;
      if (
        organization &&
        organization.api_url &&
        selectedTractor?.heartbeat?.drive_action_details?.current_drive_action
      ) {
        const commandData = {
          command: 'implement_ctrl_params',
          implement: selectedImplementName ? selectedImplementName : 'None',
          ...savedImplementDetails,
        };
        const { msg } = await addRemoteAVCommand(
          organization.api_url,
          [commandData],
          selectedTractor?.heartbeat?.drive_action_details
            ?.current_drive_action as string,
        );
        msg &&
          notification.success({
            message: msg,
            duration: 1,
          });
      }
    } catch (err: any) {
      notification.error({
        message: err?.message,
        duration: 1,
      });
    } finally {
      // setShowLoader(false);
    }
  };

  const showAutoDrive = () =>
    showAutoDrivePop &&
    selectedTractor &&
    selectedTractor.id &&
    isPlatFromWeb(heartbeat) &&
    checkIsTractorConnected(selectedTractor, userDetails.id) &&
    userDetails &&
    userDetails.id &&
    selectedTractor?.heartbeat?.drive_action_details?.current_operator_id ===
      userDetails.id &&
    !checkTractorDrivingState(heartbeat, userDetails.id);

  const zindexStarts = 100;
  const tBox: any = document.querySelectorAll('.adtBottomBox');

  for (let i = 0; i < tBox.length; i++) {
    if (tBox[i]) {
      tBox[i].style['z-index'] = zindexStarts - i;
    }
  }
  // hiding things when tractor connecting
  const hideOnTractorConnect = () =>
    hideOnTractorConnectCommon(
      selectedTractor,
      selectedTractorsIds,
      showConnectLoader,
    );
  const handleConnectCloseHandler = (state: boolean) => {
    // close pop with out any action
    if (state) {
      // connect the tractor again
      connectToTractor(selectedTractor.id);
    } else {
      // hiding the connect loader gif
      dispatch({
        type: SET_SHOW_CONNECT_LOADER,
        payload: false,
      });
      // selecting the tractor tab
      dispatch({
        type: SET_SELECTED_OPENED_FLEET_TAB,
        payload: fleetListTabs.fleetListTractorsList,
      });
    }
    // hiding the connect again pop
    dispatch({
      type: SET_SHOW_CONNECT_TRY_AGAIN_POP,
      payload: false,
    });
  };
  useEffect(() => {
    function onlineHandler() {
      setIsOnline(true);
    }

    function offlineHandler() {
      setIsOnline(false);
    }

    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);

    return () => {
      window.removeEventListener('online', onlineHandler);
      window.removeEventListener('offline', offlineHandler);
    };
  }, []);

  return (
    <>
      <div className="rautoDriveRightSec grayBlock">
        <div className="autoDriveTractorHead">
          <div
            className="tractorPin"
            data-testid="tractorPin-RemoteDriveDetailsNew"
            onClick={() => {
              mapPinnedTractors(selectedTractor.id);
              isPinned &&
                dispatch({
                  type: SET_PINNED_TRACTORS,
                  payload: [],
                });
              autoDriveDispatch({
                type: SET_PINNED,
                payload: !isPinned,
              });
            }}
          ></div>
          <div
            className="adtHeadSec"
            onClick={() => {
              dispatch({
                type: SET_SHOW_REMOTE_DRIVE_DETAILS,
                payload: false,
              });
            }}
          >
            <span className="adtHeadTextIcon">
              <img src={atArrow} alt="atArrow" />
            </span>
            <span
              className="adtHeadText Button"
              data-testid="tractorDetailsLink-RemoteDriveDetailsNew"
            >
              TRACTOR DETAILS
            </span>
          </div>
        </div>
        <div className={`whiteWrapper ${isPinned ? 'whiteLayer' : ''}`}></div>
        <div className="adtMainContainer">
          <div
            className={`adtTopSection ${geTractorStatusIcon(
              selectedTractor.isAvailable,
            )}_color ${pinnedTractors.length < 2 ? 'tdtl_nopin' : ''}`}
          >
            {/* <div
            className={`adtTopSection autoDrive_color ${
              pinnedTractors.length < 2 ? 'tdtl_nopin' : ''
            }`}
          > */}
            <div className={`adtTopBox ${isPinned ? 'adRoundBox' : ''}`}>
              <div
                className="adtClose"
                onClick={() => {
                  if (pinnedTractors.length == 1) {
                    if (isPinnedFun(selectedTractor.id)) {
                      dispatch({
                        type: SET_SHOW_REMOTE_DRIVE_DETAILS,
                        payload: false,
                      });
                      dispatch({
                        type: SELECTED_TRACTORS,
                        payload: [],
                      });
                    } else {
                      const [id] = pinnedTractors;
                      const [ftractor] = tractorsList.filter(
                        (tractor: any) => tractor.id === id,
                      );
                      dispatch({
                        type: SELECTED_TRACTORS,
                        payload: [ftractor],
                      });
                    }
                    unPinnedTractors(selectedTractor.id);
                    handleResetCommand('');
                  } else if (pinnedTractors.length > 1) {
                    const list = pinnedTractors.filter(
                      (item: any) => item !== selectedTractor.id,
                    );
                    const [ftractor] = tractorsList.filter(
                      (tractor: any) => tractor.id === list[0],
                    );
                    dispatch({
                      type: SELECTED_TRACTORS,
                      payload: [ftractor],
                    });
                    handleResetCommand('');
                    unPinnedTractors(selectedTractor.id);
                  } else {
                    dispatch({
                      type: SET_SHOW_REMOTE_DRIVE_DETAILS,
                      payload: false,
                    });
                    dispatch({
                      type: SELECTED_TRACTORS,
                      payload: [],
                    });
                    handleResetCommand('');
                  }
                }}
                data-testid="closeIcon-RemoteDriveDetailsNew"
              ></div>
              <div className="adTabsHeadSec">
                <div
                  className="headline3 adtabsHeadText"
                  data-testid="tractorName-RemoteDriveDetailsNew"
                >
                  {selectedTractor?.name}
                </div>
                <div
                  className={`ttractor_state  ${getStatusClass(
                    selectedTractor.isAvailable,
                    selectedTractor?.heartbeat?.drive_action_details
                      ?.drive_action_created_at,
                  )}`}
                >
                  {getStatusForTractor(
                    selectedTractor.isAvailable,
                    selectedTractor?.heartbeat?.drive_action_details
                      ?.drive_action_created_at,
                  )}
                </div>
              </div>
              <div className="adTabsContentSec" id="adTabsContentSec">
                <div className="rautoDriveStatus">
                  <div className="autoDrivetime">
                    <img src={hoursOfOp} alt="" className="hourIcon" />
                    <span className="ttipMeter body2 fReg">
                      {secondsToHours(
                        Number(selectedTractor?.heartbeat?.daily_hour_meter),
                      )}
                    </span>
                  </div>
                  <div className="autoDriverName body2">
                    {selectedTractor.isAvailable !==
                      tractorConstantsNew.off.key && (
                      <>
                        {getOperatorNameByHeartBeat(heartbeat).trim() !==
                          '' && <img className="t_lock" src={tCLock} />}
                        <span className="aDname body3">
                          {getOperatorNameByHeartBeat(heartbeat)}
                        </span>
                      </>
                    )}
                  </div>
                  <div className="autodriveStatusIcons">
                    {selectedTractor &&
                      selectedTractor.id &&
                      getIndicatorIcons(selectedTractor)}
                  </div>
                </div>
                {handleSeederShow(heartbeat?.implement) &&
                  checkTractorDrivingState(heartbeat, userDetails.id) &&
                  remainingTime > 0 && (
                    <div className="remainSeed">
                      <div className="remainSeedFlexBtn mb5">
                        <div className="remainSeedFlex">
                          <img src={seeder_refill} width={20} />
                          <span className="overline2">Remaining Seed</span>
                        </div>
                        <h6 className="overline3 fMed">
                          Refill in {getMinsFromSecs(remainingTime)} min
                        </h6>
                      </div>
                      {/* <div className="remainSeedProgress">
                        <Progress strokeLinecap="butt" percent={75} />
                        <h6 className="headline3 remainSeedValue">
                          1100<span className="overline3 fMed mb2">lbs</span>
                        </h6>
                      </div> */}
                    </div>
                  )}

                {checkDisconnectCheck(
                  userDetails,
                  selectedTractor,
                  autoDriveMonitorErrorCodes,
                ) && (
                  <div className="dp_flex mb15">
                    <button
                      className="adrDisconnectBtn Button fs10"
                      onClick={(e) => {
                        disConnectTractor(e);
                      }}
                    >
                      <span>
                        <img
                          src={adrDicBtn}
                          alt=""
                          className="adrBtnIcon"
                          height="8"
                        />
                      </span>
                      <span>Disconnect</span>
                    </button>
                  </div>
                )}
                <div className="tractorSpeedometer">
                  <div className="t_info_progress_circle ">
                    <div
                      className="progressbar"
                      style={{
                        background: `radial-gradient(
                            closest-side,
                            white 75%,
                            transparent 0 99.9%,
                            white 0
                          ),
                          conic-gradient(#5BCD66 ${batteryValue({
                            heartbeat: heartbeat,
                          })}%, #F5F7F6 0)`,
                      }}
                    >
                      <div
                        className={`tractor_status_img ${loginUserHaveInterruptsOrNot(
                          autoDriveInterruptMsgs,
                          selectedTractor?.id,
                          heartbeat,
                          userDetails?.id,
                          true,
                        )}
                        ${
                          (checkTractorDrivingState(
                            heartbeat,
                            userDetails.id,
                          ) &&
                            showAutoDrivePoorConnectivityLoader) ||
                          !isOnline
                            ? 'grayout'
                            : ''
                        }
                        `}
                      >
                        <div className="tractorImage">
                          {' '}
                          <img
                            src={getTractorImage(
                              selectedTractor?.isAvailable,
                              selectedTractor?.tractor_model,
                            )}
                          />
                        </div>
                        {/* display if not charging,offline and available */}
                        {selectedTractor.isAvailable !==
                          tractorConstantsNew.available.key &&
                          selectedTractor.isAvailable !==
                            tractorConstantsNew.faulty.key &&
                          selectedTractor.isAvailable !==
                            tractorConstantsNew.off.key &&
                          selectedTractor.isAvailable !==
                            tractorConstantsNew.charging.key &&
                          selectedTractor.isAvailable !==
                            tractorConstantsNew.service.key && (
                            <div className="t_speed_cont">
                              <span className="type">{getFNR(heartbeat)}</span>
                              <span className="speed headline1">
                                {checkTractorDrivingState(
                                  heartbeat,
                                  userDetails.id,
                                ) && velocity > 0
                                  ? getSpeedFromVelocity(velocity)
                                  : getSpeed(heartbeat)}{' '}
                              </span>
                              <span className="measure">mph</span>
                            </div>
                          )}
                        <div className="tractorPasued headline5">
                          {loginUserHaveInterruptsOrNot(
                            autoDriveInterruptMsgs,
                            selectedTractor?.id,
                            heartbeat,
                            userDetails?.id,
                          )}
                        </div>
                      </div>
                      <div
                        className="rotate"
                        style={{
                          transform: `${`rotate(${
                            batteryValue({
                              heartbeat: heartbeat,
                            }) * 3.6
                          }deg)`}`,
                        }}
                      >
                        <div className="counterrotate">
                          <div
                            className="inner"
                            style={{
                              transform: `${`rotate(-${
                                batteryValue({
                                  heartbeat: heartbeat,
                                }) * 3.6
                              }deg)`}`,
                            }}
                          >
                            <div
                              className="innerInner"
                              data-testid="chargingPercentage-RemoteDriveDetailsNew"
                            >
                              {batteryValue({
                                heartbeat: heartbeat,
                              })}
                              %
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {!hideOnTractorConnect() && (
                    <>
                      <span className="trip-meter body3">
                        <span className="tripLbl">TRIP</span>
                        <span>{getTripMeterValue(heartbeat)}</span>
                      </span>
                    </>
                  )}
                  {!isOnline &&
                    checkTractorDrivingState(heartbeat, userDetails.id) && (
                      <div className="collision_msg">
                        Browser lost connection. Pause/Resume will not work &
                        Information displayed may be inaccurate. Collision
                        Avoidance is still active.
                      </div>
                    )}
                  {checkTractorDrivingState(heartbeat, userDetails.id) &&
                    showAutoDrivePoorConnectivityLoader && (
                      <div className="collision_msg">
                        Information displayed may be inaccurate due to poor
                        connectivity. Collision Avoidance is still active.
                      </div>
                    )}
                </div>
                {!hideOnTractorConnect() && (
                  <>
                    {!showInterruptMsg(
                      autoDriveInterruptMsgs,
                      selectedTractor,
                      userDetails,
                    ) &&
                      showEndAndDisconnect === tractorEndPopDefault && (
                        <RemoteDriveActionsNewDesign
                          showDeleteConfirm={(tractorId: number) =>
                            setShowEndAndDisconnect(tractorId)
                          }
                        />
                      )}
                    {showSideFeedBack &&
                      showSideFeedBack[selectedTractor.id] && (
                        <AutoDriveFeedBackPop
                          showModal={true}
                          type={2}
                          disConnectTractor={disConnectTractor}
                        />
                      )}

                    <MaintenanceAndStartAutoDrive
                      setShowEndAndDisconnect={setShowEndAndDisconnect}
                      stopAndDisconnectHandler={stopAndDisconnectHandler}
                      showEndAndDisconnect={showEndAndDisconnect}
                      clearAll={clearAll}
                      setAutonomyFlag={setAutonomyFlag}
                    />
                    <div className="autoDriveScoller">
                      <div className="autoDriveCamViews">
                        {selectedTractor && selectedTractor.isAvailable && (
                          <>
                            {showCameras ? (
                              <Cameras
                                orgId={userDetails?.organization?.id}
                                triggerSnapshot={createSnapshots}
                                selectedTractorId={selectedTractor.id}
                                tractorName={selectedTractor.name}
                                model={selectedTractor?.tractor_model}
                              />
                            ) : (
                              <div className="video_unavilable">
                                <span>
                                  <img
                                    src={errorIcon}
                                    alt=" "
                                    className="errorIcon"
                                  />
                                </span>
                                <p>
                                  Live video is unavailable due to poor tractor
                                  connectivity and may not return for the
                                  remainder of your Autodrive
                                </p>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {getSystemPausedInterrupts(
                      autoDriveInterruptMsgs,
                      selectedTractor.id,
                    ).length === 0 &&
                      selectedTractor.isAvailable ===
                        tractorConstantsNew.available.key && (
                        <div
                          className={`tImplement ${
                            getImplementName(selectedTractor) === noneAttached
                              ? 'disableRoutine'
                              : ''
                          }`}
                        >
                          <span>
                            {getImplementName(selectedTractor) ===
                            noneAttached ? (
                              <img
                                src={fleet_implement_new}
                                alt=""
                                className="mr12"
                              />
                            ) : (
                              <img
                                src={hitch_implement_S}
                                alt="implement"
                                width="16"
                                height="16"
                                className="mr12"
                              />
                            )}
                          </span>
                          <span className="Button">
                            {` ${getImplementName(selectedTractor)}`}
                          </span>
                        </div>
                      )}
                  </>
                )}
              </div>
            </div>
            {!hideOnTractorConnect() && <PinnedTractors />}
          </div>

          <div className="rautoDriveRightWrapper"></div>
        </div>
        {showPathModal && (
          <GuardrailWidget
            handleIgnore={() => handleIgnore('ignore_and_continue')}
          />
        )}
        {hideOnTractorConnect() && (
          <div className="connectAutoDriveLoader btm20vh">
            {isVineYardOrDairy(userDetails as UserDetails) ===
              dairyAutoDrive && (
              <div className="posAbsBeta">
                <span className="beta-btn">BETA</span>
              </div>
            )}

            <div className="loader">
              <img
                // style={{ width: '40px', height: '40px' }}
                src={connecting_generating_path}
              />
            </div>
            <div className="createPath">Connecting to tractor</div>
            {/* <div
              className="cancelPathBtn"
              onClick={() => {
                dispatch({
                  type: SET_SHOW_CONNECT_LOADER,
                  payload: false,
                });
              }}
            >
              Cancel
            </div> */}
          </div>
        )}
        {/* connecting_generating_path.gif */}
      </div>
      {hideOnTractorConnect() && showConnectTryAgainPop && (
        <CommonPopAutoDrive
          showModal={true}
          closeModel={(state: boolean) => {
            // first=> button return always false
            // second => button return always true
            dispatch({
              type: SET_SHOW_CONNECT_TRY_AGAIN_POP,
              payload: false,
            });
            console.log(state);
            handleConnectCloseHandler(state);
          }}
          title="Unable to Connect to Tractor"
          description="Please try again or move tractor to a different location with better connectivity."
        />
      )}
      {checkAutoDriveMonitorErrorCodes(
        autoDriveMonitorErrorCodes,
        selectedTractor?.id,
      ) &&
        errorCodeFlag && (
          <Errorcodepopup showModal={errorCodeFlag} closeModel={closeModel} />
        )}
      {showAutoDrive() && (
        <>
          {isVineYardOrDairy(userDetails as UserDetails) ===
          vineYardAutoDrive ? (
            <div className="auto-drive-routine">
              <AutoDriveRoutine
                handleResetCommand={handleResetCommand}
                preCommandState={preCommandState}
                addRowFollowRVCommand={addRowFollowRVCommand}
                clearPreviewPath={clearPreviewPath}
                setShowEndAutoDrive={setShowEndAutoDrive}
              />
            </div>
          ) : (
            <DairyAutoDrive
              handleResetCommand={handleResetCommand}
              setShowDairyEndAutoDrive={setShowDairyEndAutoDrive}
              clearPreviewPath={clearPreviewPath}
            />
          )}
        </>
      )}
      {showDairyEndAutoDrive && (
        <CommonPopAutoDrive
          showModal={true}
          closeModel={(state: boolean) => {
            // first=> button return always false
            // second => button return always true
            if (state) {
              clearTheAutoDrive(state);
            }
            setShowDairyEndAutoDrive(false);
          }}
          title="Exit Autodrive?"
          description="Exiting will end the Autodrive and disconnect from the tractor?"
          firstButtonText="Cancel"
          secondButtonText="Exit and Disconnect"
        />
      )}
      {/* reconnecting popup */}
      {showEndAutoDrive && (
        <CommonPopAutoDrive
          showModal={true}
          closeModel={(state: boolean) => {
            // first=> button return always false
            // second => button return always true
            if (state) {
              clearTheAutoDrive(state);
            }
            setShowEndAutoDrive(false);
          }}
          title="Are You Sure?"
          description="Exiting Autodrive Setup will not save your progress and will disconnect you from the tractor."
          firstButtonText="Cancel"
          secondButtonText="Exit"
        />
      )}
      {autonomyFlag && (
        <DriveOptionSettingModal
          showModal={autonomyFlag}
          handleClose={(details?: any) => {
            details && addImplementSettings(details, false);
            setAutonomyFlag(false);
          }}
          id={selectedImplement}
          tractorId={selectedTractor.id}
          driveActionUUID={
            selectedTractor?.heartbeat.drive_action_details
              ?.current_drive_action as string
          }
        />
      )}
    </>
  );
};

export default RemoteDriveDetailsNew;

import moment from 'moment';
import Point from 'ol/geom/Point';

export const fetchName = (firstName: string, lastName: string) => {
  return firstName && lastName
    ? firstName?.slice(0, 1).toLocaleUpperCase() +
        lastName?.slice(0, 1)?.toLocaleUpperCase()
    : '';
};

export const getUserName = (firstName: string, lastName: string) => {
  return lastName ? `${firstName} ${lastName}` : firstName ?? '';
};

export const getHoursAndMinutes = (timeString: string) => {
  const operations = {
    hours: timeString ? timeString?.split(':')?.[0] : 0,
    minutes: timeString ? timeString?.split(':')?.[1] : 0,
  };
  return operations;
};

export const getHrsMinsFromMins = (mins: number) => {
  if (!mins) return { hours: 0, minutes: 0 };
  const hours = Math.floor(mins / 60);
  const minutes = mins ? Number(mins) % 60 : 0;
  return {
    hours: String(hours).padStart(2, '0'),
    minutes: String(minutes).padStart(2, '0'),
  };
};

export const defaultDates = [moment().subtract(7, 'days'), moment()];

export const convertDateFormat = (dateString: string): string => {
  // Split the date string into year, month, and day components
  const [year, month, day] = dateString.split('-');

  // Format the date components into 'MM/dd' format
  const formattedDate = `${month}/${day}`;

  return formattedDate;
};

export const getCurrentResolution = (screens: any) => {
  const fscreens = Object.entries(screens).filter((screen) => !!screen[1]);
  const resolution = fscreens[fscreens.length - 1] || [];
  return resolution[0];
};

export const changeTimeFormat = (epochTime: number) => {
  const date = new Date(epochTime);

  // Extract the components of the date
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return new Date(year, month, day, hours, minutes);
};

export const getColorCodeByOpType = (op_type: string) => {
  switch (op_type) {
    case 'manual':
      return '#67afb7';
    case 'idle':
      return '#cfcfcf';
    case 'op_assist':
      return '#f1ce90';
    case 'remote_av':
      return '#ec9322';
    default:
      return '#67afb7';
  }
};
export const handleSameLocationFeatures = async (
  features: any,
  distance = 5,
) => {
  const featuresByCoordinates: any = {};
  if (features && features.length > 0) {
    await features.map((feature: any) => {
      if (feature) {
        const coordinates1 = feature
          ? feature?.getGeometry()?.getCoordinates()
          : [];
        const key = coordinates1?.toString();
        if (key) {
          if (!featuresByCoordinates[key]) {
            featuresByCoordinates[key] = [];
          }

          featuresByCoordinates[key].push(feature);
        }
      }
    });

    Object.keys(featuresByCoordinates).forEach((coordString) => {
      const features = featuresByCoordinates[coordString];

      if (features.length > 1) {
        // Multiple features at the same location
        features.forEach((feature: any) => {
          const geometry: any = feature.getGeometry();
          const co = geometry.getCoordinates();
          feature.setGeometry(
            new Point([
              co[0] * Number(`1.00000${Math.floor(Math.random() * distance)}`),
              co[1] * Number(`1.00000${Math.floor(Math.random() * distance)}`),
            ]),
          );
        });
      }
    });
  }
};
export const handleSameLocationChangeFeatures = async (
  features: any,
  distance = 5,
) => {
  const featuresByCoordinates: any = [];
  if (features && features.length > 0) {
    features.reduce(
      (
        a: { data: any[][]; i: number | undefined; last_item: any },
        path_pt: any,
        i: number,
      ) => {
        if (i < features.length - 1) {
          const coords1 = path_pt.getGeometry().getCoordinates();
          const coords2 = features[i + 1].getGeometry().getCoordinates();
          const diffX = coords2[0] - coords1[0];
          const diffY = coords2[1] - coords1[1];
          if (
            (diffX > -0.002 && diffX < 0.002) ||
            (diffY < 0.002 && diffY > -0.002)
          ) {
            featuresByCoordinates.push(path_pt);
          }
        }
      },
    );

    if (featuresByCoordinates.length > 0) {
      featuresByCoordinates.forEach((feature: any) => {
        const geometry: any = feature.getGeometry();
        const co = geometry.getCoordinates();
        feature.setGeometry(
          new Point([
            co[0] * Number(`1.00000${Math.floor(Math.random() * distance)}`),
            co[1] * Number(`1.00000${Math.floor(Math.random() * distance)}`),
          ]),
        );
      });
    }
  }
};

export const findNearestTimestamp = (
  timestamps: { created_date_time: number }[],
  targetTimestamp: number,
): number | null => {
  if (timestamps.length === 0) {
    return 0; // Return null if the array is empty
  }
  let index = 0;

  let nearestTimestamp = timestamps[0].created_date_time; // Start by assuming the first timestamp is the nearest
  let minDifference = Math.abs(targetTimestamp - nearestTimestamp);

  // Iterate through the array to find the nearest timestamp
  for (let i = 1; i < timestamps.length; i++) {
    const currentTimestamp = timestamps[i].created_date_time;
    const difference = Math.abs(targetTimestamp - currentTimestamp);

    // Update nearestTimestamp if the current timestamp is closer to the target
    if (difference < minDifference) {
      minDifference = difference;
      nearestTimestamp = currentTimestamp;
      index = i;
    }
  }

  return index;
};
export interface selectedPath {
  created_date_time: number;
  drive_action_uuid: string;
  fromClick: mapClickType;
}

export type mapClickType = 'default' | 'map' | 'chart';

export const removeItem = (list: string[], item: string): string[] =>
  list.filter((listItem) => listItem !== item);

export const isActiveClass = (
  list: any[],
  item: string,
  className: string,
): string => (list.includes(item) ? className : '');

export const isEmpty = (value: any) => {
  switch (typeof value) {
    case 'number':
      return false;
    case 'string':
      return value?.trim() === '';
    case 'undefined':
      return true;
    case 'object':
      return !Array.isArray(value);
    default:
      return false;
  }
};

export const convertToDecimalHours = (hours: number, minutes: number) => {
  return (hours + minutes / 60).toFixed(2);
};

export const getValue = (item: any) => {
  if (item) {
    const time = item.split(':');
    return convertToDecimalHours(parseInt(time[0], 10), parseInt(time[1], 10));
  } else {
    return 0;
  }
  // return item ? item.replace(':', '.') : 0;
};

export const getTotalHours = (time: string) => {
  if (!time) return '0';
  const fTime = time?.split(':');
  if (fTime[0] === '0') {
    return `${fTime[1]}m`;
  }
  return `${fTime[0]}h ${fTime[1]}m`;
};

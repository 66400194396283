import { Col, Row, Tabs } from 'antd';
import React, { useContext, useEffect, useState } from 'react';

import imp_icon from './images/implement-1.svg';
import user_fill from './images/user-fill-1.svg';

import Efficiency from './efficiency';
import DateRangePicker from './ui/DateRangePicker';

import useAnalytics from './hooks/useAnalytics';
import useStore from './store/operationAnalytics';
import useOperatorAnalyticStore from './store/operatorAnalyticStore';

import { ApplicationContext } from '../../context/AppContext';

import OperationalCoverageMap from './OperationalCoverageMap/OperationalCoverageMap';

import MultiStackedBarChart from '../charts/MultiStackedBarChart';
import StackedBarChart from '../charts/StackedBarChart';

import TotalSavings from './totalSavings';
import { WeekendsFilter } from './ui/helper';

import './css/global.scss';
import './css/styles.scss';
import './styles.scss';
import { pathColorCodes } from './constants';

const { TabPane } = Tabs;

interface Props {
  onChangeOperation: (type: string, selectedTab: string) => void;
}

const OperationAnalytics: React.FC<Props> = ({ onChangeOperation }: Props) => {
  const {
    operationalHours,
    coverage,
    toggleWeekends,
    includeWeekends,
    operationalHoursByOperations,
    operationalHoursByOperators,
    coverageByOperators,
    coverageByOperations,
    setDateRange,
    dateRange,
    setView,
  } = useStore();
  const {
    operationsLoader,
    initOperationalHoursAndCoverageData,
    dateRangeText,
  } = useAnalytics();
  const { setOperatorId } = useOperatorAnalyticStore();
  const { userDetails } = useContext(ApplicationContext);
  const [handleType, setHandleType] = useState<string>('operations');
  const [tabid, setTabid] = useState('operationalHours');

  useEffect(() => {
    if (userDetails?.organization) {
      const [fromDate, toDate] = dateRange;
      initOperationalHoursAndCoverageData(
        fromDate?.startOf('day')?.toDate()?.getTime(),
        toDate?.endOf('day')?.toDate()?.getTime(),
      );
    }
  }, [dateRange, userDetails]);

  const handleOperatorsType = () => {
    setHandleType((prevType) =>
      prevType === 'operations' ? 'operators' : 'operations',
    );
  };

  const OnOperationsClick = (category: string) => {
    onChangeOperation(category, tabid);
    setView(category === 'No Implement' ? 'no_implement' : 'operations');
  };

  const OnOperatorClick = (operator: string, data?: any) => {
    onChangeOperation(operator, tabid);
    setView('operator');
    setOperatorId(data?.operator_id);
  };

  return (
    <>
      <section className="background-parent">
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={10}>
            <div className="frame-parent">
              <div className="frame-wrapper">
                <div className="frame-group">
                  <div className="november-3rd-9th-parent">
                    <h1 className="november-3rd">{dateRangeText}</h1>
                    <DateRangePicker
                      handleOnChange={(dates) => setDateRange(dates)}
                      selectedDates={dateRange}
                    />
                  </div>
                  <div className="select-an-operation">
                    Select an operation type on the graph below for specific
                    analytics and videos.
                  </div>
                </div>
              </div>
              <div className="operations-graph">
                <section className="op_tabs_sec">
                  <div className="toggle-placement">
                    <input
                      type="checkbox"
                      id="toggle"
                      className="toggleCheckbox"
                      onChange={handleOperatorsType}
                    />
                    <label htmlFor="toggle" className="toggleContainer">
                      <div>
                        <img src={imp_icon} />
                        <span className="opTgl">Operations</span>
                      </div>
                      <div>
                        <img src={user_fill} />
                        <span className="opTgl">Operators</span>
                      </div>
                    </label>
                  </div>
                  <Tabs
                    defaultActiveKey="1"
                    activeKey={tabid}
                    onChange={(key) => {
                      setTabid(key);
                    }}
                  >
                    <TabPane
                      tab={
                        <>
                          <div>
                            <div className="op_tab_title">
                              Operational Hours
                            </div>
                          </div>

                          <div className="wrapper">
                            <div className="div34">
                              <span className="span">
                                {handleType === 'operations'
                                  ? operationalHours?.operations?.hours
                                  : operationalHours?.operators?.hours}
                              </span>
                              <span className="h">h</span>
                              <span className="span1">
                                {' '}
                                {handleType === 'operations'
                                  ? operationalHours?.operations?.minutes
                                  : operationalHours?.operators?.minutes}
                              </span>
                              <span className="m1">m</span>
                            </div>
                          </div>
                        </>
                      }
                      key="operationalHours"
                    >
                      {handleType === 'operations' && (
                        <MultiStackedBarChart
                          className="msBarChart"
                          loader={operationsLoader}
                          chartId="OperationalHoursByOperations"
                          data={operationalHoursByOperations}
                          barColors={[
                            pathColorCodes.manual,
                            pathColorCodes.op_assists,
                            pathColorCodes.autonomy,
                            pathColorCodes.idle,
                          ]}
                          yTitle="Hours Of Operation"
                          onClick={OnOperationsClick}
                        />
                      )}
                      {handleType === 'operators' && (
                        <StackedBarChart
                          className="msBarChart"
                          loader={operationsLoader}
                          chartId="operationalHoursByOperators"
                          barColors={[
                            pathColorCodes.manual,
                            pathColorCodes.op_assists,
                            pathColorCodes.autonomy,
                            pathColorCodes.idle,
                          ]}
                          labels={['level1', 'level2', 'level4', 'level5']}
                          data={operationalHoursByOperators}
                          yTitle="Hours of Operation"
                          onClick={OnOperatorClick}
                          dataType="time"
                        />
                      )}
                    </TabPane>
                    <TabPane
                      tab={
                        <div>
                          <div className="op_tab_title">Coverage (ac)</div>
                          <div className="container">
                            <div className="div35">
                              {handleType === 'operations'
                                ? coverage?.operations
                                : coverage?.operators}
                            </div>
                          </div>
                        </div>
                      }
                      key="coverage"
                    >
                      {handleType === 'operations' && (
                        <StackedBarChart
                          className="msBarChart"
                          loader={operationsLoader}
                          chartId="CoverageByOperations"
                          barColors={[pathColorCodes.manual]}
                          labels={['total']}
                          data={coverageByOperations}
                          yTitle="Acres Covered"
                          onClick={OnOperationsClick}
                          dataType="area"
                          type="operationscoverage"
                        />
                      )}
                      {handleType === 'operators' && (
                        <StackedBarChart
                          className="msBarChart"
                          loader={operationsLoader}
                          chartId="CoverageByOperators"
                          barColors={[
                            pathColorCodes.manual,
                            pathColorCodes.op_assists,
                            pathColorCodes.autonomy,
                            pathColorCodes.idle,
                          ]}
                          labels={['level1', 'level2', 'level4', 'level5']}
                          data={coverageByOperators}
                          yTitle="Acres Covered"
                          onClick={OnOperatorClick}
                          dataType="area"
                        />
                      )}
                    </TabPane>
                  </Tabs>
                </section>
              </div>
              <Efficiency onOperationsClick={OnOperationsClick} />
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={14}>
            <div className="scale-scientist">
              <WeekendsFilter
                includeWeekends={includeWeekends}
                toggleWeekends={toggleWeekends}
              />

              <OperationalCoverageMap />
              <TotalSavings />
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default OperationAnalytics;

import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import CustomMultiSelect from './CustomMultiSelect';
import { ApplicationContext } from '../../context/AppContext';
import { cropTypes, defaultSelectedOrg } from '../admin/Common';
import { getOrganizations } from '../admin/api';
import { notification } from 'antd';

interface Props {
  handleOrgs: (ids: string[]) => void;
  defaultOrgs?: any[];
}

const CropOrgFilter: React.FC<Props> = ({ handleOrgs, defaultOrgs }) => {
  const { userDetails } = useContext(ApplicationContext);
  const [selectedCrop, setSelectedCrop] = useState<any>([]);
  const [selectedInternalOrgs, setSelectedInternalOrgs] = useState<string[]>(
    [],
  );
  const [selectedExternalOrgs, setSelectedExternalOrgs] = useState<string[]>(
    [],
  );
  const [organizations, setOrganizations] = useState<any>([]);
  const [internalOrgs, setInternalOrgs] = useState<any>([]);
  const [externalOrgs, setExternalOrgs] = useState<any>([]);
  const init = useRef<boolean>(false);

  const cropTypeList = useMemo(
    () =>
      cropTypes.map((data: any) => ({
        title: <span className="ellipsis">{data.title}</span>,
        value: `${data.value}`,
      })),
    [cropTypes],
  );

  useEffect(() => {
    return () => {
      setOrganizations([]);
      setExternalOrgs([]);
      setInternalOrgs([]);
      setSelectedExternalOrgs([]);
      setSelectedInternalOrgs([]);
      setSelectedCrop([]);
    };
  }, []);

  useEffect(() => {
    const defaultSelectedCrops = cropTypes.map((item: any) => item.value);
    setSelectedCrop(defaultSelectedCrops);
  }, [cropTypes]);

  const selectCrops = (ids: any) => {
    selectExternalOrgs([]);
    selectInternalOrgs([]);
    setSelectedCrop(ids);
  };

  const selectExternalOrgs = (ids: any) => {
    setSelectedExternalOrgs(ids);
  };

  const selectInternalOrgs = (ids: any) => {
    setSelectedInternalOrgs(ids);
  };

  const mapDropdown = (list: any[]) =>
    list
      ?.sort((a: any, b: any) =>
        a?.name?.toLowerCase() > b?.name?.toLowerCase() ? 1 : -1,
      )
      .map((data: any) => ({
        title: <span className="ellipsis">{data.name}</span>,
        value: `${data.id}`,
      }));

  useEffect(() => {
    const initData = async () => {
      try {
        const { organization } = userDetails;
        const records = await getOrganizations(
          organization.api_url,
          selectedCrop,
        );
        const list =
          records && records.length && records.length > 0 ? records : [];

        setOrganizations([...list]);
        init.current = true;
      } catch (error: any) {
        notification.error({
          message: error.message,
          duration: 1,
        });
      }
    };
    if (userDetails?.organization && selectedCrop.length) initData();
  }, [userDetails, selectedCrop]);

  useEffect(() => {
    if (!defaultOrgs) {
      if (userDetails.organization?.account_type === 'dealer') {
        const defaultSelectedIds = organizations.map((item: any) =>
          String(item.id),
        );
        setSelectedExternalOrgs(defaultSelectedIds);
      } else {
        const defaultSelectedIds = organizations
          .filter((org: any) => defaultSelectedOrg(org))
          .map((item: any) => String(item.id));
        setSelectedExternalOrgs(defaultSelectedIds);
      }
    }
    let externalOrgs = organizations.filter((org: any) =>
      defaultSelectedOrg(org),
    );
    externalOrgs = mapDropdown(externalOrgs);
    setExternalOrgs([...externalOrgs]);
    let internalOrgs = organizations.filter(
      (org: any) => !defaultSelectedOrg(org),
    );
    internalOrgs = mapDropdown(internalOrgs);
    setInternalOrgs([...internalOrgs]);
  }, [organizations]);

  useEffect(() => {
    if (defaultOrgs && externalOrgs.length && internalOrgs.length) {
      const fExternalOrgs = externalOrgs
        .filter((org: any) => defaultOrgs.includes(org.value))
        .map((org: any) => org.value);
      const fInternalOrgs = internalOrgs
        .filter((org: any) => defaultOrgs.includes(org.value))
        .map((org: any) => org.value);
      setSelectedExternalOrgs([...fExternalOrgs]);
      setSelectedInternalOrgs([...fInternalOrgs]);
    }
  }, [defaultOrgs, externalOrgs, internalOrgs]);

  useEffect(() => {
    const orgs = [...selectedExternalOrgs, ...selectedInternalOrgs];
    handleOrgs([...orgs]);
    if (init.current && orgs.length) {
      init.current = false;
    }
  }, [selectedExternalOrgs, selectedInternalOrgs]);

  return (
    <>
      <CustomMultiSelect
        handleTreeSelect={selectCrops}
        selectedId={selectedCrop}
        list={cropTypeList}
        label="Crops"
      />
      <CustomMultiSelect
        handleTreeSelect={selectExternalOrgs}
        selectedId={selectedExternalOrgs}
        list={externalOrgs}
        label="External Organizations"
      />
      <CustomMultiSelect
        handleTreeSelect={selectInternalOrgs}
        selectedId={selectedInternalOrgs}
        list={internalOrgs}
        label="Internal Organizations"
      />
    </>
  );
};

export default CropOrgFilter;

import { Button } from 'antd';
import React from 'react';
import alert_circle_red from '../../assets/images/alert_circle_red.svg';
import tickets_icon from '../../assets/images/tickets_icon.svg';

interface Props {
  hoverList: any;
  setTabid: any;
  setEquipId: any;
  tickettId?: any;
  setTicketId?: any;
}
const ScheduleFailedRemarks: React.FC<Props> = ({
  hoverList,
  setTabid,
  setEquipId,
  tickettId,
  setTicketId,
}) => {
  const viewTicket = () => {
    setTabid('2');
    setEquipId(hoverList.id);
    setTicketId(tickettId);
  };

  return (
    <div className="schRemarks">
      {tickettId ? (
        <>
          <div className="fleetContent mb16">
            Monarch Support has been contacted via Ticket #{tickettId} to
            resolve this issue. The tractor is still operable on the previous
            version.
          </div>
          <Button className="viewTicketBtn" onClick={viewTicket}>
            <img src={tickets_icon} alt="" className="imgmr6" />
            View Ticket
          </Button>
        </>
      ) : (
        <>
          <div className="fleetContent mb16">
            Update could not start due to following issues. Please try again.
          </div>
          <div className="updateCon mt12">
            <img src={alert_circle_red} alt="" />
            <div className="fleetContent ml5">{hoverList?.scheduleRemarks}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default ScheduleFailedRemarks;

/* eslint-disable react/jsx-key */
import { Button, notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { getInstallList, getSummary } from '../../constants/types';
import moment from 'moment';
import { ApplicationContext } from '../../context/AppContext';
import { LeftOutlined } from '@ant-design/icons';
import { otaSchedule } from '../../constants/Api';
import scheduled from '../../assets/images/scheduled.svg';
import { CommonRightSchedule } from './CommonRightSchedule';
import { CommonReleaseNotes } from './CommonReleaseNotes';
import check_circle_fill from '../../assets/images/check_circle_fill.svg';

interface Props {
  closeModal: () => void;
  closeAndUpdate: () => void;
  tractorsList: getInstallList[];
  scheduleDate: any;
  tractorIds: any;
  selectedTime: any;
  summaryList: getSummary;
  setTractorsList: (d: any) => void;
  setSelectedIds: (d: any) => void;
}

export const ConfirmSchedule: React.FC<Props> = ({
  closeModal,
  closeAndUpdate,
  tractorsList,
  scheduleDate,
  tractorIds,
  selectedTime,
  summaryList,
  setTractorsList,
  setSelectedIds,
}) => {
  const { userDetails } = useContext(ApplicationContext);
  const [seeAll, setSeeAll] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [showComplete, setShowComplete] = useState<boolean>(false);

  useEffect(() => {
    if (tractorsList.length > 0) {
      setDisable(false);
    } else setDisable(true);
  }, [tractorsList]);

  const otaScheduleUpdate = async () => {
    const combinedDateTime =
      moment(scheduleDate).format('YYYY-MM-DD') +
      ' ' +
      selectedTime.format('h:mm A');
    const combinedMilliseconds = moment(
      combinedDateTime,
      'YYYY-MM-DD h:mm A',
    ).valueOf();
    try {
      setLoading(true);
      setShowComplete(false);
      const payload = {
        tractor_ids: tractorIds,
        organization_id: userDetails.organization.id,
        scheduled_date_time: combinedMilliseconds,
        scheduled_date: moment(scheduleDate).format('YYYY-MM-DD'),
      };
      await otaSchedule(userDetails.organization.api_url, payload);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setTimeout(() => {
        setLoading(false);
        setShowComplete(true);

        setTimeout(() => {
          setShowComplete(false);
          notification.success({
            message: 'OTA scheduled created successfully',
          });
          closeAndUpdate();
        }, 2000);
      }, 2000);
    }
  };

  const removeSchItems = (id: number) => {
    const updatedSelectedIds = [...tractorIds];
    updatedSelectedIds.splice(updatedSelectedIds.indexOf(id), 1);
    setSelectedIds(updatedSelectedIds);
    const filterList = tractorsList.filter((d: any) => d.id !== id);
    setTractorsList(filterList);
  };

  return (
    <>
      <div className="scheduleFleet">
        <div
          className="updateArrow"
          onClick={() => {
            !seeAll ? closeModal() : setSeeAll(false);
          }}
        >
          <LeftOutlined />
        </div>
        <div className="otaHead mb0">
          {!seeAll ? 'Confirm Schedule' : 'Release Notes'}
        </div>
      </div>
      {!seeAll ? (
        <>
          <div className="updateVersionTxt">
            All requirements must be met for the tractors to update
            automatically. You will not be able to operate the tractors or use
            the Smart Screen during that time.
          </div>

          <div className="scheduleGrid">
            <div className="schUpdateGridBg">
              <div>
                <div className="tractorContainer borderBtm">
                  <div className="otaHead">
                    <img src={scheduled} alt="" height="24px" />{' '}
                    {moment(scheduleDate).format('dddd, MMM D')} at{' '}
                    {selectedTime.format('h:mm A')}
                  </div>
                  <div className="tractorsLength">{tractorsList.length}</div>
                </div>

                <div className="swVersionTxt">
                  {tractorsList &&
                    tractorsList.length > 0 &&
                    tractorsList?.map((item: any, i: number) => (
                      <div className="serialContainer plr15">
                        <div className="straName">{item?.name}</div>
                        <div
                          className="removeTxt"
                          onClick={() => removeSchItems(item.id)}
                        >
                          Remove
                        </div>
                      </div>
                    ))}
                </div>
                <div className="margin16">
                  <Button
                    className={showComplete ? 'confirmBtn' : 'nextBtn'}
                    onClick={otaScheduleUpdate}
                    disabled={disable}
                    loading={loading}
                  >
                    {showComplete ? (
                      <>
                        <img src={check_circle_fill} alt="" className="mr4" />{' '}
                        Confirmed
                      </>
                    ) : (
                      'Confirm Schedule'
                    )}
                  </Button>
                </div>
              </div>
            </div>
            <CommonRightSchedule
              summaryList={summaryList as any}
              setSeeAll={setSeeAll}
            />
          </div>
        </>
      ) : (
        <div className="releaseAllNotes">
          <CommonReleaseNotes summaryList={summaryList as any} />
        </div>
      )}
    </>
  );
};

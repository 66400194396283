/* eslint-disable no-console */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import './css/styles.scss';
import './css/global.scss';
import {
  Col,
  Input,
  Popover,
  Row,
  Space,
  Spin,
  Switch,
  Table,
  Tabs,
  Tooltip,
} from 'antd';
import user_fill from './images/user-fill-1.svg';
import decrease from './images/decrease.svg';
import increase from './images/increase.svg';
import Search from 'antd/lib/transfer/search';
import info_img from './images/info-3.svg';
import trip_outline from './images/trip_outline-01.svg';
import { ColumnsType } from 'antd/lib/table';
import bnatterycharger from './images/savings-01.svg';

import hr2 from './images/efficiency-01.svg';
import { ApplicationContext } from '../../context/AppContext';
import useAnalyticsDetails from './hooks/useAnalyticsDetails';
import useStore from './store/operationAnalytics';
import StackedBarChart from '../charts/StackedBarChart';
import useOperationalAnalyticDetailsStore from './store/operationAnalyticDetails';
import tractorAnalyticDetailsStore from './store/tractorAnalyticDetails';
import DateRangePicker from './ui/DateRangePicker';
import { isEmpty, fetchName, getHoursAndMinutes } from './common';
import LineChart from '../charts/LineChart';
import useAnalytics from './hooks/useAnalytics';
import { completedWorkInfoContent } from './constants';
import OperationalCoverageMap from './OperationalCoverageMap/OperationalCoverageMap';
import { HrsMins, WeekendsFilter } from './ui/helper';
import moment from 'moment';
import Spinner from '../common/Spinner';

const { TabPane } = Tabs;
interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}
interface Props {
  onBack: () => void;
  operationType: string;
  selectedTab: string;
}

const OperationAnalytics: React.FC<Props> = ({
  onBack,
  operationType,
  selectedTab,
}: Props) => {
  const { setSelectedDate } = useStore();
  const [tabid, setTabid] = useState('1');

  const {
    operationalHours,
    coverage,
    completedWork,
    energyCostSavingsData,
    implementTypeEfficiencyData,
    totalSavings,
    operationalHoursByOperations,
    coverageByOperations,
    addCompletedWorkData,
  } = useOperationalAnalyticDetailsStore();

  const { selectTractor } = tractorAnalyticDetailsStore();
  const {
    includeWeekends,
    dateRange,
    toggleWeekends,
    setDateRange,
    setView,
    setFromView,
    view,
  } = useStore();

  const {
    operationsLoader,
    efficiencyChartLoader,
    savingChartLoader,
    completedWorkLoader,
    initCompletedWorkData,
    initAnalyticsData,
    initImplementTypeEfficiencyData,
    initOperationalHoursAndCoverageData,
  } = useAnalyticsDetails();

  const { dateRangeText } = useAnalytics();
  const { userDetails } = useContext(ApplicationContext);

  useEffect(() => {
    setTabid(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    if (userDetails?.organization) {
      const [fromDate, toDate] = dateRange;
      initCompletedWorkData(
        operationType,
        fromDate?.toDate()?.getTime(),
        toDate?.toDate()?.getTime(),
      );
      initAnalyticsData(
        fromDate?.toDate()?.getTime(),
        toDate?.toDate()?.getTime(),
        operationType,
      );
      initOperationalHoursAndCoverageData(
        fromDate?.toDate()?.getTime(),
        toDate?.toDate()?.getTime(),
        operationType,
      );
      initImplementTypeEfficiencyData(
        fromDate?.toDate()?.getTime(),
        toDate?.toDate()?.getTime(),
        operationType,
      );
    }
    return () => {
      addCompletedWorkData([]);
    };
  }, [userDetails, dateRange]);

  // const dateRangeText = useMemo(() => {
  //   if (dateRange && !dateRange.length) return '';
  //   const [fromDate, toDate] = dateRange;
  //   return (
  //     <>
  //       {moment(fromDate).format('MMMM Do')} - {moment(toDate).format('Do')}
  //     </>
  //   );
  // }, [dateRange]);

  const operatorsBlock = useCallback((operators = []) => {
    return operators?.slice(0, 3).map((operator: any, key: number) => {
      const { first_name, last_name, id } = operator;
      return (
        <div key={id} className={`avatar-circles${key === 0 ? '' : key}`}>
          <div className="mm uppercase">
            {key !== 2 ? fetchName(first_name, last_name) : '+2'}
          </div>
        </div>
      );
    });
  }, []);

  const columns: ColumnsType<any> = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date) => {
        return date ? moment(date).format('MM/DD') : '';
      },
      // width: 60,
    },
    {
      title: 'Tractor',
      dataIndex: 'tractorShortPin',
      key: 'tractorShortPin',
      render: (value, record) => {
        return (
          <Tooltip title={record?.tractorPin}>
            <span>{value}</span>
          </Tooltip>
        );
      },
      // width: 80,
    },
    {
      title: 'Implement',
      dataIndex: 'implement',
      key: 'implement',
      ellipsis: true,
      // width: 150,
      render: (implement) => (!isEmpty(implement) ? implement : 'No Implement'),
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      ellipsis: true,
      render(value) {
        const data = getHoursAndMinutes(value);
        return <HrsMins hours={data?.hours} minutes={data?.minutes} />;
      },
      // width: 100,
    },
    {
      title: 'Coverage',
      dataIndex: 'coverage',
      key: 'coverage',
      ellipsis: true,
      render(value) {
        return <>{isEmpty(value) ? 0 : value} ac</>;
      },
      // width: 70,
    },
    {
      title: 'Operator',
      dataIndex: 'operators',
      key: 'operators',
      ellipsis: true,
      render(value) {
        return (
          <Space>{Array.isArray(value) ? operatorsBlock(value) : ''}</Space>
        );
      },
    },
  ];

  const getRow = (record: any) => {
    setFromView(view);
    selectTractor(record);
    setView('tractor');
    setSelectedDate(moment(record.date));
  };

  return (
    <>
      <section className="background-parent">
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={10}>
            <div className="frame-parent">
              <div className="frame-wrapper">
                <div className="frame-group">
                  <div className="back-header">
                    <div className="backButtonBig" onClick={onBack}></div>
                    <div className="header">
                      <div className="mowing">{operationType}</div>
                      <div className="date-picker-block">
                        <div className="date-month-selection">
                          {dateRangeText}
                        </div>
                        <div className="">
                          <DateRangePicker
                            handleOnChange={(dates) => setDateRange(dates)}
                            selectedDates={dateRange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="operations-graph">
                <section className="op_tabs_sec">
                  <div className="chip_container">
                    <div className="shape-compiler">
                      <img
                        className="implement-1-icon1"
                        alt=""
                        src={user_fill}
                      />

                      <div className="image-parser">
                        {operationalHoursByOperations?.length}
                      </div>
                    </div>
                  </div>
                  <Tabs
                    defaultActiveKey={tabid}
                    onChange={(key) => {
                      setTabid(key);
                    }}
                    activeKey={tabid}
                  >
                    <TabPane
                      tab={
                        <>
                          <div>
                            <div className="op_tab_title">
                              Operational Hours
                            </div>
                          </div>

                          <div className="wrapper">
                            <div className="div34">
                              <span className="span">
                                {operationalHours?.hours}
                              </span>
                              <span className="h">h</span>
                              <span className="span1">
                                {' '}
                                {operationalHours?.minutes}
                              </span>
                              <span className="m1">m</span>
                            </div>
                          </div>
                        </>
                      }
                      key="operationalHours"
                    >
                      <StackedBarChart
                        className="msBarChart"
                        loader={operationsLoader}
                        chartId="operationalHoursByOperators1"
                        barColors={['#67afb7', '#f1ce90', '#ec9322', '#cfcfcf']}
                        labels={['level1', 'level2', 'level4', 'level5']}
                        data={operationalHoursByOperations}
                        yTitle="Hours of Operation"
                        dataType="time"
                      />
                    </TabPane>
                    <TabPane
                      tab={
                        <div>
                          <div className="op_tab_title">Coverage (ac)</div>
                          <div className="container">
                            <div className="div35">{coverage}</div>
                          </div>
                        </div>
                      }
                      key="coverage"
                    >
                      <StackedBarChart
                        className="msBarChart"
                        loader={operationsLoader}
                        chartId="CoverageByOperators1"
                        barColors={['#67AFB7']}
                        labels={['level1']}
                        data={coverageByOperations}
                        yTitle="Acres Covered"
                        dataType="area"
                      />
                    </TabPane>
                  </Tabs>
                </section>
              </div>
              <div className="bottom-left-graphs operation2_left">
                <div className="header2">
                  <div className="header2">
                    <div className="title-info">
                      <div className="operations9">Completed Work</div>
                      <div className="info-bubbles">
                        {/* <img className="info-3-icon" src={info_img} /> */}
                        <Popover
                          overlayClassName="savingPopover"
                          placement="bottomLeft"
                          content={completedWorkInfoContent}
                          // trigger={['click']}
                        >
                          <img className="info-3-icon" src={info_img} />
                        </Popover>
                      </div>
                    </div>
                    <div className="shape-compiler">
                      <img
                        className="implement-1-icon1"
                        alt=""
                        src={trip_outline}
                      />

                      <div className="image-parser">
                        {completedWork?.length}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tableParent scrollBarPos1">
                  <Table
                    className="completedWorkTable"
                    columns={columns}
                    dataSource={completedWork}
                    scroll={{ y: 150 }}
                    pagination={{ pageSize: completedWork?.length }}
                    onRow={(record) => {
                      return {
                        onClick: (event) => getRow(record), // click row
                      };
                    }}
                    loading={{
                      indicator: (
                        <div>
                          <Spinner loader={true} />
                        </div>
                      ),
                      spinning: completedWorkLoader,
                    }}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={14}>
            <div className="scale-scientist">
              <WeekendsFilter
                includeWeekends={includeWeekends}
                toggleWeekends={toggleWeekends}
              />
              <OperationalCoverageMap operationType={operationType} />

              <div className="container-17 efc_savings">
                <div className="bottom-left-graphs1 no-gap">
                  <div className="header2 align-top">
                    <div className="align-left graph-call-outs6">
                      <div className="operations4 op-title">Efficiency</div>
                      <div className="proximity-provider">
                        <div className="layer-llama">
                          <span className="span2">
                            {implementTypeEfficiencyData?.totalEfficiency}{' '}
                          </span>
                          <span className="achr">ac/hr</span>
                        </div>

                        {/* <div className="comparative-statistic">
                          <img
                            className="increase-icon"
                            loading="lazy"
                            alt=""
                            src={increase}
                          />

                          <div className="blend-brewer">
                            {implementTypeEfficiencyData?.totalEfficiency}%
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div>
                      <img src={hr2} alt="" />
                    </div>
                  </div>
                  <div className="link-aggregator savings-graph">
                    <LineChart
                      loader={efficiencyChartLoader}
                      data={implementTypeEfficiencyData?.efficiencyData}
                      chartId="efficiency"
                      smooth={true}
                      points={true}
                      coverArea={true}
                      lineColor="#546cb2"
                      // height={115}
                    />
                  </div>
                </div>

                <div className="bottom-left-graphs1 no-gap">
                  <div className="header2 align-top">
                    <div className="align-left graph-call-outs6">
                      <div className="title-info">
                        <div className="operations op-title">Savings</div>
                        {/* <div className="info-bubbles">
                          <Popover
                            overlayClassName="savingPopover"
                            placement="bottomLeft"
                            content={avgBatteryInfoContent}
                            trigger={['click']}
                          >
                            <img className="info-3-icon" src={info_img} />
                          </Popover>
                        </div> */}
                      </div>
                      <div className="proximity-provider">
                        <div className="layer-llama">
                          <span className="span2">${totalSavings}</span>
                        </div>
                        {/* <div className="comparative-statistic2">
                          <img
                            className="decrease-icon1"
                            alt=""
                            src={decrease}
                          />
                          <div className="blend-brewer">8%</div>
                        </div> */}
                      </div>
                    </div>
                    <div>
                      <img src={bnatterycharger} alt="" />
                    </div>
                  </div>
                  <div className="link-aggregator savings-graph">
                    <LineChart
                      loader={savingChartLoader}
                      data={energyCostSavingsData}
                      chartId="savings"
                      smooth={true}
                      points={true}
                      coverArea={true}
                      lineColor="#546cb2"
                      height={115}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default OperationAnalytics;

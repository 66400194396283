/* eslint-disable no-console */
import { Button } from 'antd';
import React, { useContext, useEffect, useState } from 'react';

// import autodrive_icon from '../../assets/images/autodrive_btn_hover.svg';

import hi_gray_icon from '../../assets/images/hi_gray.svg';
// import hi_black_icon from '../../assets/images/hitch1.svg';
import {
  checkIsTractorConnectedNoATOMCond,
  checkTractorConnectedFromMobileState,
  checkTractorConnectedState,
  checkTractorDrivingState,
  getFloatText,
  getGearText,
  getHeight,
  getImplementName,
  getPTOState,
  getRpm,
  getSystemPausedInterrupts,
  noneAttached,
  showInterruptMsg,
  tractorConstantsNew,
} from '../../constants/Common';
import { privilagesConstants } from '../../constants/Privilages';
import { MOBILE, tractorEndPopDefault } from '../../constants/constant';
import { TractorHeartBeat } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import { AutoDriveApplicationContext } from '../../context/AutoDriveContext';
import AutoDriveEndPopup from './AutoDriveEndPopup';
import { AutoDriveInterruptMsg } from './AutoDriveInterruptMsg';
import { RemoteDriveAppCtx } from './RemoteDriveContext';

interface Props {
  clearAll: (heartBeat: TractorHeartBeat) => void;
  stopAndDisconnectHandler: () => void;
  setShowEndAndDisconnect: (tractorId: number) => void;
  showEndAndDisconnect: number;
  setAutonomyFlag: (state: boolean) => void;
}
const MaintenanceAndStartAutoDrive: React.FC<Props> = ({
  clearAll,
  stopAndDisconnectHandler,
  setShowEndAndDisconnect,
  showEndAndDisconnect,
  setAutonomyFlag,
}) => {
  const { userDetails, APPReducer, privilegeChecker } =
    useContext(ApplicationContext);
  const { RDReducer, startAutoDrive } = useContext(RemoteDriveAppCtx);
  const [state] = RDReducer;
  const {
    selectedTractor,
    autoDriveInterruptMsgs,
    showAutoDrivePoorConnectivityLoader,
  } = state;
  const { AutoDriveReducer } = useContext(AutoDriveApplicationContext);
  const [autoDriveState] = AutoDriveReducer;
  const { showSideFeedBack } = autoDriveState;
  const { heartbeat } = selectedTractor;

  const [appState] = APPReducer;
  const { showAutoDriveConnect } = appState;
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  // to display the start auto drive button
  // if user  connect from mobile don't show
  const showStartAutoDrive = () =>
    selectedTractor?.heartbeat?.drive_action_details?.platform !== MOBILE &&
    !showSideFeedBack[selectedTractor.id] &&
    selectedTractor.isAvailable === tractorConstantsNew.available.key &&
    showAutoDriveConnect &&
    privilegeChecker(
      privilagesConstants.Remote_Tractor_and_Autodrive_access_allow_disallow_Based_on_Training,
    ) &&
    userDetails?.is_active;

  useEffect(() => {
    function onlineHandler() {
      setIsOnline(true);
    }

    function offlineHandler() {
      setIsOnline(false);
    }

    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);

    return () => {
      window.removeEventListener('online', onlineHandler);
      window.removeEventListener('offline', offlineHandler);
    };
  }, []);

  return (
    <>
      <div className="ppMsgBox">
        {!showSideFeedBack[selectedTractor.id] &&
          checkTractorConnectedState(
            selectedTractor?.heartbeat,
            userDetails.id,
          ) && (
            <div className="maint_autodrive_btn_section">
              <Button
                className="startAdriveBtn"
                onClick={(e) => {
                  startAutoDrive(e);
                }}
              >
                <span className="maint_text Button mt2">START AUTODRIVE</span>
              </Button>
            </div>
          )}
        {!showSideFeedBack[selectedTractor.id] &&
          checkTractorConnectedFromMobileState(
            selectedTractor?.heartbeat,
            userDetails.id,
          ) && (
            <div className="login_from_mobile">
              <p>
                Autodrive in progress from mobile. If you want to move to this
                device, please end the operation and restart from this device
              </p>
            </div>
          )}
        {getSystemPausedInterrupts(autoDriveInterruptMsgs, selectedTractor.id)
          .length === 0 &&
          showEndAndDisconnect === tractorEndPopDefault && (
            <>
              {checkTractorDrivingState(
                selectedTractor.heartbeat,
                userDetails.id,
              ) &&
                getImplementName(selectedTractor) !== noneAttached && (
                  <div className="Button rotrayMowerBtn">
                    <img src={hi_gray_icon} alt="" />
                    {getImplementName(selectedTractor)}
                  </div>
                )}
              {checkIsTractorConnectedNoATOMCond(selectedTractor) && (
                <div
                  className={`tOperationDtlSec ${
                    (checkTractorDrivingState(heartbeat, userDetails.id) &&
                      showAutoDrivePoorConnectivityLoader) ||
                    !isOnline
                      ? 'grayout'
                      : ''
                  }`}
                >
                  <div className="tOpTypeSec">
                    <span className="a_icon a_pto_icon iconAutodrive"></span>
                    <span className="a_value headline3 fMed">
                      {getPTOState(selectedTractor?.heartbeat)
                        ? getRpm(
                            selectedTractor?.heartbeat as TractorHeartBeat,
                            selectedTractor?.tractor_model,
                          )
                        : '-'}
                    </span>
                    <span className="a_label body2">
                      {getPTOState(selectedTractor.heartbeat)
                        ? ' PTO RPM'
                        : ' PTO OFF'}
                    </span>
                  </div>
                  <div className="tOpTypeSec">
                    <span className="a_icon a_gear_icon iconAutodrive"></span>
                    <span className="a_value headline3 fMed">
                      {selectedTractor &&
                        getGearText(selectedTractor?.heartbeat)}
                    </span>
                    <span className="a_label body2">Gear</span>
                  </div>
                  <div className="tOpTypeSec">
                    <span className="a_icon a_height_icon iconAutodrive"></span>
                    <span className="a_value headline3 fMed">
                      {selectedTractor && getHeight(selectedTractor.heartbeat)}%
                    </span>
                    <span className="a_label body2">HEIGHT</span>
                  </div>
                  <div className="tOpTypeSec">
                    <span className="a_icon a_potion_icon iconAutodrive"></span>
                    <span className="a_value2 headline3 fMed">
                      {getFloatText(selectedTractor?.heartbeat)}
                    </span>
                  </div>
                </div>
              )}
            </>
          )}
        {/* End operation code starts here  */}
        {checkTractorDrivingState(selectedTractor.heartbeat, userDetails.id) &&
          showEndAndDisconnect === selectedTractor.id && (
            <AutoDriveEndPopup
              endPopIndex={showEndAndDisconnect}
              setEndPopIndex={setShowEndAndDisconnect}
              index={selectedTractor.id}
              stopAndDisconnectHandler={stopAndDisconnectHandler}
              From="DETAILS"
            />
          )}
        {/* End operation code ends here  */}
        {checkTractorDrivingState(selectedTractor.heartbeat, userDetails.id) &&
          getImplementName(selectedTractor) !== noneAttached && (
            <div className="editParamBtn" onClick={() => setAutonomyFlag(true)}>
              Edit parameters
            </div>
          )}
        {showStartAutoDrive() && (
          <>
            <div className="maint_autodrive_btn_section">
              <Button
                className="startAdriveBtn"
                onClick={(e) => {
                  startAutoDrive(e);
                }}
              >
                <span className="autodrive-btn-bg"></span>
                <span className="maint_text Button">START AUTODRIVE</span>
              </Button>
            </div>
          </>
        )}
        {/* {checkTractorDrivingState(
          selectedTractor.heartbeat,
          userDetails.id,
        ) && <AutoDriveMonitorErrorContent />} */}
        {showInterruptMsg(
          autoDriveInterruptMsgs,
          selectedTractor,
          userDetails,
        ) && (
          <AutoDriveInterruptMsg
            stopAndDisconnectHandler={stopAndDisconnectHandler}
            clearAll={clearAll}
          />
        )}
        {/* {checkTractorDrivingState(selectedTractor.heartbeat, userDetails.id) &&
          showEndAndDisconnect === selectedTractor.id && (
            <EndAutoDriveConfirm
              stopAndDisconnectHandler={stopAndDisconnectHandler}
              setShowEndAndDisconnect={setShowEndAndDisconnect}
              showEndAndDisconnect={showEndAndDisconnect}
            />
          )} */}
      </div>
    </>
  );
};

export default MaintenanceAndStartAutoDrive;

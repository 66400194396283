/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/display-name */
import { Layout, Popover, notification } from 'antd';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { getUpdatesBasestationList } from '../../constants/Api';
import { BaseStationResponse, Software } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import sortDown from '../../assets/images/sort_down.svg';
import sortUp from '../../assets/images/sort_up.svg';
import dwlIcon from '../../assets/images/dwlIcon.svg';
import verHisIcon from '../../assets/images/Group 1296.svg';
import {
  checkActivity,
  getTractorStatusClass,
  initScroller,
} from '../../constants/Common';
import { BaseStationModal } from './BaseStationModal';
import ProgressBar from '../widget/ProgressBar/index';

interface Props {
  search: string;
  connection: string;
}
const BaseStationUpdates: React.FC<Props> = ({ search, connection }: Props) => {
  const { t } = useTranslation();
  const { userDetails, user } = useContext(ApplicationContext);
  const [filterData, setFilter] = useState<BaseStationResponse[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [pageSize] = useState<any>(25);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalcount, settotalcount] = useState<any>();
  const [sortedInfo, setSortedInfo] = useState<any>({
    columnKey: 'name',
    order: 'ascend',
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [sortedData, setSortedData] = useState<any>({
    name: 'ascend',
  });

  const [initialize, setInitialize] = useState<boolean>(false);
  const activity = useRef<string>('');
  const searchString = useRef<string>('');
  const [baseStations, setBaseStations] = useState<BaseStationResponse[]>([]);
  // const [baseStationNames, setBaseStationNames] = useState<string[]>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<Software>();

  const showUpdaterIcon1 = (record: any) => {
    if (user?.isSupportUser()) {
      return true;
    }

    return record.csoftware_version !== record?.lsoftware_version;
  };

  const columns = [
    {
      render: (name: string) => {
        return <span data-testid={`${name}-BaseStation`}>{name}</span>;
      },
      title: () => {
        return (
          <>
            {t(translate.fleet.baseStationName)}
            <span className="sort-pad">
              <img
                className="sort-pad"
                src={sortedData['name'] === 'ascend' ? sortUp : sortDown}
              />
            </span>
          </>
        );
      },
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend', 'descend'],
      sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      ellipsis: true,
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      showSorterTooltip: false,
      sorter: true,
    },
    {
      title: `${t(translate.fleet.status)}`,
      width: '15%',
      render: (record: any) => {
        return (
          <span className={getTractorStatusClass(record.status)}>
            {record.status}
          </span>
        );
      },
    },
    {
      title: `${t(translate.fleet.pin)}`,
      dataIndex: 'bsin',
      key: 'bsin',
      width: '15%',
    },
    {
      title: `${t(translate.baseStation.baseStationModal)}`,
      dataIndex: 'basestation_model',
      key: 'basestation_model',
      width: '15%',
    },
    {
      title: `${t(translate.baseStation.otaStatus)}`,
      dataIndex: 'ota_status',
      key: 'ota_status',
      width: '15%',
      render: (_: string, record: any) => {
        const { ota_percentage, ota_status } = record;
        return ota_status ? (
          <ProgressBar status={ota_status} percent={ota_percentage} />
        ) : (
          <>{record.ota_status}</>
        );
      },
    },
    {
      title: `${t(translate.headers.softwareVersion)}`,
      dataIndex: 'csoftware_version',
      key: 'csoftware_version',
      width: '15%',
      render: (csoftware_version: string, record: any) => {
        return (
          <>
            <span>
              <div className="versionList">
                <span className="upTname">{csoftware_version}</span>

                {showUpdaterIcon1(record) && (
                  <>
                    <Popover
                      overlayClassName="softwarePopover"
                      placement="topRight"
                      title={`Version ${
                        record?.lsoftware_version ?? '-'
                      } available now. Please Update.`}
                    >
                      <img src={verHisIcon} className="upMl" />
                    </Popover>
                    {record.status !== 'Off' && (
                      <img
                        src={dwlIcon}
                        height="38px"
                        alt="download"
                        className="upMl cPointer"
                        onClick={() => {
                          showModal(record);
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </span>
          </>
        );
      },
    },
  ];

  const showModal = (record: any) => {
    setIsModalVisible(true);
    setSelectedItem(record);
  };

  const wrapResult = async (
    apiUrl: string,
    ogId: number,
    pageNumber: number,
    pageSize: number,
    search: string,
    sortedInfo: string,
    connection: string,
  ) => {
    const result = await getUpdatesBasestationList(
      apiUrl,
      ogId,
      pageNumber,
      pageSize,
      search,
      sortedInfo,
      connection !== 'show_all' ? connection : '',
    );
    return {
      result,
      searchKey: search,
    };
  };

  const getBasestations = async () => {
    if (checkActivity(activity.current)) initScroller();
    setLoading(true);
    try {
      const { result: baserecords, searchKey } = await wrapResult(
        userDetails.organization.api_url,
        userDetails.organization.id,
        pageNumber,
        pageSize,
        search,
        sortedInfo,
        connection !== 'show_all' ? connection : '',
      );

      if (searchString.current !== searchKey) return;

      settotalcount(baserecords?._metadata?.total_records_count);
      const data =
        baserecords && baserecords?.records && baserecords?.records?.length > 0
          ? baserecords?.records
          : [];
      data?.forEach((element: any) => {
        let baseStnPin = '';
        if (element?.bsin) {
          baseStnPin =
            element?.bsin === '0' || element?.bsin === '-1'
              ? ''
              : element?.bsin;
        } else {
          baseStnPin = '';
        }
        element.csoftware_version = element?.current_software?.software_version;
        element.lsoftware_version = element?.latest_software?.software_version;
        (element.name = element?.name ?? '-'), (element.bsin = baseStnPin);
        element.software_version_id =
          element?.current_software?.software_version_id;
        element.latest_software_version_id =
          element?.latest_software?.software_version_id;
        element.ota_status = element?.status;
        element.status =
          element?.basestation_connected_status === 'online'
            ? 'available'
            : 'Off';
      });
      if (!initialize) setBaseStations(data);

      setHasMore(data?.length === pageSize);

      if (checkActivity(activity.current)) setFilter(data);
      else if (activity.current === 'paginate')
        setFilter([...filterData, ...data]);
      else setFilter(data);
    } catch (err: any) {
      notification.error({
        message: err?.message,
      });
    } finally {
      setLoading(false);
      if (!initialize) setInitialize(true);
    }
  };
  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity(activity.current) && document)
      initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    activity.current = 'sort';
    const { columnKey, order } = sorter;
    setSortedData({ ...sortedData, [columnKey]: order });
    setSortedInfo(sorter);
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) loadBaseStations();
  }, [userDetails, pageNumber]);

  useEffect(() => {
    searchString.current = search;
    activity.current = 'search';

    if (userDetails && userDetails.organization && initialize)
      loadBaseStations();
  }, [search]);

  // useEffect(() => {
  //   const bNames = baseStations.map((item: any) => item.name);
  //   setBaseStationNames(bNames);
  // }, [baseStations]);

  useEffect(() => {
    if (sortedInfo.order && initialize) loadBaseStations();
  }, [sortedInfo.order]);

  useEffect(() => {
    if (initialize) {
      activity.current = 'filter';
      loadBaseStations();
    }
  }, [connection]);

  const loadBaseStations = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity(activity.current)) {
      pageNumber !== 1 ? setPageNumber(1) : getBasestations();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getBasestations();
    }
  };

  return (
    <Layout>
      <div className="common_table">
        <InfiniteScrollTable
          columns={columns}
          hasMore={hasMore}
          loading={loading}
          filterData={filterData}
          totalcount={totalcount}
          handleLoadMore={handleLoadMore}
          filename="Base Stations"
          onChange={handleChange}
        />
      </div>
      {isModalVisible && (
        <BaseStationModal
          isModalVisible={isModalVisible}
          setIsModalVisible={(state) => {
            setIsModalVisible(state);
            getBasestations();
          }}
          data={selectedItem as any}
        />
      )}
    </Layout>
  );
};

export default BaseStationUpdates;

import moment from 'moment';
import {
  formatTeams,
  getDateTimes,
  getDateTimesByString,
  getFullDate,
  getTractorAvailableState,
  indicatorsOrder,
} from '../constants/Common';
import {
  DailyUsageReports,
  BatteryList,
  ChargePointsData,
} from '../constants/types';

export const mapTractorsData = (data: any[]) =>
  data.map((element: any) => {
    let connectivity_level = 0;
    let network_type = [];
    const { heartbeat, basestation, battery, tractor_pin } = element;

    let hourMeter = (Number(element.heartbeat?.hour_meter) / 3600).toFixed();
    hourMeter = !isNaN(Number(hourMeter)) ? `${hourMeter} hours` : '-';

    element.hour_meter = heartbeat ? heartbeat.hour_meter && hourMeter : 0;

    element.softwareversion = heartbeat ? heartbeat?.software_version : '';
    element.firmware = heartbeat ? heartbeat?.firmware_version : '';

    element.basestation_id = basestation?.id || '-';
    element.basestation_name = basestation?.name || '-';

    element.battery_name = battery?.name || '-';
    element.basestation_pin = basestation?.bsin || '-';
    element.battery_pin = battery?.bin || '-';

    element.indicatorsData = [];
    element.isAvailable = getTractorAvailableState(
      element.connection,
      element?.heartbeat,
    );
    for (const arrangeIndicator of indicatorsOrder) {
      for (const indicator of element.indicators) {
        if (arrangeIndicator === indicator.indicator_name) {
          element.indicatorsData.push(indicator);
          if (indicator.indicator_key === 'Tractor_Communication')
            connectivity_level = indicator.indicator_level;
        }
      }
    }

    element.connectivity_level = connectivity_level;
    element.basestation_connected_status =
      basestation?.basestation_connected_status;
    network_type = heartbeat?.system_model?.split(',');

    if (network_type && network_type?.length >= 210) {
      element.active_network = network_type[209];
    }

    element.tractor_pin = ['0', '-1'].includes(tractor_pin)
      ? '-'
      : tractor_pin || '-';

    element.isTractorError =
      element?.indicators.filter(
        (indicator: any) => indicator.indicator_level === 3,
      ).length > 0;
    return element;
  });

export const mapBasestationData = (data: any[]) =>
  data?.map((element: any) => {
    const { bsin, name, basestation_connected_status: bs_con_status } = element;
    element.bsin = ['0', '-1'].includes(bsin) ? '-' : bsin || '-';
    element.name = name ?? '-';
    element.status = bs_con_status === 'online' ? 'available' : 'Off';
    element.software_version = element.current_software?.software_version;
    return element;
  });

export const mapEmployessData = (data: any[]) =>
  data.map((ele: any) => {
    const { teams = [] } = ele;
    ele.teamName = formatTeams(teams);
    ele.appAcess = ele?.teams?.some((team: any) => team?.app_access);
    return ele;
  });

export const mapReportsData = (
  data: DailyUsageReports[],
): DailyUsageReports[] =>
  data.map((record: DailyUsageReports) =>
    Object.assign({
      created_date_time: getDateTimes(record.created_date_time),
      date: `${getFullDate(record.start_date_str)} -
        ${getFullDate(record?.end_date_str)}`,
      name: record.report_title ? record.report_title : record.reports_name,
      id: record.id,
      reports_date: getDateTimesByString(record.report_date_str),
      reports_name: record.reports_name,
      download_report_url: record.download_report_url,
    }),
  );

export const mapBatteryData = (data: BatteryList[]): BatteryList[] =>
  data.map((element: any) => {
    const { bin, name, current_hour_meter_hours: hourMeter } = element;
    element.name = name ?? '-';
    element.bin = ['0', '-1'].includes(bin) ? '-' : bin || '-';
    element.hour_meter = hourMeter === '0' ? '-' : `${hourMeter} hours`;
    return element;
  });

export const mapChargingPointData = (
  data: ChargePointsData[],
): ChargePointsData[] =>
  data.map((record: ChargePointsData) => {
    const {
      charge_point_name,
      model,
      voltage,
      make,
      id,
      electricity_provider_plan_id,
    } = record;
    return Object.assign({
      charge_point_name,
      model,
      electricity_provider: electricity_provider_plan_id
        ? electricity_provider_plan_id?.provider
        : '-',
      voltage,
      make: make || '-',
      electricityId: record?.electricity_provider_plan_id?.id,
      id: id,
    });
  });

export const mapDiagnosticData = (data: any[]): any[] =>
  data.map((record: any) => {
    const {
      description = '',
      subsystem = '',
      functionality_affected = '',
      indicator = '',
      counter_measure = '',
    } = record.error_code_data ? record.error_code_data : {};
    const {
      id,
      tractor_id,
      error_code,
      created_date_time,
      indicator_level,
      modified_date_time,
    } = record;
    return Object.assign({
      id,
      start_time: created_date_time ? getDateTimes(created_date_time) : '-',
      error_code,
      severity: indicator_level,
      subsystem: subsystem || '-',
      description: description || '-',
      indicator_type: indicator || '-',
      created_date_time,
      modified_date_time: modified_date_time
        ? getDateTimes(modified_date_time)
        : '-',
      functionality_affected: functionality_affected ?? '',
      counter_measure: counter_measure ? counter_measure?.split(';') : '',
      tractor_id,
    });
  });

export const mapBasestationDiagnosticData = (data: any[]): any[] =>
  data.map((record: any) => {
    const { error_code_data, created_date_time } = record;
    record.created_date_time = created_date_time
      ? getDateTimes(created_date_time)
      : '-';
    record.subsystem = error_code_data?.subsystem;
    record.description = error_code_data?.description;
    return record;
  });

export const mapMaintenance = (data: any[]): any[] =>
  data.map((ele: any) => {
    ele.tractorName = ele.tractor ? ele.tractor?.name : '';
    ele.open_date_time = ele.open_date_time
      ? getDateTimes(ele.open_date_time)
      : '';
    ele.rulename = ele.name;
    return ele;
  });

export const mapTickets = (data: any[]): any[] =>
  data.map((ele: any) => {
    ele.completed_date_time = ele.completed_date_time
      ? getDateTimes(ele.completed_date_time)
      : '';
    ele.date_time = ele.created_date_time;
    ele.created_date_time = ele.created_date_time
      ? getDateTimes(ele.created_date_time)
      : '';
    ele.completed_by_user = ele.completed_by_user
      ? ele.completed_by_user.first_name + ' ' + ele.completed_by_user.last_name
      : '';
    ele.created_by_user = ele.created_by_user
      ? ele.created_by_user.first_name + ' ' + ele.created_by_user.last_name
      : '';
    ele.assignee = ele.assignee
      ? ele.assignee.first_name + ' ' + ele.assignee.last_name
      : '';
    ele.equipmentName = ele.equipment ? ele.equipment.name : 'Other';
    ele.ticket_level = ele.ticket_level.toString();
    ele.tractorId = ele.tractor_id;
    ele.ticketStatus = ele.status;
    ele.ticket_id = ele?.ticket_id || '-';
    return ele;
  });

export const mapImplementsData = (data: any[], tstate: any): any[] =>
  data.map((record: any) => {
    return {
      name: record.name,
      manufacturer: record.config_imm ? record.config_imm.manufacturer : '',
      model: record.config_imm ? record.config_imm.model : '',
      serial_number: record.serial_number,
      id: record.id,
      config_imm_uuid: record.config_imm_uuid,
      config_implements_uuid: record.config_implements_uuid,
      implement_type: record?.implement_type,
      is_autonomy_full_filled: record?.is_autonomy_full_filled,
      is_autonomy_implement: record?.is_autonomy_implement,
      showAutoDriveConnect: tstate?.showAutoDriveConnect,
      is_implement_info_missing: record?.is_implement_info_missing,
    };
  });

export const mapSchUpdatesData = (data: any[]) =>
  data?.map((record: any) => {
    return {
      name: record.name,
      softwareVersion: record?.fleet_health?.current_software_version || '-',
      firmWareVersion: record?.fleet_health?.current_firmware_version || '-',
      ota_status: record?.fleet_health ? record?.fleet_health?.ota_status : '-',
      id: record?.id,
      ticket_id: record?.fleet_health.ticket_id,
      serial_number: record?.serial_number,
      otaPercentage: record?.fleet_health?.ota_percentage,
      scheduleRemarks: record?.fleet_health?.scheduled_failed_remarks,
      schDate: record?.fleet_health
        ? moment(record?.fleet_health?.scheduled_date_time).format(
            'ddd, MMM D [at] h:mm A',
          )
        : '-',
    };
  });

export const mapTractorUsageData = (data: any[]): any[] =>
  data.map((record: any) => ({
    date: record.date ?? '-',
    distance: record.distance || 0,
    manual_hours_num: record.manual_hours || '0',
    autonomy_num: record.autonomy_hours || '0',
    energy_used: record.energy_used || 0,
    co2_saved: record.co2_saved || 0,
    idle_hours_num: record.standby_hours || '0',
    human_detections: record.human_detections || 0,
    customer_name: record?.organization?.name ?? '',
    follow_me_hours_num: record.follow_me_hours || '0',
    vinerow_hours_num: record.vinerow_hours || '0',
    copycat_hours_num: record.copycat_hours || '0',
    driving_hours_num: record.driving_hours || '0',
    standby_hours_num: record.standby_hours || '0',
    remote_av_hours_num: record.remote_av_hours || '0',
    follow_me_hours:
      record.follow_me_hours_str === '0:00' ? '0' : record.follow_me_hours_str,
    vinerow_hours:
      record.vinerow_hours_str === '0:00' ? '0' : record.vinerow_hours_str,
    copycat_hours:
      record.copycat_hours_str === '0:00' ? '0' : record.copycat_hours_str,
    driving_hours:
      record.driving_hours_str === '0:00' ? '0' : record.driving_hours_str,
    standby_hours:
      record.standby_hours_str === '0:00' ? '0' : record.standby_hours_str,
    remote_av_hours:
      record.remote_av_hours_str === '0:00' ? '0' : record.remote_av_hours_str,
    manual_hours:
      record.manual_hours_str === '0:00' ? '0' : record.manual_hours_str,
    autonomy_hours:
      record.autonomy_hours_str === '0:00' ? '0' : record.autonomy_hours_str,
  }));

export const ANALYTICS_API = '/tractor-indicators';
export const ENERGY_COST_SAVING_API = '/analytics/energy-cost-saving';
export const EFFIENCY_API = '/analytics/implement-type/data';
export const IMPLEMENT_TYPE_EFFIENCY_API = '/analytics/efficiency-date-imp';
export const TRACTOR_ANALYTICS_DETAILS_API =
  '/analytics/op-details-user-implement-type';
export const OPERATION_HOURS_AND_COVERAGE_API =
  '/analytics/op-hours-ac-coverage';
export const TRACTOR_IMEPLEMENT_API = '/analytics/implements/drop-down';
export const ANALYTIC_FILTER_API = '/analytics/operation-search-filter';
export const ANALYTIC_RESULTS_API =
  '/analytics/operation-search/implement-usage-summary';
export const OPERATOR_HOURS_AND_COVERAGE_API =
  '/analytics/op-hours-ac-coverage';

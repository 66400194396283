import React from 'react';
import exUpdateTimeW from '../../assets/images/exUpdateTimeW.svg';
import upToDateW from '../../assets/images/upToDateW.svg';
import outOfDateW from '../../assets/images/outOfDateW.svg';
import { getSummary } from '../../constants/types';
import { timeDuration } from '../../constants/Common';

interface Props {
  summaryList: getSummary;
}

const UpdatesSummary: React.FC<Props> = ({ summaryList }) => {
  return (
    <>
      {summaryList && (
        <div className="gridContainer updateGrid">
          <div className="gridItem">
            <img src={outOfDateW} alt="outOfDateW" />
            <div className="widget_content">
              <div className="assignTxt">Out of Date</div>
              <div className="assignNum">
                {summaryList?.ota_out_of_date_tractors_count}
              </div>
            </div>
          </div>

          <div className="gridItem">
            <img src={upToDateW} alt="upToDateW" />
            <div className="widget_content">
              <div className="assignTxt">Up to Date</div>
              <div className="assignNum">
                {' '}
                {summaryList?.ota_up_to_date_tractors_count}
              </div>
            </div>
          </div>

          <div className="gridItem">
            <img src={exUpdateTimeW} alt="updateTime" />
            <div className="widget_content">
              <div className="assignTxt">Update Released</div>
              <div className="assignNum">
                {summaryList?.last_released_software_date_time >
                summaryList?.latest_firmware_release_date ? (
                  <>
                    {timeDuration(
                      summaryList?.last_released_software_date_time,
                    )}
                  </>
                ) : (
                  <>{timeDuration(summaryList?.latest_firmware_release_date)}</>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdatesSummary;

/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-self-assign */
import { UserOutlined } from '@ant-design/icons';
import {
  Breadcrumb,
  Button,
  Card,
  Menu,
  notification,
  Space,
  Spin,
  Table,
  Modal,
} from 'antd';
import deleteIcon from '../../assets/images/del_icon.png';
import BreadcrumbItem from 'antd/lib/breadcrumb/BreadcrumbItem';
import Layout, { Content } from 'antd/lib/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { track } from '../../util/logger';
import {
  deleteJumpAndGoRecord,
  getAssignedTractorDetails,
  updateTaskstatus,
} from '../../constants/Api';
import { getTask, getJumpAndGoRecords } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import { getDateTimes } from '../../constants/Common';
import { Taskdetails } from '../../constants/types';
import RoutesConstants from '../../routes/RoutesConstant';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Interweave from 'interweave';
import AddJumpAndGOModel from './AddJumpandGoModel';
import EditTaskModel from './EditTaskModel';
import Updatesupervisor from './Updatesupervisor';
import Search from 'antd/lib/input/Search';

const TaskDetails: React.FC = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { confirm } = Modal;
  const [edittask, setEdittask] = useState(false);
  const [Updatesupervisorvisable, setupdatesupervisorvisable] = useState(false);
  const location = useLocation();
  const { userDetails } = useContext(ApplicationContext);
  const [task, setTask] = useState<Taskdetails>({} as Taskdetails);
  const [data, setData] = useState<any[]>([]);
  const [filterData, setFilter] = useState<any[]>([]);
  const [jumpandgoData, setJumpandgoData] = useState<any>();
  const [addJumpAndGO, setaddJumpAndGO] = useState<boolean>(false);
  const [jumpandgoListData, setJumpandgoListData] = useState<any>();

  const [taskuuid, setTaskuuid] = useState<string>('');
  const [loader, setLoader] = useState<boolean>(false);
  const filterVals = (currValue: string) => {
    const filteredVals = data.filter((jumpandgo: { tractorname: string }) => {
      if (
        jumpandgo.tractorname &&
        jumpandgo.tractorname.toLowerCase().includes(currValue)
      ) {
        return jumpandgo;
      }
    });
    if (currValue) {
      setFilter(filteredVals);
    } else {
      setFilter(data);
    }
  };
  const closeFleetModel = () => {
    setaddJumpAndGO(false);
    getTractorsListData(taskuuid);
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      const state = location.state;
      if (state && (state as { task_uuid: string })) {
        const { task_uuid } = state as { task_uuid: string };
        getTaskDetails(task_uuid);
        getTractorsListData(task_uuid);
      }
    }
  }, [userDetails]);
  const getTaskDetails = async (task_uuid: string) => {
    setLoader(true);
    const data: any = await getTask(
      userDetails.organization.api_url,
      userDetails.organization.id,
      task_uuid,
    );
    setLoader(false);
    try {
      const desc = JSON.parse(data.description);
      const data1 = desc.blocks.map((d: any) => {
        return d.text;
      });
      data.description = data1.join(' ');
    } catch {
      data.description = data.description;
    }
    setTask(data);
    setTaskuuid(task_uuid);
    getJumpAndGoRecordsData(
      data.planned_start_date_time,
      data.planned_finish_date_time,
    );
  };

  const getTractorsListData = async (task_uuid: string) => {
    try {
      const data: any = await getAssignedTractorDetails(
        userDetails.organization.api_url,
        task_uuid,
      );
      setJumpandgoListData(data.records);
      const jumpAndGoList: {
        tractorname: string;
        operator: string;
        starttime: string;
        finishtime: string;
        driveactionid: number;
      }[] = [];
      data.records.map((item: any) => {
        jumpAndGoList.push({
          tractorname: item.tractor.name,
          operator: item.user.first_name,
          starttime: getDateTimes(
            item.drive_action.drive_action_start_date_time,
          ),
          finishtime: getDateTimes(
            item.drive_action.drive_action_finish_date_time,
          ),
          driveactionid: item.drive_action_uuid,
        });
      });
      setData(jumpAndGoList);
      setFilter(jumpAndGoList);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };

  const getJumpAndGoRecordsData = async (
    starttime: number,
    finishtime: number,
  ) => {
    try {
      const resp = await getJumpAndGoRecords(
        userDetails.organization.api_url,
        starttime,
        finishtime,
        userDetails.id,
      );
      setJumpandgoData(resp.records);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };

  const closeModel = () => {
    setEdittask(false);
    getTaskDetails(task.task_uuid);
  };
  const closesupModel = () => {
    setupdatesupervisorvisable(false);
    getTaskDetails(task.task_uuid);
  };

  const columns = [
    {
      title: `${t(translate.baseStation.tractorName)}`,
      dataIndex: 'tractorname',
      key: 'tractorname',
      width: '200px',
    },
    {
      title: `${t(translate.Jumpandgo.operator)}`,
      dataIndex: 'operator',
      key: 'operator',
      width: '150px',
    },
    {
      title: `${t(translate.taskmaster.starttime)}`,
      dataIndex: 'starttime',
      key: 'starttime',
      width: 200,
    },
    {
      title: `${t(translate.taskmaster.finishtime)}`,
      dataIndex: 'finishtime',
      key: 'finishtime',
      width: 200,
    },
    {
      title: `${t(translate.updateManager.action)}`,
      dataIndex: 'driveactionid',
      key: 'id',
      width: 100,
      // eslint-disable-next-line react/display-name
      render: (id: number) => {
        return (
          <img
            src={deleteIcon}
            alt="Delete"
            height="18"
            width="18"
            className="marginLeftIcon"
            onClick={() => deleteJumpAndGoFromList(id)}
          />
        );
      },
    },
  ];

  const deleteJumpAndGoFromList = async (record: any) => {
    confirm({
      title: 'Are you sure to remove Jump & Go?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk() {
        deleteJumpAndGoFromLists(record);
      },
      onCancel() {},
      okButtonProps: {
        className: 'okBtn',
      },
      cancelButtonProps: {
        className: 'canelBtn',
      },
    });
  };

  const deleteJumpAndGoFromLists = async (driveactionid: number) => {
    const payload = {
      drive_action_uuids: [driveactionid],
    };
    try {
      await deleteJumpAndGoRecord(
        userDetails.organization.api_url,
        taskuuid,
        payload,
      );
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
    getTractorsListData(taskuuid);
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<UserOutlined />}>
        1st menu item
      </Menu.Item>
      <Menu.Item key="2" icon={<UserOutlined />}>
        2nd menu item
      </Menu.Item>
      <Menu.Item key="3" icon={<UserOutlined />}>
        3rd menu item
      </Menu.Item>
    </Menu>
  );
  const Updatetaskstatusdict = async (status: string) => {
    const payload = {
      action: status,
    };
    try {
      const response: { msg: string } = await updateTaskstatus(
        userDetails.organization.api_url,
        payload,
        userDetails.organization.id,
        task.task_uuid,
      );

      getTaskDetails(task.task_uuid);
      notification.success({
        message: response.msg,
      });
      if (status === 'CLOSE') {
        push({
          pathname: RoutesConstants.Taskmaster,
        });
      }
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };
  const goBacktaskmaster = () => {
    push({
      pathname: RoutesConstants.Taskmaster,
    });
  };
  return (
    <Layout>
      <div className="mainContent">
        <div className="taskmasterblk taskdetailsBlk">
          <div className="tblContainer">
            <div>
              <Breadcrumb>
                <BreadcrumbItem onClick={() => goBacktaskmaster()}>
                  <a>Operation Planner</a>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <span style={{ color: '#366376' }}>{task && task.title}</span>
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
            <div className="tsm">
              {loader && (
                <div className="loader">
                  <Spin size="large" />
                </div>
              )}
              <Content>
                <div className="taskDetailsStyles dbWidget taskWidget taskmasterWidget taskdetailsWidget">
                  <div className="taskmasterInrBlk mb40">
                    {task && (
                      <Card
                        title={task && task.title}
                        bordered={true}
                        extra={
                          <Space>
                            {task &&
                              task.supervisor &&
                              task.supervisor.id === userDetails.id &&
                              task.task_status === -300 && (
                                <Button
                                  type="primary"
                                  className="btn-create btn-start"
                                  size="large"
                                  onClick={() => {
                                    track('StartTask', {
                                      event: 'Start Task',
                                    });
                                    Updatetaskstatusdict('START');
                                  }}
                                >
                                  {t(translate.buttons.start)}
                                </Button>
                              )}

                            {task &&
                              task.supervisor &&
                              task.supervisor.id === userDetails.id &&
                              task.task_status === -302 && (
                                <Button
                                  type="primary"
                                  className="btn-create btn-finish"
                                  size="large"
                                  onClick={() => {
                                    track('FinishTask', {
                                      event: 'Finish Task',
                                    });
                                    Updatetaskstatusdict('FINISH');
                                  }}
                                >
                                  {t(translate.buttons.finish)}
                                </Button>
                              )}
                            {task &&
                              task.created_by &&
                              task.created_by.id === userDetails.id &&
                              task.task_status !== -308 && (
                                <Button
                                  type="primary"
                                  className="btn-create btn-finish"
                                  size="large"
                                  onClick={() => {
                                    track('CloseTask', {
                                      event: 'Close Task',
                                    });
                                    Updatetaskstatusdict('CLOSE');
                                  }}
                                >
                                  {t(translate.buttons.close)}
                                </Button>
                              )}

                            {task &&
                              task.task_status !== -308 &&
                              task.task_status !== -305 && (
                                <Button
                                  type="primary"
                                  className="btn-create"
                                  size="large"
                                  onClick={() => {
                                    track('EditTask', {
                                      event: 'Edit Task',
                                    });
                                    setEdittask(true);
                                  }}
                                >
                                  {t(translate.buttons.edit)}
                                </Button>
                              )}
                          </Space>
                        }
                        className="iconGap"
                      >
                        <div className="container">
                          <div className="col2">
                            <div className="col2-styles profCropRow">
                              <div className="orgDetails profCropHead">
                                <p>Supervisor</p>
                              </div>
                              <div className="orgData profCropValue">
                                <p>
                                  {task.supervisor &&
                                    task.supervisor.first_name +
                                      ' ' +
                                      task.supervisor.last_name}
                                  {!task.supervisor && '-'}
                                  {task.created_by &&
                                    task.task_status !== -308 &&
                                    task.task_status !== -305 &&
                                    task.created_by.id === userDetails.id && (
                                      <span
                                        className="editTxtLink"
                                        onClick={() => {
                                          track('EditSupervisor', {
                                            event: 'Edit Supervisor',
                                          });
                                          setupdatesupervisorvisable(true);
                                        }}
                                      >
                                        Edit
                                      </span>
                                    )}
                                </p>
                              </div>
                            </div>
                            <div className="col2-styles profCropRow">
                              <div className="orgDetails profCropHead">
                                <p>Type</p>
                              </div>
                              <div className="orgData profCropValue">
                                <p>{task.task_type}</p>
                              </div>
                            </div>
                            <div className="col2-styles profCropRow">
                              <div className="orgDetails profCropHead">
                                <p>Desired Start Time</p>
                              </div>
                              <div className="orgData profCropValue">
                                <p>
                                  {getDateTimes(task.planned_start_date_time)}
                                </p>
                              </div>
                            </div>
                            <div className="col2-styles profCropRow">
                              <div className="orgDetails profCropHead">
                                <p>Desired finish Time</p>
                              </div>
                              <div className="orgData profCropValue">
                                <p>
                                  {getDateTimes(task.planned_finish_date_time)}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col2">
                            <div className="col2-styles profCropRow">
                              <div className="orgDetails profCropHead">
                                <p>Created By</p>
                              </div>
                              <div className="orgData profCropValue">
                                {task.created_by && (
                                  <p>
                                    {task.created_by.first_name +
                                      ' ' +
                                      task.created_by.last_name}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col2-styles profCropRow">
                              <div className="orgDetails profCropHead">
                                <p>Status</p>
                              </div>
                              <div className="orgData profCropValue">
                                <p>{task.task_status_name}</p>
                              </div>
                            </div>

                            <div className="col2-styles profCropRow">
                              <div className="orgDetails profCropHead">
                                <p>Actual Start Time</p>
                              </div>
                              <div className="orgData profCropValue">
                                {task.actual_start_date_time ? (
                                  <p>
                                    {getDateTimes(task.actual_start_date_time)}
                                  </p>
                                ) : (
                                  <p>-</p>
                                )}
                              </div>
                            </div>
                            <div className="col2-styles profCropRow">
                              <div className="orgDetails profCropHead">
                                <p>Actual finish Time</p>
                              </div>
                              <div className="orgData profCropValue">
                                {task.actual_finish_date_time ? (
                                  <p>
                                    {getDateTimes(task.actual_finish_date_time)}
                                  </p>
                                ) : (
                                  <p>-</p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="container">
                          <div className="col2">
                            <div className="col2-styles profCropRow">
                              <div className="orgDetails profCropHead">
                                <p>Description</p>
                              </div>
                              {task.description ? (
                                <div
                                  className="orgData profCropValue"
                                  style={{
                                    overflowY: 'auto',
                                    height: '100px',
                                  }}
                                >
                                  <p>
                                    <div
                                      style={{
                                        padding: '2px',
                                        width: '100%',
                                      }}
                                    >
                                      <Interweave content={task.description} />
                                    </div>
                                  </p>
                                </div>
                              ) : (
                                '-'
                              )}
                            </div>
                          </div>
                          <div className="col2"></div>
                        </div>
                      </Card>
                    )}
                  </div>
                  <div className="taskDetailTbl">
                    <Card title="" bordered={false}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignContent: 'center',
                          alignItems: 'center',
                        }}
                        className="pb-15 mb40"
                      >
                        <Space className="" direction="horizontal">
                          <div className="searchSec searchUnit">
                            <Search
                              placeholder="Search Tractor"
                              onChange={(e) => filterVals(e.target.value)}
                            />
                          </div>
                        </Space>
                        <Button
                          type="primary"
                          className="btn-create"
                          size="large"
                          onClick={() => {
                            track('AddJumpAndGo', {
                              event: 'Add Jump ANd Go',
                            });
                            setaddJumpAndGO(!addJumpAndGO);
                          }}
                        >
                          {t(translate.buttons.add)}
                        </Button>
                      </div>
                      <Table
                        rowClassName={() => 'rowClassName1'}
                        className="tblDft taskDetailTbl"
                        columns={columns}
                        dataSource={filterData}
                        sticky={true}
                        pagination={false}
                        scroll={{ scrollToFirstRowOnChange: true, y: 325 }}
                      ></Table>
                      {addJumpAndGO && jumpandgoData && (
                        <AddJumpAndGOModel
                          showModal={addJumpAndGO}
                          handleCancel={() => closeFleetModel()}
                          jumpandgodata={jumpandgoData}
                          task_uuid={taskuuid}
                          jumpandgoTractorListdata={jumpandgoListData}
                        />
                      )}
                    </Card>
                  </div>
                </div>
              </Content>
            </div>
            <div>
              {edittask && task && (
                <EditTaskModel
                  showModel={edittask}
                  closeModel={() => closeModel()}
                  taskData={task}
                />
              )}
            </div>

            <div>
              {Updatesupervisorvisable && task && (
                <Updatesupervisor
                  showModel={Updatesupervisorvisable}
                  closeModel={() => closesupModel()}
                  tasksuper={task}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default TaskDetails;

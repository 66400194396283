import React, { useContext, useEffect, useRef, useState } from 'react';
import { Content } from 'antd/lib/layout/layout';
import { Button, Layout, Row, notification } from 'antd';
import UpdatesSummary from './UpdatesSummary';
import { UpdatesList } from './UpdatesList';
import { UpdateSchedule } from './UpdateSchedule';
import { getUpdateSummary, getUpdatesList } from '../../constants/Api';
import { getSummary } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import { ScheduleLater } from './ScheduleLater';
import { InstallNow } from './InstallNow';
import usePaginate from '../../hooks/usePaginate';
import { mapSchUpdatesData } from '../../lib/dataFormat';
import { initScroller } from '../../constants/Common';
import { CommonReleaseNotes } from './CommonReleaseNotes';
import { LeftOutlined } from '@ant-design/icons';
import './style.css';

interface Props {
  updateCount: number;
  setTabid: string;
  setEquipId: number;
  setTicketId: string;
}

export const Updates: React.FC<Props> = ({
  updateCount,
  setTabid,
  setEquipId,
  setTicketId,
}) => {
  const { userDetails } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(true);
  const [summaryList, setSummaryList] = useState<getSummary>();
  const [scheduleUpdate, setScheduleUpdate] = useState<boolean>(false);
  const [installUpdate, setInstallUpdate] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize] = useState<any>(25);
  const [total, setTotal] = useState<number>(0);
  const [initialize, setInitialize] = useState<boolean>(false);
  const [tractorIds, setTractorIds] = useState<any[]>([]);
  const [seeAll, setSeeAll] = useState<boolean>(false);
  const [confirmSchedule, setConfirmSchedule] = useState<boolean>(false);
  const { filterData, activity, checkActivity, hasMore, setData } =
    usePaginate();
  const callbackInterval = useRef<any>(null);

  useEffect(() => {
    if (tractorIds.length > 0) {
      setDisable(false);
    } else setDisable(true);
  }, [tractorIds]);

  useEffect(() => {
    if (userDetails && userDetails.organization) getSummaryData();
  }, [userDetails]);

  const getSummaryData = async () => {
    try {
      setLoader(true);
      const records = await getUpdateSummary(userDetails.organization.api_url);
      setSummaryList(records);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const wrapResult = async (
    apiUrl: string,
    pageNumber: number,
    pageSize: number,
  ) => {
    const result = await getUpdatesList(apiUrl, pageNumber, pageSize);
    return {
      result,
    };
  };

  const getSoftwareUpdates = async () => {
    if (checkActivity()) initScroller();
    setLoader(true);
    try {
      const { result } = await wrapResult(
        userDetails.organization.api_url,
        pageNumber,
        pageSize,
      );

      setTotal(result?._metadata?.total_records_count);
      const { records } = result;
      let data = Array.isArray(records) ? records : [];
      data = mapSchUpdatesData(data);
      setData(data);
    } catch (err: any) {
      notification.error({
        message: err?.message,
      });
    } finally {
      setLoader(false);
      if (!initialize) setInitialize(true);
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) loadUpdates();
  }, [pageNumber]);

  const loadUpdates = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : getSoftwareUpdates();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getSoftwareUpdates();
    }
  };

  useEffect(() => {
    callbackInterval.current = setInterval(() => {
      if (userDetails?.organization) loadUpdates();
    }, 5 * 60 * 1000);
    if (userDetails?.organization) loadUpdates();
    return () =>
      callbackInterval.current && clearInterval(callbackInterval.current);
  }, [userDetails]);

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const closeUpdate = () => {
    setInstallUpdate(false);
    setTractorIds([]);
    getSummaryData();
    loadUpdates();
  };

  const closeUpdateSchedule = () => {
    setScheduleUpdate(false);
    setConfirmSchedule(false);
    setTractorIds([]);
    getSummaryData();
    loadUpdates();
  };

  return (
    <Layout>
      {!(scheduleUpdate || installUpdate || seeAll) ? (
        <div className="mainContent">
          <Content>
            <div className="tblContainer viewportContainer">
              <Row>
                {updateCount > 0 && <UpdateSchedule setSeeAll={setSeeAll} />}
                <UpdatesSummary summaryList={summaryList as any} />
                <div className="tractorContainer">
                  <div>
                    <div className="fleetTxt">Your Fleet</div>
                    <div className="fleetContent mt12">
                      Select tractors to schedule for an automatic update or
                      install now.
                    </div>
                  </div>
                  <div className="secGrid">
                    <Button
                      data-testid="installNowButton-Updates"
                      className="installNowBtn"
                      onClick={() => setInstallUpdate(true)}
                      disabled={disable}
                    >
                      Install Now
                    </Button>
                    <Button
                      className="schBtn"
                      onClick={() => setScheduleUpdate(true)}
                      disabled={disable}
                    >
                      Schedule
                    </Button>
                  </div>
                </div>
                <UpdatesList
                  loader={loader}
                  filterData={filterData}
                  total={total}
                  hasMore={hasMore}
                  handleLoadMore={handleLoadMore}
                  tractorIds={tractorIds}
                  setTractorIds={setTractorIds}
                  setTabid={setTabid}
                  setEquipId={setEquipId}
                  setTicketId={setTicketId}
                />
              </Row>
            </div>
          </Content>
        </div>
      ) : (
        <>
          {scheduleUpdate && tractorIds && (
            <ScheduleLater
              closeModal={() => {
                setScheduleUpdate(false);
                setTractorIds([]);
              }}
              closeAndUpdate={closeUpdateSchedule}
              loader={loader}
              setLoader={setLoader}
              tractorIds={tractorIds}
              summaryList={summaryList as any}
              confirmSchedule={confirmSchedule}
              setConfirmSchedule={setConfirmSchedule}
            />
          )}
          {installUpdate && (
            <InstallNow
              closeModal={() => {
                setInstallUpdate(false);
                setTractorIds([]);
              }}
              closeAndUpdate={closeUpdate}
              summaryList={summaryList as any}
              tractorIds={tractorIds}
            />
          )}
          {seeAll && (
            <>
              <div className="scheduleFleet">
                <div
                  className="updateArrow"
                  onClick={() => {
                    setSeeAll(false);
                    setTractorIds([]);
                  }}
                >
                  <LeftOutlined />
                </div>
                <div className="otaHead mb0">Release Notes</div>
              </div>
              <div className="releaseAllNotes">
                <CommonReleaseNotes summaryList={summaryList as any} />
              </div>
            </>
          )}
        </>
      )}
    </Layout>
  );
};

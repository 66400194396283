export const SET_TITLE = 'SET_TITLE';
export const SET_NAVIGATE = 'SET_NAVIGATE';
export const RESET_NAVIGATE = 'RESET_NAVIGATE';
export const TOGGLE_VIDEO_WIDGET = 'TOGGLE_VIDEO_WIDGET';
export const SET_TRACTORS = 'SET_TRACTORS';
export const SET_TRACTORS_LOADER = 'SET_TRACTORS_LOADER';
export const SET_TEAMS = 'SET_TEAMS';
export const SET_TEAMS_REFRESH = 'SET_TEAMS_REFRESH';
export const SET_EMP_REFRESH = 'SET_EMP_REFRESH';
export const SET_LAST_TAB_INDEX = 'SET_LAST_TAB_INDEX';
export const SET_TRACTOR_IMPLEMENT_STATE = 'SET_TRACTOR_IMPLEMENT_STATE';
export const SET_TRACTORS_LIST = 'SET_TRACTORS_LIST';
export const SET_GROUND_ZERO = 'SET_GROUND_ZERO';
export const SET_POLYGONS_LIST = 'SET_POLYGONS_LIST';
export const SET_ROUTE_REFRESH = 'SET_ROUTE_REFRESH';
export const SET_JAG_DACTION = 'SET_JAG_DACTION';
export const SET_PINNED = 'SET_PINNED';
export const EXECUTE = 'EXECUTE';
export const LOAD_REMOTE_DRIVE_TRACTORS = 'LOAD_REMOTE_DRIVE_TRACTORS';
export const AUTO_DRIVE_LOGOUT_CONFIRM = 'AUTO_DRIVE_LOGOUT_CONFIRM';
export const SET_SHOW_AUTO_DRIVE = 'SET_SHOW_AUTO_DRIVE';
export const SET_AUTO_DRIVE_TRACTOR_LOST_INTERRUPT_MESSAGES =
  'SET_AUTO_DRIVE_TRACTOR_LOST_INTERRUPT_MESSAGES';

/* eslint-disable react/jsx-key */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-self-assign */
import { Layout, notification, Image, Modal } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import translate from '../../../locale/en_translate.json';
import closeGreen from '../../../assets/images/closeGreen.svg';
import { ApplicationContext } from '../../../context/AppContext';
import { FarmobservationsDetails } from '../../../constants/Api';
import { getDateTimes } from '../../../constants/Common';
import Interweave from 'interweave';
import AppLoader from '../AppLoader';
import video_icon from '../../../assets/images/play_icon.png';

interface Props {
  ticketObj?: any;
  Close: (action?: string) => void;
  toggleWidget: boolean;
}
const FarmObservationsDetails: React.FC<Props> = ({
  ticketObj,
  Close,
  toggleWidget,
}: Props) => {
  const { t } = useTranslation();
  const [FleetData, setFleetData] = useState<any>([]);
  const [farmObj, setfarmobj] = useState<any>();
  const { userDetails } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [vedioObj, setVedioObj] = useState<any>();

  useEffect(() => {
    if (ticketObj && ticketObj?.observations_uuid) {
      farmobservationsData();
    }
  }, [ticketObj]);
  const farmobservationsData = async () => {
    try {
      setLoader(true);
      setfarmobj('');
      const result: any = await FarmobservationsDetails(
        userDetails.organization_id,
        ticketObj.observations_uuid,
      );

      const dataarray: any[] = [];
      result?.Attachments?.map((ele: any, index: any) => {
        const obj = {
          uid: index.toString(),
          name: '',
          status: 'done',
          url: ele.image_url ? ele.image_url : '',
          id: ele.id,
          fleet_maintenance_log_id: ele?.fleet_maintenance_log_id,
          fleet_id: result?.fleet_id,
          index: index,
        };
        dataarray.push(obj);
      });
      result.description = result.description;
      setFleetData(dataarray);
      if (result.created_by) {
        const { first_name, last_name } = result.created_by;
        result.created_by_user = `${first_name} ${last_name}`;
      }
      setfarmobj(result);
      setLoader(false);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const Closebtn = () => {
    Close();
  };

  const handleCancel = () => {
    setPreviewOpen(false);
  };

  const OnVedio = (obj: any) => {
    setVedioObj(obj);
    setPreviewOpen(true);
  };

  return (
    <Layout>
      <div className={'rightSideWidegt ' + (toggleWidget ? 'expand' : 'hide')}>
        <div className="widgetCloseBtn flexBtn">
          <img
            src={closeGreen}
            alt="close icon"
            onClick={Closebtn}
            data-testid="modelCloseIcon-TicketViewEdit"
          />
        </div>

        <div className="profileView rightWidgetView">
          <div className="proRow tcktInfo">
            <h4 className="posRel errorTcktTitle headline3">
              <span style={{ fontFamily: 'Montserrat-SemiBold' }}>
                {t(translate.farmObservations.title)} #{farmObj?.id}
              </span>
            </h4>
            <h3 className="overline1">
              {getDateTimes(farmObj?.created_date_time)}
            </h3>
          </div>
          <>
            {farmObj?.equipment_data ? (
              <div className="proRowType rowGapBtm3">
                <h5 className="subFlex alignCenter">
                  <span className="headline4 subTitles">
                    {t(translate.farmObservations.tractorName)}
                  </span>
                  <span className="width50 body2">
                    <label>{farmObj?.equipment_data?.name}</label>
                  </span>
                </h5>
              </div>
            ) : (
              <div className="proRowType rowGapBtm3">
                <h5 className="subFlex alignCenter">
                  <span className="headline4 subTitles">
                    {t(translate.farmObservations.equipment)}
                  </span>
                  <span className="width50 body2">
                    <label>{t(translate.farmObservations.other)}</label>
                  </span>
                </h5>
              </div>
            )}
            {farmObj?.created_by_user && (
              <div className="proRowType rowGapBtm3">
                <h5 className="subFlex alignCenter">
                  <span className="headline4 subTitles">
                    {t(translate.farmObservations.creator)}
                  </span>
                  <span className="width50 body2 subdesc">
                    {farmObj?.created_by_user ? (
                      <label>{farmObj?.created_by_user}</label>
                    ) : (
                      <label></label>
                    )}
                  </span>
                </h5>
              </div>
            )}
            {farmObj?.description && (
              <div className="proRowType rowGapBtm3">
                <h5 className="headline4">
                  {t(translate.farmObservations.description)}
                </h5>
                <div className="txtLftGap w100 body2">
                  <Interweave content={farmObj?.description} />
                </div>
              </div>
            )}
            {farmObj?.tags && farmObj?.tags.length > 0 && (
              <div className="rowGapBtm3 tagsSec subFlex alignBaseline">
                <h4 className="mr11 headline4">
                  {t(translate.farmObservations.tags)}
                </h4>
                <div className="tagsBlk">
                  <div className="tags-container">
                    {farmObj?.tags?.map((data: any) => {
                      return (
                        <div className="tag-chip-selected body3" key={data}>
                          <span>{data}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}

            <div className="attachemtBlk rowGapBtm1">
              <h4 className="headline4 mb16">
                {t(translate.farmObservations.attachements)} (
                {farmObj?.attachments?.length})
              </h4>
              <div className="videoImgStyle fovideostyle">
                <Image.PreviewGroup>
                  {farmObj &&
                    farmObj?.attachments &&
                    farmObj?.attachments?.map((ele: any, i: number) => {
                      return (
                        <>
                          {ele.local_path.split('.')[1] !== 'mp4' && (
                            <Image
                              key={i}
                              src={ele?.cloud_file_path}
                              style={{
                                width: 75,
                                height: 75,
                              }}
                            />
                          )}

                          {ele.local_path.split('.')[1] === 'mp4' && (
                            <div
                              className="fbsvideoicon"
                              onClick={() => {
                                OnVedio(ele);
                              }}
                            >
                              <video
                                key={i}
                                className="video knowledgeImg"
                                src={ele?.cloud_file_path}
                              />
                              <img
                                src={video_icon}
                                alt=""
                                className="playIcon"
                              />
                            </div>
                          )}
                        </>
                      );
                    })}
                </Image.PreviewGroup>
              </div>
            </div>
            <div className="attachemtBlk rowGapBtm1">
              <h4 className="headline4 mb16">
                {t(translate.farmObservations.audioFile)}
              </h4>
              <div className="videoImgStyle">
                {farmObj &&
                  farmObj?.audio_attachments &&
                  farmObj?.audio_attachments?.map((ele: any, i: number) => {
                    return (
                      <audio controls>
                        <source
                          src={ele?.cloud_file_path}
                          type="audio/wav"
                        ></source>
                      </audio>
                    );
                  })}
              </div>
            </div>
            <AppLoader loader={loader} />
          </>
        </div>
      </div>

      <Modal
        visible={previewOpen}
        title={'    '}
        footer={null}
        maskClosable={false}
        onCancel={handleCancel}
        className="previewModal noTitle fomodal"
      >
        <div className="previewmodalBody">
          {vedioObj && (
            <div className="knowledgeFileVideo">
              <video width="100%" height="100%" id="video" controls>
                <source src={vedioObj?.cloud_file_path} id="source" />
              </video>
            </div>
          )}
        </div>
      </Modal>
    </Layout>
  );
};

export default FarmObservationsDetails;

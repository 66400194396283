const RoutesConstants = {
  Dashboard: '/dashboard',
  Login: '/login',
  Attention: '/',
  Admin: '/admin',

  Exception: {
    401: '/exception/401',
    403: '/exception/403',
    404: '/exception/404',
    500: '/exception/500',
  },

  Profile: '/profile',
  ChangeAdminEmail: '/change_org_admin',
  AdminChangeSuccess: '/AdminChangeSuccess',
  ChangePassword: '/changePassword',
  ResetPassword: '/resetPassword',
  ForgotPassword: '/forgot_password',
  EmailConfirm: '/email_confirm',
  ResetPwSuccess: '/resetPwSuccess',
  ForgotPwSuccess: '/ForgotPwSuccess',
  Organization: '/organization',
  Basestation: '/basestation',
  Tractors: '/tractors',
  Taskmaster: '/tasks',
  Taskdetails: '/task',
  TaskmasterHistory: '/taskmaskhistory',
  TractorDetails: '/tractor',
  LiveMap: '/',
  MapMakerBeta: '/MapMaker',
  Notifications: '/notify',
  Indicators: '/indicators',
  IndicatorDetails: '/indicator-details',
  Snapshot: '/Snapshot',
  DailyFleetUsage: '/dailyfleet',
  Maintain: '/maintain',
  WorkInProgress: '/workinprogress',
  SomethingWentWrong: '/SomethingWentWrong',
  SnapshotView: '/SnapshotView',
  TractorPopDetails: '/tractor-details',
  Jumpandgo: '/Jumpandgo',
  Jumpandgodetails: '/Jumpandgodetails',
  RemoteAccess: '/remote_access_grant',
  EmployeeProfileDetails: '/EmployeeProfileDetails',
  RoleDetails: '/RoleDetails',
  BaseStationDetails: '/BaseStationDetails',
  ImplementTabs: '/ImplementTabs',
  RestartManager: '/restartManager',
  RepairManual: '/repair-manual',
  UpdateManager: '/updateManager',
  Fleetsoftwarehistory: '/fleetsoftwarehistory',
  MapMaker: '/map-maker',
  LiveCamera: '/LiveCamera',
  LiveSnapshot: '/LiveSnapshot',
  ReportsDistribution: '/ReportsDistribution',
  LiveMapTractorDetails: '/LiveMapTractorDetails',
  Guardrails: '/Guardrails',
  RemoteAccessMHistory: '/RemoteAccessMHistory',
  CameraView: '/CameraView',
  AllCameraView: '/AllCameraView',
  RemoteDrive: '/RemoteDrive',
  Indicatorspopup: '/Indicators',
  NotificationsPopup: '/Notifications',
  Shadow: '/Shadow',
  Copycat: '/Copycat',
  RowFollow: '/RowFollow',
  FleetIndicatorHistory: '/fleetIndicatorHistory',
  FleetTicketsHistory: '/fleetTicketsHistory',
  DataVault: '/datavault',
  RecordedVideo: '/recordedVideo',
  VideoSnaps: '/videoSnaps',
  DayView: '/dayView',
  BaseIndicatorDetails: '/baseIndicatorDetails',
  VideoPop: '/VideoPop',
  FormProfile: '/formProfile',
  Analytics: '/analytics',
  Apps: '/apps',
  Trimble: '/apps/trimble',
  TrimbleDetails: '/apps/trimble/Details',
  FleetHealth: '/fleethealth',
  Reports: '/reports',
  FileUploadView: '/FileUploadView',
  // RemoteAccess: '/remote_access_grant',
  FleetUsage: '/fleetusage',
  HowToManual: '/howtomanual',
  Analytic: '/analytic',
  VideoFeed: '/videofeed',
  ChatWeb: '/converse',
  ChangeUserName: '/username_change',
  ImpactDashBoard: '/impact-dashboard',
  DealersFarmProfile: '/dealer/farmProfile',
  DealersMaintenance: '/dealer/maintenance',
  DealerAnalytics: '/dealer/analytics',
  DealerPlanning: '/dealer/planning',
  HelpCenterSearch: '/helpcenter/search',
  HelpCenterResult: '/helpcenter/result',
  HelpCenterMedia: '/helpcenter/media',
  HelpCenter: '/helpcenter',
  CategoryVideo: '/categoryvideo',
  TrainingVideos: '/trainingvideos',
  OperationalAnalytics: '/operationalAnalytics',
  ImplementsList: '/implementsList',
  markMaintenance: '/maintainance',
};

export default RoutesConstants;

import React, { useContext, useEffect, useState } from 'react';
import { notification, Row } from 'antd';
import { getTractorsListData, getOtaLogs } from '../../constants/Api';
import Layout from 'antd/lib/layout/layout';
import CSelect from '../common/CSelect';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import translate from '../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';
import { ApplicationContext } from '../../context/AppContext';
import { TractorsList, otaLogs } from '../../constants/types';
import {
  getDateTimes,
  getTractorAvailableState,
  tractorSort,
} from '../../constants/Common';
import OtaDetailsModal from './OtaDetailsModal';
import CustomSelect from '../common/CustomSelect';
import CustomSelect2 from '../common/CustomSelect2';
import Interweave from 'interweave';

interface TractorResponseType {
  label: string;
  value: number | string | boolean;
  disabled: boolean;
  index: number | string;
  isAvailable?: string;
}

const OtaLogs: React.FC = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [tractorId, setTractorId] = useState<any>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPagesize] = useState<any>(25);
  const [tractors, setTractorsData] = useState<TractorResponseType[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [filterData, setFilter] = useState<otaLogs[]>([]);
  const [totalCount, setTotalCount] = useState<any>();
  const [otaDetailsModal, setOtaDetailsModal] = useState<boolean>(false);
  const [otaRecords, setOtaRecords] = useState();
  const [status, setStatus] = useState<string>('');

  const statusFilterData = [
    { name: 'Failed', value: 'failed' },
    { name: 'In Progress', value: 'inprogress' },
    { name: 'Success', value: 'success' },
  ];

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getTractorsList();
    }
  }, [userDetails]);

  const getTractorsList = async () => {
    try {
      const tractor: { records: TractorsList[] } = await getTractorsListData(
        userDetails.organization.api_url,
        userDetails.organization_id,
        pageNumber,
      );
      const data =
        tractor && tractor.records && tractor.records.length > 0
          ? tractor.records
          : [];
      const tractorData: TractorResponseType[] = data.map((d: TractorsList) => {
        return {
          label: d.name,
          value: d.id,
          disabled: false,
          index: d.id,
          id: d.id,
          connection: d.connection,
          isOnline: d.connection === 'online' ? true : false,
          isAvailable: getTractorAvailableState(d.connection, d?.heartbeat),
        };
      });
      const arr = tractorSort(tractorData, 'label');
      setTractorsData(arr);
      if (tractorData.length > 0) {
        setTractorId(arr[0]?.value);
      }
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  useEffect(() => {
    if (tractorId) {
      getOtaLogsList();
    }
  }, [userDetails, pageNumber, tractorId, status]);

  const getOtaLogsList = async () => {
    try {
      setLoader(true);
      const logs = await getOtaLogs(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
        tractorId,
        pageNumber,
        pageSize,
        status,
      );
      setTotalCount(logs._metadata.total_records_count);
      const data =
        logs && logs.records && logs.records.length > 0 ? logs.records : [];
      const shadowData: any[] = [];
      data.map((record: any) => {
        const obj = {
          id: record.id,
          name: record.tractor.name,
          tractorId: record.tractor.id,
          softwareVersion: record.software_version_id,
          status: record?.status,
          releaseNotes: record?.release_notes ? record.release_notes : '-',
          created_date_time: record?.created_date_time
            ? getDateTimes(record?.created_date_time)
            : '-',
        };
        shadowData.push(obj);
      });
      if (data.length > 0) {
        if (pageNumber === 1) {
          setFilter([...shadowData]);
        } else {
          setFilter([...filterData, ...shadowData]);
        }
      } else {
        setFilter(shadowData);
      }
      if (shadowData.length < 25) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const columns = [
    {
      title: `${t(translate.copycat.tractorName)}`,
      dataIndex: 'name',
      key: 'name',
      width: '14%',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: `${t(translate.fleet.softwareVersion)}`,
      dataIndex: 'softwareVersion',
      key: 'softwareVersion',
      width: '21%',
      sorter: (a: any, b: any) =>
        a.softwareVersion.localeCompare(b.softwareVersion),
    },
    {
      title: `${t(translate.fleetticketsHistory.createdbydate)}`,
      dataIndex: 'created_date_time',
      key: 'created_date_time',
      width: '16%',
    },
    {
      title: `${t(translate.softwarehistorylogs.releaseNotes)}`,
      dataIndex: 'releaseNotes',
      key: 'releaseNotes',
      render: (releaseNotes: string) => <Interweave content={releaseNotes} />,
    },
    {
      title: `${t(translate.label.status)}`,
      dataIndex: 'status',
      key: 'status',
      width: '12%',
      sorter: (a: any, b: any) => a.status.localeCompare(b.status),
    },
  ];

  const handleLoadMore = () => {
    setPageNumber(pageNumber + 1);
  };

  const handleSelect = async (e: any) => {
    setTractorId(e);
    setPageNumber(1);
  };

  return (
    <Layout>
      <div className="mainContent">
        <div className="tblContainer viewportContainer">
          <Row>
            <div className="common_wrapper wWidget_wrapper">
              <div className="filters_card personnelCard">
                <CustomSelect2
                  label="Tractor"
                  cssClass="min_width"
                  value={tractorId}
                  setValue={(id: any) => {
                    handleSelect(id);
                  }}
                  options={tractors.map((item) =>
                    Object.assign({
                      id: item.index,
                      name: item.label,
                      isAvailable: item.isAvailable,
                    }),
                  )}
                />
                <CustomSelect
                  label="Status"
                  cssClass="min_width"
                  value={status}
                  setValue={(id: any) => {
                    setStatus(id);
                  }}
                  options={statusFilterData}
                  optionKey="value"
                />
              </div>
              <div className="common_table">
                <InfiniteScrollTable
                  columns={columns}
                  loading={loader}
                  hasMore={hasMore}
                  filterData={filterData}
                  totalcount={totalCount}
                  handleLoadMore={handleLoadMore}
                  filename="OtaLogs"
                  onRowClick={(record: any) => {
                    setOtaRecords(record);
                    setOtaDetailsModal(true);
                  }}
                />
              </div>
              <div>
                {otaDetailsModal && (
                  <OtaDetailsModal
                    showModel={otaDetailsModal}
                    closeModel={() => setOtaDetailsModal(false)}
                    otaRecords={otaRecords}
                  />
                )}
              </div>
            </div>
          </Row>
        </div>
      </div>
    </Layout>
  );
};

export default OtaLogs;

/* eslint-disable no-console */
import { Col, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import './css/global.scss';
import './css/styles.scss';

import Search from 'antd/lib/transfer/search';
import './css/global.scss';
import './css/styles.scss';
import Back_Button from './images/Back_Button.svg';
import imp_icon from './images/implement-1.svg';
import coverage from './images/coverage.svg';
import distance from './images/distance.svg';
import efficiency from './images/efficiency.svg';
import low from './images/low.svg';
import medium from './images/medium.svg';
import time from './images/time.svg';

import speed from './images/speed.svg';
import tickets_outline from './images/tickets_outline-01.svg';

import moment from 'moment';
import { ApplicationContext } from '../../context/AppContext';
import EditImplement from './EditImplement';
import { isEmpty, fetchName } from './common';
import useAnalytics from './hooks/useAnalytics';
import useTractorAnalyticsDetails from './hooks/useTractorAnalyticsDetails';
import useAnalyticsStore from './store/operationAnalytics';
import tractorAnalyticDetailsStore from './store/tractorAnalyticDetails';
import OperationalAnalyticsTicketDetails from './OperationalAnalyticsTicketDetails';

export interface Task {
  id: number;
  title: string;
  startTime: string; // Format: HH:mm (e.g., "09:00")
  endTime: string; // Format: HH:mm (e.g., "17:00")
}

import PieChart from '../charts/PieChart';
import MapAndCamera from './OperationalCoverageMap/MapAndCamera';
import { pathColorCodes } from './constants';
import CustomDatePicker from './ui/CustomDatePicker';
import { HrsMins, WeekendsFilter } from './ui/helper';
import Spinner from '../common/Spinner';

interface Props {
  onBack: () => void;
  operationType: string;
}

const OperationAnalytics: React.FC<Props> = ({ onBack, operationType }) => {
  const { userDetails } = useContext(ApplicationContext);

  const { ticketsLoader, initAnalyticsData } = useTractorAnalyticsDetails();
  const { dateText } = useAnalytics();
  const { selectedDate, setSelectedDate, toggleWeekends, includeWeekends } =
    useAnalyticsStore();
  const {
    analyticDetails,
    ticketsData,
    operationalHours,
    selectedTractor,
    selectTractor,
  } = tractorAnalyticDetailsStore();
  const [showModal, setShowModal] = useState(false);
  const [toggleTicketWidget, setToggleTicketWidget] = useState<boolean>(false);
  const [ticketId, setTicketId] = useState<any>();

  const { operationHours, totalOperationalHours, operators } = analyticDetails;

  useEffect(() => {
    if (userDetails?.organization && selectedDate && selectedTractor) {
      const { implement, tractorId } = selectedTractor;

      initAnalyticsData(
        selectedDate?.startOf('day')?.toDate()?.getTime(),
        selectedDate?.endOf('day')?.toDate()?.getTime(),
        implement,
        tractorId,
      );
    }
  }, [userDetails, selectedDate, selectedTractor]);

  const renderTime = ({ hours, minutes }: any) => {
    return <HrsMins hours={hours} minutes={minutes} />;
  };

  const wrapDateTime = (timestamp: number) => {
    return <>{moment(timestamp).format('D/MM, h:mm a')}</>;
  };

  const getSeverity = (level: number) => {
    return (
      <>
        <img src={level > 0 ? medium : low} />
        <img src={level > 1 ? medium : low} />
        <img src={level > 2 ? medium : low} />
      </>
    );
  };

  const ticketDetailsById = (ticket: any) => {
    setToggleTicketWidget(true);
    setTicketId(ticket?.ticketId);
  };

  const onCloseTicketWidget = () => {
    setToggleTicketWidget(false);
  };

  const onImplementSave = (implement: string) => {
    selectTractor({ ...selectedTractor, implement, implement_name: implement });
    setShowModal(false);
  };

  return (
    <>
      <section className="background-parent">
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={10}>
            <div className="frame-parent2 noimplement_modes">
              <div className="frame-wrapper">
                <div className="frame-group">
                  <div className="back-header">
                    <div className="backButtonBig" onClick={onBack}></div>
                    {/* <img src={Back_Button} height="55" onClick={onBack} /> */}
                    <div className="header">
                      <div className="mowing">{operationType}</div>
                      <div className="date-picker-block">
                        <div className="date-month-selection">{dateText}</div>
                        <div className="date-picker">
                          <CustomDatePicker
                            handleOnChange={(dates) => setSelectedDate(dates)}
                            selectedDate={selectedDate}
                          />
                          {/* <img src={cal_img} alt="" /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="operations-graph1">
                <div className="header-info">
                  <div className="name-implement-operators">
                    <span className="text-10343">
                      {selectedTractor?.tractorShortPin}
                    </span>
                    <div className="implement-operators">
                      <div className="edit-implement">
                        <div className="implement-name">
                          <div className="">
                            <img src={imp_icon} />
                          </div>
                          <span className="bush-hog-rotary-mower">
                            {!isEmpty(selectedTractor?.implement)
                              ? selectedTractor?.implement
                              : 'No Implement'}
                          </span>
                        </div>
                        <div className="frame-69">
                          <span
                            className="edit-implement-6a"
                            onClick={() => setShowModal(!showModal)}
                          >
                            Edit Implement
                          </span>
                        </div>
                      </div>
                      <div className="frame-6b">
                        {operators.map((operator, i: number) => {
                          return (
                            <div className="frame-6c" key={i}>
                              <div className="operator-icons">
                                <div className="avatar-circles">
                                  <div className="mm">{operator?.name}</div>
                                </div>
                              </div>
                              <span className="matt-monroe">
                                {operator?.operator}:
                              </span>
                              <span className="h-m-ac">
                                <HrsMins
                                  hours={operator?.operationalHours.hours}
                                  minutes={operator?.operationalHours.minutes}
                                />
                                , {operator.coverage} ac
                              </span>
                            </div>
                          );
                        })}
                        {/* <div className="frame-6c">
                          <div className="operator-icons">
                            <div className="avatar-circles">
                              <div className="mm">MS</div>
                            </div>
                          </div>
                          <span className="matt-monroe">Mike Smith:</span>
                          <span className="h-m-ac">3h 34m, 13.5ac</span>
                        </div> */}
                        {/* <div className="frame-70">
                          <div className="operator-icons">
                            <div className="avatar-circles">
                              <div className="mm">MS</div>
                            </div>
                          </div>
                          <span className="mike-smith">Mike Smith:</span>
                          <span className="h-m-ac-74">3h 34m, 13.5ac</span>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="tractor-render">
                    <div className="image"></div>
                  </div>
                </div>
                <div className="duration">
                  <div className="duration-block">
                    <div className="graph-call-outs sixEight">
                      <div className="title-icon">
                        <img src={time} />
                        <span className="operations-duration">Duration</span>
                      </div>
                      <div className="caption">
                        <span className="operation-lasted">
                          This operation lasted{' '}
                        </span>
                        <span className="h-m">
                          <HrsMins
                            hours={totalOperationalHours?.hours}
                            minutes={totalOperationalHours?.minutes}
                          />
                        </span>
                        <span className="operation-lasted-76"> </span>
                        <span className="pie-chart">
                          across different drive modes.
                        </span>
                      </div>
                      <div className="frame-77">
                        <div className="rectangle-78">
                          <div className="manual-bar"></div>
                          <div className="title-4h-03m">
                            <span className="title-manual">
                              {renderTime(operationHours?.manual)}
                            </span>
                            <span className="frame-7a">Manual</span>
                          </div>
                        </div>
                        <div className="title-autonomy">
                          <div className="autonomy-bar"></div>
                          <div className="rectangle-81">
                            <span className="frame-82">
                              {renderTime(operationHours?.autoDrive)}
                            </span>
                            <span className="title-35-m">Autonomy</span>
                          </div>
                        </div>
                        <div className="rectangle-7b">
                          <div className="opassist-bar"></div>
                          <div className="title-2h-05m">
                            <div className="op-assist">
                              {renderTime(operationHours?.opAssist)}
                            </div>
                            <span className="title-1h-12m">Op Assist</span>
                          </div>
                        </div>

                        <div className="group-1a">
                          <div className="idle-bar"></div>
                          <div className="group-1b">
                            <div className="wrapper-22">
                              {renderTime(operationHours?.idle)}
                              {/* {operationHours?.idle?.hours && (
                                <>
                                  <span className="text-52">
                                    {operationHours?.idle?.hours}
                                  </span>
                                  <span className="text-53">h </span>
                                </>
                              )}
                              <span className="text-52">
                                {operationHours?.idle?.minutes}
                              </span>
                              <span className="text-53">m</span> */}
                            </div>
                            <span className="idle-time">Idle time</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="group">
                      {/* <img className="" src={PieChart} /> */}
                      <PieChart
                        loader={ticketsLoader}
                        chartId="OperationalHours"
                        data={operationalHours}
                        barColors={[
                          pathColorCodes?.manual,
                          pathColorCodes?.autonomy,
                          pathColorCodes?.op_assists,
                          pathColorCodes?.idle,
                        ]}
                        // barColors={['#67afb7', '#ec9322', '#f1ce90', '#cfcfcf']}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="statistics">
                <div className="statistics-item">
                  <img src={coverage} />
                  <div className="statistics-group">
                    <span className="statistics-title ">Coverage (ac)</span>
                    <span className="statistics-value">
                      {analyticDetails?.coverage}
                    </span>
                  </div>
                </div>
                <div className="statistics-item">
                  <img src={efficiency} />
                  <div className="statistics-group">
                    <span className="statistics-title">Efficiency (ac/hr)</span>
                    <span className="statistics-value">
                      {analyticDetails?.efficiency}
                    </span>
                  </div>
                </div>
                <div className="statistics-item">
                  <img src={distance} />
                  <div className="statistics-group">
                    <span className="statistics-title ">Distance (mi)</span>
                    <span className="statistics-value">
                      {analyticDetails?.distance}
                    </span>
                  </div>
                </div>
                <div className="statistics-item">
                  <img src={speed} />
                  <div className="statistics-group">
                    <span className="statistics-title ">Speed (mph)</span>
                    <span className="statistics-value">
                      {analyticDetails?.averageSpeed}
                    </span>
                  </div>
                </div>
              </div>
              <div className="bottom-left-tickets">
                <div className="header2">
                  <div className="header2">
                    <div className="title-info">
                      <div className="operations9">Tickets</div>
                    </div>
                    <div className="shape-compiler">
                      <img
                        className="implement-1-icon1"
                        alt=""
                        src={tickets_outline}
                      />

                      <div className="image-parser">{ticketsData?.length}</div>
                    </div>
                  </div>
                </div>
                <div className="link-aggregator">
                  <div className="tickets-block">
                    {!ticketsData?.length && (
                      <div className="empty-ticket-block">
                        {ticketsLoader ? (
                          <Spinner loader={ticketsLoader} />
                        ) : (
                          'No Tickets Available'
                        )}
                      </div>
                    )}
                    {ticketsData?.map((ticket, i: number) => {
                      return (
                        <div
                          className="ticket-item"
                          key={i}
                          onClick={() => ticketDetailsById(ticket)}
                        >
                          <div className="top-section">
                            <div className="assigned-to-title">
                              <div className="assigned-to">
                                <span className="a-name">
                                  {ticket?.assignee &&
                                    fetchName(
                                      ticket?.assignee?.first_name,
                                      ticket?.assignee?.last_name,
                                    )}
                                </span>
                              </div>
                              <span className="ticket-number">
                                Ticket #{ticket?.ticketId}
                              </span>
                            </div>
                            {ticket?.status === 'resolved' ? (
                              <button className="status-rsl">
                                <span className="resolved">Resolved</span>
                              </button>
                            ) : (
                              <button className="status-fwd">
                                <span className="forwarded">Forwarded</span>
                              </button>
                            )}
                          </div>
                          <div className="copy-severity">
                            <div className="description-date">
                              <span className="reboot-hitch-not-responding verticalEllipsis">
                                {ticket?.description}
                              </span>
                              <div className="creator-time-193">
                                <span className="c-name">
                                  {ticket?.createdBy &&
                                    fetchName(
                                      ticket?.createdBy?.first_name,
                                      ticket?.createdBy?.last_name,
                                    )}
                                </span>
                                <div className="ellipse-195"></div>
                                <span className="pm-196">
                                  {wrapDateTime(ticket?.date)}
                                </span>
                                {/* <span className="pm-196">11/9, 8:37 AM</span> */}
                              </div>
                            </div>
                            <div className="severity-flag">
                              {/* <img src={medium} />
                              <img src={low} />
                              <img src={low} /> */}
                              {getSeverity(ticket?.ticketLevel)}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {/* <div className="ticket-item">
                      <div className="top-section">
                        <div className="assigned-to-title">
                          <div className="assigned-to">
                            <span className="a-name">NL</span>
                          </div>
                          <span className="ticket-number">Ticket #123452</span>
                        </div>
                        <button className="status-rsl">
                          <span className="resolved">Resolved</span>
                        </button>
                      </div>
                      <div className="copy-severity">
                        <div className="description-date">
                          <span className="reboot-hitch-not-responding">
                            Check tire pressure before operating.
                          </span>
                          <div className="creator-time-193">
                            <span className="c-name">MM</span>
                            <div className="ellipse-195"></div>
                            <span className="pm-196">11/9, 8:37 AM</span>
                          </div>
                        </div>
                        <div className="severity-flag">
                          <img src={medium} />
                          <img src={low} />
                          <img src={low} />
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="ticket-item">
                      <div className="top-section">
                        <div className="assigned-to-title">
                          <div className="assigned-to-monarch">
                            <img src={amonarch} />
                          </div>
                          <span className="ticket-number">Ticket #123452</span>
                        </div>
                        <button className="status-fwd">
                          <span className="forwarded">Forwarded</span>
                        </button>
                      </div>
                      <div className="copy-severity">
                        <div className="description-date">
                          <span className="reboot-hitch-not-responding">
                            Check tire pressure before operating.
                          </span>
                          <div className="creator-time-193">
                            <span className="c-name">MM</span>
                            <div className="ellipse-195"></div>
                            <span className="pm-196">11/9, 8:37 AM</span>
                          </div>
                        </div>
                        <div className="severity-flag">
                          <img src={medium} />
                          <img src={medium} />
                          <img src={low} />
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="ticket-item">
                      <div className="top-section">
                        <div className="assigned-to-title">
                          <div className="assigned-to-monarch">
                            <img src={amonarch} />
                          </div>
                          <span className="ticket-number">Ticket #178828</span>
                        </div>
                        <button className="status-fwd">
                          <span className="forwarded">Forwarded</span>
                        </button>
                      </div>
                      <div className="copy-severity">
                        <div className="description-date">
                          <span className="reboot-hitch-not-responding">
                            Implement detached mid-operation. Review fastening
                          </span>
                          <div className="creator-time-193">
                            <span className="c-name">MM</span>
                            <div className="ellipse-195"></div>
                            <span className="pm-196">11/9, 1:13 PM</span>
                          </div>
                        </div>
                        <div className="severity-flag">
                          <img src={medium} />
                          <img src={medium} />
                          <img src={low} />
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={14}>
            <div className="incidents-section">
              <WeekendsFilter
                includeWeekends={includeWeekends}
                toggleWeekends={toggleWeekends}
              />
              <MapAndCamera />

              {/* <div className="container-17">
                <div className="bottom-left-graphs1">
                  <div className="header2 align-top">
                    <div className="align-left graph-call-outs6">
                      <div className="operations4">Efficiency</div>
                      <div className="proximity-provider">
                        <div className="layer-llama">
                          <span className="span2">3.2 </span>
                          <span className="achr">ac/hr</span>
                        </div>

                        <div className="comparative-statistic">
                          <img
                            className="increase-icon"
                            loading="lazy"
                            alt=""
                            src={increase}
                          />

                          <div className="blend-brewer">19%</div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <img src={hr2} alt="" />
                    </div>
                  </div>
                  <div className="link-aggregator ">
                    <img src={efficiency_graph} className="graphImg" />
                  </div>
                </div>

                <div className="bottom-left-graphs1">
                  <div className="header2 align-top">
                    <div className="align-left graph-call-outs6">
                      <div className="title-info">
                        <div className="operations">Average Battery Range</div>
                        <div className="info-bubbles">
                          <img className="info-3-icon" src={info_img} />
                        </div>
                      </div>
                      <div className="proximity-provider">
                        <div className="layer-llama">
                          <span className="span2">8</span>
                          <span className="achr">h</span>
                          <span className="span2"> 42</span>
                          <span className="achr">m</span>
                        </div>
                        <div className="comparative-statistic2">
                          <img
                            className="decrease-icon1"
                            alt=""
                            src={decrease}
                          />
                          <div className="blend-brewer">8%</div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <img src={bnatterycharger} alt="" />
                    </div>
                  </div>
                  <div className="link-aggregator ">
                    <img src={battery_graph} className="graphImg" />
                  </div>
                </div>
              </div> */}
              {/* <div className="savings">
                <div className="left-side">
                  <div className="graph-call-outs2">
                    <div className="title-info">
                      <div className="operations9">Total Savings</div>
                      <div className="info-bubbles">
                        <img className="info-3-icon" src={info_img} />
                      </div>
                    </div>
                    <div className="filter-merger">$287.32</div>
                  </div>
                  <div className="image-cropper">
                    <div className="graph-call-outs3">
                      <div className="operations10">CO2 Savings</div>
                      <div className="div49">62.8 lbs</div>
                    </div>
                    <div className="numerical-summarizer">
                      <img className="co2-icon" src={co2_img} />
                    </div>
                  </div>
                </div>
                <div className="savings-graph">
                  <img src={savings_graph} style={{ height: "123px" }} />
                </div>
              </div> */}
            </div>
          </Col>
        </Row>
      </section>
      {showModal && <EditImplement onSave={onImplementSave} />}
      {toggleTicketWidget && (
        <OperationalAnalyticsTicketDetails
          ticketId={ticketId}
          onCloseTicketWidget={onCloseTicketWidget}
          toggleTicketWidget={toggleTicketWidget}
        />
      )}
    </>
  );
};

export default OperationAnalytics;

import { notification } from 'antd';
import useTractorAnalyticDetailsStore from '../store/tractorAnalyticDetails';
import { getTractorAnalyticDetails } from '../services/api';
import { useContext, useState } from 'react';
import { ApplicationContext } from '../../../context/AppContext';
import { fetchName, getHoursAndMinutes, getUserName } from '../common';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useTractorAnalyticsDetails = () => {
  const { addTicketsData, addAnalyticsDetails, addOperationalHoursData } =
    useTractorAnalyticDetailsStore();
  const { userDetails } = useContext(ApplicationContext);
  const [ticketsLoader, setTicketsLoader] = useState(false);

  const initAnalyticsData = async (
    fromDate: string,
    toDate: string,
    implementType: string,
    tractorId: string,
  ) => {
    try {
      addTicketsData([]);
      setTicketsLoader(true);
      let analyticDetails = {};
      if (!userDetails?.organization) return false;
      // const { organization } = userDetails;
      // const { api_url } = organization;
      const data = await getTractorAnalyticDetails(
        fromDate,
        toDate,
        implementType,
        tractorId,
      );
      const {
        tractor_ticket_response,
        op_hours_coverage_data: details,
        user_op_data,
      } = data;
      if (user_op_data?.length) {
        const [{ implement_details, tractor_id }] = user_op_data;
        analyticDetails = {
          tractorId: tractor_id,
          implementName: implement_details?.name,
        };
      }
      const operators = user_op_data.map((item: any) => {
        return {
          id: item?.operator_id,
          coverage: item?.total_covered_area,
          operationalHours: getHoursAndMinutes(item?.total_operational_hours),
          name: fetchName(item?.user_first_name, item?.user_last_name),
          operator: getUserName(item?.user_first_name, item?.user_last_name),
        };
      });
      analyticDetails = {
        ...analyticDetails,
        operators,
        operationHours: {
          manual: getHoursAndMinutes(details?.manual_hours),
          idle: getHoursAndMinutes(details?.ideal_hours),
          opAssist: getHoursAndMinutes(details?.operator_assist_hours),
          autoDrive: getHoursAndMinutes(details?.autodrive_hours),
        },
        totalOperationalHours: getHoursAndMinutes(
          details?.total_operational_hours,
        ),
        efficiency: details?.overall_implement_efficiency,
        averageSpeed: details?.average_speed,
        coverage: details?.total_covered_area,
        distance: details?.total_distance,
      };
      addAnalyticsDetails(analyticDetails);
      const fdata = tractor_ticket_response.map((item: any) => ({
        date: item.date,
        status: item.status,
        ticketId: item.ticket_id,
        tractorId: item.tractor_id,
        createdBy: item?.createdBy,
        assignee: item?.assignee_user_details,
        ticketLevel: item?.ticket_level,
        description: item?.ticket_description,
        latitude: item?.latitude,
        longitude: item?.longitude,
      }));

      addTicketsData(fdata);
      const operationalHours = [
        { category: 'Manual', value: details?.manual_hours_percentage },
        { category: 'Auto Drive', value: details?.autodrive_hours_percentage },
        {
          category: 'Op Assist',
          value: details?.operator_assist_hours_percentage,
        },
        { category: 'Idle', value: details?.ideal_hours_percentage },
      ];
      addOperationalHoursData(operationalHours);
    } catch (error: any) {
      notification.error({ message: error?.message });
    } finally {
      setTicketsLoader(false);
    }
  };

  return {
    ticketsLoader,
    initAnalyticsData,
  };
};

export default useTractorAnalyticsDetails;

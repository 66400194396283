/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from 'react';
import autodrive_pause_circle from '../../assets/images/autodrive_pause_circle.svg';
import autodrive_play_circle from '../../assets/images/autodrive_play_circle.svg';
import health_concern from '../../assets/images/errorCode_icon.svg';
import play_circle from '../../assets/images/play_circle.svg';
import seeder_refill from '../../assets/images/seeder_refill.svg';
import translate from '../../locale/en_translate.json';
import './autodrivelist.css';

import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { getImplementsList1 } from '../../constants/Api';
import {
  getInterruptsByTractor,
  getMonitorErrorsList,
  getPriorityInterrupt,
  getSystemPausedInterrupts,
  isHaveSeederInterrupt,
  loginUserHaveMosCloudInterruptsOrNot,
  setInterruptByTractor,
} from '../../constants/Common';
import {
  HARD,
  MOS_CLOUD,
  PAUSE,
  SOFT,
  error_message,
  getDriveActionUuid,
  getInterruptImage,
  getInterruptTitle,
  getPayloadForContinueOperation,
  getPayloadForEndOperation,
  interruptKey,
  interruptMessageTypes,
  tractorEndPopDefault,
} from '../../constants/constant';
import { MosEventsResp, TractorHeartBeat } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import { AutoDriveApplicationContext } from '../../context/AutoDriveContext';
import CustomInput from '../common/CustomInput';
import DriveOptionSettings from '../common/DriveOptionSettings';
import AutoDriveEndPopup from './AutoDriveEndPopup';
import GuardRailsImages from './GuardRailsImages';
import { RemoteDriveAppCtx } from './RemoteDriveContext';
import { SET_AUTO_DRIVE_INTERRUPT_MESSAGES } from './actions';

interface Props {
  clearAll: (heartBeat: TractorHeartBeat) => void;
  stopAndDisconnectHandler: () => void;
}

let globalAutoDriveInterruptMsgs: any = {};

export const AutoDriveInterruptMsg: React.FC<Props> = ({
  clearAll,
  stopAndDisconnectHandler,
}) => {
  const { t } = useTranslation();
  const [showResumeAndService, setShowResumeAndService] =
    useState<number>(tractorEndPopDefault);
  const { userDetails, APPReducer } = useContext(ApplicationContext);
  const {
    RDReducer,
    startAutoDriveIteration,
    getImplement,
    addImplementSettings,
  } = useContext(RemoteDriveAppCtx);
  const [state, dispatch] = RDReducer;
  const [appState] = APPReducer;
  const { tractorsList } = appState;
  const [endPopIndex, setEndPopIndex] = useState(tractorEndPopDefault);
  const [endADIM, setEndADIM] = useState<any>();
  const { selectedTractor, autoDriveInterruptMsgs } = state;
  const { AutoDriveReducer } = useContext(AutoDriveApplicationContext);
  const [autoDriveState] = AutoDriveReducer;
  const { remoteAvTractors } = autoDriveState;
  const [currentCapacity, setCurrentCapacity] = useState<number>(0);
  const [applicationRate, setApplicationRate] = useState<number>(0);
  const [selectedImplement, setSelectedImplement] = useState<string>('');
  const [implementsList, setImplements] = useState<any[]>([]);
  const [savedImplementDetails, setSavedImplementDetails] = useState<any>(null);
  useEffect(() => {
    setCurrentCapacity(Number(savedImplementDetails?.current_capacity || 0));
    setApplicationRate(Number(savedImplementDetails?.application_rate || 0));
  }, [savedImplementDetails]);
  useEffect(() => {
    globalAutoDriveInterruptMsgs = autoDriveInterruptMsgs;
  }, [autoDriveInterruptMsgs]);

  useEffect(() => {
    return () => {
      globalAutoDriveInterruptMsgs = {};
    };
  }, []);

  const getDescription = (aDIM: MosEventsResp) => {
    const details = aDIM?.metadata?.details;
    switch (details) {
      case interruptMessageTypes.guardrails_human:
        return (
          <p className="body2">
            {aDIM.metadata.type === SOFT &&
              t(translate.AutoDrive.AutoDriveInterrupt.guardrailsHumanSoft)}
            {aDIM.metadata.type === HARD &&
              t(translate.AutoDrive.AutoDriveInterrupt.guardrailsHumanHard)}
          </p>
        );
      case interruptMessageTypes.guardrails_path_presence:
        return (
          <p className="body2">
            {aDIM.metadata.type === SOFT &&
              t(
                translate.AutoDrive.AutoDriveInterrupt
                  .guardrailsPathPresenceSoft,
              )}
            {aDIM.metadata.type === HARD &&
              t(
                translate.AutoDrive.AutoDriveInterrupt
                  .guardrailsPathPresenceHard,
              )}
          </p>
        );
      case interruptMessageTypes.lost_localization:
        return (
          <>
            {aDIM.metadata.type === SOFT && (
              <>
                <p className="body2">
                  {t(
                    translate.AutoDrive.AutoDriveInterrupt
                      .lostLocalizationSoft1,
                  )}
                </p>
                <p className="body2">
                  {t(
                    translate.AutoDrive.AutoDriveInterrupt
                      .lostLocalizationSoft2,
                  )}
                </p>
              </>
            )}
            {aDIM.metadata.type === HARD && (
              <>
                <p className="body2">
                  {t(
                    translate.AutoDrive.AutoDriveInterrupt
                      .lostLocalizationSoft1,
                  )}
                </p>
                <p className="body2">
                  {t(
                    translate.AutoDrive.AutoDriveInterrupt
                      .lostLocalizationSoft2,
                  )}
                </p>
              </>
            )}
          </>
        );
      case interruptMessageTypes.steer_calibration_error:
        return (
          <>
            <p className="body2">
              {t(translate.AutoDrive.AutoDriveInterrupt.steerCalibrationError1)}
            </p>
            <p className="body2">
              {t(translate.AutoDrive.AutoDriveInterrupt.steerCalibrationError2)}
            </p>
          </>
        );
      case interruptMessageTypes.firmware_fault:
        return (
          <>
            <p className="body2">
              {t(translate.AutoDrive.AutoDriveInterrupt.firmwareFault1)}
            </p>
            <p className="body2">
              {t(translate.AutoDrive.AutoDriveInterrupt.firmwareFault2)}
            </p>
          </>
        );
      case interruptMessageTypes.firmware_fault_other:
        return (
          <p className="body2">
            {t(translate.AutoDrive.AutoDriveInterrupt.firmwareFaultOther)}:
          </p>
        );
      case interruptMessageTypes.poor_camera_visibility:
        return (
          <p className="body2">
            {t(translate.AutoDrive.AutoDriveInterrupt.poorCameraVisibility)}
          </p>
        );
      case interruptMessageTypes.guardrails_human_front:
        return (
          <>
            <p className="body2">
              {t(translate.AutoDrive.AutoDriveInterrupt.guardrailsHumanFront)}
            </p>
          </>
        );
      case interruptMessageTypes.tractor_moved_off_path:
        return (
          <>
            <p className="body2">
              {t(translate.AutoDrive.AutoDriveInterrupt.tractorMovedOffPath1)}
            </p>
            <p className="body2">
              {t(translate.AutoDrive.AutoDriveInterrupt.tractorMovedOffPath2)}
            </p>
          </>
        );
      case interruptMessageTypes.tractor_lost_connection:
        return (
          <>
            {aDIM.metadata.type === SOFT && (
              <>
                <p className="body2">
                  {t(
                    translate.AutoDrive.AutoDriveInterrupt
                      .tractorLostConnectionSoft1,
                  )}
                </p>
                <p className="body2">
                  {t(
                    translate.AutoDrive.AutoDriveInterrupt
                      .tractorLostConnectionSoft2,
                  )}
                </p>
              </>
            )}
            {aDIM.metadata.type === HARD && (
              <>
                <p className="body2">
                  {t(
                    translate.AutoDrive.AutoDriveInterrupt
                      .tractorLostConnectionHard1,
                  )}
                </p>
                <p className="body2">
                  {t(
                    translate.AutoDrive.AutoDriveInterrupt
                      .tractorLostConnectionHard2,
                  )}
                </p>
              </>
            )}
          </>
        );
      case interruptMessageTypes.guardrails_slip:
        return (
          <>
            <p className="body2">
              {t(translate.AutoDrive.AutoDriveInterrupt.guardrailsSlip1)}
            </p>
            <p className="body2">
              {t(translate.AutoDrive.AutoDriveInterrupt.guardrailsSlip2)}
            </p>
          </>
        );

      case interruptMessageTypes.seatbelt_state_change:
        return (
          <>
            <p className="body2">
              {t(translate.AutoDrive.AutoDriveInterrupt.seatbeltStateChange1)}
            </p>
            <p className="body2">
              {t(translate.AutoDrive.AutoDriveInterrupt.seatbeltStateChange2)}
            </p>
          </>
        );
      case interruptMessageTypes.driver_occupancy_state_change:
        return (
          <>
            <p className="body2">
              {t(
                translate.AutoDrive.AutoDriveInterrupt
                  .driverOccupancyStateChange1,
              )}
            </p>
            <p className="body2">
              {t(
                translate.AutoDrive.AutoDriveInterrupt
                  .driverOccupancyStateChange2,
              )}
            </p>
          </>
        );
      case interruptMessageTypes.guardrails_implement_collision:
        return (
          <>
            <p className="body2">
              {t(
                translate.AutoDrive.AutoDriveInterrupt
                  .guardrailsImplementCollision,
              )}
            </p>
          </>
        );
      case interruptMessageTypes.implement_refill:
        return (
          <>
            <p className="body2">
              {t(translate.AutoDrive.AutoDriveInterrupt.implementRefill)}
            </p>
          </>
        );
      case interruptMessageTypes.remote_estop:
        return (
          <>
            <p className="body2">
              {t(translate.AutoDrive.AutoDriveInterrupt.remoteEStop)}
            </p>
          </>
        );
      case interruptMessageTypes.autonomy_disabled:
        return (
          <>
            <p className="body2">
              {t(translate.AutoDrive.AutoDriveInterrupt.autonomyDisabled)}
            </p>
          </>
        );
      case interruptMessageTypes.vinerow_vision_error:
        return (
          <>
            <p className="body2">
              {t(translate.AutoDrive.AutoDriveInterrupt.VinerowVisionError1)}{' '}
              {t(translate.AutoDrive.AutoDriveInterrupt.VinerowVisionError2)}
            </p>
          </>
        );
      case interruptMessageTypes.row_follow_tracking_error:
        return (
          <>
            <p className="body2">
              {t(
                translate.AutoDrive.AutoDriveInterrupt.RowFollowTrackingError1,
              )}{' '}
              {t(
                translate.AutoDrive.AutoDriveInterrupt.RowFollowTrackingError2,
              )}
            </p>
          </>
        );
      case interruptMessageTypes.firmware_fault_feedpusher:
        return (
          <>
            <p className="body2">
              {t(translate.AutoDrive.AutoDriveInterrupt.feedPusherError1)}{' '}
              {t(translate.AutoDrive.AutoDriveInterrupt.feedPusherError2)}
            </p>
          </>
        );
      case interruptMessageTypes.guardrails_cow:
        return (
          <>
            <p className="body2">
              {t(translate.AutoDrive.AutoDriveInterrupt.cowBlockingPath1)}{' '}
              {t(translate.AutoDrive.AutoDriveInterrupt.cowBlockingPath2)}
            </p>
          </>
        );
      case interruptMessageTypes.vehicle_presence:
        return (
          <>
            <p className="body2">
              {t(translate.AutoDrive.AutoDriveInterrupt.vehicleBlockingPath1)}{' '}
              {t(translate.AutoDrive.AutoDriveInterrupt.vehicleBlockingPath2)}
            </p>
          </>
        );
      case interruptMessageTypes.loc:
        return (
          <>
            {aDIM.metadata.type === SOFT && (
              <>
                <p className="body2">
                  {t(translate.AutoDrive.AutoDriveInterrupt.locSoft1)}
                </p>
                <p className="body2">
                  {t(translate.AutoDrive.AutoDriveInterrupt.locSoft2)}
                </p>
              </>
            )}
            {aDIM.metadata.type === HARD && (
              <>
                <p className="body2">
                  {t(translate.AutoDrive.AutoDriveInterrupt.locHard1)}
                </p>
                <p className="body2">
                  {t(translate.AutoDrive.AutoDriveInterrupt.locHard2)}
                </p>
              </>
            )}
          </>
        );

      default:
        return (
          <>
            <p className="body2">
              {t(translate.AutoDrive.AutoDriveInterrupt.interruptDefaultDesc1)}
            </p>
            <p className="body2">
              {t(translate.AutoDrive.AutoDriveInterrupt.interruptDefaultDesc2)}
            </p>
          </>
        );
    }
  };
  const continueOperation = (aDIM: MosEventsResp) => {
    const payload = getPayloadForContinueOperation(aDIM, userDetails.id);
    if (payload) {
      startAutoDriveIteration(selectedTractor.id, payload);
      const details = aDIM?.metadata?.details;
      if (
        details === interruptMessageTypes.guardrails_human ||
        details === interruptMessageTypes.guardrails_path_presence ||
        details === interruptMessageTypes.guardrails_implement_collision
      ) {
        const adIm = {
          ...aDIM,
          metadata: { ...aDIM.metadata, type: SOFT },
        };
        const payload1 = getPayloadForContinueOperation(adIm, userDetails.id);
        payload1 && startAutoDriveIteration(selectedTractor.id, payload1);
      }
      const iMsgs = getInterruptsByTractor(
        globalAutoDriveInterruptMsgs,
        selectedTractor.id,
      );
      delete iMsgs[interruptKey(aDIM)];
      const gADM = setInterruptByTractor(
        globalAutoDriveInterruptMsgs,
        iMsgs,
        selectedTractor.id,
      );
      dispatch({
        type: SET_AUTO_DRIVE_INTERRUPT_MESSAGES,
        payload: { ...gADM },
      });
    }
  };
  const endOperation = (aDIM: MosEventsResp) => {
    const payload = getPayloadForEndOperation(aDIM);
    if (payload) {
      startAutoDriveIteration(selectedTractor.id, payload);
      clearAll(selectedTractor?.heartbeat);
      // globalAutoDriveInterruptMsgs &&
      //   Object.keys(globalAutoDriveInterruptMsgs).map(
      //     (autoDriveInterruptMsg: string) => {
      //       if (autoDriveInterruptMsg.includes(selectedTractor.id)) {
      //         delete globalAutoDriveInterruptMsgs[autoDriveInterruptMsg];
      //       }
      //     },
      //   );
      globalAutoDriveInterruptMsgs[selectedTractor.id] = {};
      dispatch({
        type: SET_AUTO_DRIVE_INTERRUPT_MESSAGES,
        payload: globalAutoDriveInterruptMsgs,
      });
    }
  };

  const isPathProtectionDisabled = (aDIM: MosEventsResp): boolean => {
    let resp = false;
    const details = aDIM?.metadata?.details;
    const type = aDIM?.metadata?.type;
    const list = getMonitorErrorsList(remoteAvTractors, selectedTractor.id);
    if (type === HARD && list && list.length > 0) {
      list.map((error: number) => {
        if (
          // (details === interruptMessageTypes.guardrails_path_presence &&
          //   error == 76200) ||
          // (details === interruptMessageTypes.guardrails_human &&
          //   error == 76100) ||
          (details === interruptMessageTypes.lost_localization &&
            error == 7003) ||
          (details === interruptMessageTypes.loc && error == 7011)
          // (details === interruptMessageTypes.guardrails_human_front &&
          //   error == 76150)
        ) {
          resp = true;
        }
      });
    }
    return resp;
  };

  const interruptType = () => {
    const isSeeder = isHaveSeederInterrupt(
      autoDriveInterruptMsgs,
      selectedTractor.id,
    );
    const [aDIM] = getPriorityInterrupt(
      autoDriveInterruptMsgs,
      selectedTractor.id,
    );
    if (
      isSeeder &&
      aDIM?.metadata?.details === interruptMessageTypes.implement_refill
    ) {
      return interruptMessageTypes.implement_refill;
    } else {
      return interruptCount();
    }
  };
  const interruptCount = () => {
    const list = getSystemPausedInterrupts(
      autoDriveInterruptMsgs,
      selectedTractor.id,
    );
    // for multiple message
    return list.length > 0 ? list.length : 0;
    // for single message
    // return list.length > 0 ? 1 : 0;
  };
  const pauseButton = (aDIM: MosEventsResp) => {
    return (
      <button
        type="button"
        className="pOpBtn puaseBtn newPauseBtn Button2"
        onClick={() => {
          pauseOperationHandle(aDIM);
        }}
      >
        <img src={autodrive_pause_circle} className="mr6" />{' '}
        {t(translate.AutoDrive.Actions.StayPaused)}
      </button>
    );
  };
  const endOperationButton = (aDIM: MosEventsResp, index: number) => {
    return (
      <button
        type="button"
        className="pOpBtn Button2"
        onClick={() => {
          setEndPopIndex(index);
          setEndADIM(aDIM);
        }}
      >
        {t(translate.AutoDrive.Actions.EndOperation)}
      </button>
    );
  };
  const getPayloadForPauseOperation = (aDIM: MosEventsResp) => {
    const details = aDIM?.metadata?.details;
    const pausePayload = {
      action: PAUSE,
      details: MOS_CLOUD,
      category: MOS_CLOUD,
      type: HARD,
    };
    switch (details) {
      case interruptMessageTypes.guardrails_human:
        return pausePayload;
      case interruptMessageTypes.guardrails_path_presence:
        return pausePayload;
      case interruptMessageTypes.guardrails_implement_collision:
        return pausePayload;
      case interruptMessageTypes.guardrails_cow:
        return pausePayload;
      case interruptMessageTypes.vehicle_presence:
        return pausePayload;
      case interruptMessageTypes.firmware_fault_feedpusher:
        return pausePayload;
      default:
        return null;
    }
  };
  const pauseOperationHandle = (aDIM: MosEventsResp) => {
    const payload = getPayloadForPauseOperation(aDIM);
    if (payload) {
      startAutoDriveIteration(selectedTractor.id, payload);
      aDIM.showPause = true;
      const iMsgs = getInterruptsByTractor(
        globalAutoDriveInterruptMsgs,
        selectedTractor.id,
      );
      iMsgs[interruptKey(aDIM)] = aDIM;
      const gADM = setInterruptByTractor(
        globalAutoDriveInterruptMsgs,
        iMsgs,
        selectedTractor.id,
      );
      dispatch({
        type: SET_AUTO_DRIVE_INTERRUPT_MESSAGES,
        payload: { ...gADM },
      });
    }
  };

  useEffect(() => {
    userDetails && userDetails.organization && getImplementsListData();
  }, [userDetails]);

  const getImplementsListData = async () => {
    try {
      const records = await getImplementsList1(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
      );
      records && records.length > 0
        ? setImplements(records)
        : setImplements([]);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      // setLoader(false);
    }
  };
  useEffect(() => {
    setSelectedImplement('');
    if (selectedTractor && selectedTractor.id && implementsList.length > 0) {
      implementsList.forEach((implement: any) => {
        if (
          selectedTractor &&
          selectedTractor?.heartbeat &&
          selectedTractor?.heartbeat?.implement &&
          selectedTractor?.heartbeat?.implement?.current_implement &&
          selectedTractor?.heartbeat?.implement?.current_implement.length > 0 &&
          implement.name ===
            selectedTractor?.heartbeat?.implement?.current_implement
        ) {
          setSelectedImplement(implement.config_implements_uuid);
        }
      });
    }
  }, [selectedTractor, implementsList]);

  useEffect(() => {
    selectedTractor && selectedTractor.id && getImplement(selectedTractor.id);
  }, [selectedTractor]);

  const handleSeederResume = () => {
    const data = {
      ...savedImplementDetails,
      current_capacity: Number(currentCapacity),
      application_rate: Number(applicationRate),
    };
    addImplementSettings(data, false);
  };

  const interruptCodeElements = (aDIM: any, index: number) => {
    return (
      <div>
        {(aDIM?.metadata?.details ===
          interruptMessageTypes.guardrails_implement_collision ||
          aDIM?.metadata?.details ===
            interruptMessageTypes.guardrails_path_presence ||
          aDIM?.metadata?.details === interruptMessageTypes.guardrails_human ||
          aDIM?.metadata?.details ===
            interruptMessageTypes.poor_camera_visibility ||
          aDIM?.metadata?.details ===
            interruptMessageTypes.guardrails_human_front ||
          aDIM?.metadata?.details === interruptMessageTypes.lost_localization ||
          aDIM?.metadata?.details === interruptMessageTypes.loc ||
          aDIM?.metadata?.details ===
            interruptMessageTypes.firmware_fault_feedpusher ||
          aDIM?.metadata?.details === interruptMessageTypes.guardrails_cow ||
          aDIM?.metadata?.details === interruptMessageTypes.vehicle_presence) &&
          showResumeAndService === index && (
            <div
              className={`${
                interruptCount() > 1 ? '' : 'errorBoxBlock'
              } tractorDetailsAlertBox`}
            >
              <div className="pathPRow minh140">
                <div className="pTextFull color_black">
                  <h2 className="headline2 fSbd txtCenter">
                    {t(
                      translate.AutoDrive.AutoDriveInterrupt.ProceedWithCaution,
                    )}
                  </h2>
                  <div className="minH130">
                    <p className="body2">
                      {t(
                        translate.AutoDrive.AutoDriveInterrupt
                          .ProceedWithCautionDesc,
                      )}{' '}
                      {t(getInterruptTitle(aDIM?.metadata?.details))} camera.
                    </p>
                    <p className="body2">
                      {t(
                        translate.AutoDrive.AutoDriveInterrupt
                          .ProceedWithCautionDesc1,
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="pathPRowBtn">
                <button
                  type="button"
                  className="pOpBtn Button2"
                  onClick={() => setShowResumeAndService(tractorEndPopDefault)}
                >
                  {t(translate.AutoDrive.Actions.Cancel)}
                </button>
                <button
                  type="button"
                  className="pOpBtn ppSelBtn Button2"
                  onClick={() => {
                    setShowResumeAndService(tractorEndPopDefault);
                    continueOperation(aDIM);
                  }}
                >
                  {t(translate.AutoDrive.Actions.Confirm)}
                </button>
              </div>
            </div>
          )}
        {(aDIM?.metadata?.details ===
          interruptMessageTypes.guardrails_implement_collision ||
          aDIM?.metadata?.details ===
            interruptMessageTypes.guardrails_path_presence ||
          aDIM?.metadata?.details === interruptMessageTypes.guardrails_human ||
          aDIM?.metadata?.details ===
            interruptMessageTypes.poor_camera_visibility ||
          aDIM?.metadata?.details ===
            interruptMessageTypes.guardrails_human_front ||
          aDIM?.metadata?.details ===
            interruptMessageTypes.firmware_fault_feedpusher ||
          aDIM?.metadata?.details === interruptMessageTypes.guardrails_cow ||
          aDIM?.metadata?.details === interruptMessageTypes.vehicle_presence) &&
          endPopIndex !== index &&
          showResumeAndService !== index && (
            <div
              className={`${
                interruptCount() > 1 ? '' : 'errorBoxBlock'
              } tractorDetailsAlertBox`}
            >
              <div className="minh140">
                <div className="pathPRow">
                  <span className="errImgBlock">
                    <img src={getInterruptImage(aDIM)} alt="ppIcon" />
                  </span>
                  <div className="pText">
                    <h2 className="headline2 fSbd">
                      {t(getInterruptTitle(aDIM?.metadata?.details))}
                    </h2>
                    <div className="">
                      <p className="body2">{getDescription(aDIM)}</p>
                    </div>
                  </div>
                </div>
                {aDIM?.metadata?.details ===
                  interruptMessageTypes.guardrails_human_front && (
                  <div className="snapshotsSec">Front Camera</div>
                )}
                {/* {aDIM?.metadata?.uids && aDIM?.metadata?.uids.length > 0 && (
                  <GuardRailsImages guardrailId={aDIM?.metadata?.uids[0]} />
                )} */}
                {aDIM?.metadata?.uids && aDIM?.metadata?.uids.length > 0 && (
                  <>
                    <div className="pathPRowCenter dp_flex mb20">
                      <div className="snapshotsSec newSnapshotBlk">
                        {aDIM?.metadata?.uids.map(
                          (uid: string, index: number) => (
                            <>
                              <GuardRailsImages
                                guardrailId={uid}
                                loader={index === 0 ? true : false}
                              />
                            </>
                          ),
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="pathPRowBtn">
                {(aDIM?.metadata?.details ===
                  interruptMessageTypes.guardrails_path_presence ||
                  aDIM?.metadata?.details ===
                    interruptMessageTypes.guardrails_human ||
                  aDIM?.metadata?.details ===
                    interruptMessageTypes.poor_camera_visibility ||
                  aDIM?.metadata?.details ===
                    interruptMessageTypes.guardrails_human_front ||
                  aDIM?.metadata?.details ===
                    interruptMessageTypes.firmware_fault_feedpusher ||
                  aDIM?.metadata?.details ===
                    interruptMessageTypes.guardrails_cow ||
                  aDIM?.metadata?.details ===
                    interruptMessageTypes.vehicle_presence) &&
                aDIM?.metadata?.type === SOFT ? (
                  <>
                    {!aDIM?.showPause &&
                    !loginUserHaveMosCloudInterruptsOrNot(
                      autoDriveInterruptMsgs,
                      selectedTractor?.id,
                      selectedTractor?.heartbeat,
                      userDetails?.id,
                    )
                      ? pauseButton(aDIM)
                      : endOperationButton(aDIM, index)}
                  </>
                ) : (
                  endOperationButton(aDIM, index)
                )}
                {/* don't continue on soft front human guard rail */}
                {aDIM?.metadata?.details ===
                  interruptMessageTypes.guardrails_human_front &&
                aDIM?.metadata?.type === SOFT ? (
                  <></>
                ) : (
                  <button
                    type="button"
                    className="pOpBtn ppSelBtn Button2"
                    disabled={isPathProtectionDisabled(aDIM)}
                    onClick={() => setShowResumeAndService(index)}
                  >
                    <img src={autodrive_play_circle} className="mr6" />
                    {t(translate.AutoDrive.Actions.ResumeAutoDrive)}
                  </button>
                )}
              </div>
            </div>
          )}

        {(aDIM?.metadata?.details === interruptMessageTypes.lost_localization ||
          aDIM?.metadata?.details === interruptMessageTypes.loc) &&
          endPopIndex !== index &&
          showResumeAndService !== index && (
            <div
              className={`${
                interruptCount() > 1 ? '' : 'localizationDown'
              } tractorDetailsAlertBox ${
                aDIM?.metadata?.type === SOFT ? 'tractorDetailsAlertBox1' : ''
              }`}
            >
              <div className="pathPRow">
                <span className="terrImgBlock">
                  <img src={getInterruptImage(aDIM)} alt="ppIcon" />
                </span>
                <div className="pText">
                  <h2 className="headline2 fSbd">
                    {t(getInterruptTitle(aDIM?.metadata?.details))}
                  </h2>
                  <div className="minH70">
                    <p className="body2">{getDescription(aDIM)}</p>
                  </div>
                </div>
              </div>
              <div className="pathPRowBtn df_jc">
                {/* {aDIM?.metadata?.type === HARD && ( */}
                {getPayloadForEndOperation(aDIM) && (
                  <>{endOperationButton(aDIM, index)}</>
                )}
                {/* {aDIM?.metadata?.type === SOFT &&
                  !aDIM?.showPause &&
                  !loginUserHaveMosCloudInterruptsOrNot(
                    autoDriveInterruptMsgs,
                    selectedTractor?.id,
                    selectedTractor?.heartbeat,
                    userDetails?.id,
                  ) &&
                  pauseButton(aDIM)} */}
                {/* don't show if error code have 7003 */}
                {getPayloadForContinueOperation(aDIM, userDetails.id) &&
                  !isPathProtectionDisabled(aDIM) &&
                  aDIM?.metadata?.type === HARD && (
                    <button
                      type="button"
                      className="pOpBtn ppSelBtn Button2"
                      onClick={() => setShowResumeAndService(index)}
                    >
                      <img src={autodrive_play_circle} className="mr6" />
                      {t(translate.AutoDrive.Actions.ResumeAutoDrive)}
                    </button>
                  )}
              </div>
            </div>
          )}

        {(aDIM?.metadata?.details === interruptMessageTypes.firmware_fault ||
          aDIM?.metadata?.details ===
            interruptMessageTypes.tractor_moved_off_path ||
          aDIM?.metadata?.details ===
            interruptMessageTypes.tractor_lost_connection ||
          aDIM?.metadata?.details === interruptMessageTypes.guardrails_slip ||
          aDIM?.metadata?.details ===
            interruptMessageTypes.seatbelt_state_change ||
          aDIM?.metadata?.details ===
            interruptMessageTypes.driver_occupancy_state_change ||
          aDIM?.metadata?.details ===
            interruptMessageTypes.row_follow_tracking_error ||
          aDIM?.metadata?.details ===
            interruptMessageTypes.vinerow_vision_error ||
          aDIM?.metadata?.details === interruptMessageTypes.autonomy_disabled ||
          aDIM?.metadata?.details === interruptMessageTypes.remote_estop) &&
          endPopIndex !== index && (
            <div
              className={`${
                interruptCount() > 1 ? '' : 'errorBoxBlock'
              } tractorDetailsAlertBox
              ${
                // added this for reducing the font size of buttons
                aDIM?.metadata?.details === interruptMessageTypes.firmware_fault
                  ? 'firmware_fault'
                  : ''
              }
              `}
            >
              <div className="pathPRow minh140">
                <span className="errImgBlock">
                  <img src={getInterruptImage(aDIM)} alt="ppIcon" />
                </span>
                <div className="pText">
                  <h2 className="headline2 fSbd">
                    {t(getInterruptTitle(aDIM?.metadata?.details))}
                  </h2>
                  <div className="minH130">{getDescription(aDIM)}</div>
                </div>
              </div>
              <div className="pathPRowBtn">
                {getPayloadForEndOperation(aDIM) && (
                  <>{endOperationButton(aDIM, index)}</>
                )}
                {getPayloadForContinueOperation(aDIM, userDetails.id) && (
                  <button
                    type="button"
                    className="pOpBtn ppSelBtn Button2"
                    onClick={() => continueOperation(aDIM)}
                    disabled={isPathProtectionDisabled(aDIM)}
                  >
                    <img src={autodrive_play_circle} className="mr6" />
                    {aDIM?.metadata?.details ===
                      interruptMessageTypes.seatbelt_state_change ||
                    aDIM?.metadata?.details ===
                      interruptMessageTypes.driver_occupancy_state_change
                      ? t(translate.AutoDrive.Actions.ResumeAutoDrive)
                      : ''}
                    {aDIM?.metadata?.details ===
                    interruptMessageTypes.firmware_fault
                      ? t(translate.AutoDrive.Actions.TryOperating)
                      : ''}
                    {aDIM?.metadata?.details ===
                    interruptMessageTypes.guardrails_slip
                      ? t(translate.AutoDrive.Actions.TryOperating)
                      : ''}
                  </button>
                )}
              </div>
            </div>
          )}
        {aDIM?.metadata?.details ===
          interruptMessageTypes.steer_calibration_error &&
          endPopIndex !== index && (
            <div
              className={`${
                interruptCount() > 1 ? '' : 'pwCaution'
              } tractorDetailsAlertBox`}
            >
              <div className="pathPRow">
                <div className="pText">
                  <h2 className="headline2 fSbd">
                    {t(getInterruptTitle(aDIM?.metadata?.details))}
                  </h2>
                  <div className="minH130 body2 dvHeight">
                    {getDescription(aDIM)}
                  </div>
                </div>
              </div>
              <div className="pathPRowBtn">
                {getPayloadForEndOperation(aDIM) && (
                  <>{endOperationButton(aDIM, index)}</>
                )}
              </div>
            </div>
          )}

        {aDIM?.metadata?.details ===
          interruptMessageTypes.firmware_fault_other &&
          endPopIndex !== index && (
            <div
              // className="errorBoxBlock tractorDetailsAlertBox minHAuto"
              className={`${
                interruptCount() > 1 ? '' : 'errorBoxBlock'
              } tractorDetailsAlertBox minHAuto`}
            >
              <div className="pathPRow">
                <span className="errImgBlock">
                  <img src={health_concern} alt="ppIcon" />
                </span>
                <div className="pText">
                  <h2 className="headline2 fSbd">
                    {t(getInterruptTitle(aDIM?.metadata?.details))}
                  </h2>
                  <div className="minH130 body2 dvHeight">
                    {getDescription(aDIM)}
                  </div>
                </div>
              </div>
              <div className="pathPRow">
                <div className="listItemsSec">
                  <ul>
                    {aDIM?.metadata?.errors.map(
                      (error: number) =>
                        error_message[error] && (
                          <>
                            <li>{t(error_message[error])}</li>
                          </>
                        ),
                    )}
                  </ul>
                  <div className="pText">
                    <h2 className="headline2 fSbd"> </h2>
                    <div className="">
                      <p className="body2">
                        {t(
                          translate.AutoDrive.AutoDriveInterrupt
                            .firmwareFaultOther1,
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pathPRowBtn">
                {getPayloadForEndOperation(aDIM) && (
                  <>{endOperationButton(aDIM, index)}</>
                )}
              </div>
            </div>
          )}
      </div>
    );
  };

  return (
    <>
      {getPriorityInterrupt(autoDriveInterruptMsgs, selectedTractor?.id).map(
        (aDIM: any, index: number) => (
          <>
            {getInterruptTitle(aDIM?.metadata?.details) !== '' ? (
              <>
                {Number(interruptType()) > 1 && endPopIndex !== index ? (
                  <div id="msgContainer">
                    <div className="top_image"></div>
                    <div className="middle_image">
                      <div className="interruptMsgCount">
                        <span>+</span>
                        <span>{interruptCount() - 1}</span>
                      </div>
                      {/* This content is dynamic and the height of the triangle will
                    change with the height of the content. The width is fixed.
                    Try adding some more text and see the height change.
                    */}
                      {interruptCodeElements(aDIM, index)}
                    </div>
                    <div className="bottom_image"></div>
                  </div>
                ) : Number(interruptType()) === 1 ? (
                  <>{interruptCodeElements(aDIM, index)}</>
                ) : interruptType() ===
                    interruptMessageTypes.implement_refill &&
                  endPopIndex !== index ? (
                  <div>
                    <div className="refill_seeder tractorDetailsAlertBox">
                      <div className="minh140">
                        <div className="pathPRow">
                          <span className="errImgBlock">
                            <img
                              src={seeder_refill}
                              alt="ppIcon"
                              width="40"
                              className="refil_img"
                            />
                          </span>
                          <div className="pText">
                            <h2 className="headline2 fSbd">
                              {t(getInterruptTitle(aDIM?.metadata?.details))}
                            </h2>
                            {getDescription(aDIM)}
                            <div className="ErrorSeederFields widget_card">
                              <CustomInput
                                label="Seed Quantity"
                                value={currentCapacity}
                                showLabel="LB"
                                setValue={setCurrentCapacity}
                                type="Number"
                              ></CustomInput>
                              <CustomInput
                                label="Application Rate"
                                value={applicationRate}
                                showLabel="LB / AC"
                                setValue={setApplicationRate}
                                type="Number"
                              ></CustomInput>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="seedPathPRowBtn">
                        {endOperationButton(aDIM, index)}
                        {tractorsList &&
                          tractorsList.length &&
                          selectedTractor &&
                          selectedTractor.id && (
                            <div
                              className="opsSettings"
                              style={{ display: 'none' }}
                            >
                              <DriveOptionSettings
                                id={selectedImplement as string}
                                tractorId={selectedTractor.id}
                                driveActionUUID={
                                  getDriveActionUuid(
                                    tractorsList,
                                    selectedTractor.id,
                                  ) as string
                                }
                                handleDetails={(data) => {
                                  setSavedImplementDetails(data);
                                }}
                                // handleValidate={(state: boolean) => {
                                //   setIsValid(state);
                                // }}
                              />
                            </div>
                          )}
                        <button
                          type="button"
                          className="pOpBtn ppSelBtn Button2"
                          onClick={() => handleSeederResume()}
                        >
                          <img
                            src={play_circle}
                            alt=""
                            className="playcircle"
                          />
                          {t(translate.AutoDrive.Actions.Resume)}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {endPopIndex === index && (
                  <AutoDriveEndPopup
                    endPopIndex={endPopIndex}
                    setEndPopIndex={setEndPopIndex}
                    index={index}
                    endOperation={endOperation}
                    endADIM={endADIM}
                    stopAndDisconnectHandler={stopAndDisconnectHandler}
                    From="INTERRUPT"
                  />
                )}
              </>
            ) : (
              <></>
            )}
          </>
        ),
      )}
    </>
  );
};

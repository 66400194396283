import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Input, Row, Select, Space, notification } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import translate from '../../locale/en_translate.json';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import usePaginate from '../../hooks/usePaginate';
import { ApplicationContext } from '../../context/AppContext';
import { initScroller } from '../../constants/Common';
import { useTranslation } from 'react-i18next';
import CustomMultiSelect from '../common/CustomMultiSelect';
import { getAllTractors, getOrgDropdown } from '../admin/api';
import { mapTractorsData } from '../admin/Common';
import { SearchOutlined } from '@ant-design/icons';

const TractorMigration = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loader, setLoader] = useState<boolean>(false);
  const searchString = useRef<string>('');
  const [selectedOrgId, setSelectedOrgId] = useState<string[]>([]);
  const [organizations, setOrganizations] = useState<string[]>([]);
  const [organizationsList, setOrganizationsList] = useState<any[]>([]);
  const [search, setSearch] = useState<string>('');
  const [total, setTotal] = useState<number>(0);
  const [orgName, setOrgName] = useState<any>();
  const [pageSize] = useState<any>(25);
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();
  const wrapResult = async (
    pageNumber: number,
    pageSize: number,
    search: string,
  ) => {
    const { organization } = userDetails;
    const result = await getAllTractors(
      organization.api_url,
      selectedOrgId,
      pageNumber,
      pageSize,
      search,
    );
    return {
      result,
      searchKey: search,
    };
  };

  const getAllOrganizations = async () => {
    try {
      const { api_url } = userDetails?.organization;
      const result = await getOrgDropdown(api_url);
      const orgList = result.map((data: any) => ({
        title: <span className="ellipsis">{data.name}</span>,
        value: `${data.id}`,
      }));
      const orgNames = result.map((data: any) => data);
      const defaultSelectedIds = result?.map((item: any) => String(item.id));
      setSelectedOrgId(defaultSelectedIds);
      setOrganizations(orgList);
      setOrganizationsList(orgNames);
    } catch (error: any) {
      notification.error({ message: error.message });
    }
  };

  const getTractorsList = async () => {
    try {
      if (checkActivity()) initScroller();
      setLoader(true);
      const { result, searchKey } = await wrapResult(
        pageNumber,
        pageSize,
        search,
      );
      if (searchString.current !== searchKey) return;
      // eslint-disable-next-line no-underscore-dangle
      setTotal(result._metadata.total_records_count);
      const { records } = result;
      const data = mapTractorsData(records);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      //   if (!init.current) init.current = true;
      setLoader(false);
    }
  };

  const loadTractors = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      // eslint-disable-next-line no-unused-expressions
      pageNumber !== 1 ? setPageNumber(1) : getTractorsList();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getTractorsList();
    }
  };

  const handleOrg = (ids: any) => {
    setSelectedOrgId(ids);
  };

  const handleOrgName = (e: any) => {
    // eslint-disable-next-line no-console
    console.log('e', e);
    // setOrgName(version);
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const handleSearch = (e: any) => {
    activity.current = 'search';
    const value = e.target.value.trim();
    if (value.length <= 1) {
      searchString.current = '';
      setSearch('');
    } else {
      searchString.current = value;
      setSearch(value);
    }
  };

  useEffect(() => {
    if (selectedOrgId?.length === 0) {
      setData([]);
      setTotal(0);
    } else if (selectedOrgId.length) {
      loadTractors();
    }
  }, [userDetails, selectedOrgId, pageNumber, pageSize, search]);

  useEffect(() => {
    if (userDetails.organization) getAllOrganizations();
  }, [userDetails]);

  const columnsData = [
    {
      title: `${t(translate.tractors.name)}`,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: `${t(translate.tractors.client_node_name)}`,
      dataIndex: 'clientNodeName',
      key: 'clientNodeName',
    },
    {
      title: `${t(translate.tractors.currentorganization)}`,
      dataIndex: 'customer_organization_name',
      key: 'customer_organization_name',
    },

    {
      title: `${t(translate.tractors.targetOrganization)}`,
      dataIndex: 'model_name',
      key: 'model_name',
      render: () => (
        <Select
          showSearch
          style={{ width: 156, marginRight: 10 }}
          className="orgNameDropDown"
          defaultValue="Select Organization"
          value={orgName}
          onSelect={(e) => handleOrgName(e)}
        >
          {organizationsList.map((data: any, index: number) => {
            return (
              <option value={data.name} key={index}>
                {data?.name}
              </option>
            );
          })}
        </Select>
      ),
    },
    {
      title: `${t(translate.notifications.action)}`,
      dataIndex: 'connection',
      key: 'connection',
      ellipsis: true,
      render: (connection: string) => (
        <>
          {connection === 'offline' ? (
            <Button className="migrateBtn offlineBtn" disabled>
              Offline
            </Button>
          ) : (
            <Button className="migrateBtn">Migrate</Button>
          )}
        </>
      ),
    },

    // {
    //   title: `${t(translate.tractors.lastMigratedOn)}`,
    //   dataIndex: '',
    //   key: '',
    //   width: '180px',
    //   render: () => <>{'-'}</>,
    // },
  ];

  return (
    <Layout className="posRel">
      <Content>
        <div className="tblContainer viewportContainer">
          <Row>
            <div className="common_wrapper wWidget_wrapper">
              <div className="filters_card spaceBtnAlignCenter personnelCard ad_filters_card">
                <div className="displayFlex">
                  <Space size="large">
                    <Input
                      style={{ display: 'none' }}
                      addonBefore={<SearchOutlined />}
                      placeholder="Search Tractor Migrations"
                      className="common_search"
                      onChange={(e) => handleSearch(e)}
                      autoComplete="off"
                    />
                    <CustomMultiSelect
                      handleTreeSelect={handleOrg}
                      selectedId={selectedOrgId}
                      list={organizations}
                      label="Organizations"
                    />
                  </Space>
                </div>
              </div>
              <div className="common_table">
                <InfiniteScrollTable
                  columns={columnsData}
                  loading={loader}
                  hasMore={hasMore}
                  filterData={filterData}
                  totalcount={total}
                  handleLoadMore={handleLoadMore}
                  filename="Tractor Migrations"
                />
              </div>
            </div>
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

export default TractorMigration;

/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-self-assign */
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationContext } from '../../../context/AppContext';
import translate from '../../../locale/en_translate.json';
import { FarmobservationsList } from '../../../constants/Api';
import { Card, Layout, Row, notification } from 'antd';
import { getDateTimes, initScroller } from '../../../constants/Common';
import { Content } from 'antd/lib/layout/layout';
import InfiniteScrollTable from '../InfiniteScrollTable';
import FarmObservationsDetails from './FarmObservationsDetails';

const FarmObservations = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize] = useState<any>(25);
  const [sortedInfo, setSortedInfo] = useState<any>({
    columnKey: 'created_date_time',
    order: 'descend',
  });
  const [filterData, setFilter] = useState<any[]>([]);
  const [search, setSearch] = useState<string>('');
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [totalcount, settotalcount] = useState<any>();
  const activity = useRef<string>('');
  const searchString = useRef<string>('');
  const [observationObj, setObservationObj] = useState<any>();
  const [observationFlag, setObservationFlag] = useState<boolean>(true);

  const checkActivity = () =>
    ['search', 'sort', 'filter', 'update', 'create'].includes(activity.current);

  const columns = [
    {
      title: `${t(translate.fleetticketsHistory.dateTime)}`,
      dataIndex: 'created_date_time',
      key: 'created_date_time',
      width: '15%',
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: `${t(translate.fleetticketsHistory.name)}`,
      dataIndex: 'equipmentName',
      key: 'equipmentName',
      width: '15%',
    },

    {
      title: `${t(translate.fleetticketsHistory.createdbyuser)}`,
      dataIndex: 'created_by_user',
      key: 'created_by_user',
      width: '15%',
    },
    {
      title: `${t(translate.fleetticketsHistory.ticketDescription)}`,
      dataIndex: 'description',
      key: 'description',
      width: '20%',
      ellipsis: true,
      render: (name: string) => {
        return (
          <span className="description" data-testid={`${name}-Tickets`}>
            {name}
          </span>
        );
      },
    },
    {
      title: `${t(translate.fleetticketsHistory.tags)}`,
      dataIndex: 'tags',
      render: (tags: any) => {
        return (
          <div className="tags-container">
            <div className="tags-no-hover">
              {tags?.slice(0, 2).map((data: any) => {
                return (
                  <div
                    className="tag-chip-selected overline2"
                    key={data}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <span data-testid={`${data}-Tickets`}>
                      {data.toLowerCase() === 'driveability'
                        ? 'Drivability'.toUpperCase()
                        : data}
                    </span>
                  </div>
                );
              })}
              {tags?.length > 2 && (
                <div
                  className="tag-chip-selected overline2 moreTags"
                  onClick={(e) => e.stopPropagation()}
                >
                  <span> + {tags.length - 2}</span>
                </div>
              )}
            </div>
            <div className="tags-hover">
              {tags?.map((data: any) => {
                return (
                  <div
                    className="tag-chip-selected overline2"
                    key={`hover-${data}`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {data.toLowerCase() === 'driveability'
                      ? 'Drivability'.toUpperCase()
                      : data}
                  </div>
                );
              })}
            </div>
          </div>
        );
      },
    },
  ];

  const formatTicket = (data: any[]) =>
    data.map((ele: any) => {
      ele.date_time = ele.created_date_time;
      ele.created_date_time = ele.created_date_time
        ? getDateTimes(ele.created_date_time)
        : '';
      ele.created_by_user = ele.created_by
        ? ele.created_by.first_name + ' ' + ele.created_by.last_name
        : '';
      ele.equipmentName = ele.equipment_data
        ? ele.equipment_data.name
        : 'Other';
      ele.description = ele.description;
      return ele;
    });

  const wrapResult = async (
    orgId: number,
    pageNumber: number,
    pageSize: number,
  ) => {
    const response = await FarmobservationsList(orgId, pageNumber, pageSize);
    return { response, searchKey: search };
  };

  const getTickets = async () => {
    if (checkActivity()) initScroller();

    const filter: any = { pageNumber, pageSize };
    try {
      setLoading(true);
      const { searchKey, response: tickethistory } = await wrapResult(
        userDetails.organization_id,
        pageNumber,
        pageSize,
      );
      if (searchString.current !== searchKey) return;
      settotalcount(tickethistory._metadata?.total_records_count);
      let data =
        tickethistory &&
        tickethistory.records &&
        tickethistory.records.length > 0
          ? tickethistory.records
          : [];
      data = formatTicket(data);
      setHasMore(data.length === pageSize);
      if (checkActivity()) setFilter(data);
      else if (activity.current === 'paginate')
        setFilter([...filterData, ...data]);
      else setFilter(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
        duration: 2,
      });
    } finally {
      activity.current = '';
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setFilter([]);
      settotalcount(0);
      setSearch('');
    };
  }, []);

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    if (!checkActivity()) {
      activity.current = 'paginate';
      setPageNumber(pageNumber + 1);
    }
  };

  useEffect(() => {
    loadTickets();
  }, [pageNumber, userDetails]);

  useEffect(() => {
    if (search?.length > 2 || !search?.length) loadTickets();
  }, [search]);

  const loadTickets = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : getTickets();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getTickets();
    }
  };
  const viewObservation = (record: any) => {
    setObservationObj(record);
    setObservationFlag(true);
  };

  const Close = () => {
    setObservationFlag(false);
    activity.current = '';
    if (pageNumber === 1) {
      loadTickets();
    } else {
      setPageNumber(1);
    }
  };

  return (
    <>
      <div className="farmObsTab">
        <div className="common_wrapper common_table">
          <InfiniteScrollTable
            columns={columns}
            hasMore={hasMore}
            loading={loading}
            filterData={filterData}
            totalcount={totalcount}
            handleLoadMore={handleLoadMore}
            filename="Farm Observations"
            onRowClick={(record: any) => viewObservation(record)}
          />
        </div>
      </div>

      {observationObj && observationFlag && (
        <FarmObservationsDetails
          Close={Close}
          ticketObj={observationObj}
          toggleWidget={observationFlag}
        ></FarmObservationsDetails>
      )}
    </>
    // <Layout style={{ height: '80vh' }}>
    //   <div className="mainContent">
    //     <Content>
    //       <div className="tblContainer viewportContainer fbsTbl">
    //         <Row>
    //           <div className="tblDft farmTabsTbl hoverable cPointer">
    //             <InfiniteScrollTable
    //               columns={columns}
    //               hasMore={hasMore}
    //               loading={loading}
    //               filterData={filterData}
    //               totalcount={totalcount}
    //               handleLoadMore={handleLoadMore}
    //               filename="Farm Observations"
    //               onRowClick={(record: any) => viewObservation(record)}
    //             />
    //           </div>
    //         </Row>
    //       </div>
    //     </Content>
    //   </div>
    // </Layout>
  );
};

export default FarmObservations;

import Modal from 'antd/lib/modal';
import React, { useEffect, useState } from 'react';

import mtLogoGreen from './images/mt_logo_green.svg';
import htich_view from '../../assets/images/htich_view.svg';
import hitch_bottom_gray from '../../assets/images/hitch_bottom_gray.svg';
import impImg from './images/imp-img.png';
import { Checkbox, notification } from 'antd';
import CustomSelect from '../common/CustomSelect';
import useAnalyticsStore from './store/operationAnalytics';
import tractorAnalyticDetailsStore from './store/tractorAnalyticDetails';
import { getTractorImplements } from './services/api';
import { HrsMins } from './ui/helper';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';

interface Props {
  onSave: (implement: string) => void;
}

const EditImplement: React.FC<Props> = ({ onSave }) => {
  const { selectedDate } = useAnalyticsStore();
  const { selectedTractor, analyticDetails } = tractorAnalyticDetailsStore();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [operatorId, setOperatorId] = useState('');
  const [implementsList, setImplementsList] = useState([]);
  const [selectedImplement, setSelectedImplement] = useState<any>(null);
  const { operators } = analyticDetails;
  const { t } = useTranslation();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const init = async () => {
      try {
        const { tractorId } = selectedTractor;
        const response = await getTractorImplements(
          selectedDate?.startOf('day')?.toDate()?.getTime(),
          selectedDate?.endOf('day')?.toDate()?.getTime(),
          tractorId,
          operatorId,
        );
        setImplementsList(response);
        setSelectedImplement(null);
      } catch (error: any) {
        notification.error({ message: error?.message });
      }
    };
    if (selectedTractor && selectedDate && isModalOpen) init();
  }, [selectedTractor, operatorId, selectedDate, isModalOpen]);

  const selectImplement = (name: string) => {
    const [implement] = implementsList.filter(
      (implement: any) => implement.name == name,
    );
    setSelectedImplement(implement);
  };

  return (
    <>
      <Modal
        visible={isModalOpen}
        footer={false}
        onOk={handleOk}
        onCancel={handleCancel}
        className="edit-implement popup-common"
      >
        <div className="imp-container">
          <div className="header">
            <div className="icon-title">
              <img src={mtLogoGreen} />
              <span className="edit-implement-2">Edit Implement</span>
            </div>
            <span className="selecting-operation">
              Selecting an implement with a different operation type will move
              this operation to that category and affect any overall statistics.
            </span>
          </div>
          <div className="body">
            <div className="jngs">
              {operators?.map((operator) => {
                return (
                  <div
                    className={`operator-item ${
                      operatorId == operator?.id ? 'active' : ''
                    }`}
                    key={operator?.id}
                    onClick={() => {
                      setOperatorId(operator?.id);
                    }}
                  >
                    <div className="avatar-circle">
                      <span className="op-nm">{operator?.name}</span>
                    </div>
                    <div className="name-duration">
                      <span className="operator-name">
                        {operator?.operator}
                        {/* {fetchName(operator?.first_name, operator?.last_name)} */}
                      </span>
                      <span className="h-m">
                        <HrsMins
                          hours={operator?.operationalHours.hours}
                          minutes={operator?.operationalHours.minutes}
                        />
                      </span>
                    </div>
                  </div>
                );
              })}
              {/* <div className="operator-item">
                <div className="avatar-circle">
                  <span className="op-nm">MS</span>
                </div>
                <div className="name-duration">
                  <span className="operator-name">Mike Smith</span>
                  <span className="h-m">3h 34m</span>
                </div>
              </div> */}
            </div>
            <div className="right-col">
              <div className="implement-selection">
                <div className="drop-down-filter filters_card">
                  {/* <img src={ddImg} width="323" /> */}
                  <CustomSelect
                    testId="statusDropdownField-Employees"
                    label="Implement"
                    cssClass="min_width"
                    className="implementDropdown"
                    value={selectedImplement?.name || undefined}
                    setValue={(id: any) => {
                      // activity.current = 'filter';
                      selectImplement(id);
                    }}
                    options={implementsList.map(({ name }) => ({
                      id: name,
                      name,
                    }))}
                    optionKey="id"
                    optionDisplay="name"
                  />
                </div>
                <div className="implement-info">
                  <div className="imp-ellipse">
                    <img src={selectedImplement?.image || impImg} />
                  </div>
                  <div className="imp-dtl-row">
                    <span className="imp-type">{selectedImplement?.name}</span>
                    <span className="imp-model">
                      {selectedImplement?.model}
                    </span>
                  </div>
                  <div className="imp-dtl-row">
                    <span className="imp-type">
                      {selectedImplement?.implement_type}
                    </span>
                    <span className="imp-model">
                      {selectedImplement?.attachment_type}
                    </span>
                  </div>
                </div>
                {selectedImplement && (
                  <>
                    <h5 className="headline5 viewImpSubHead mt24 mb8">
                      {t(translate.implementView.dimensions)}
                    </h5>
                    <div className="impBox">
                      <div className="impInrBox flexBtnCenter gap28">
                        <div className="gridCenter gap20 w50per textCenter impDimesions">
                          <div className="">
                            <h6 className="body2">
                              {t(translate.implementView.height)}
                            </h6>
                            <p>
                              {selectedImplement?.height && (
                                <>
                                  {selectedImplement?.height}{' '}
                                  <span>
                                    {t(translate.implementView.inches)}
                                  </span>
                                </>
                              )}
                            </p>
                          </div>
                          <img src={htich_view} alt="" />
                        </div>
                        <div className="gridCenter gap20 w50per textCenter impDimesions">
                          <div className="">
                            <h6 className="body2">
                              {t(translate.implementView.length)}
                            </h6>
                            <p>
                              {selectedImplement?.length && (
                                <>
                                  {selectedImplement?.length}{' '}
                                  <span>
                                    {t(translate.implementView.inches)}
                                  </span>
                                </>
                              )}
                            </p>
                          </div>
                          <img src={hitch_bottom_gray} alt="" />
                        </div>
                      </div>
                      <div className="impInrBox flexBtnCenter gap28">
                        <div className="gridCenter gap20 w50per impDimesions">
                          <h6 className="body2 textCenter">
                            {t(translate.implementView.width)}
                          </h6>
                          <p>
                            {selectedImplement?.width && (
                              <>
                                {selectedImplement?.width}{' '}
                                <span>{t(translate.implementView.inches)}</span>
                              </>
                            )}
                          </p>
                        </div>
                        <div className="gridCenter gap20 w50per impDimesions">
                          <h6 className="body2 textCenter">
                            {t(translate.implementView.weight)}
                          </h6>
                          <p>
                            {selectedImplement?.weight && (
                              <>
                                {selectedImplement?.weight}{' '}
                                <span>{t(translate.implementView.lbs)}</span>
                              </>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="checkbox">
                  <Checkbox className="checkbox-lbl">
                    Apply changes to all drive sessions
                  </Checkbox>
                </div>
              </div>
              <button
                className="imp-button-sec"
                disabled={!selectedImplement?.name}
                onClick={() => onSave(selectedImplement?.name)}
              >
                <span className="btn-save">Save Changes</span>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditImplement;

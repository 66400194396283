/* eslint-disable no-console */
/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import './style.css';
import failedInfo from '../../assets/images/fialedInfo.svg';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import Layout, { Content } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { Progress } from 'antd';
import ScheduleFailedRemarks from './ScheduleFailedRemarks';

interface Props {
  loader: boolean;
  filterData: any;
  total: number;
  hasMore: any;
  handleLoadMore: () => void;
  tractorIds: any[];
  setTractorIds: (data: any) => void;
  setTabid: any;
  setEquipId: any;
  setTicketId: any;
}

export const UpdatesList: React.FC<Props> = ({
  loader,
  filterData,
  total,
  hasMore,
  handleLoadMore,
  tractorIds,
  setTractorIds,
  setTabid,
  setEquipId,
  setTicketId,
}) => {
  const { t } = useTranslation();
  const [hover, setHover] = useState<boolean>(false);
  const [hoverList, setHoverList] = useState<any>();

  const handleCheckBox = (id: number) => {
    const updatedSelectedIds = [...tractorIds];

    if (updatedSelectedIds.includes(id)) {
      updatedSelectedIds.splice(updatedSelectedIds.indexOf(id), 1);
    } else {
      updatedSelectedIds.push(id);
    }

    setTractorIds(updatedSelectedIds);
  };

  const onHover = (e: any, record: any) => {
    e.preventDefault();
    setHover(true);
    setHoverList(record);
  };

  const statusColors = (status: string, record: any) => {
    switch (status) {
      case 'pending':
        return (
          <>
            <div className="posRel">
              <div className="percentTxt">{record?.otaPercentage}%</div>
              <div className="progressStyle">
                <Progress percent={record?.otaPercentage} showInfo={false} />
              </div>
            </div>
          </>
        );

      case 'inprogress':
        return (
          <>
            <div className="posRel">
              <div className="percentTxt">{record?.otaPercentage}%</div>
              <div className="progressStyle">
                <Progress percent={record?.otaPercentage} showInfo={false} />
              </div>
            </div>
          </>
        );

      case 'up_to_date':
        return (
          <>
            <div className="progressBox upToDateBorder">
              <div className="updateDot upToDateBg" />
              <div className="progressTxt upToDateClr">Up to Date</div>
            </div>
          </>
        );

      case 'out_of_date':
        return (
          <>
            <div className="progressBox outOfDateBorder">
              <div className="updateDot outOfDateBg" />
              <div className="progressTxt outOfDateClr">Out of Date</div>
            </div>
          </>
        );

      case 'failed':
        return (
          <>
            <div className="progressBox failedBorder">
              <div className="updateDot failedBg" />
              <div className="progressTxt failedClr">Update Failed</div>
              <div className="infoImgHover">
                <img
                  src={failedInfo}
                  alt="info"
                  className="ml5 infoIconNew"
                  onMouseEnter={(e) => onHover(e, record)}
                />
                {hover && (
                  <ScheduleFailedRemarks
                    hoverList={hoverList}
                    setTabid={setTabid}
                    setEquipId={setEquipId}
                    tickettId={record?.ticket_id}
                    setTicketId={setTicketId}
                  />
                )}
              </div>
            </div>
          </>
        );

      case 'scheduled':
        return (
          <>
            <div className="progressBox scheduleBorder">
              <div className="updateDot scheduleBg" />
              <div className="progressTxt scheduleClr">Scheduled</div>
            </div>
          </>
        );

      case 'schedule_failed':
        return (
          <>
            <div className="progressBox failedBorder">
              <div className="updateDot failedBg" />
              <div className="progressTxt failedClr">Missing Req</div>
              <div className="infoImgHover">
                <img
                  src={failedInfo}
                  alt="info"
                  className="ml5 infoIconNew"
                  onMouseEnter={(e) => onHover(e, record)}
                />
                {hover && (
                  <ScheduleFailedRemarks
                    hoverList={hoverList}
                    setTabid={setTabid}
                    setEquipId={setEquipId}
                  />
                )}
              </div>
            </div>
          </>
        );

      default:
        return <>-</>;
    }
  };

  const otaStatusBase = (record: any) =>
    record?.ota_status !== 'pending' &&
    record?.ota_status !== 'inprogress' &&
    record?.ota_status !== 'up_to_date';

  const columns = [
    {
      title: `${t(translate.label.tractor)}`,
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      render: (name: string, record: any) => {
        return (
          <div className="updateWrapper">
            {otaStatusBase(record) && (
              <Checkbox
                className={
                  tractorIds.includes(record.id) ? 'hoverShow1' : 'hoverShow'
                }
                checked={tractorIds.includes(record.id)}
                key={record.id}
                onChange={() => handleCheckBox(record.id)}
              />
            )}

            <span className="updateName">{name}</span>
          </div>
        );
      },
      onCell: (record: any) => ({
        onClick: (e: any) => {
          e.preventDefault();
          otaStatusBase(record) && handleCheckBox(record.id);
        },
      }),
    },
    {
      title: `${t(translate.heartbeat.status)}`,
      dataIndex: 'ota_status',
      key: 'ota_status',
      width: '20%',
      defaultSortOrder: 'ascend',
      sorter: (a: any, b: any) => {
        const order = [
          'failed',
          'schedule_failed',
          'pending',
          'in_progress',
          'out_of_date',
          'scheduled',
          'up_to_date',
        ];
        return order.indexOf(a.ota_status) - order.indexOf(b.ota_status);
      },
      render: (status: string, record: any) => statusColors(status, record),
      onCell: (record: any) => ({
        onClick: () => otaStatusBase(record) && handleCheckBox(record.id),
      }),
    },
    {
      title: `${t(translate.headers.softwareVersion)}`,
      dataIndex: 'softwareVersion',
      key: 'softwareVersion',
      width: '20%',
      onCell: (record: any) => ({
        onClick: () => otaStatusBase(record) && handleCheckBox(record.id),
      }),
    },
    {
      title: `${t(translate.headers.firmwareVersion)}`,
      dataIndex: 'firmWareVersion',
      key: 'firmWareVersion',
      width: '20%',
      onCell: (record: any) => ({
        onClick: () => otaStatusBase(record) && handleCheckBox(record.id),
      }),
    },
    {
      title: `${t(translate.bsOtaLogs.schedule)}`,
      dataIndex: 'schDate',
      key: 'schDate',
      width: '20%',
      render: (schDate: any, record: any) =>
        record.ota_status === 'scheduled' ? <>{schDate}</> : '-',
      onCell: (record: any) => ({
        onClick: () => otaStatusBase(record) && handleCheckBox(record.id),
      }),
    },
  ];

  return (
    <Layout className="yourFleetTbl">
      <div className="mainContent">
        <Content>
          <div className="tblContainer viewportContainer">
            <div className="common_wrapper upWidget_wrapper">
              <div className="common_table updateFail">
                <InfiniteScrollTable
                  columns={columns}
                  hasMore={hasMore}
                  loading={loader}
                  filterData={filterData}
                  totalcount={total}
                  handleLoadMore={handleLoadMore}
                  filename="Updates"
                  isFromUpdates
                  rowIds={tractorIds}
                />
              </div>
            </div>
          </div>
        </Content>
      </div>
    </Layout>
  );
};

/* eslint-disable react/display-name */
/* eslint-disable no-console */

import React, { useContext, useState, useEffect, useRef } from 'react';
import { Row, Space, notification } from 'antd';
import Layout from 'antd/lib/layout/layout';
import { getTractorsListData } from '../../../constants/Api';
import { ApplicationContext } from '../../../context/AppContext';
import { fleetObject, TractorsList } from '../../../constants/types';
import {
  getDateStamp,
  getTractorAvailableState,
  tractorSort,
} from '../../../constants/Common';
import { useTranslation } from 'react-i18next';
import translate from '../../../locale/en_translate.json';
import 'react-datepicker/dist/react-datepicker.css';
import InfiniteScrollTable from '../../common/InfiniteScrollTable';
import { PubSub } from 'aws-amplify';
import { Button, Col } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { MOS_EVENTS_TOPIC } from '../../../constants/constant';
import CustomSelect2 from '../../common/CustomSelect2';
import CustomSelect from '../../common/CustomSelect';

interface TractorResponseType {
  label: string;
  value: number | string | boolean;
  disabled: boolean;
  index: number | string;
  isAvailable?: string;
  id: number | string;
  tractor_id?: number;
}

const COUNT = 200;

const HeartBeat: React.FC = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [tractors, setTractorsData] = useState<TractorResponseType[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loader, setLoader] = useState<boolean>(false);
  const [filterData, setFilter] = useState<fleetObject[]>([]);
  const [isRecordClicked, setIsRecordClicked] = useState(false);
  const [jsonDataViewer, setJsonDataViewer] = useState<any>({});
  const [tractorId, setTractorId] = useState<string>();
  const [onClearObj, setOnClearObj] = useState<boolean>(false);
  const [heartBeatTable, setHeartBeatTable] = useState<any>([]);
  const heartBeatData = useRef<any[]>([]);
  const mosData = useRef<any[]>([]);
  const [dataFilter, setDataFilter] = useState<string>('heartbeat');
  const [mosFilterData, setMosFilterData] = useState<any[]>([]);
  const { APPReducer } = useContext(ApplicationContext);
  const [state] = APPReducer;
  const { tractorsMap } = state;

  useEffect(() => {
    setJsonDataViewer({});
    if (userDetails && userDetails.organization) {
      getTractorsList();
    }
  }, [userDetails]);

  useEffect(() => {
    const tdata =
      dataFilter === 'mos_events' ? mosData.current : heartBeatData.current;
    if (tractorId) {
      const fresult: any[] = tdata.filter(
        (item: any) => item.tractor_id === tractorId,
      );
      setTotalCount(fresult.length);
      dataFilter === 'mos_events'
        ? setMosFilterData(fresult)
        : setHeartBeatTable(fresult);
    } else {
      dataFilter === 'mos_events'
        ? setMosFilterData(tdata)
        : setHeartBeatTable(tdata);
    }
  }, [filterData, tractorId]);

  useEffect(() => {
    if (userDetails && !userDetails?.organization) return;
    let sub: any;
    try {
      sub = PubSub.subscribe(
        `HeartBeat/org/${userDetails.organization_id}`,
      ).subscribe({
        next: (data: any) => {
          heartBeatHandler(JSON.stringify(data.value));
        },
        // eslint-disable-next-line no-console
        error: (error: any) => console.error(error),
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    return () => {
      sub.unsubscribe();
      heartBeatData.current = [];
      setFilter([]);
      setHeartBeatTable([]);
    };
  }, [userDetails]);

  const updateMosData = () => {
    setMosFilterData(mosData.current);
  };

  useEffect(() => {
    if (userDetails && !userDetails?.organization) return;
    if (tractorsMap && !tractorsMap?.size) return;
    let sub: any;
    try {
      sub = PubSub.subscribe(
        `${MOS_EVENTS_TOPIC}${userDetails.organization_id}`,
      ).subscribe({
        next: (data: any) => {
          let tdata: any = JSON.stringify(data.value);
          tdata = JSON.parse(tdata);
          const tractor =
            tdata?.tractor_id && tractorsMap.get(tdata?.tractor_id);
          tdata['name'] = tractor.name || '';
          mosData.current = [...mosData.current, tdata];
          updateMosData();
        },
        // eslint-disable-next-line no-console
        error: (error: any) => console.error(error),
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    return () => {
      sub.unsubscribe();
    };
  }, [userDetails, tractorsMap]);

  const heartBeatHandler = (data: any) => {
    const d = JSON.parse(data);
    const obj: any = {
      ...d,
      label: d?.tractor_name,
      disabled: false,
      id: Math.random(),
      tractor_id: d?.tractor_id,
      manager: d?.planning_manager ? d?.planning_manager?.planner_name : '',
      status:
        d?.planning_manager?.planner_status === 'running'
          ? 'Online'
          : 'Offline',
      isOnline: d.connection === 'online' ? true : false,
      isAvailable: getTractorAvailableState(d.connection, d),
      timestamp: d?.date_time,
      datetime: getDateStamp(d?.date_time),
    };
    let arr = [...heartBeatData.current, obj];
    if (arr.length > COUNT) arr = arr.slice(-COUNT);
    heartBeatData.current = arr;
    setFilter([...arr]);
  };

  const getTractorsList = async () => {
    try {
      setLoader(true);
      const tractor: { records: TractorsList[] } = await getTractorsListData(
        userDetails.organization.api_url,
        userDetails.organization_id,
        pageNumber,
      );
      const data =
        tractor && tractor.records && tractor.records.length > 0
          ? tractor.records
          : [];
      const tractorData: any[] = [];

      data.map((d: any) => {
        const obj = {
          ...d.heartbeat,
          id: Math.random(),
          label: d?.name,
          disabled: false,
          tractor_id: d?.heartbeat?.tractor_id,
          manager: d?.heartbeat?.planning_manager
            ? d?.heartbeat?.planning_manager?.planner_name
            : '',
          status:
            d?.heartbeat?.planning_manager?.planner_status === 'running'
              ? 'Online'
              : 'Offline',
          isOnline: d.connection === 'online' ? true : false,
          isAvailable: getTractorAvailableState(d.connection, d?.heartbeat),
          timestamp: d?.date_time,
          datetime: getDateStamp(d?.date_time),
        };
        tractorData.push(obj);
        setTotalCount(tractorData?.length);
      });

      const arr = tractorSort(tractorData, 'label');
      setTractorsData(arr);
      if (data.length > 0) {
        if (pageNumber === 1) {
          heartBeatData.current = tractorData;
          setFilter(tractorData);
        } else {
          heartBeatData.current = [...filterData, ...tractorData];
          setFilter([...filterData, ...tractorData]);
        }
      } else {
        heartBeatData.current = tractorData;
        setFilter(tractorData);
      }
      if (tractorData.length < 25) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      setLoader(false);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const columns = [
    {
      title: `${t(translate.heartbeat.id)}`,
      dataIndex: 'tractor_id',
      key: 'tractor_id',
      width: '10%',
    },
    {
      title: `${t(translate.heartbeat.tractor_name)}`,
      dataIndex: 'label',
      key: 'label',
      width: '12%',
    },

    // {
    //   title: `${t(translate.heartbeat.planning_manager)}`,
    //   dataIndex: 'manager',
    //   key: 'manager',
    //   width: '15%',
    // },
    {
      title: `${t(translate.heartbeat.datetime)}`,
      dataIndex: 'datetime',
      key: 'datetime',
      width: '15%',
    },
    {
      title: `${t(translate.heartbeat.status)}`,
      dataIndex: 'isAvailable',
      key: 'isAvailable',
      width: '14%',
    },
  ];

  const mos_columns = [
    {
      title: `${t(translate.heartbeat.id)}`,
      dataIndex: 'tractor_id',
      key: 'tractor_id',
      width: '10%',
    },
    {
      title: `${t(translate.heartbeat.tractor_name)}`,
      dataIndex: 'name',
      key: 'name',
      // width: '12%',
    },
    {
      title: `${t(translate.heartbeat.category)}`,
      dataIndex: 'category',
      key: 'category',
      // width: '12%',
    },
    {
      title: `${t(translate.heartbeat.datetime)}`,
      dataIndex: 'datetime',
      key: 'datetime',
      width: '15%',
    },
    {
      title: `${t(translate.heartbeat.message)}`,
      dataIndex: 'message',
      key: 'message',
      // width: '15%',
    },
  ];

  const handleSelect = async (selected: any) => {
    setTractorId(selected);
    // fleetHistory.length = 0;
    filterData.length = 0;
    setPageNumber(1);
  };

  const handleLoadMore = () => {
    // setPageNumber(pageNumber + 1);
  };
  const showModal = (record: any) => {
    setIsRecordClicked(true);
    setJsonDataViewer(record);
  };

  const onClear = () => {
    setOnClearObj(true);
    setFilter([]);
    setJsonDataViewer({});
    heartBeatData.current = [];
    mosData.current = [];
    setHeartBeatTable([]);
    setMosFilterData([]);
  };
  useEffect(() => {
    if (onClearObj === true) {
      return () => {
        heartBeatData.current = [];
        setHeartBeatTable([]);
        setFilter([]);
      };
    }
  }, [onClearObj]);

  function CopyToClipboard(containerid: string) {
    const doc: any = document;
    const str = doc.getElementById(containerid).innerHTML;
    const el = doc.createElement('textarea');
    el.value = str;
    doc.body.appendChild(el);
    el.select();
    doc.execCommand('copy');
    doc.body.removeChild(el);
    // alert('Copied the text:' + el.value);
  }

  const getColumns = () =>
    dataFilter === 'mos_events' ? mos_columns : columns;
  const getData = () =>
    dataFilter === 'mos_events' ? mosFilterData : heartBeatTable;

  const tractor =
    jsonDataViewer?.tractor_id && tractorsMap.get(jsonDataViewer?.tractor_id);

  return (
    <Layout>
      <div className="mainContent">
        <div className="tblContainer viewportContainer">
          <Row>
            <div className="common_wrapper wWidget_wrapper">
              <div className="filters_card personnelCard">
                {/* <CSelect
                    list={tractors.map((item: any) =>
                      Object.assign({
                        id: item?.tractor_id,
                        name: item.label,
                        isAvailable: item.isAvailable,
                      }),
                    )}
                    selectTrigger={(selected) => {
                      setJsonDataViewer({});
                      handleSelect(selected ? selected?.id : 0);
                    }}
                    placeholder="All Tractors"
                    label="Tractor"
                    isAll={true}
                    tractorStatus={true}
                    className="dropdownStyle slkttractor tractorField"
                  /> */}
                <CustomSelect2
                  label="Tractor"
                  cssClass="min_width"
                  value={tractorId}
                  setValue={(id: any) => {
                    handleSelect(id);
                  }}
                  options={tractors.map((item: any) =>
                    Object.assign({
                      id: item?.tractor_id,
                      name: item.label,
                      isAvailable: item.isAvailable,
                    }),
                  )}
                />
                <CustomSelect
                  label="Data Filter"
                  cssClass="min_width"
                  value={dataFilter}
                  setValue={(value: any) => {
                    setJsonDataViewer({});
                    setDataFilter(value);
                  }}
                  options={[
                    { id: 'heartbeat', name: 'Heartbeat' },
                    { id: 'mos_events', name: 'Mos Events' },
                  ]}
                />
                {/* <div>
                  <Select
                    className="dropdownStyle customField"
                    onSelect={(value) => {
                      setJsonDataViewer({});
                      setDataFilter(value);
                    }}
                    value={dataFilter}
                  >
                    <Option value="heartbeat">Heartbeat</Option>
                    <Option value="mos_events">Mos Events</Option>
                    <Option value="preview_path">Preview Path</Option>
                  </Select>
                </div> */}
                <div>
                  <Button
                    className="changeBtnCancel"
                    type="default"
                    onClick={onClear}
                  >
                    Clear
                  </Button>
                </div>
              </div>

              <div className="common_table">
                <Row gutter={16}>
                  <Col span={12}>
                    <InfiniteScrollTable
                      // xScroll={1800}
                      columns={getColumns()}
                      loading={loader}
                      hasMore={hasMore}
                      filterData={getData()}
                      totalcount={getData()?.length || 0}
                      onRowClick={(record: any) => showModal(record)}
                      handleLoadMore={handleLoadMore}
                      filename="HeartBeat"
                    />
                  </Col>
                  <Col span={12}>
                    <div className="blockCodeContainer">
                      <div className="bc-header">
                        {tractor ? (
                          <Space>
                            Tractor : <b>{tractor?.name}</b> | Timestamp :{' '}
                            <b>
                              {getDateStamp(jsonDataViewer?.timestamp) ||
                                getDateStamp(jsonDataViewer?.date_time)}
                            </b>
                          </Space>
                        ) : (
                          <></>
                        )}
                      </div>
                      {isRecordClicked && (
                        <>
                          <div className="copy-block">
                            <CopyOutlined
                              onClick={() => CopyToClipboard('code-block')}
                            />
                          </div>
                          <pre className="preStyle" id="code-block">
                            {JSON.stringify(jsonDataViewer, null, 2)}
                          </pre>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Row>
        </div>
      </div>
    </Layout>
  );
};

export default HeartBeat;

/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import $ from 'jquery';
import { Button, DatePicker, Space } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import cal_img from '../images/date_picker.svg';

import './styles.scss';
import { defaultDates } from '../common';

const { RangePicker } = DatePicker;

interface Props {
  handleOnChange: (dates: any) => void;
  selectedDates: any[];
}

const DateRangePicker: React.FC<Props> = ({
  handleOnChange,
  selectedDates,
}) => {
  const [toggle, setToggle] = useState(false);
  const wrapperRef: any = useRef(null);
  const [dates, setDates] = useState<any>([]);

  useEffect(() => {
    if (toggle) setDates(selectedDates);
  }, [selectedDates, toggle]);

  const handleDates = (dates: any) => {
    if (dates) {
      const fDates = [dates[0]?.startOf('day'), dates[1]?.endOf('day')];
      // handleOnChange(dates);
      setDates(fDates);
    } else {
      // handleOnChange([]);
      setDates([null, null]);
    }
  };

  const onChange: RangePickerProps['onChange'] = handleDates;

  const togglePicker = () => {
    setToggle(!toggle);
  };

  function disabledDate(current: any) {
    // if (includeWeekends) return !current.isBefore(moment());
    // Can not select sundays and predfined days
    // return (
    //   [0, 6].includes(moment(current).day()) || !current.isBefore(moment())
    // );
    return !current.isBefore(moment());
  }

  const handleClick = (dates: any) => {
    handleDates(dates);
    handleOnChange(dates);
    togglePicker();
  };

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event: any) => {
      if (toggle && !$(event.target).parents('.ant-picker-dropdown')?.length) {
        if (!$(event.target).parents('.date-picker').length) setToggle(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [toggle]);

  return (
    <div className={toggle ? 'dhover date-picker' : 'date-picker'}>
      <div ref={wrapperRef} id="dateRangePicker">
        <RangePicker
          value={dates}
          className="hideDatePicker"
          dropdownClassName="singleMonth"
          disabledDate={disabledDate}
          open={toggle}
          // ranges={{
          //   Today: [moment(), moment()],
          //   'Last 7 Days': [moment().subtract('7', 'days'), moment()],
          //   'Last 2 weeks': [moment().subtract('14', 'days'), moment()],
          //   'Last 3 weeks': [moment().subtract('21', 'days'), moment()],
          //   'Last 30 days': [moment().subtract('1', 'month'), moment()],
          // }}
          renderExtraFooter={() => (
            <>
              <Space>
                <Button
                  className="datepickerBtn"
                  onClick={() => {
                    handleClick([moment(), moment()]);
                  }}
                >
                  Today
                </Button>
                <Button
                  className="datepickerBtn"
                  onClick={() => {
                    handleClick([moment().subtract('7', 'days'), moment()]);
                  }}
                >
                  Last 7 Days
                </Button>
                <Button
                  className="datepickerBtn"
                  onClick={() => {
                    handleClick([moment().subtract('14', 'days'), moment()]);
                  }}
                >
                  Last 2 weeks
                </Button>
              </Space>
              <Space>
                <Button
                  className="datepickerBtn"
                  onClick={() => {
                    handleClick([moment().subtract('21', 'days'), moment()]);
                  }}
                >
                  Last 3 weeks
                </Button>
                <Button
                  className="datepickerBtn"
                  onClick={() => {
                    handleClick([moment().subtract('1', 'month'), moment()]);
                  }}
                >
                  Last 30 days
                </Button>
              </Space>
            </>
          )}
          onChange={onChange}
          onOpenChange={(open) => {
            handleOnChange(
              Array.isArray(dates) && dates[0] ? dates : selectedDates,
            );
          }}
          onPanelChange={(value, mode) => {
            setDates([null, null]);
          }}
        />
        {/* <img
          className="calendarIcon dpIcon"
          onClick={togglePicker}
          // src={cal_img}
          alt="calendar"
        /> */}
        <div
          className={toggle ? 'dpIconhover' : 'dpIcon'}
          onClick={togglePicker}
        ></div>
      </div>
    </div>
  );
};

export default DateRangePicker;

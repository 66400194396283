/* eslint-disable no-console */
import { Input, Modal, notification, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { updatePolyLine } from '../../constants/Api';
import { addingAltitude } from '../../constants/Common';
import { LatLngVertices, PolyLinePayload } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';

interface Props {
  showModal: boolean;
  handleCancel: (reload?: boolean, brownPoly?: any) => void;
  polyLinePayload: PolyLinePayload;
}
export const PolyLine: React.FC<Props> = ({
  showModal,
  handleCancel,
  polyLinePayload,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const [name, setName] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [latLongVertices, setLatLongVertices] = useState<LatLngVertices[]>([]);

  useEffect(() => {
    const onInIt = async () => {
      if (
        polyLinePayload &&
        polyLinePayload.lat_long_vertices &&
        polyLinePayload.lat_long_vertices.length > 0
      ) {
        const lat_long_vertices = await addingAltitude(
          polyLinePayload.lat_long_vertices,
        );
        setLatLongVertices(lat_long_vertices);
      }
      polyLinePayload && polyLinePayload.name && setName(polyLinePayload.name);
    };
    onInIt();
  }, [polyLinePayload]);
  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      name &&
      name.trim().length > 0
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [userDetails, name]);

  const updatePolyLineHandler = async () => {
    try {
      setLoader(true);
      const payload = {
        name: name.trim(),
        lat_long_vertices: latLongVertices,
      };
      setName(name.trim());

      const { organization } = userDetails;
      const resp = await updatePolyLine(
        organization.api_url,
        organization.farm.id,
        payload,
        polyLinePayload.polyline_uuid,
      );
      if (resp && resp.msg) {
        handleCancel(true);
        notification.success({
          message: resp.msg,
        });
      }
    } catch (err: any) {
      notification.error({
        message: err?.message,
      });
    } finally {
      setLoader(false);
    }
  };
  return (
    <Modal
      className="commonPopup addPolygon"
      title={`Update Poly Line`}
      centered
      closable={false}
      width={'60vw'}
      visible={showModal}
      onCancel={() => handleCancel()}
      okText="Save"
      onOk={updatePolyLineHandler}
      okButtonProps={{
        className: 'btnSave ',
        disabled: isDisabled,
      }}
      cancelButtonProps={{
        className: 'btnCancel',
      }}
    >
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel">
            Name <span style={{ color: 'red' }}> *</span>
          </label>
          <Input
            className="commInput"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <div className="edit-loader">
            {loader && (
              <div className="loader">
                <Spin size="large" />
              </div>
            )}
          </div>
        </div>
        <div className="formCol">
          <label className="formLabel"> </label>
        </div>
      </div>
      <div className="formRow">
        <div className="formColFull">
          <label className="formLabel">
            Vertices <span style={{ color: 'red' }}> *</span>
          </label>
          <div className="verticsSec">
            {latLongVertices.map((data: any) => {
              return (
                <>
                  <div>
                    ({data?.latitude}, {data?.longitude}, {data?.altitude})
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default PolyLine;

/* eslint-disable no-console */
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  DatePicker,
  Input,
  Row,
  Tag,
  message,
  notification,
} from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import translate from '../../../locale/en_translate.json';
import InfiniteScrollTable from '../../common/InfiniteScrollTable';
import usePaginate from '../../../hooks/usePaginate';
import { ApplicationContext } from '../../../context/AppContext';
import { initScroller } from '../../../constants/Common';
import { getMarkTicketsHistory, getMarkTicketsSummary } from '../api';
import { useTranslation } from 'react-i18next';
import { mapMarkTickets, markFilters } from '../Common';
import './style.css';
import CustomSelect from '../../common/CustomSelect';
import totalResolved from '../../../assets/images/totalresolved.svg';
import total_unresolved from '../../../assets/images/total_unresolved.svg';
import assignedToMe from '../../../assets/images/assignedtome.svg';
import mtSupport from '../../../assets/images/mtsupport.svg';
import FlagGrayIcon from '../../../assets/images/flag_gray.svg';
import FlagOrangeIcon from '../../../assets/images/flag_orange.svg';
import FlagRedIcon from '../../../assets/images/flag_red.svg';
import { SearchOutlined } from '@ant-design/icons';
import CustomSeverityLevel from '../../common/CustomSeverityLevel';
import moment from 'moment';
import CropOrgFilter from '../../common/CropOrgFilter';
import { useLocation } from 'react-router-dom';

const { RangePicker } = DatePicker;

interface Props {
  defaultOrgs?: any[];
}

const Tickets: React.FC<Props> = ({ defaultOrgs }) => {
  const { t } = useTranslation();
  const { state }: any = useLocation();
  const { userDetails } = useContext(ApplicationContext);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const searchString = useRef<string>('');
  const [selectedOrgId, setSelectedOrgId] = useState<string[]>([]);
  const [search, setSearch] = useState<string>('');
  const [total, setTotal] = useState<number>(0);
  const [pageSize] = useState<any>(25);
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState<any>('');
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();
  const [sortedInfo, setSortedInfo] = useState<any>();
  const [severityLevel, setSeverityLevel] = useState<string>('');
  const [filter, setFilter] = useState<string>('Daily');
  const [filterObj, setFilterObj] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    from_date: state?.date
      ? moment(state.date).startOf('day').format('YYYY-MM-DD')
      : moment().subtract(7, 'days').format('YYYY-MM-DD'),
    to_date: state?.date
      ? moment(state.date).endOf('day').format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD'),
    type: 'Daily',
  });
  const init = useRef<boolean>(true);

  const wrapResult = async (
    apiUrl: string,
    fleetId: number,
    orgId: any,
    pageNumber: number,
    pageSize: number,
    filter: any,
    fromDateTime: number | string,
    toDateTime: number | string,
    search: string,
    sortedInfo: any,
  ) => {
    const response = await getMarkTicketsHistory(
      apiUrl,
      fleetId,
      orgId,
      pageNumber,
      pageSize,
      filter,
      fromDateTime,
      toDateTime,
      search,
      sortedInfo,
    );
    return { response, searchKey: search };
  };

  const getTractorsList = async () => {
    if (!userDetails?.organization || selectedOrgId.length === 0) return;
    try {
      if (checkActivity()) initScroller();
      setLoading(true);
      const fromDate =
        filterObj.type === 'Yearly'
          ? `${filterObj.year}-01-01`
          : filterObj.from_date;
      const toDate =
        filterObj.type === 'Yearly'
          ? moment(new Date()).format(`YYYY-MM-DD`)
          : filterObj.to_date;

      const fromDateTime = moment(fromDate).startOf('day').toDate().getTime();
      const toDateTime = moment(toDate).endOf('day').toDate().getTime();
      const { searchKey, response: result } = await wrapResult(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
        selectedOrgId,
        pageNumber,
        pageSize,
        severityLevel,
        fromDateTime,
        toDateTime,
        search,
        sortedInfo,
      );

      if (searchString.current !== searchKey) return;

      // eslint-disable-next-line no-underscore-dangle
      setTotal(result?._metadata?.total_records_count);

      const { records } = result;
      const data = Array.isArray(records) ? records : [];
      const updatedData = mapMarkTickets(data);

      setData(updatedData);
    } catch (error: any) {
      notification.error({
        message: error.message || error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const loadTickets = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      // eslint-disable-next-line no-unused-expressions
      pageNumber !== 1 ? setPageNumber(1) : getTractorsList();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getTractorsList();
    }
  };

  const handleOrg = (ids: any) => {
    activity.current = 'filter';
    setSelectedOrgId(ids);
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const handleSearch = (e: any) => {
    activity.current = 'search';
    const value = e.target.value.trim();
    if (value.length <= 1) {
      searchString.current = '';
      setSearch('');
    } else {
      searchString.current = value;
      setSearch(value);
    }
  };

  useEffect(() => {
    if (selectedOrgId.length === 0) {
      setData([]);
      setTotal(0);
    } else {
      loadTickets();
    }
  }, [sortedInfo, pageNumber]);

  const getTicketsSummary = async () => {
    try {
      const { organization } = userDetails;
      const fromDate =
        filterObj.type === 'Yearly'
          ? `${filterObj.year}-01-01`
          : filterObj.from_date;
      const toDate =
        filterObj.type === 'Yearly'
          ? moment(new Date()).format(`YYYY-MM-DD`)
          : filterObj.to_date;
      const fromDateTime = moment(fromDate).startOf('day').toDate().getTime();
      const toDateTime = moment(toDate).endOf('day').toDate().getTime();
      const tractorSummary = await getMarkTicketsSummary(
        organization.api_url,
        organization.fleet.id,
        selectedOrgId,
        fromDateTime,
        toDateTime,
      );
      setSummary(tractorSummary);
    } catch (error: any) {
      notification.error({ message: error.message });
    }
  };

  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      selectedOrgId.length > 0 &&
      init.current
    ) {
      loadTickets();
      getTicketsSummary();
      init.current = false;
    }
  }, [selectedOrgId, userDetails]);

  useEffect(() => {
    return () => {
      window.history.replaceState({}, '');
    };
  }, [state]);

  const getSeverityIcon = (level: number, orderNumber: number) => {
    if (level >= 3) {
      return FlagRedIcon;
    }
    if (orderNumber <= level) {
      return FlagOrangeIcon;
    }
    return FlagGrayIcon;
  };

  const columns = [
    {
      title: `${t(translate.fleetticketsHistory.ticketid)}`,
      dataIndex: 'ticket_id',
      key: 'ticket_id',
      width: '120px',
    },
    {
      title: `${t(translate.fleetticketsHistory.dateTime)}`,
      sortDirections: ['descend', 'ascend', 'descend'],
      dataIndex: 'created_date_time',
      key: 'created_date_time',
      width: '150px',
      render: (created_date_time: string, ticketsObj: any) => (
        <span>{created_date_time}</span>
      ),
      ellipsis: true,
      sorter: true,
    },
    {
      title: `${t(translate.fleetticketsHistory.severity)}`,
      sortDirections: ['descend', 'ascend', 'descend'],
      dataIndex: 'ticket_level',
      key: 'ticket_level',
      width: '110px',
      ellipsis: true,
      sorter: true,
      render: (level: any) => {
        return (
          <div className="description">
            <img className="mr7" src={getSeverityIcon(level, 1)} />
            <img className="mr7" src={getSeverityIcon(level, 2)} />
            <img className="mr7" src={getSeverityIcon(level, 3)} />
          </div>
        );
      },
    },
    {
      title: `${t(translate.baseStation.status)}`,
      dataIndex: 'ticketStatus',
      key: 'ticketStatus',
      width: '120px',
      render: (ticketStatus: string) =>
        ticketStatus === 'completed' ? (
          <Tag className="resolveStyle" style={{ borderRadius: '12px' }}>
            {t(translate.tickets.status.resolved)}
          </Tag>
        ) : (
          <>
            {ticketStatus === 'inprogress' ? (
              <Tag className="inProgressStyle">
                {t(translate.tickets.status.inProgress)}
              </Tag>
            ) : (
              <>
                {ticketStatus === 'Forwarded' ? (
                  <Tag className="forwardStyle">{ticketStatus}</Tag>
                ) : (
                  <Tag color="red" className="openStyle">
                    {t(translate.tickets.status.open)}
                  </Tag>
                )}{' '}
              </>
            )}
          </>
        ),
    },
    {
      title: `${t(translate.fleetticketsHistory.assignto)}`,
      dataIndex: 'assignee',
      key: 'assignee',
      width: '120px',
      render: (assignee: string, record: any) => (
        <div>
          {record.is_service_ticket_raised ? (
            <div style={{ fontFamily: 'Montserrat-Medium' }}>
              {t(translate.tickets.defaultAssignee)}
            </div>
          ) : record.assignee ? (
            <>{assignee}</>
          ) : (
            <>-</>
          )}
        </div>
      ),
    },
    {
      title: `${t(translate.fleetticketsHistory.equipment)}`,
      dataIndex: 'equipmentName',
      key: 'equipmentName',
      width: '150px',
      render: (equipmentName: string) => (
        <span style={{ color: equipmentName === 'Other' ? '#99999C' : '' }}>
          {equipmentName}
        </span>
      ),
    },
    {
      title: `${t(translate.fleetticketsHistory.ticketDescription)}`,
      dataIndex: 'ticket_description',
      key: 'ticket_description',
      width: '220px',
      ellipsis: true,
      render: (ticket_description: string) => {
        return <span className="description">{ticket_description}</span>;
      },
    },
  ];

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    activity.current = 'filter';
    const { columnKey, order } = sorter;
    setSortedInfo({
      sort_column: columnKey,
      sort_order: order === 'ascend' ? 'asc' : 'desc',
    });
  };

  const onYealy = (date: any, dateString: string) => {
    setFilterObj({
      ...filterObj,
      year: Number(dateString),
      type: 'Yearly',
    });
  };

  const onMonthly = (date: any, dateString: string) => {
    if (date) {
      const year = date.format('YYYY');
      const month = date.format('MM');
      const from_date = moment(date).startOf('month').format('YYYY-MM-DD');
      const to_date = moment(date).endOf('month').format('YYYY-MM-DD');
      setFilterObj({
        ...filterObj,
        year: Number(year),
        month: Number(month),
        from_date,
        to_date,
        type: 'Monthly',
      });
    }
  };

  const getDatesByWeek = (weekNumber = moment().week()) => {
    const from_date = moment()
      .day('Sunday')
      .week(weekNumber)
      .format('YYYY-MM-DD');
    const to_date = moment(from_date).add('6', 'days').format('YYYY-MM-DD');
    return [from_date, to_date];
  };

  const onWeekly = (date: any) => {
    const week = moment(date).week();
    const from_date = moment(date)
      .day('Sunday')
      .week(week)
      .format('YYYY-MM-DD');
    const to_date = moment(from_date).add('6', 'days').format('YYYY-MM-DD');
    setFilterObj({
      ...filterObj,
      year: date.format('YYYY'),
      month: date.format('MM'),
      from_date,
      to_date,
      type: 'Weekly',
    });
  };

  const onDateRangeChange = (dates: any) => {
    const [from_date, to_date] = dates;
    setFilterObj({
      ...filterObj,
      from_date: from_date.format('YYYY-MM-DD'),
      to_date: to_date.format('YYYY-MM-DD'),
      type: 'Daily',
    });
  };

  const handleFilter = (value: string) => {
    const [from_date, to_date] = getDatesByWeek();

    if (value === 'Lifetime') setFilterObj({ ...filterObj, type: filter });
    if (value === 'Yearly') {
      setFilterObj({
        ...filterObj,
        year: new Date().getFullYear() - 1,
        type: value,
        from_date,
        to_date,
      });
    }
    if (value === 'Weekly' || value === 'Daily') {
      const [from_date, to_date] = getDatesByWeek();
      setFilterObj({ ...filterObj, from_date, to_date, type: value });
    }
    if (value === 'Monthly') {
      const from_date = moment(`${filterObj.year}-${filterObj.month}`)
        .startOf('month')
        .format('YYYY-MM-DD');
      const to_date = moment(`${filterObj.year}-${filterObj.month}`)
        .endOf('month')
        .format('YYYY-MM-DD');
      setFilterObj({ ...filterObj, from_date, to_date, type: value });
    }
    setFilter(value);
  };

  const disabledDates = (current: any) => {
    return current && current > moment();
  };

  const levelSelect = (e: string) => {
    activity.current = 'filter';
    setSeverityLevel(e);
  };

  const handleSubmit = () => {
    activity.current = 'filter';
    if (search !== '') {
      setPageNumber(1);
    }
    if (userDetails && selectedOrgId.length) {
      loadTickets();
      getTicketsSummary();
    } else {
      message.warning('Please select an organization');
      setData([]);
    }
  };

  return (
    <>
      <div className="widget_section fleet_issues">
        <div className="gridItem mtRight ">
          <img src={total_unresolved} alt="total Tractors" />
          <div className="widget_content">
            <div className="assignTxt cardTitle">
              {t(translate.fleetticketsHistory.summary.totalUnresolved)}
            </div>
            <div className="assignNum">{summary?.total_unresolved || 0}</div>
          </div>
          <div>
            <div className="forwardTxt">
              <span className="squareCon"></span>
              <span>{summary?.total_unresolved_internal || 0}</span>
              <h4>{t(translate.fleetticketsHistory.summary.internal)}</h4>
            </div>
            <div className="forwardTxt">
              <span className="squareCon squareBg"></span>
              <span>{summary?.total_unresolved_external || 0}</span>
              <h4>{t(translate.fleetticketsHistory.summary.customer)}</h4>
            </div>
          </div>
        </div>
        <div className="gridItem mtRight ">
          <img src={assignedToMe} alt="Online Tractors" />
          <div className="widget_content">
            <div className="assignTxt cardTitle">
              {t(translate.fleetticketsHistory.summary.severity)}
            </div>
            <div className="assignNum"> {summary?.total_severity || 0}</div>
          </div>
          <div>
            <div className="forwardTxt Svt">
              {/* <span className="squareCon"></span> */}
              <img src={FlagRedIcon} alt="High Severity" height={8} width={8} />
              <img src={FlagRedIcon} alt="High Severity" height={8} width={8} />
              <img src={FlagRedIcon} alt="High Severity" height={8} width={8} />
              <span>{summary?.severity_high || 0}</span>
              <h4>{t(translate.fleetticketsHistory.summary.high)}</h4>
            </div>
            <div className="forwardTxt Svt">
              {/* <span className="squareCon squareBg"></span> */}
              <img
                src={FlagOrangeIcon}
                alt="Medium Severity"
                height={8}
                width={8}
              />
              <img
                src={FlagOrangeIcon}
                alt="Medium Severity"
                height={8}
                width={8}
              />
              <img src={FlagGrayIcon} alt="Low Severity" height={8} width={8} />
              <span>{summary?.severity_medium || 0}</span>
              <h4>{t(translate.fleetticketsHistory.summary.medium)}</h4>
            </div>
            <div className="forwardTxt Svt">
              <img
                src={FlagOrangeIcon}
                alt="Medium Severity"
                height={8}
                width={8}
              />
              <img src={FlagGrayIcon} alt="Low Severity" height={8} width={8} />
              <img src={FlagGrayIcon} alt="Low Severity" height={8} width={8} />
              <span>{summary?.severity_low || 0}</span>
              <h4>{t(translate.fleetticketsHistory.summary.low)}</h4>
            </div>
          </div>
        </div>
        <div className="gridItem mtRight ">
          <img src={mtSupport} alt="gold build" />
          <div className="widget_content">
            <div className="assignTxt cardTitle">
              {t(translate.fleetticketsHistory.summary.mtSupport)}
            </div>
            <div className="assignNum"> {summary?.total_mtsupport || 0}</div>
          </div>
          <div>
            <div className="forwardTxt">
              <span className="squareCon"></span>
              <span>{summary?.forwarded_tickets || 0}</span>
              <h4>{t(translate.fleetticketsHistory.summary.forwarded)}</h4>
            </div>
            <div className="forwardTxt">
              <span className="squareCon squareBg"></span>
              <span>{summary?.in_progress_tickets || 0}</span>
              <h4>{t(translate.fleetticketsHistory.summary.inProgress)}</h4>
            </div>
          </div>
        </div>
        <div className="gridItem mtRight ">
          <img src={totalResolved} alt="non gold build" />
          <div className="widget_content">
            <div className="assignTxt cardTitle">
              {t(translate.fleetticketsHistory.summary.totalResolved)}
            </div>
            <div className="assignNum"> {summary?.total_resolved || 0}</div>
          </div>
          <div>
            <div className="forwardTxt">
              <span className="squareCon"></span>
              <span>{summary?.total_resolved_internal || 0}</span>
              <h4>{t(translate.fleetticketsHistory.summary.internal)}</h4>
            </div>
            <div className="forwardTxt">
              <span className="squareCon squareBg"></span>
              <span>{summary?.total_resolved_external || 0}</span>
              <h4>{t(translate.fleetticketsHistory.summary.customer)}</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="fleet_issues_container">
        <Row>
          <div className="common_wrapper">
            <div className="filters_card filter_inputs align_top">
              <div className="filtersHealthScreen">
                <Input
                  addonBefore={<SearchOutlined />}
                  placeholder="Search Tickets"
                  className="common_search w160"
                  onChange={handleSearch}
                  autoComplete="off"
                  width="160 px"
                  // value={search}
                />
                <CropOrgFilter
                  handleOrgs={handleOrg}
                  defaultOrgs={state?.selectedOrgs}
                />

                <CustomSeverityLevel
                  testId="severityLevelDropdownField-Tickets"
                  label="Severity Level"
                  cssClass="min_width"
                  value={severityLevel}
                  onSelect={(selected) => {
                    levelSelect(selected);
                  }}
                />
                <CustomSelect
                  label="Filters"
                  cssClass="ad_min_width"
                  value={filter}
                  setValue={handleFilter}
                  options={markFilters}
                  optionKey="value"
                  optionDisplay="name"
                />
                {filter === 'Monthly' && (
                  <DatePicker
                    allowClear={false}
                    format="MMM-YYYY"
                    value={moment(`${filterObj.year}-${filterObj.month}`)}
                    onChange={onMonthly}
                    disabledDate={disabledDates}
                    picker="month"
                  />
                )}
                {filter === 'Weekly' && (
                  <DatePicker
                    allowClear={false}
                    value={moment(filterObj.from_date)}
                    onChange={onWeekly}
                    disabledDate={disabledDates}
                    picker="week"
                  />
                )}
                {filter === 'Yearly' && (
                  <DatePicker
                    allowClear={false}
                    value={moment(`${filterObj.year}-01-01`)}
                    onChange={onYealy}
                    disabledDate={disabledDates}
                    picker="year"
                  />
                )}
                {filter === 'Daily' && (
                  <RangePicker
                    allowClear={false}
                    value={[
                      moment(filterObj.from_date),
                      moment(filterObj.to_date),
                    ]}
                    onChange={onDateRangeChange}
                    disabledDate={disabledDates}
                    className="datepickerWidth"
                  />
                )}
                <Button
                  className="submitBtn"
                  onClick={handleSubmit}
                  loading={loading}
                >
                  {t(translate.buttons.submit)}
                </Button>
              </div>
            </div>
            <div className="common_table">
              <InfiniteScrollTable
                columns={columns}
                loading={loading}
                hasMore={hasMore}
                filterData={filterData}
                totalcount={total}
                handleLoadMore={handleLoadMore}
                filename={total > 0 ? 'Tickets' : 'Ticket'}
                onChange={handleChange}
              />
            </div>
          </div>
        </Row>
      </div>
    </>
  );
};

export default Tickets;

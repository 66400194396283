import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ApplicationContext } from '../../../context/AppContext';
import useAnalytics from '../hooks/useAnalytics';
// import decrease from '../images/decrease.svg';
import imp_icon from '../images/implement-1.svg';
import increase from '../images/increase.svg';
import useStore from '../store/operationAnalytics';
import { fetchName } from '../common';
import './styles.scss';
import Spinner from '../../common/Spinner';

interface Props {
  onOperationsClick: (operationType: string) => void;
}

const Efficiency: React.FC<Props> = ({ onOperationsClick }) => {
  const {
    operationalTypesEfficiency: efficiency,
    addOperationalTypesEfficiency,
    dateRange,
  } = useStore();
  const { initEfficiencyData, efficiencyLoader } = useAnalytics();
  const { userDetails } = useContext(ApplicationContext);

  useEffect(() => {
    const [fromDate, toDate] = dateRange;
    if (userDetails?.organization)
      initEfficiencyData(
        '',
        fromDate?.startOf('day')?.toDate().getTime(),
        toDate?.endOf('dat')?.toDate().getTime(),
      );
    return () => {
      addOperationalTypesEfficiency({});
    };
  }, [userDetails, dateRange]);

  const operatorsByOperation = useCallback(
    (data) => {
      const { operators = [] } = data;
      return operators.slice(0, 3).map((operator: any, key: number) => {
        const { first_name, last_name, id } = operator;
        return (
          <div key={id} className={`avatar-circles${key === 0 ? '' : key}`}>
            <div className="mm uppercase">
              {key !== 2 ? fetchName(first_name, last_name) : '+2'}
            </div>
          </div>
        );
      });
    },
    [efficiency],
  );

  return (
    <div className="bottom-left-graphs operation1_left">
      <div className="header2">
        <div className="expression-list">
          <div className="work-overview">Efficiency (ac/hr)</div>
        </div>
        <div className="shape-compiler">
          <img className="implement-1-icon1" alt="" src={imp_icon} />

          <div className="image-parser">{Object.keys(efficiency).length}</div>
        </div>
      </div>
      <div className="efficiencyContainer scrollBarPos">
        <Spinner loader={efficiencyLoader} />
        <div className="color-coder">
          {Object.keys(efficiency).map((item) => {
            const data = efficiency[item];
            return (
              <div
                className="efficiency-cards"
                key={item}
                onClick={() => {
                  onOperationsClick(item);
                }}
              >
                <div className="positioner">
                  <div className="operations3">{item}</div>
                  <div className="operator-icons">
                    {operatorsByOperation(data)}
                  </div>
                </div>
                <div className="proximity-provider">
                  <div className="layer-llama">
                    <span className="span2">{data?.efficiency} </span>
                    <span className="achr">ac/hr</span>
                  </div>
                  {/* <div className="comparative-statistic">
                    <img
                      className="increase-icon"
                      loading="lazy"
                      alt=""
                      src={increase}
                    />

                    <div className="blend-brewer">{data?.efficiency}%</div>
                  </div> */}
                </div>
              </div>
            );
          })}

          {/* <div className="efficiency-cards">
            <div className="positioner">
              <div className="operations3">Mowing</div>
              <div className="operator-icons">
                {operatorsByOperation('mowing')}
              </div>
            </div>
            <div className="proximity-provider">
              <div className="layer-llama">
                <span className="span2">{mowing?.acer} </span>
                <span className="achr">ac/hr</span>
              </div>
              <div className="comparative-statistic">
                <img
                  className="increase-icon"
                  loading="lazy"
                  alt=""
                  src={increase}
                />

                <div className="blend-brewer">{mowing?.acer}%</div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Efficiency;

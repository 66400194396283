import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Checkbox, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import translate from '../../../locale/en_translate.json';
import { getDriveActionPath, getPolygonsData } from '../../../constants/Api';
import { ApplicationContext } from '../../../context/AppContext';
import { AnalyticsContext } from '../AnalyticsContext';

import { Feature, Overlay, View } from 'ol';
import { ScaleLine } from 'ol/control';
import Geometry from 'ol/geom/Geometry';
import Point from 'ol/geom/Point';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import Heatmap from 'ol/layer/Heatmap';
import OLMap from 'ol/Map';
import * as proj from 'ol/proj';
import OSM from 'ol/source/OSM';
import VectorSource from 'ol/source/Vector';
import XYZ from 'ol/source/XYZ';
import { Fill, Icon, Stroke, Style } from 'ol/style';

import LineString from 'ol/geom/LineString';
import CircleStyle from 'ol/style/Circle';
import ground_zero from '../../../assets/images/ground_zero.svg';

import datalayerIcon from '../../../assets/images/dataLayer.svg';
import guardRails from '../../../assets/images/guardRails.svg';
import SnapIcon from '../../../assets/images/m_sshot.svg';
import TicketIcon from '../../../assets/images/m_ticket.svg';
import MapCenterIcon from '../../../assets/images/map_recenter.svg';
import mapNor from '../../../assets/images/mapNor.svg';
import mapSat from '../../../assets/images/mapSat.svg';
import polygonIcon from '../../../assets/images/polygon.svg';
import thumbnail_icon from '../../../assets/images/thumbnail_icon.svg';
import radioIcon from '../../../assets/images/radio.svg';
import FleetIndicator from '../../../assets/images/tractor-icons/FleetIndicator.svg';
import {
  calPtsDistance,
  displayRecenterAllViewPortSetUp,
  displayRecenterViewPortSetUp,
  drawPoly,
  getDateTime,
  getFleetUsagePointsInfo,
  getFleetUsagePointsPathInfo,
  getFullName,
  getHour,
  getPathBorderColor,
  getPathColor,
  polygonHoverLabel,
  priority,
  toggleVineRowStyle,
  vineRowHoverStyle,
} from '../../../constants/Common';
import { track } from '../../../util/logger';

import { rangeForAnalytics } from '../../../constants/constant';
import { MapFeature } from '../../../constants/types';
import { SET_POLYGONS_LIST } from '../../../context/actions';
import applicationIds from '../../../locale/applicationIds.json';
import { onGlobalZoomToShowZoomIcons } from '../../map/LiveMap';
import { getVineRows } from '../../map/VineRows';
import { SET_IS_LOADING, SET_SELECTED_JAG } from '../action';
import { getCoverageRangePoints } from '../../HelpCenter/services/API';

let selectedDriveActionUser = '';
let globalZoom = 0;

const LiveMap: React.FC = () => {
  const { t } = useTranslation();
  const { user, userDetails, updateFullScreen, APPReducer, getGroundZeroData } =
    useContext(ApplicationContext);
  const [appState, appDispatch] = APPReducer;
  const { groundZero, polygonsList } = appState;

  const { setJAG, analyticReducer, jumpAndGoStore } =
    useContext(AnalyticsContext);
  const [analyticState, analyticDispatch] = analyticReducer;

  const [mapInitialized, setMapInitialized] = useState(false);
  const [base, setBase] = useState<[number, number]>([0, 0]);
  const [toggleSatellite, setToggleSatellite] = useState(true);
  const [rightSideToggle, setRightSideToggle] = useState(false);
  const [selectedJumpnGo, setselectedJumpnGo] = useState<any>();
  const selectedJumpnGoRef = useRef<any>(null);
  const [showThumbnails, setShowThumbnails] = useState<boolean>(false);
  const mapElement: any = useRef();
  const container: any = useRef();
  const content: any = useRef();
  const centerRef: any = useRef();
  const mapRef = useRef<OLMap | null>(null);
  const vectorTileLayerRef = useRef<TileLayer<any>>();
  const satelliteTileLayerRef = useRef<TileLayer<any>>();
  const initialLayer = useRef<VectorLayer<any>>();
  const heatMapLayerRefs = useRef<VectorLayer<any>>();
  const mapTicketsRefs = useRef<VectorLayer<any>>();
  const mapIndicatorRefs = useRef<VectorLayer<any>>();
  const tractorPathLayerRef = useRef<VectorLayer<any>>();
  const markerPointRef = useRef<VectorLayer<any>>();
  const guardRailsRefs = useRef<VectorLayer<any>>();
  const snapshotRefs = useRef<VectorLayer<any>>();
  const jumpAndGoRefs = useRef<VectorLayer<any>>();
  const hoverPointLayerRef = useRef<VectorLayer<any>>();
  const polygonsLayerRef = useRef<VectorLayer<any>>();
  const vineRowsLayerRef = useRef<VectorLayer<any>>();
  const [jumpAndGos, setJumpAndGos] = useState<any>(null);
  const [coverageRangeData, setCoverageRangeData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fullScreenFlag, setFullScreenFlag] = useState<boolean>(false);
  const [showGuardRailsLayer, setShowGuardRailsLayer] = useState(true);
  const [showSnapShotRailsLayer, setShowSnapShotRailsLayer] = useState(true);
  const [showPathlayer, setShowPathlayer] = useState(true);
  const [showhealdIndicatorsLayer, setShowhealdIndicatorsLayer] =
    useState(true);
  const [rightSideThumbnailsToggle, setRightSideThumbnailsToggle] =
    useState<boolean>(false);
  const [showTicketsLayer, setshowTicketsLayer] = useState(true);
  const [showPolygonsLayer, setshowPolygonsLayer] = useState(true);
  const [showHeatMapLayer, setShowHeatMapLayer] = useState(false);
  const [zoomLevel, setZoomLevel] = useState<number>(100);
  const fieldFeatureItem = useRef<any>();
  const jAGSSIcons = useRef<any>(new Map());

  const { operations } = analyticState;

  // cleanup component state
  useEffect(() => {
    return () => {
      setJumpAndGos([]);
      // setGuardrailData([]);
      setJumpAndGos([]);
      jumpAndGoStore.current.clear();
    };
  }, []);

  useEffect(() => {
    setJumpAndGos(operations);
  }, [operations]);

  const handleRecenter = () => {
    // if (mapRef && mapRef.current && centerRef && centerRef.current) {
    if (
      mapRef &&
      mapRef.current &&
      fieldFeatureItem &&
      fieldFeatureItem.current
    ) {
      displayRecenterAllViewPortSetUp(
        [fieldFeatureItem.current as Feature<Geometry>],
        mapRef.current,
      );
    }
  };

  useEffect(() => {
    if (!groundZero) getGroundZeroData();
    if (user && groundZero) {
      initializeMap();
    }
  }, [user, groundZero]);

  //  mapInitialization start here
  const initializeMap = async () => {
    try {
      const { latitude, longitude } = groundZero;
      setBase([latitude, longitude]);

      const baseLocation = new Feature({
        geometry: new Point(proj.fromLonLat([longitude, latitude])),
      });
      baseLocation.set('name', 'Ground zero');
      baseLocation.setStyle(
        new Style({
          image: new Icon({
            src: ground_zero,
            scale: 0.2,
          }),
          zIndex: 100,
        }),
      );

      const initialFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [baseLocation],
        }),
      });

      const vectorLayer = new TileLayer({
        source: new OSM(),
        visible: false,
      });

      const satelliteLayer = new TileLayer({
        source: new XYZ({
          url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga',
          cacheSize: 1000,
        }),
        visible: true,
      });

      // polygons list layar
      const polygonsFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
      });

      const heatMapFeatureLayer = new Heatmap({
        source: new VectorSource({
          features: [],
        }),
        blur: 7,
        radius: 5,
        weight: function (feature) {
          // You can customize the weight function as per your data
          return Math.random() * 10;
        },
        visible: false,
      });

      // create map
      const initialMap = new OLMap({
        target: mapElement.current,
        layers: [
          satelliteLayer,
          vectorLayer,
          initialFeaturesLayer,
          polygonsFeaturesLayer,
          heatMapFeatureLayer,
        ],
        controls: [scaleControl()],
        view: new View({
          projection: 'EPSG:3857',
          center: proj.transform(
            [longitude, latitude],
            'EPSG:4326',
            'EPSG:3857',
          ),
          zoom: 17,
          maxZoom: 25,
        }),
      });
      // set flag for map initialization
      setMapInitialized(true);
      mapRef.current = initialMap;
      initialLayer.current = initialFeaturesLayer;
      // initialize satelight layer
      satelliteTileLayerRef.current = satelliteLayer;
      // initialize vector layer
      vectorTileLayerRef.current = vectorLayer;
      // polygons list
      polygonsLayerRef.current = polygonsFeaturesLayer;
      // heatmap vector layer
      heatMapLayerRefs.current = heatMapFeatureLayer;
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };
  //  mapInitialization ends here

  const scaleControl = () => {
    const control = new ScaleLine({
      units: 'metric',
      className: 'positionMap',
    });
    return control;
  };
  // to change road map and satelight map
  useEffect(() => {
    vectorTileLayerRef.current?.setVisible(!toggleSatellite);
    satelliteTileLayerRef.current?.setVisible(toggleSatellite);
  }, [toggleSatellite]);
  /**
   * Zoom In Zoom Out and Recenter
   */

  const handleZoomLevel = (zoom: any) => {
    if (zoom <= 14) {
      setZoomLevel(1000);
    } else if (zoom > 14 && zoom <= 17) {
      setZoomLevel(100);
    } else if (zoom > 17 && zoom <= 19.3) {
      setZoomLevel(10);
    } else if (zoom > 21.5) {
      setZoomLevel(1);
    }
  };

  const handleZoom = (zoomType: string) => {
    if (mapRef.current) {
      const view = mapRef.current.getView();
      const zoom = view.getZoom();
      if (zoom && !isNaN(zoom)) handleZoomLevel(zoom);
      if (zoom) {
        if (zoomType === 'zoomIn') {
          view.setZoom(zoom + 1);
          track('Analytics Map', {
            event: `Map Zoom In ${zoom + 1}`,
          });
        } else {
          view.setZoom(zoom - 1);
          track('Analytics Map', {
            event: `Map Zoom out ${zoom - 1}`,
          });
        }
      }
    }
  };

  const moveEndHandler = (map: any) => {
    let currZoom = map?.getView().getZoom();
    map &&
      map.on('moveend', function (e: any) {
        const newZoom = map?.getView().getZoom();
        handleZoomLevel(newZoom);
        if (currZoom != newZoom) {
          currZoom = newZoom;
          globalZoom = newZoom;
          handleLayerVisible(newZoom);
        }
      });
  };
  const handleLayerVisible = (zoom: number) => {
    vineRowsLayerRef?.current?.setVisible(zoom >= 17);
    toggleVineRowStyle(
      vineRowsLayerRef.current,
      toggleSatellite,
      globalZoom >= onGlobalZoomToShowZoomIcons,
    );
  };

  useEffect(() => {
    if (mapInitialized) {
      if (mapRef.current) moveEndHandler(mapRef.current);
      const overlay = new Overlay({
        element: container.current,
        autoPan: false,
        autoPanAnimation: {
          duration: 10,
        },
      });
      mapRef.current?.on('pointermove', function (evt) {
        const hit = mapRef.current?.forEachFeatureAtPixel(
          evt.pixel,
          function (feature: any) {
            const coordinate = evt.coordinate;
            const name = feature.get('name');
            if (name) {
              content.current.innerHTML = '<p>' + name + '</p>';
              overlay.setPosition(coordinate);
              mapRef.current?.addOverlay(overlay);
              return true;
            }
            return false;
          },
        );
        if (hit) {
          evt.map.getTargetElement().style.cursor = 'pointer';
        } else {
          overlay.setPosition(undefined);
          mapRef.current?.addOverlay(overlay);
          evt.map.getTargetElement().style.cursor = '';
        }
      });
    }
  }, [userDetails, mapInitialized]);

  /**
   * Polygons initialization Starts Here
   */
  useEffect(() => {
    const init = async () => {
      polygonsLayerRef.current?.getSource().clear();
      try {
        const { organization } = userDetails;
        let recordsList = [];
        if (polygonsList && polygonsList.length > 0) {
          recordsList = polygonsList;
        } else {
          const response = await getPolygonsData(
            organization.api_url,
            organization.farm.id,
          );
          const { records } = response;
          recordsList =
            records && records.length && records.length > 0 ? records : [];
        }
        // const response = await getPolygonsData(
        //   organization.api_url,
        //   organization.farm.id,
        // );
        // const { records } = response;
        // drawPolygon(records);
        drawPolygon(
          recordsList,
          polygonsList && polygonsList.length > 0 ? false : true,
        );
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };
    if (userDetails && userDetails.organization && mapInitialized && base) {
      init();
    }
  }, [userDetails, mapInitialized, base]);

  const drawPolygon = async (polyList: any, save: boolean) => {
    try {
      const records = polyList && polyList.length > 0 ? polyList : [];
      const listItems: MapFeature[] = [];
      records.map(async (record: any, index: number) => {
        const { polygonFeature, polygonItem } = await drawPoly(
          userDetails,
          base,
          polygonsLayerRef.current,
          1,
          1,
          record,
          false, // suggested as false
          !save, // call api to get vertices -> false call api , -> true dont call api
        );
        if (record.color === 'white') {
          fieldFeatureItem.current = polygonFeature;
          displayRecenterViewPortSetUp(
            polygonFeature as Feature<Geometry>,
            mapRef.current,
          );
        }
        if (polygonItem && polygonItem.vertices) {
          record.vertices = polygonItem.vertices;
        }
        // polygons added to maintain app context start
        listItems.push(polygonItem as MapFeature);
        if (records && records.length - 1 === index) {
          save &&
            appDispatch({
              type: SET_POLYGONS_LIST,
              payload: records,
            });
        }
        // polygons added to maintain app context end
      });
      polygonHoverLabel(mapRef.current);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };
  useEffect(() => {
    const init = async () => {
      const { layer }: { layer: any } = await getVineRows(userDetails, base);
      if (layer) {
        mapRef.current?.addLayer(layer);
        vineRowsLayerRef.current = layer;
      }
    };
    if (userDetails && userDetails.organization && mapInitialized && base) {
      init();
    }
  }, [userDetails, mapInitialized, base]);

  // get data for the coverage range

  useEffect(() => {
    const init = async () => {
      const { organization } = userDetails;
      const { radio_heartbeat_data = [] } = await getCoverageRangePoints(
        organization.id,
        zoomLevel || 100,
      );
      if (Array.isArray(radio_heartbeat_data))
        setCoverageRangeData(radio_heartbeat_data);
    };
    if (userDetails?.organization && mapInitialized && user?.isSupportUser())
      init();
  }, [userDetails, mapInitialized, zoomLevel]);

  /**
   * Subscribing to Tractors and getting Information Starts Here
   */

  const setHoverName = (user: any) => {
    const n = `${user?.first_name}  ${user?.last_name}`;
    selectedDriveActionUser = n;
  };

  const clearTractorPoints = () => {
    if (tractorPathLayerRef.current) {
      tractorPathLayerRef?.current?.getSource()?.clear();
      mapRef.current?.removeLayer(tractorPathLayerRef.current);
    }
    if (markerPointRef.current) {
      markerPointRef?.current?.getSource()?.clear();
      mapRef.current?.removeLayer(markerPointRef.current);
    }
    const tractorPathFeaturesLayer = new VectorLayer({
      source: new VectorSource(),
      zIndex: priority.PATH,
    });
    mapRef.current?.addLayer(tractorPathFeaturesLayer);
    tractorPathLayerRef.current = tractorPathFeaturesLayer;
  };

  const drawJAG = (id: string, jumpAndGo: any, all = false) => {
    const {
      tractor_id: tractorId,
      records: points,
      tractor_name = '',
      operator_name = '',
      jng,
    } = jumpAndGo;
    const defaultLayer = tractorPathLayerRef.current;
    let oarr: any = [];
    let lastPlannerLastPoint: any = null;
    let lastIndexPoint: any = null;
    points
      .filter((point: any) => point.planner !== 'standby') // filter standby
      .map((point: any, index: number, tpoints: any) => {
        // slice points
        if (lastIndexPoint) point.path = [lastIndexPoint, ...point.path];

        const result = point.path.reduce(
          (
            a: { data: any[][]; i: number | undefined; last_item: any },
            path_pt: {
              longitude: number;
              latitude: number;
              created_date_time: number;
              dTime: string;
              accuracy: number;
            },
            i: number,
          ) => {
            const ar = point.path;
            // if (i == 0 && Object.keys(a.last_item).length) ar.push(point.path);

            let distance = 0;

            if (i < ar.length) {
              distance = ar[i + 1] ? calPtsDistance([path_pt, ar[i + 1]]) : 1;

              if (distance > rangeForAnalytics) {
                a.data.push(ar.slice(a.i, i + 1));
                a.i = i + 1;
                if (ar[i + 1]) a.data.push([ar[i], ar[i + 1]]);
              }
            }

            if (ar.length - 1 == i && a.data.length == 0) {
              a.data.push(ar);
              // a.last_item = ar[ar.length - 1];
              lastIndexPoint = ar[ar.length - 1];
              return a.data;
            }
            if (ar.length - 1 == i && a.data.length > 0) {
              // a.last_item = ar[ar.length - 1];
              lastIndexPoint = ar[ar.length - 1];
              return a.data;
            }
            return a;
          },
          { i: 0, data: [], last_item: {} },
        );

        result
          .filter((item: any, key: number) => !(key % 2))
          .map((item: any, key: number) => {
            const arr: any[] = [];
            // if (lastPlannerLastPoint) arr.push(lastPlannerLastPoint);
            item.map(
              (
                path_pt: {
                  longitude: number;
                  latitude: number;
                  created_date_time: number;
                  dTime: string;
                  accuracy: number;
                },
                i: number,
              ) => {
                path_pt.dTime = getDateTime(path_pt.created_date_time);
                arr?.push(
                  proj.fromLonLat([path_pt.longitude, path_pt.latitude]),
                );
              },
            );

            let tractorLocation: Feature<any> = new Feature();
            if (defaultLayer) {
              tractorLocation = defaultLayer?.getSource().getFeatureById(id);
            }
            if (!tractorLocation) {
              tractorLocation = new Feature();
            }
            oarr = [...oarr, ...arr];
            tractorLocation.setGeometry(new LineString(arr));
            // let color = '#13B0FC';
            // const borderColor = '#FFFFFF';
            // if (point.planner === pathContants.standby.key) {
            //   color = '#EECDB4';
            // } else if (
            //   point.planner === pathContants.copycat.key ||
            //   point.planner === pathContants.remoteav.key
            // ) {
            //   color = '#755bb6';
            // } else if (
            //   point.planner === pathContants.follow_me.key ||
            //   point.planner === pathContants.vinerow.key
            // ) {
            //   color = '#7adcda';
            // } else if (point.planner === pathContants.manual.key) {
            //   color = '#42aebc';
            // }
            let color = getPathColor(point.planner);
            const borderColor = getPathBorderColor(point.planner);
            if (key % 2) color = 'grey';
            const style = [
              new Style({
                stroke: new Stroke({
                  color: borderColor,
                  width: 8,
                }),
                zIndex: 0,
              }),
              new Style({
                stroke: new Stroke({
                  color: color,
                  width: 6,
                }),
                zIndex: 1,
              }),
            ];
            const pathHover = [
              new Style({
                stroke: new Stroke({
                  color: borderColor,
                  width: 10,
                }),
                zIndex: 1,
              }),
              new Style({
                stroke: new Stroke({
                  color,
                  width: 6,
                }),
                zIndex: 1,
              }),
            ];

            tractorLocation.setStyle(style);
            tractorLocation.set('style', style);
            tractorLocation.set('hoverStyle', pathHover);
            tractorLocation.set('name', selectedDriveActionUser);
            tractorLocation.set('pointType', 'PATH');
            tractorLocation.set('drawPointsText', point);
            tractorLocation.set('tractorId', tractorId);
            tractorLocation.set('tractor_name', tractor_name);
            tractorLocation.set('operator_name', operator_name);
            tractorLocation.set('jng', jng);

            defaultLayer?.getSource().addFeature(tractorLocation);
            if (arr && arr.length) {
              lastPlannerLastPoint = arr[arr.length - 1];
            }
          });
      });

    if (oarr.length > 2) {
      jAGSSIcons.current?.set(id, [jng, oarr[0], oarr[oarr.length - 1]]);
      // const pointFeature = jAGStartNdStop(oarr[0], 'Start', jng);
      // const pointFeature1 = jAGStartNdStop(oarr[oarr.length - 1], 'End', jng);
      // defaultLayer?.getSource().addFeatures([pointFeature, pointFeature1]);
    }

    if (!all && oarr && oarr.length > 0 && mapRef.current) {
      const view = mapRef.current?.getView();
      const zoom = view.getZoom();
      if (zoom) {
        // mapRef.current?.getView().setCenter(oarr[oarr.length - 1]);
        mapRef.current?.getView().setCenter(oarr[0]);
        mapRef.current?.getView().setZoom(17);
        centerRef.current = oarr[0];
      }
    }
  };

  useEffect(() => {
    if (mapInitialized) {
      handleClick();
      handlePointerMove();
      const pointLocation: Feature<any> = new Feature();
      pointLocation.setId(999);
      const selectPointFeatureLayer = new VectorLayer({
        source: new VectorSource({
          features: [pointLocation],
        }),
        visible: true,
        style: new Style({}),
        zIndex: 111,
      });
      mapRef.current?.addLayer(selectPointFeatureLayer);
      hoverPointLayerRef.current = selectPointFeatureLayer;
    }
  }, [mapInitialized]);

  const handleClick = () => {
    mapRef.current?.on('click', (evt) => {
      if (markerPointRef.current) {
        mapRef.current?.removeLayer(markerPointRef.current);
      }
      mapRef.current?.forEachFeatureAtPixel(evt.pixel, function (feature: any) {
        const feat = feature.get('features');
        if (feat && feat.length > 1) {
          const view = mapRef.current?.getView();
          if (view) {
            const zoom = view.getZoom();
            if (zoom && zoom < 18) {
              view.setZoom(18);
            }
          }
        }
        const pointType = feature.get('pointType');
        if (pointType && pointType === 'PATH') {
          const jng = feature.get('jng');
          // analyticDispatch({
          //   type: SET_POLY_LAYER,
          //   payload: polygonsLayerRef.current,
          // });
          analyticDispatch({
            type: SET_SELECTED_JAG,
            payload: jng,
          });
        }
      });
    });
  };

  const handlePointerMove = () => {
    const overlay = new Overlay({
      element: container.current,
      autoPan: false,
      autoPanAnimation: {
        duration: 10,
      },
    });
    let selected: any = null;
    mapRef.current?.on('pointermove', function (evt) {
      const pointLayer = hoverPointLayerRef.current;
      if (selected !== null) {
        const feat = pointLayer?.getSource().getFeatureById(999);
        feat?.setStyle(new Style({}));
        selected?.setStyle(selected?.get('style'));
        selected = null;
      }
      const hit = mapRef.current?.forEachFeatureAtPixel(
        evt.pixel,
        function (feature: any) {
          const pointType = feature.get('pointType');
          if (pointType && pointType === 'vinerow') {
            selected = feature;
            feature.setStyle(
              vineRowHoverStyle(globalZoom > onGlobalZoomToShowZoomIcons),
            );
            return true;
          }
          if (pointType == 'SNAP') {
            const contentData = feature.get('content');
            if (contentData && contentData.created_at === 'GUARD_RAILS') {
              selected = feature;
              selected?.setStyle(feature.get('hoverStyle'));
            }
          }
          if (pointType === 'PATH') {
            const feat = pointLayer?.getSource().getFeatureById(999);
            if (feat) {
              feat.setGeometry(
                new Point(
                  proj.fromLonLat(
                    proj.transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326'),
                  ),
                ),
              );
              feat.setStyle(
                new Style({
                  image: new CircleStyle({
                    radius: 5,
                    stroke: new Stroke({
                      color: 'blue',
                    }),
                    fill: new Fill({
                      color: '#3399CC',
                    }),
                  }),
                  zIndex: 10,
                }),
              );
              selected = feature;
              selected?.setStyle(feature.get('hoverStyle'));
              const geometry = feature.getGeometry();
              const point = geometry.getClosestPoint(evt.coordinate);
              const coordinates = geometry.getCoordinates();

              for (
                let element = 0;
                element < coordinates.length - 1;
                element++
              ) {
                if (
                  new LineString([
                    coordinates[element],
                    coordinates[element + 1],
                  ]).intersectsCoordinate(point)
                ) {
                  const drawPointsText = selected.get('drawPointsText');
                  if (drawPointsText.path && drawPointsText.path[element]) {
                    drawPointsText.path[element]['tractor_name'] =
                      selected.get('tractor_name');
                    drawPointsText.path[element]['operator_name'] =
                      selected.get('operator_name');
                    const innerHTML = getFleetUsagePointsPathInfo(
                      drawPointsText.path[element],
                    );
                    selected.set('name', innerHTML);
                    content.current.innerHTML = innerHTML;
                    if (
                      !overlay
                        .getElement()
                        ?.classList.contains('ol-details-popup')
                    ) {
                      overlay.getElement()?.classList.add('ol-details-popup');
                    }
                    overlay.setPosition(evt.coordinate);
                    mapRef.current?.addOverlay(overlay);
                    return true;
                  }
                }
              }
            }
          }
          const coordinate = evt.coordinate;
          const name = feature.get('name');
          const dataType = feature.get('pointType');
          const contentData = feature.get('content');
          if (name) {
            content.current.innerHTML = '<p>' + name + '</p>';
            if (
              dataType == 'JAG_START_STOP' ||
              dataType == 'INDICATOR' ||
              dataType == 'Ticket' ||
              name === 'GUARD_RAILS'
            ) {
              if (name === 'GUARD_RAILS') {
                const feat = feature.get('features')[0];
                const dataType = feat.get('pointType');
                const contentData = feat.get('content');
                content.current.innerHTML = getFleetUsagePointsInfo(
                  dataType,
                  contentData,
                );
              } else {
                content.current.innerHTML = getFleetUsagePointsInfo(
                  dataType,
                  contentData,
                );
              }
              if (
                !overlay.getElement()?.classList.contains('ol-details-popup')
              ) {
                overlay.getElement()?.classList.add('ol-details-popup');
              }
            } else {
              // stop removing path class removal
              if (dataType !== 'PATH') {
                overlay.getElement()?.classList.remove('ol-details-popup');
              }
            }

            overlay.setPosition(coordinate);
            mapRef.current?.addOverlay(overlay);
            return true;
          }

          return false;
        },
      );
      if (hit) {
        evt.map.getTargetElement().style.cursor = 'pointer';
      } else {
        overlay.setPosition(undefined);
        mapRef.current?.addOverlay(overlay);
        evt.map.getTargetElement().style.cursor = '';
      }
    });
  };

  const handleShowImagesVideos = (e: boolean) => {
    // setShowThumbnails(e);
    snapshotRefs.current?.setVisible(e);
  };
  const handleShowGuardRails = (e: boolean) => {
    guardRailsRefs.current?.setVisible(e);
  };

  const handleShowTravelledPath = (e: boolean) => {
    tractorPathLayerRef.current?.setVisible(e);
    jumpAndGoRefs.current?.setVisible(e);
  };

  const handleShowIndicators = (e: boolean) => {
    mapIndicatorRefs.current?.setVisible(e);
  };

  const handleShowTickets = (e: boolean) => {
    mapTicketsRefs.current?.setVisible(e);
  };

  const handleShowPolygons = (e: boolean) => {
    polygonsLayerRef.current?.setVisible(e);
  };

  const handleShowHeatMapLayer = (e: boolean) => {
    heatMapLayerRefs.current?.setVisible(e);
  };

  const toggleMapView = () => {
    track('Analytics Map', {
      event: `${toggleSatellite ? 'Map View' : 'satellite View'}`,
    });
    setToggleSatellite(!toggleSatellite);
    if (vineRowsLayerRef.current)
      toggleVineRowStyle(
        vineRowsLayerRef.current,
        !toggleSatellite,
        globalZoom >= onGlobalZoomToShowZoomIcons,
      );
  };

  useEffect(() => {
    if (selectedJumpnGo) {
      // setSelectedDriveAction(selectedJumpnGo?.drive_action_uuid);
      setShowThumbnails(true);
      setHoverName(selectedJumpnGo?.operator);
      setRightSideThumbnailsToggle(true);
    }
    selectedJumpnGoRef.current = selectedJumpnGo;
    setJAG(selectedJumpnGo);
  }, [selectedJumpnGo]);

  const getAllPoints = async (
    jumpAndGo: any,
    index: number,
    jumpAndGoLength: number,
  ) => {
    try {
      const {
        drive_action_uuid: selectedDriveAction,
        tractor,
        operator,
      } = jumpAndGo;
      if (
        userDetails &&
        userDetails.organization &&
        selectedDriveAction &&
        selectedDriveAction.length > 0
      ) {
        const { organization } = userDetails;
        const response = jumpAndGoStore.current.get(selectedDriveAction)
          ? jumpAndGoStore.current.get(selectedDriveAction)
          : await getDriveActionPath(organization.api_url, selectedDriveAction);
        jumpAndGoStore.current.set(selectedDriveAction, response);

        response['jng'] = jumpAndGo;
        response['tractor_name'] = tractor?.name;
        response['operator_name'] = getFullName(operator);

        drawJAG(selectedDriveAction, response, true);
        if (index + 1 === jumpAndGoLength && base && base.length) {
          centerRef.current = proj.fromLonLat([base[1], base[0]]);
          handleRecenter();
        }
      }
      return true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return false;
    }
  };

  useEffect(() => {
    if (jumpAndGos) {
      if (jumpAndGos.length) setIsLoading(jumpAndGos.length ? false : true);
      setselectedJumpnGo(null);
      clearTractorPoints();
      jumpAndGoStore.current.clear();
      setShowThumbnails(false);
      const fetchPromise = (jng: any, index: number) =>
        new Promise((resolve) => {
          getAllPoints(jng, index, jumpAndGos.length)
            .then(() => resolve(true))
            .catch(() => resolve(false));
        });
      const promies = jumpAndGos.map((jng: any, index: number) =>
        fetchPromise(jng, index),
      );
      Promise.all(promies)
        .then(() => {
          setIsLoading(true);
        })
        .catch(() => setIsLoading(true));
    }
  }, [jumpAndGos]);

  useEffect(() => {
    analyticDispatch({
      type: SET_IS_LOADING,
      payload: isLoading,
    });
  }, [isLoading]);

  const toggleFullScreen = (flagdata: any) => {
    updateFullScreen(flagdata);
    setFullScreenFlag(flagdata);
  };

  const validHour = (timestamp: any) => {
    const hour = getHour(timestamp);
    return hour === 'Invalid date' ? '  ' : hour;
  };

  const jAGTitle = selectedJumpnGo
    ? `${selectedJumpnGo?.tractor.name} | ${
        validHour(selectedJumpnGo?.drive_action_start_date_time) +
        ' - ' +
        validHour(selectedJumpnGo?.drive_action_finish_date_time)
      } | ${getFullName(selectedJumpnGo.operator)}`
    : '';

  useEffect(() => {
    if (mapInitialized && coverageRangeData) {
      const defaultLayer = heatMapLayerRefs.current;
      defaultLayer?.getSource().clear();
      coverageRangeData.map(([latitude, longitude]: any) => {
        const coordinates = proj.fromLonLat([longitude, latitude]);
        const feature = new Feature(new Point(coordinates));
        defaultLayer?.getSource().addFeature(feature);
      });
    }
  }, [mapInitialized, coverageRangeData]);

  const iconSection = useMemo(
    () => (
      <div
        className={
          rightSideThumbnailsToggle || rightSideToggle
            ? 'mapIconSec anaExpand'
            : 'mapIconSec'
        }
        style={{ marginTop: '180px' }}
      >
        <div
          className={`mapIcons ${
            showThumbnails ? 'afterAnaMap' : 'beforeCollapseAnaMap'
          } `}
        >
          <div className="mapRecenter" onClick={handleRecenter}>
            <img
              // style={{ width: '40px', height: '40px' }}
              src={MapCenterIcon}
            />
          </div>
          <div
            className={fullScreenFlag ? 'f_screenCol' : 'f_screen f_screen1'}
            data-testid="fullScreenMap-FleetMap"
            onClick={() => {
              toggleFullScreen(!fullScreenFlag);
              setTimeout(() => {
                mapRef.current?.updateSize();
              }, 100);
            }}
          ></div>
          <div className="zoom_in_out">
            <button
              id={applicationIds.analyticsMapScreen.mapZoomIn}
              data-testid="zoomInMap-FleetMap"
              className="zoomIn"
              onClick={() => handleZoom('zoomIn')}
            />
            <button
              id={applicationIds.analyticsMapScreen.mapZoomOut}
              data-testid="zoomOutMap-FleetMap"
              className="zoomOut"
              onClick={() => handleZoom('zoomOut')}
            />
          </div>
          <div className="mapView">
            <img
              id={applicationIds.analyticsMapScreen.toggleMap}
              data-testid="toggleMap-FleetMap"
              src={toggleSatellite ? mapNor : mapSat}
              onClick={toggleMapView}
              alt=""
            />
          </div>
        </div>
      </div>
    ),
    [
      rightSideThumbnailsToggle,
      rightSideToggle,
      fullScreenFlag,
      toggleSatellite,
    ],
  );

  return (
    <>
      <div className={fullScreenFlag ? 'fullScreen' : 'normalCl'}>
        <div className="tblContainer lMapCont analyticsMapSec">
          <div
            ref={mapElement}
            style={{
              height: fullScreenFlag ? '100vh' : '100%',
              width: fullScreenFlag ? '100vw' : '100%',
            }}
            //to align right bottom of map distance
            className={`posHd ${showThumbnails ? 'afterAnaMapAlign' : ''} 
          ${
            rightSideToggle || rightSideThumbnailsToggle
              ? 'afterAnaMapRightAlign'
              : ''
          } `}
          >
            <div
              id={applicationIds.analyticsMapScreen.toggleRowBtn}
              className="dataLayerBtn"
              onClick={() => setRightSideToggle(!rightSideToggle)}
            >
              <span className="dataArrow">{/* <LeftOutlined /> */}</span>
              <span className="dLIcon">
                <img
                  src={datalayerIcon}
                  alt="dataLayer"
                  data-testid="mapLayerOut-FleetMap"
                  width="18"
                />
              </span>
              <span className="dLTxt">{t(translate.fleetMap.mapLayers)}</span>
            </div>
            {selectedJumpnGo && (
              <div
                id={applicationIds.analyticsMapScreen.toggleRowBtn}
                className="dataLayerBtn"
                style={{ top: '150px' }}
                onClick={() =>
                  setRightSideThumbnailsToggle(!rightSideThumbnailsToggle)
                }
              >
                <span className="dataArrow">
                  <LeftOutlined />
                </span>
                <span className="dLIcon">
                  <img src={thumbnail_icon} alt="dataLayer" width="17" />
                </span>
                <span className="dLTxt">
                  {t(translate.fleetMap.thumbnails)}
                </span>
              </div>
            )}
            {iconSection}
            {selectedJumpnGo && (
              <>
                <div
                  className={
                    rightSideThumbnailsToggle
                      ? 'analyticsRightside expand'
                      : 'analyticsRightside'
                  }
                ></div>
                <div className="jag-head">{jAGTitle}</div>
              </>
            )}
            <div
              className={
                rightSideToggle
                  ? 'analyticsRightside expand'
                  : 'analyticsRightside'
              }
            >
              <div className="dataLayerWrapper">
                <div className="dataLayerRowHead mb28">
                  <span
                    onClick={() => {
                      track('Analytics Map', {
                        event: `${
                          rightSideToggle
                            ? 'Data Layer Closed'
                            : 'Data Layer Opened'
                        }`,
                      });
                      setRightSideToggle(!rightSideToggle);
                    }}
                    style={{ cursor: 'pointer' }}
                    data-testid="backArrowMaplayer-FleetMap"
                  >
                    <RightOutlined />
                  </span>
                  <span className="dataIcon">
                    <img src={datalayerIcon} alt="dataLayer" height="18" />
                  </span>
                  <span className="dataHead">
                    {t(translate.fleetMap.mapLayersCap)}
                  </span>
                </div>
                {/* datalayers block */}
                <div className="datalayerContent ">
                  <div className="dataLayerRow mplChkBox">
                    <div className="checkBoxItem">
                      <span className="layerName">
                        {t(translate.fleetMap.mapData)}
                      </span>
                    </div>
                  </div>
                  <div className="dataLayerInnerRow mplChkBox">
                    <div className="checkBoxItem ml50">
                      <span className="iconW">
                        <img
                          src={guardRails}
                          alt="GuardRails"
                          width="14"
                          height="20"
                        />
                      </span>
                      <span className="layerName">
                        {t(translate.fleetMap.guardRails)}
                      </span>
                      <span className="lChkBox">
                        <Checkbox
                          data-testid="guardCheckBox-FleetMap"
                          defaultChecked
                          checked={showGuardRailsLayer}
                          onChange={(e) => {
                            handleShowGuardRails(e.target.checked);
                            setShowGuardRailsLayer(e.target.checked);
                          }}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="dataLayerInnerRow mplChkBox">
                    <div className="checkBoxItem ml50">
                      <span className="iconW">
                        <img
                          src={SnapIcon}
                          alt="Snapshots"
                          width="14"
                          height="20"
                        />
                      </span>
                      <span className="layerName">
                        {t(translate.fleetMap.snapshots)}
                      </span>
                      <span className="lChkBox">
                        <Checkbox
                          data-testid="snapShotsCheckBox-FleetMap"
                          defaultChecked
                          checked={showSnapShotRailsLayer}
                          onChange={(e) => {
                            handleShowImagesVideos(e.target.checked);
                            setShowSnapShotRailsLayer(e.target.checked);
                          }}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="dataLayerInnerRow mplChkBox">
                    <div className="checkBoxItem ml50">
                      <span className="iconW">
                        <img
                          src={FleetIndicator}
                          alt="Implements"
                          width="14"
                          height="20"
                        />
                      </span>
                      <span className="layerName">
                        {t(translate.fleetMap.healthIndicators)}
                      </span>
                      <span className="lChkBox">
                        <Checkbox
                          data-testid="healthIndicatorCheckBox-FleetMap"
                          defaultChecked
                          checked={showhealdIndicatorsLayer}
                          onChange={(e) => {
                            handleShowIndicators(e.target.checked);
                            setShowhealdIndicatorsLayer(e.target.checked);
                          }}
                        />
                      </span>
                    </div>
                  </div>

                  <div className="dataLayerInnerRow mplChkBox">
                    <div className="checkBoxItem ml50">
                      <span className="iconW">
                        <img
                          src={TicketIcon}
                          alt="Implements"
                          width="14"
                          height="20"
                        />
                      </span>
                      <span className="layerName">
                        {t(translate.fleetMap.tickets)}
                      </span>
                      <span className="lChkBox">
                        <Checkbox
                          data-testid="ticketsCheckBox-FleetMap"
                          defaultChecked
                          checked={showTicketsLayer}
                          onChange={(e) => {
                            handleShowTickets(e.target.checked);
                            setshowTicketsLayer(e.target.checked);
                          }}
                        />
                      </span>
                    </div>
                  </div>

                  <div className="dataLayerInnerRow mplChkBox">
                    <div className="checkBoxItem ml50">
                      <span className="iconW">
                        <img
                          src={polygonIcon}
                          alt="Implements"
                          width="14"
                          height="20"
                        />
                      </span>
                      <span className="layerName">
                        {t(translate.fleetMap.polygon)}
                      </span>
                      <span className="lChkBox">
                        <Checkbox
                          data-testid="polygonCheckBox-FleetMap"
                          defaultChecked
                          checked={showPolygonsLayer}
                          onChange={(e) => {
                            handleShowPolygons(e.target.checked);
                            setshowPolygonsLayer(e.target.checked);
                          }}
                        ></Checkbox>
                      </span>
                    </div>
                  </div>

                  {user?.isSupportUser() && (
                    <MapLayerItem
                      onChange={(e) => {
                        handleShowHeatMapLayer(e.target.checked);
                        setShowHeatMapLayer(e.target.checked);
                      }}
                      isChecked={showHeatMapLayer}
                      name="HeatMap"
                      icon={radioIcon}
                    />
                  )}

                  <div className="dataLayerRow mplChkBox">
                    <div className="checkBoxItem">
                      <span className="layerName">
                        {t(translate.fleetMap.pathData)}
                      </span>
                      <span className="lChkBox">
                        <Checkbox
                          data-testid="pathCheckBox-FleetMap"
                          defaultChecked
                          checked={showPathlayer}
                          onChange={(e) => {
                            handleShowTravelledPath(e.target.checked);
                            setShowPathlayer(e.target.checked);
                          }}
                        ></Checkbox>
                      </span>
                    </div>
                  </div>

                  <div className="dataLayerInnerRow mplChkBox">
                    <div className="checkBoxItem ml50">
                      <span className="iconW">
                        <span className="pathBox manualBox"></span>
                      </span>
                      <span className="layerName">
                        {t(translate.fleetMap.manual)}
                      </span>
                    </div>
                  </div>

                  <div className="dataLayerInnerRow mplChkBox">
                    <div className="checkBoxItem ml50">
                      <span className="iconW">
                        <span className="pathBox operatorAssist"></span>
                      </span>
                      <span className="layerName">
                        {t(translate.fleetMap.operatorAssist)}
                      </span>
                    </div>
                  </div>
                  <div className="dataLayerInnerRow mplChkBox">
                    <div className="checkBoxItem ml50">
                      <span className="iconW">
                        <span className="pathBox autodrive"></span>
                      </span>
                      <span className="layerName">
                        {t(translate.fleetMap.autoDrive)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {mapInitialized && (
            <div ref={container} className="ol-popup">
              <div ref={content}></div>
            </div>
          )}
        </div>
        <div className={!isLoading ? 'wave-loader' : 'hide'}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </>
  );
};

interface Props1 {
  onChange: (e: any) => void;
  icon: string;
  name: string;
  isChecked: boolean;
}

const MapLayerItem: React.FC<Props1> = ({
  onChange,
  icon,
  name,
  isChecked,
}) => (
  <div className="dataLayerInnerRow mplChkBox">
    <div className="checkBoxItem ml50">
      <span className="iconW">
        <img src={icon} alt="Implements" width="14" height="20" />
      </span>
      <span className="layerName">{name}</span>
      <span className="lChkBox">
        <Checkbox
          data-testid="polygonCheckBox-FleetMap"
          defaultChecked
          checked={isChecked}
          onChange={onChange}
        ></Checkbox>
      </span>
    </div>
  </div>
);

export default LiveMap;

/* eslint-disable react/jsx-key */
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CloseOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Checkbox,
  Input,
  Modal,
  notification,
  Select,
  Spin,
  Upload,
} from 'antd';
import 'antd/dist/antd.css';
import Layout from 'antd/lib/layout/layout';
import { RcFile } from 'antd/lib/upload';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { track } from '../../util/logger';
import addCBtn from '../../assets/images/addCBtn.svg';
import closeGreen from '../../assets/images/closeGreen.svg';
import errorCodeIcon from '../../assets/images/errorCodeIcon.svg';
import FlagGrayIcon from '../../assets/images/flag_gray.svg';
import FlagOrangeIcon from '../../assets/images/flag_orange.svg';
import FlagRedIcon from '../../assets/images/flag_red.svg';
import {
  addComment,
  createdAddticket,
  getCommentsTickets,
  getOrgEquipments,
  getOrgTags,
  getTasksearch,
  ticketaddattachment,
  UpdateStatus,
} from '../../constants/Api';
import { getDateTime, getDateTimes } from '../../constants/Common';
import { speCharRegX } from '../../constants/constant';
import { Taskview, Taskviewdetails } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import applicationIds from '../../locale/applicationIds.json';
import translate from '../../locale/en_translate.json';
import { useErrorCodes } from '../../hooks/useErrorCodes';

const { Option } = Select;

interface Props {
  tractorId: number;
  details?: any;
  onCloseTicketWidget: () => void;
  getSeverity: (level: number) => any;
  toggleTicketWidget: boolean;
}
const TicketWidget: React.FC<Props> = ({
  tractorId,
  details,
  onCloseTicketWidget,
  getSeverity,
  toggleTicketWidget,
}: Props) => {
  const { TextArea } = Input;
  const { t } = useTranslation();
  const [editflag, seteditflag] = useState<boolean>(true);
  const [description, setDescription] = useState('');
  const { userDetails, APPReducer } = useContext(ApplicationContext);
  const errorCodes = useErrorCodes();
  const [loader, setLoader] = useState<boolean>(false);
  const [severity, setSeverity] = useState<number>(0);
  const [equipment, setEquipment] = useState<any>();
  const [tagsList, setTagsList] = useState<any[]>([]);
  const [equipmentsList, setEquipmentsList] = useState<any[]>([]);
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [ticketDetails, setTicketDetails] = useState<any>(null);
  const [fileList, setFileList] = useState<any>([]);
  const [stauslable, SetStatusLable] = useState<any>();
  const [CommentsData, setCommentsData] = useState<any>([]);
  const [commentflag, setComment] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [commentData, setCommentData] = useState<any>();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [currentIndex, setCurrentIndex] = useState<any>();
  const [assignid, setAssignid] = useState<any>();
  const [result, setResult] = useState<Taskviewdetails[]>([]);
  const [tractorName, setTractorName] = useState<any>();
  const [uuidnum, setuuid] = useState<any>();
  const [appState] = APPReducer;
  const { tractors } = appState;

  useEffect(() => {
    if (toggleTicketWidget) seteditflag(true);
  }, [toggleTicketWidget]);

  useEffect(() => {
    if (tractors && tractors.length > 0) {
      const [tractorinfo] = tractors.filter(
        (tractor: any) => tractor.id === tractorId,
      );

      setTractorName(tractorinfo.name);
    }
  }, [tractors]);

  const creator =
    userDetails && `${userDetails.first_name} ${userDetails.last_name}`;

  const handleSelectedTags = (id: number) => {
    const tags = selectedTags;
    tags.includes(id)
      ? tags.splice(
          tags.findIndex((item) => item === id),
          1,
        )
      : tags.push(id);
    const ftagsList = tagsList.map((item) => {
      item.selected = tags.includes(item.id);
      return item;
    });
    setSelectedTags(tags);
    setTagsList(ftagsList);

    track('TicketsCreateEdit', {
      event: 'Select Tags',
    });
  };

  const tagsDiv = tagsList.map((item, i) => {
    return (
      <div
        onClick={() => {
          handleSelectedTags(item.id);
          setRefresh(!refresh);
        }}
        className={
          selectedTags.includes(item.id) ? 'tags tag-selected' : 'tags'
        }
        key={i}
      >
        {item.name}
      </div>
    );
  });

  const getTicketDetails = (ticket: any) => {
    const {
      id,
      fleet_id,
      ticket_id,
      status,
      ticket_description: description,
      error_codes,
      created_date_time,
      ticket_level: severity,
    } = ticket;
    return Object.assign({
      ticket_id,
      status,
      severity,
      equipment: 'PS1',
      start_time: created_date_time ? getDateTime(created_date_time) : '',
      description,
      error_codes: error_codes || [],
      fleet_id,
      id,
    });
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      loadTags();
      getassigned();
    }
  }, [userDetails]);

  useEffect(() => {
    if (userDetails && userDetails.organization && tractorName) {
      loadEquipments();
    }
  }, [tractorName]);

  useEffect(() => {
    if (
      severity > 0 &&
      description &&
      description.trim().length > 0 &&
      equipment
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [severity, description, equipment]);

  const getassigned = async () => {
    let res: any[] = [];
    const taskviewData: Taskviewdetails = await getTasksearch(
      userDetails.organization.api_url,
      '',
    );
    taskviewData.records.map((ele: Taskview) => {
      ele.fullname = ele.first_name + ' ' + ele.last_name;
    });
    res = taskviewData.records;
    setResult(res);
  };

  useEffect(() => {
    if (details?.id) {
      seteditflag(true);
      let tdesc = '';
      if (details?.error_code) tdesc += `${details?.error_code} `;
      if (details?.subsystem) tdesc += `# ${details?.subsystem} `;
      if (details?.description) tdesc += `, ${details?.description}`;
      setDescription(tdesc);
      setSeverity(details?.severity);
    }
  }, [details]);

  useEffect(() => {
    if (ticketDetails && fileList.length) {
      addAttchaments();
    } else if (ticketDetails) {
      seteditflag(false);
    }
  }, [ticketDetails]);

  const loadTags = async () => {
    const result: any = await getOrgTags(
      userDetails.organization.api_url,
      userDetails.organization_id,
    );

    result.map((tag: any) => {
      tag.selected = false;
    });

    setTagsList(result);
  };

  const loadEquipments = async () => {
    let result: any = await getOrgEquipments(
      userDetails.organization.api_url,
      userDetails.organization_id,
    );

    result = result.filter(
      (equipment: any) => equipment.equipment_type !== 'None',
    );
    setEquipment(result[0]?.name);
    setuuid(1);
    result = result.map((equip: any, i: any) => {
      if (tractorName === equip.name) {
        setEquipment(equip.name);
        setuuid(i + 1);
      }
      const { name } = equip;
      equip.uuid = i + 1;
      equip.name = !speCharRegX?.test(name) ? encodeURIComponent(name) : name;
      return equip;
    });

    setEquipmentsList(result);
  };

  useEffect(() => {
    if (ticketDetails?.id) {
      getComments();
    }
  }, [ticketDetails]);

  useEffect(() => {
    if (userDetails?.id && result.length) {
      details?.assignee_id
        ? setAssignid(details?.assignee_id)
        : setAssignid(userDetails?.id);
    }
  }, [result]);

  const saveTicket = async () => {
    const selTags: any[] = [];
    let equipmentType = '';
    let equipmentid: any = '';

    tagsList.map((tag: any) => {
      if (tag.selected) {
        selTags.push(tag.name);
      }
    });
    equipmentsList.map((equip: any) => {
      if (equip.uuid == uuidnum) {
        equipmentType = equip.equipment_type;
        equipmentid = equip.equipment_id;
      }
    });
    const data: any = {
      ticket_description: description && description.trim(),
      ticket_level: severity,
      tags: selTags,
      equipment_id: equipmentid,
      equipment_type: equipmentType.trim(),
      error_code: details?.error_code,
      assignee_id: assignid,
    };
    if (equipmentid !== 'NULL' && equipmentType.trim() !== 'BASESTATION') {
      data.tractor_id = equipmentid;
    }
    try {
      setLoader(true);

      const response: any = await createdAddticket(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
        data,
      );

      if (response.error_codes) {
        const errorcode = response.error_codes.split(',');
        const errordata = errorCodes.filter((x: any) =>
          errorcode.includes(x.indicator_error_code.toString()),
        );
        response.error_codes = errordata;
      }
      const ticketData = getTicketDetails(response);
      ticketData['equipmentName'] = equipment;
      setTicketDetails(ticketData);
      const status = ticketData?.status === 'open' ? 'Unresolved' : 'Resolved';
      SetStatusLable(status);
      track('AddedTicket', {
        event: 'Ticket Added Sucessfully',
      });
      notification.success({ message: t(translate.label.ticketCreated) });
      seteditflag(false);
      onCloseTicketWidget();
    } catch (err: any) {
      track('AddedTicketFailed', {
        event: 'Ticket Added Failed',
      });
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleUpload = (info: any) => {
    const { file, fileList } = info;
    if (fileList.length) {
      file.status = 'done';
      fileList[fileList.length - 1].status = 'done';
      fileList.map((ele: any, i: any) => {
        ele.index = i;
      });
      setFileList(fileList);
      track('TicketWidget', {
        event: 'Added Ticket Attachment',
      });
    }
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const onPreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
    );
    setCurrentIndex(file.index);
  };

  const addAttchaments = async () => {
    if (fileList.length > 0) {
      const promiseall = fileList.map(async (ele: any, index: any) => {
        const formData = new FormData();
        formData.append('image', ele.originFileObj);
        await ticketaddattachment(
          userDetails.organization.api_url,
          ticketDetails?.fleet_id,
          ticketDetails?.id,
          formData,
        );
      });
      try {
        await Promise.all(promiseall);
        track('TicketWidget', {
          event: 'Ticket Attachment Added Sucessfully',
        });
      } catch (e: any) {
        track('TicketWidget', {
          event: 'Ticket Attachment Failed',
        });
      }
      seteditflag(false);
    }
  };

  const StatusonChange = (e: any) => {
    if (e.target.checked) {
      SetStatusLable('Resolved');
      track('TicketWidget', {
        event: `Select Status Resolved`,
      });
      UpdateTicketStatus('completed');
    } else {
      SetStatusLable('Unresolved');
      track('TicketWidget', {
        event: `Select Status Unresolved`,
      });
      UpdateTicketStatus('open');
    }
  };

  const SaveComment = async () => {
    if (commentData.trim()) {
      const obj = {
        data_key: 'COMMENTS',
        data_text: commentData.trim(),
        data_values: commentData.trim(),
      };
      try {
        setLoader(true);
        const response: { msg: string } = await addComment(
          userDetails.organization.api_url,
          ticketDetails.fleet_id,
          ticketDetails.id,
          obj,
        );
        setComment(false);
        getComments();
        track('TicketComment', {
          event: 'Ticket Comment Sucessfully',
        });
        notification.success({ message: response.msg });
      } catch (err: any) {
        track('TicketCommentFailed', {
          event: 'Ticket Comment Failed',
        });
        notification.error({
          message: err.message,
        });
      } finally {
        setLoader(false);
      }
    } else {
      notification.error({
        message: 'Added Comments',
      });
    }
  };
  const getComments = async () => {
    setCommentsData([]);
    const datakey: any = 'COMMENTS';
    const result: any = await getCommentsTickets(
      userDetails.organization.api_url,
      ticketDetails?.fleet_id,
      ticketDetails?.id,
      datakey,
    );
    setCommentsData(result);
  };
  const UpdateTicketStatus = async (status: any) => {
    const obj = {
      status: status,
    };
    try {
      setLoader(true);
      const response: { msg: string } = await UpdateStatus(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
        ticketDetails?.id,
        obj,
      );
      notification.success({ message: response.msg });
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };
  const getSeverityIcon = (level: number, orderNumber: number) => {
    if (level >= 3) {
      return FlagRedIcon;
    }
    if (orderNumber <= level) {
      return FlagOrangeIcon;
    }
    return FlagGrayIcon;
  };
  const equipmentSelect = (e: any) => {
    const raw = JSON.parse(e);
    equipmentsList.map((ele: any) => {
      if (ele.uuid === raw.uuid) {
        setuuid(ele.uuid);
        setEquipment(ele.name);
      }
    });
    track('TicketWidget', {
      event: `Select Equipment ${e}`,
    });
  };
  const addcomment = () => {
    setComment(true);
    track('AddedTicketComment', {
      event: 'Ticket Added Comment',
    });
  };
  const beforeUpload = (file: any) => {
    const imgSize = file.size / 1024 / 1024 < 8;
    if (!imgSize) {
      notification.error({
        message: 'file size must be smaller than 8MB!',
      });
    }
    return imgSize || Upload.LIST_IGNORE;
  };
  const leftarrowClick = async () => {
    const leftindex = currentIndex + 1;

    setCurrentIndex(leftindex);
    setPreviewTitle(
      fileList[leftindex].name ||
        fileList[leftindex].url!.substring(
          fileList[leftindex].url!.lastIndexOf('/') + 1,
        ),
    );
    const preview = await getBase64(
      fileList[leftindex].originFileObj as RcFile,
    );
    setPreviewImage(fileList[leftindex].url || (preview as string));
  };

  const rightarrowClick = async () => {
    const rightindex = currentIndex - 1;

    setCurrentIndex(rightindex);
    setPreviewTitle(
      fileList[rightindex].name ||
        fileList[rightindex].url!.substring(
          fileList[rightindex].url!.lastIndexOf('/') + 1,
        ),
    );
    const preview = await getBase64(
      fileList[rightindex].originFileObj as RcFile,
    );
    setPreviewImage(fileList[rightindex].url || (preview as string));
  };
  const handleCancel = () => setPreviewOpen(false);

  const onSelect = (e: any) => {
    setAssignid(e);
  };

  const viewBlock = (details: any) => (
    <>
      <div className="profileView">
        <div className="proRow mb48">
          <h4 className="proRoleHead">Ticket #{details.ticket_id}</h4>
          <h3 className="proRole"> {details.start_time}</h3>
        </div>
        <div className="proRowType mb34">
          <h5 className="proRoleType subHead subFlex">
            <span>Status</span>
            <span className="smallFont subdesc">
              <Checkbox
                checked={stauslable === 'Unresolved' ? false : true}
                onChange={StatusonChange}
              >
                {stauslable}
              </Checkbox>
            </span>
          </h5>
        </div>
        <div className="proRowType mb34">
          <h5 className="proRoleType subHead subFlex">
            <span>Severity Level</span>
            <span className="smallFont subdesc">
              {getSeverity(details.severity)}
              Level {details.severity || 0}
            </span>
          </h5>
        </div>
        <div className="proRowType mb34">
          <h5 className="proRoleType subHead subFlex">
            <span>Equipment</span>
            <span className="smallFont subdesc">{details.equipmentName}</span>
          </h5>
        </div>
        <div className="proRowType mb34">
          <h5 className="proRoleType subHead subFlex">
            <span>Creator</span>
            <span className="smallFont subdesc">{creator}</span>
          </h5>
        </div>
        <div className="proRowType mb34">
          <h5 className="proRoleType subHead">Ticket Description</h5>
          <div className="proRoleValue mt14 subdesc">{details.description}</div>
        </div>
        <div className="attachemtBlk mb34">
          <h4 className="tcktTitle">Attachments</h4>
          <Upload
            listType="picture-card"
            className="avatar-uploader"
            fileList={fileList}
            onPreview={onPreview}
          >
            <PlusOutlined />
          </Upload>
        </div>

        <div className="errorCodesBlk mb32">
          <h4 className="tcktTitle subHead">
            Error Codes
            <img src={errorCodeIcon} className="errorCodeIcon" />
          </h4>
          <table className="table proRoleValue">
            <tbody>
              {details?.error_codes &&
                details.error_codes.map((x: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>-</td>
                      <td>{x?.indicator_error_code}</td>
                      <td>
                        <img src={getSeverityIcon(x?.level, 1)} />
                        <img src={getSeverityIcon(x?.level, 2)} />
                        <img src={getSeverityIcon(x?.level, 3)} />
                      </td>
                      <td>{x?.subsystem}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="proRowType mb32">
          <h5 className="proRoleType subHead">Comments</h5>
          <h6 className="proRoleValue createTcktBtn mt16">
            <div>
              {CommentsData &&
                CommentsData.map((ele: any) => {
                  return (
                    <div className="commentsBlk">
                      <div className="commentsAvatar">
                        <div className="commentsIcon">
                          <Avatar
                            style={{
                              width: '43px',
                              height: '43px',
                              backgroundColor: '#67AFB7',
                              color: 'white',
                              fontFamily: 'Montserrat-Bold',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                            src={ele?.profile_url}
                          >
                            {ele.created_by_user?.first_name?.charAt(0)}
                            {ele.created_by_user?.last_name?.charAt(0)}
                          </Avatar>
                        </div>
                      </div>
                      <div className="commentsContent">
                        <h5>{ele.data_text}</h5>
                        <h6>{getDateTimes(ele?.created_date_time)}</h6>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div>
              {commentflag && (
                <>
                  <div className="tcktCommentBox">
                    <div
                      className="txtareaAvatar"
                      onClick={() => setComment(false)}
                    >
                      <Avatar
                        style={{
                          width: '38px',
                          height: '38px',
                          backgroundColor: '#67AFB7',
                          color: 'white',
                          fontFamily: 'Montserrat-Bold',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                        src={userDetails?.profile_url}
                      >
                        {userDetails?.first_name?.charAt(0)}
                        {userDetails?.last_name?.charAt(0)}
                      </Avatar>
                    </div>
                    <TextArea
                      style={{ width: '100%' }}
                      placeholder=""
                      showCount
                      autoSize={{ minRows: 6, maxRows: 8 }}
                      onChange={(e: any) => setCommentData(e.target.value)}
                    />
                    <CloseOutlined onClick={() => setComment(false)} />
                  </div>
                  <span
                    id={applicationIds.ticketScreen.saveComment}
                    onClick={SaveComment}
                    className="commentSaveBtn"
                  >
                    Save
                  </span>
                </>
              )}
            </div>

            {!commentflag && (
              <div
                id={applicationIds.ticketScreen.addComment}
                onClick={addcomment}
                className="commentBtnBlk"
              >
                <span className="addCBtn addTcktCBtn">
                  <img src={addCBtn} />
                </span>
                <span className="addCommentBtn">ADD A COMMENT</span>
              </div>
            )}
          </h6>
        </div>
      </div>
    </>
  );

  return (
    <Layout>
      <div
        className={
          'rightSideWidegt ' + (toggleTicketWidget ? 'expand' : 'hide')
        }
      >
        <div className="widgetCloseBtn">
          <img
            id={applicationIds.diagnosticsScreen.diagnosticCloseBtn}
            src={closeGreen}
            alt="close icon"
            onClick={onCloseTicketWidget}
          />
        </div>
        {editflag && details ? (
          <div className="profileEdit rightWidgetView">
            <h3 className="headline1 rowGapBtm3">Create A Ticket</h3>
            <div className="proRowType rowGapBtm3">
              <h5 className="subFlex flexBtn">
                <span className="subTitles fSbd overline1">Severity Level</span>
                <span className="createTcktFlag">
                  <div className="subFlex">
                    {Array.from({ length: 3 }).map((item, key) => {
                      return (
                        <div
                          key={key}
                          onClick={() => {
                            severity === 1 && key === 0
                              ? setSeverity(0)
                              : setSeverity(key + 1);
                            track('TicketWidget', {
                              event: `Select Severity ${key + 1}`,
                            });
                          }}
                        >
                          {severity === 0 && (
                            <img
                              className={key !== 0 ? '' : 'ml_0'}
                              src={FlagGrayIcon}
                            />
                          )}
                          {severity === 1 && (
                            <img
                              className={key !== 0 ? '' : 'ml_0'}
                              src={key === 0 ? FlagOrangeIcon : FlagGrayIcon}
                            />
                          )}
                          {severity === 2 && (
                            <img
                              className={key !== 0 ? '' : 'ml_0'}
                              src={key !== 2 ? FlagOrangeIcon : FlagGrayIcon}
                            />
                          )}
                          {severity === 3 && (
                            <img
                              className={key !== 0 ? '' : 'ml_0'}
                              src={FlagRedIcon}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </span>
              </h5>
            </div>

            <div className="rowGapBtm3">
              <Select
                style={{ width: '100%' }}
                value={equipment}
                onSelect={equipmentSelect}
                placeholder={t(translate.fleetticketsHistory.equipment)}
              >
                {equipmentsList?.map((data: any, i: number) => {
                  return (
                    <Option value={JSON.stringify(data)} key={i}>
                      {data.name}
                    </Option>
                  );
                })}
              </Select>
            </div>

            <div className="rowGapBtm3">
              <Select
                showSearch={true}
                placeholder={t(translate.fleetticketsHistory.assignto)}
                style={{ width: '100%' }}
                value={assignid}
                onChange={onSelect}
                filterOption={(input, option) => {
                  return (
                    option!.title
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
              >
                {result.map((ele: any) => (
                  <Option
                    title={ele.fullname}
                    key={ele.id}
                    value={ele.id}
                    data-testid={`${ele.fullname}-TicketViewEdit`}
                  >
                    {ele.fullname}
                  </Option>
                ))}
              </Select>
            </div>

            <div className="rowGapBtm3">
              <h4 className="fSbd overline1">Ticket Description</h4>
              <TextArea
                style={{ width: '100%' }}
                placeholder=""
                autoSize={{ minRows: 5, maxRows: 8 }}
                value={description}
                onChange={(e: any) => setDescription(e.target.value)}
              />
              <span
                style={{
                  display:
                    description !== undefined &&
                    description.length >= 3 &&
                    description.length <= 500
                      ? ''
                      : 'none',
                  float: 'right',
                }}
                className="descCount"
              >
                {description !== undefined && description.length}
                <> / 500</>
              </span>
            </div>
            <div className="tagsSec rowGapBtm3">
              <h4 className="fSbd overline1">Tags</h4>
              <div className="tagsBlk">{tagsDiv}</div>
            </div>
            <div className="attachemtBlk rowGapBtm3 ticketsAttachementBlk attachIcons">
              <h4 className="fSbd overline1">
                Attachments ({fileList.length})
              </h4>
              <Upload
                disabled={fileList.length >= 5}
                listType="picture-card"
                className="avatar-uploader"
                onChange={handleUpload}
                onPreview={onPreview}
                beforeUpload={beforeUpload}
                accept=".jpg,.png"
                maxCount={5}
              >
                <PlusOutlined />
              </Upload>
            </div>

            <div className="edit-loader">
              {loader && (
                <div className="loader">
                  <Spin size="large" />
                </div>
              )}
            </div>
            <div className="proeditBtn tcktSaveBtn saveTxtBtn">
              <button
                id={applicationIds.diagnosticsScreen.saveDiagnostic}
                onClick={saveTicket}
                disabled={isDisabled}
              >
                Save
              </button>
            </div>
          </div>
        ) : (
          ticketDetails && viewBlock(ticketDetails)
        )}
        <Modal
          visible={previewOpen}
          title={previewTitle}
          footer={null}
          maskClosable={false}
          onCancel={handleCancel}
          className="previewModal"
        >
          <div className="previewmodalBody">
            {fileList.length - 1 !== currentIndex && (
              <ArrowLeftOutlined onClick={leftarrowClick} />
            )}
            {previewImage && (
              <img
                alt="example"
                className="img-responsive"
                src={previewImage}
              />
            )}
            {currentIndex !== 0 && (
              <ArrowRightOutlined onClick={rightarrowClick} />
            )}
          </div>
        </Modal>
      </div>
    </Layout>
  );
};

export default TicketWidget;

/* eslint-disable react/jsx-key */
import React from 'react';
import battery_charger from '../../assets/images/battery_charger.svg';
import motor_rpm from '../../assets/images/motor_rpm.svg';
import Update_WiFi from '../../assets/images/Update_WiFi.svg';
import { getSummary } from '../../constants/types';
import { CommonReleaseNotes } from './CommonReleaseNotes';

interface Props {
  summaryList: getSummary;
  setSeeAll: (data: boolean) => void;
  text?: string;
}

export const CommonRightSchedule: React.FC<Props> = ({
  summaryList,
  setSeeAll,
  text,
}) => {
  return (
    <div className="rightSchedule">
      <div className="firstSch">
        <div className="otaHead mb10">How to Update</div>
        <div className="updateTractorTxt">
          To update your tractors successfully, please make sure that the
          following requirements are met prior to your scheduled date and time.{' '}
        </div>
        <div className="commonGrid">
          <div className="gridItems">
            <div className="gridTopTxt">1</div>
            <img src={battery_charger} alt="charger" />
            <div className="updateTractorTxt mt12">Battery above 20%</div>
            <div className="gridTxt">
              Plug in any tractors with low battery for a successful update.
            </div>
          </div>
          <div className="gridItems">
            {' '}
            <div className="gridTopTxt">2</div>
            <img src={motor_rpm} alt="motor" />
            <div className="updateTractorTxt mt12">Turn Motor Off</div>
            <div className="gridTxt">
              Turn only the motor off and keep the tractor online.
            </div>
          </div>
          <div className="gridItems">
            {' '}
            <div className="gridTopTxt">3</div>
            <img src={Update_WiFi} alt="wifi" />
            <div className="updateTractorTxt mt12">Strong Connectivity</div>
            <div className="gridTxt">
              Internet speed above 10mbps will result in a faster update.
            </div>
          </div>
        </div>
        {text && (
          <div className="updateTractorTxt mt15">
            {' '}
            Once the update has completed, power the tractor down, switch the
            12V off, and wait 30s before restarting.
          </div>
        )}
      </div>
      <div className="secondSch">
        <div className="firstGrid mb15">
          <div className="otaHead">Release Notes</div>
          <div
            className="releaseNotesTxt pointer seeAll"
            onClick={() => setSeeAll(true)}
          >
            See All
          </div>
        </div>
        <CommonReleaseNotes summaryList={summaryList as any} />
      </div>
    </div>
  );
};

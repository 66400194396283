import React, { useContext, useEffect, useState } from 'react';
import { Button, DatePicker, Layout, Row, Space } from 'antd';
import moment from 'moment';
import { ApplicationContext } from '../../../context/AppContext';
import { getDriveActionListData } from '../api';

import total_hours from '../images/total_hours.svg';
import no_pauses from '../images/no_pauses.svg';
import no_resumes from '../images/no_resumes.svg';
import sortDown from '../../../assets/images/sort_down.svg';
import sortUp from '../../../assets/images/sort_up.svg';

import translate from '../../../locale/en_translate.json';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  getDateTime,
  getUserName,
  initScroller,
} from '../../../constants/Common';
import DriveActionDetailsModal from './DriveActionDetailsModal';
import InfiniteScrollTable from '../../common/InfiniteScrollTable';
import usePaginate from '../../../hooks/usePaginate';
import { Content } from 'antd/lib/layout/layout';
import CustomSelect from '../../common/CustomSelect';
import { impactFilters } from '../Common';
import { useTranslation } from 'react-i18next';

import './style.css';
import { secondsToHms } from '../../../lib/utilities';

const { RangePicker } = DatePicker;

interface Props {
  onBack: () => void;
  selectedCustomer: any;
}

const DriveActions: React.FC<Props> = ({ onBack, selectedCustomer }) => {
  const { userDetails } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState<any>(0);
  const [summary, setSummary] = useState<any>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize] = useState<any>(25);
  const [selectedDriveAction, setSelectedDriveAction] = useState<any>(null);
  const { filterData, activity, checkActivity, hasMore, setData } =
    usePaginate();
  const [sortedInfo, setSortedInfo] = useState<any>({
    columnKey: 'distance',
    order: 'ascend',
  });
  const [sortedData, setSortedData] = useState<any>({
    distance: 'ascend',
  });
  // const { tractors, setOrgId } = useTractors();
  // const [selectedOperators, setSeletedOperators] = useState<string[]>([]);
  // const [selectedTractors, setSeletedTractors] = useState<string[]>([]);

  const [filterObj, setFilterObj] = useState({
    ...selectedCustomer.filterObj,
  });
  const [filter, setFilter] = useState<string>(selectedCustomer.filter);

  useEffect(() => {
    if (userDetails?.organization) loadData();
  }, [userDetails, pageNumber, sortedInfo]);

  // useEffect(() => {
  //   if (selectedCustomer?.customer_id) setOrgId(selectedCustomer.customer_id);
  // }, [selectedCustomer]);

  const loadData = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : initDriveActions();
    } else if (activity.current === 'paginate' || activity.current === '') {
      initDriveActions();
    }
  };

  const initDriveActions = async () => {
    if (userDetails) {
      const { fromDate, toDate, customer_id, totalHours, customer_name } =
        selectedCustomer;
      try {
        setLoading(true);
        const result: any = await getDriveActionListData(
          userDetails.organization.api_url,
          customer_id,
          moment(filterObj.from_date).format('YYYY-MM-DD'),
          moment(filterObj.to_date).format('YYYY-MM-DD'),
          totalHours,
          pageNumber,
          pageSize,
          sortedInfo,
        );

        setTotal(result?._metadata?.total_records_count);
        const { records, summary: summaryData } = result;
        let data = Array.isArray(records) ? records : [];
        data = data.map((item: any) => {
          return {
            drive_action_uuid: item?.drive_action_uuid,
            customer_id,
            customer_name: customer_name,
            tractor_name: item?.tractor.name,
            operator_name: item.operator ? getUserName(item.operator) : '',
            start_time: item.drive_action_start_date_time
              ? getDateTime(item.drive_action_start_date_time)
              : '-',
            end_time: item.drive_action_finish_date_time
              ? getDateTime(item.drive_action_finish_date_time)
              : '-',
            implement_name: item?.implement_name,
            distance: item.distance,
            duration: item.duration,
          };
        });
        setData(data);
        setSummary({
          ...summary,
          totalHours: summaryData?.total_hours,
          totalPauses: summaryData?.total_no_of_pauses,
          totalResumes: summaryData?.total_no_of_resumes,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const columns: any = [
    {
      title: 'Tractor Name',
      dataIndex: 'tractor_name',
      key: 'tractor_name',
      width: 150,
    },
    {
      title: 'Operator',
      dataIndex: 'operator_name',
      key: 'operator_name',
      width: 300,
    },
    {
      title: 'Start Time',
      dataIndex: 'start_time',
      key: 'start_time',
    },
    {
      title: 'End time',
      dataIndex: 'end_time',
      key: 'end_time',
    },
    {
      title: 'Implement',
      dataIndex: 'implement_name',
      key: 'implement_name',
    },
    {
      // title: 'Distance (Kms)',
      title: () => {
        return (
          <>
            {t(translate.driveActions.distance)} (Kms)
            <span className="sort-pad">
              <img
                className="sort-pad"
                src={sortedData['distance'] === 'ascend' ? sortUp : sortDown}
              />
            </span>
          </>
        );
      },
      dataIndex: 'distance',
      key: 'distance',
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.distance - b.distance,
      sortOrder: sortedInfo.columnKey === 'distance' && sortedInfo.order,
    },
    {
      title: () => {
        return (
          <>
            {t(translate.driveActions.duration)}
            <span className="sort-pad">
              <img
                className="sort-pad"
                src={sortedData['duration'] === 'ascend' ? sortUp : sortDown}
              />
            </span>
          </>
        );
      },
      dataIndex: 'duration',
      key: 'duration',
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.duration - b.duration,
      sortOrder: sortedInfo.columnKey === 'duration' && sortedInfo.order,
      render: (value: number) => {
        return value ? secondsToHms(value) : '-';
      },
    },
  ];

  const onRowClick = (record: any) => {
    setSelectedDriveAction(record);
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const handleFilter = (value: string) => {
    const [from_date, to_date] = getDatesByWeek();

    if (value === 'Lifetime') setFilterObj({ ...filterObj, type: filter });
    if (value === 'Yearly') {
      setFilterObj({
        ...filterObj,
        from_date: moment(from_date).startOf('year').format('YYYY-MM-DD'),
        to_date:
          moment(to_date).endOf('year') > moment(to_date).endOf('month')
            ? moment(to_date).endOf('month').format('YYYY-MM-DD')
            : moment(to_date).endOf('year').format('YYYY-MM-DD'),
      });
    }
    if (value === 'Weekly' || value === 'Daily') {
      const [from_date, to_date] = getDatesByWeek();
      setFilterObj({ ...filterObj, from_date, to_date, type: value });
    }
    if (value === 'Monthly') {
      setFilterObj({
        ...filterObj,
        from_date: moment(from_date).startOf('month').format('YYYY-MM-DD'),
        to_date: moment(to_date).endOf('month').format('YYYY-MM-DD'),
        type: value,
      });
    }
    setFilter(value);
  };

  const disabledDates = (current: any) => {
    return (
      (current && current > moment()) ||
      (current && current <= moment('12-31-2023'))
    );
  };

  const onYealy = (date: any, dateString: string) => {
    const from_date = moment(date).startOf('year').format('YYYY-MM-DD');
    const to_date = moment(date).endOf('year').format('YYYY-MM-DD');
    setFilterObj({
      ...filterObj,
      year: Number(dateString),
      from_date,
      to_date,
      type: 'Yearly',
    });
  };

  const onMonthly = (date: any, dateString: string) => {
    if (date) {
      const year = date.format('YYYY');
      const month = date.format('MM');
      const from_date = moment(date).startOf('month').format('YYYY-MM-DD');
      const to_date = moment(date).endOf('month').format('YYYY-MM-DD');
      setFilterObj({
        ...filterObj,
        year: Number(year),
        month: Number(month),
        from_date,
        to_date,
        type: 'Monthly',
      });
    }
  };

  const getDatesByWeek = (weekNumber = moment().week()) => {
    const from_date = moment()
      .day('Sunday')
      .week(weekNumber)
      .format('YYYY-MM-DD');
    const to_date = moment(from_date).add('6', 'days').format('YYYY-MM-DD');
    return [from_date, to_date];
  };

  const onWeekly = (date: any) => {
    const week = moment(date).week();
    const from_date = moment(date)
      .day('Sunday')
      .week(week)
      .format('YYYY-MM-DD');
    const to_date = moment(from_date).add('6', 'days').format('YYYY-MM-DD');
    setFilterObj({
      ...filterObj,
      from_date,
      to_date,
      type: 'Weekly',
    });
  };

  const onDateRangeChange = (dates: any) => {
    const [from_date, to_date] = dates;
    setFilterObj({
      ...filterObj,
      from_date: from_date.format('YYYY-MM-DD'),
      to_date: to_date.format('YYYY-MM-DD'),
      type: 'Daily',
    });
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    activity.current = 'sort';

    const { columnKey, order } = sorter;
    setSortedData({ ...sortedData, [columnKey]: order });
    setSortedInfo(sorter);
  };

  const handleSubmit = () => {
    activity.current = 'filter';
    loadData();
  };

  return (
    <Layout style={{ height: '80vh' }}>
      <div className="mainContent">
        <Content>
          <div className="tblContainer viewportContainer">
            <div className="widgets_block4 widget_columns_4">
              <div className="gridItem summaryCard">
                <img src={total_hours} alt="Total Hours" />
                <div className="widget_content">
                  <div className="assignTxt">Driving Hours</div>
                  <div className="assignNum">{summary?.totalHours || '-'}</div>
                </div>
              </div>
              <div className="gridItem summaryCard">
                <img src={no_pauses} alt="Total Pauses" />
                <div className="widget_content">
                  <div className="assignTxt">Total Pauses</div>
                  <div className="assignNum">{summary?.totalPauses || '-'}</div>
                </div>
              </div>
              <div className="gridItem summaryCard">
                <img src={no_resumes} alt="Total Resumes" />
                <div className="widget_content">
                  <div className="assignTxt">Total Resumes</div>
                  <div className="assignNum">
                    {summary?.totalResumes || '-'}
                  </div>
                </div>
              </div>
            </div>
            <div className="common_wrapper">
              <div className="filters_card gap-16">
                <Space>
                  <ArrowLeftOutlined onClick={() => onBack()} />
                  {selectedCustomer?.name}
                </Space>

                <CustomSelect
                  label="Filters"
                  cssClass="ad_min_width"
                  value={filter}
                  setValue={handleFilter}
                  options={impactFilters}
                  optionKey="value"
                  optionDisplay="name"
                />
                {filter === 'Monthly' && (
                  <DatePicker
                    allowClear={false}
                    format="MMM-YYYY"
                    value={moment(`${filterObj.year}-${filterObj.month}`)}
                    onChange={onMonthly}
                    disabledDate={disabledDates}
                    picker="month"
                  />
                )}
                {filter === 'Weekly' && (
                  <DatePicker
                    allowClear={false}
                    value={moment(filterObj.from_date)}
                    onChange={onWeekly}
                    disabledDate={disabledDates}
                    picker="week"
                  />
                )}
                {filter === 'Yearly' && (
                  <DatePicker
                    allowClear={false}
                    value={moment(`${filterObj.year}-01-01`)}
                    onChange={onYealy}
                    disabledDate={disabledDates}
                    picker="year"
                  />
                )}
                {filter === 'Daily' && (
                  <RangePicker
                    allowClear={false}
                    value={[
                      moment(filterObj.from_date),
                      moment(filterObj.to_date),
                    ]}
                    onChange={onDateRangeChange}
                    disabledDate={disabledDates}
                  />
                )}
                <Button
                  className="submitBtn"
                  onClick={handleSubmit}
                  loading={loading}
                >
                  Submit
                </Button>
              </div>
              <div className="common_table tableHover">
                <InfiniteScrollTable
                  columns={columns}
                  loading={loading}
                  filterData={filterData}
                  totalcount={total}
                  hasMore={hasMore}
                  handleLoadMore={handleLoadMore}
                  onChange={handleChange}
                  filename="jump and gos"
                  onRowClick={onRowClick}
                />
              </div>
            </div>
          </div>
        </Content>
        {Boolean(selectedDriveAction) && (
          <DriveActionDetailsModal
            driveAction={selectedDriveAction}
            handleCancel={() => setSelectedDriveAction(null)}
          />
        )}
      </div>
    </Layout>
  );
};

export default DriveActions;

import React, { useState, useContext, useEffect } from 'react';
import { Modal, notification, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import {
  getAssignedTractorsList,
  getUnassignedTractorsList,
  updateCheckedData,
} from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import './style.css';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { useLocation } from 'react-router-dom';

interface Props {
  showModal: boolean;
  handleCancel: () => void;
}

const AssignTractors: React.FC<Props> = ({
  showModal,
  handleCancel,
}: Props) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { userDetails } = useContext(ApplicationContext);
  const [tractorIds, setTractorIds] = useState<number[]>([]);
  const [tractorDetails, setTractorDetails] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const baseState: any = state;

  useEffect(() => {
    if (userDetails) {
      getAssignedTractors();
      getUnassignedTractors();
    }
  }, [userDetails, state]);

  const getAssignedTractors = async () => {
    try {
      const { records } = await getAssignedTractorsList(
        userDetails.organization.api_url,
        userDetails.organization.id,
        baseState.stateId,
      );
      const assignedListData: {
        key: number;
        tractorname: string;
        serial_number: string;
        tractor_id: number;
        checked: boolean;
      }[] = [];
      records.map((item: any) => {
        assignedListData.push({
          key: item.id,
          tractorname: item.tractor.name,
          serial_number: item.tractor.serial_number,
          tractor_id: item.tractor.id,
          checked: true,
        });
      });
      setTractorDetails((prev: any) => {
        return prev.concat(assignedListData);
      });
      records.map((i) => {
        setTractorIds((prev: any) => [...prev, i.tractor_id]);
      });
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };

  const getUnassignedTractors = async () => {
    try {
      const records = await getUnassignedTractorsList(
        userDetails.organization.api_url,
        userDetails.organization.id,
      );
      const unassignedListData: {
        key: number;
        tractorname: string;
        serial_number: string;
        tractor_id: number;
        checked: boolean;
      }[] = [];
      records.map((item: any) => {
        unassignedListData.push({
          key: item.id,
          tractorname: item.name,
          serial_number: item.serial_number,
          tractor_id: item.id,
          checked: false,
        });
      });
      setTractorDetails((prev: any) => {
        return prev.concat(unassignedListData);
      });
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };

  const saveCheckedData = async () => {
    if (tractorIds.length > 0) {
      const payload = {
        tractor_ids: tractorIds,
      };
      try {
        setLoader(true);
        const response: { msg: string } = await updateCheckedData(
          userDetails.organization.api_url,
          userDetails.organization.id,
          baseState.stateId,
          payload,
        );
        notification.success({
          message: response.msg,
        });
      } catch (err: any) {
        notification.error({
          message: err.message,
        });
      } finally {
        setLoader(false);
      }
      handleCancel();
    }
  };

  const onChange = (e: CheckboxChangeEvent) => {
    const record = e.target.value;
    record.checked = e.target.checked ? false : true;
    if (record.checked === true) {
      if (tractorIds.length >= 1) {
        const newarr = tractorIds.filter(function (x) {
          return x !== record.tractor_id;
        });
        setTractorIds(newarr);
      } else {
        setTractorIds([]);
      }
    } else {
      tractorIds.push(record.tractor_id);
    }
  };

  return (
    <Modal
      title={t(translate.baseStation.assignTractors)}
      visible={showModal}
      className="baseModalContainer"
      onOk={saveCheckedData}
      onCancel={handleCancel}
      okText={t(translate.buttons.save)}
      okButtonProps={{
        className: 'okBtn',
      }}
      cancelButtonProps={{
        className: 'canelBtn',
      }}
    >
      <div style={{ marginBottom: 6 }}></div>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Serial Number</th>
            </tr>
          </thead>
          {tractorDetails && tractorDetails.length > 0
            ? tractorDetails.map((listItem: any, index: number) => {
                return (
                  // eslint-disable-next-line react/jsx-key
                  <tbody>
                    <tr>
                      <td>
                        <Checkbox
                          key={index}
                          defaultChecked={listItem.checked}
                          value={listItem}
                          onChange={onChange}
                        >
                          {listItem.tractorname}
                        </Checkbox>
                      </td>

                      <td>{listItem.tractorname}</td>
                      <td>{listItem.serial_number}</td>
                    </tr>
                  </tbody>
                );
              })
            : null}
        </table>
        <div className="edit-loader">
          {loader && (
            <div className="loader">
              <Spin size="large" />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AssignTractors;

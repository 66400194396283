/* eslint-disable no-console */
import { Input, Modal, notification, Select, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import blue from '../../assets/images/layer_icons/layer-blue.svg';
import brown from '../../assets/images/layer_icons/layer-boundary.svg';
import green from '../../assets/images/layer_icons/layer-green.svg';
import red from '../../assets/images/layer_icons/layer-red.svg';
import white from '../../assets/images/layer_icons/layer-white.svg';
import yellow from '../../assets/images/layer_icons/layer-yellow.svg';
import { getPolyColorData, updatePolygonData } from '../../constants/Api';
import {
  addingAltitude,
  handleBrownPolyExistInBrown,
  handlePolyExistInBrown,
} from '../../constants/Common';
import { LatLngVertices, PolyColor, PolyPayload } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import translate from '../../locale/en_translate.json';

import { useTranslation } from 'react-i18next';
import { brownColor } from '../../constants/constant';

interface Props {
  showModal: boolean;
  handleCancel: (reload?: boolean) => void;
  polyPayload: PolyPayload;
  brownFeatureItems: Map<number, any>;
  updatedPolyFeatureItem: any;
  polyList: any[];
}
export const EditPoly: React.FC<Props> = ({
  showModal,
  handleCancel,
  polyPayload,
  brownFeatureItems,
  updatedPolyFeatureItem,
  polyList,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [name, setName] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [polyColor, setPolyColors] = useState<PolyColor[]>([]);
  const [color, setSelectedColor] = useState('');
  const [polygonId, setPolygonId] = useState<number>();
  const [latLongVertices, setLatLongVertices] = useState<LatLngVertices[]>([]);

  useEffect(() => {
    const onInIt = async () => {
      if (
        polyPayload &&
        polyPayload.lat_long_vertices &&
        polyPayload.lat_long_vertices.length > 0
      ) {
        const lat_long_vertices = await addingAltitude(
          polyPayload.lat_long_vertices,
        );
        setLatLongVertices(lat_long_vertices);
      }
    };
    onInIt();
  }, [polyPayload]);

  useEffect(() => {
    if (polyPayload) {
      if (polyPayload.name) {
        setName(polyPayload.name);
      }
      if (polyPayload.color) {
        setSelectedColor(polyPayload.color);
      }
      if (polyPayload.polygonId) {
        setPolygonId(polyPayload.polygonId);
      }
    }
  }, [polyPayload]);
  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getPolyColors();
    }
  }, [userDetails]);
  const getPolyColors = async () => {
    try {
      setLoader(true);
      const { organization } = userDetails;
      const response = await getPolyColorData(
        organization.api_url,
        organization.farm.id,
      );
      setPolyColors(response);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      name &&
      name.trim().length > 0 &&
      color &&
      color.trim().length > 0 &&
      ((handlePolyExistInBrown(brownFeatureItems, updatedPolyFeatureItem) &&
        color !== brownColor) ||
        (color == brownColor &&
          handleBrownPolyExistInBrown(updatedPolyFeatureItem, polyList)))
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [userDetails, name, color, polyList, updatedPolyFeatureItem]);

  const AddPolygon = async () => {
    setLoader(true);
    if (polygonId) {
      try {
        const payload = {
          name: name.trim(),
          color,
          lat_long_vertices: latLongVertices,
          area: polyPayload.area,
        };
        setName(name.trim());
        const { organization } = userDetails;
        const resp = await updatePolygonData(
          organization.api_url,
          organization.farm.id,
          polygonId,
          payload,
        );
        if (resp && resp.msg) {
          handleCancel(true);
          notification.success({
            message: resp.msg,
          });
        }
      } catch (err: any) {
        notification.error({
          message: err.message,
        });
      } finally {
        setLoader(false);
      }
    }
  };
  const handleSelect = (e: any) => {
    setSelectedColor(e);
  };
  const getImage = (color: string): string => {
    let image;
    switch (color) {
      case 'red':
        image = red;
        break;

      case 'white':
        image = white;
        break;

      case 'green':
        image = green;
        break;

      case 'blue':
        image = blue;
        break;

      case 'brown':
        image = brown;
        break;

      case 'yellow':
        image = yellow;
        break;
      default:
        image = green;
        break;
    }
    return image;
  };
  return (
    <Modal
      className="commonPopup addPolygon"
      title={`Edit ${t(translate.map.boundary)}`}
      centered
      width={'60vw'}
      closable={false}
      visible={showModal}
      onCancel={() => handleCancel()}
      okText="Save"
      onOk={AddPolygon}
      okButtonProps={{
        className: 'btnSave ',
        disabled: isDisabled,
      }}
      cancelButtonProps={{
        className: 'btnCancel',
      }}
    >
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel">
            Name <span style={{ color: 'red' }}> *</span>
          </label>
          <Input
            className="commInput"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />

          <div className="edit-loader">
            {loader && (
              <div className="loader">
                <Spin size="large" />
              </div>
            )}
          </div>
        </div>
        <div className="formCol">
          <label className="formLabel">
            Color <span style={{ color: 'red' }}> *</span>
          </label>

          <Select
            className="customSelect"
            onSelect={handleSelect}
            value={color}
          >
            {polyColor.map((data: any) => {
              if (data.name !== '') {
                return (
                  <option value={data.value} key={data.value}>
                    {/* <span style={{ textTransform: 'capitalize' }}>
                      {data.name}
                    </span> */}
                    {/* <span>{data.description}</span> */}
                    <span key={data.value}>
                      <img
                        src={getImage(data.name.toLowerCase())}
                        className="ploy_icon"
                      />{' '}
                      {data.name.toLowerCase() === 'brown'
                        ? 'Farm Boundary'
                        : data.description}
                    </span>
                  </option>
                );
              }
            })}
          </Select>
        </div>
      </div>
      <div className="formRow">
        <div className="formColFull">
          <label className="formLabel">
            Vertices <span style={{ color: 'red' }}> *</span>
          </label>
          <div className="verticsSec">
            {latLongVertices.map((data: any) => {
              return (
                <>
                  <div>
                    ({data?.latitude}, {data?.longitude}, {data?.altitude})
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
      {color !== brownColor &&
        !handlePolyExistInBrown(brownFeatureItems, updatedPolyFeatureItem) && (
          <div className="formRow">
            <div className="formColFull">
              <label className="formLabel" style={{ color: 'red' }}>
                The polygon being drawn should be within the overall Farm
                Boundary (Brown)
              </label>
            </div>
          </div>
        )}
      {color === brownColor &&
        !handleBrownPolyExistInBrown(updatedPolyFeatureItem, polyList) && (
          <div className="formRow">
            <div className="formColFull">
              <label className="formLabel" style={{ color: 'red' }}>
                All polygons must be inside the farm Boundary (brown)
              </label>
            </div>
          </div>
        )}
    </Modal>
  );
};
export default EditPoly;

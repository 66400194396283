/* eslint-disable react/jsx-key */
import React from 'react';
import { getSummary } from '../../constants/types';
import Interweave from 'interweave';

interface Props {
  summaryList?: getSummary;
}

export const CommonReleaseNotes: React.FC<Props> = ({ summaryList }) => {
  return (
    <div className="rNotesSec">
      {summaryList?.latest_software?.map((data: any) => (
        <div className="releaseNotesTxt">
          {' '}
          <Interweave content={data?.release_notes} />
        </div>
      ))}
      {summaryList?.latest_firmware?.map((data: any) => (
        <div className="releaseNotesTxt">
          {' '}
          <Interweave content={data?.release_notes} />
        </div>
      ))}
    </div>
  );
};

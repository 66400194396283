import React from 'react';
import mtsupport from '../../assets/images/mtsupport.svg';

interface Props {
  setSeeAll: (data: boolean) => void;
}

export const UpdateSchedule: React.FC<Props> = ({ setSeeAll }) => {
  return (
    <div className="supportContainer">
      <div className="firstGrid">
        <div>
          <img src={mtsupport} alt="mtsupport" height="36px" />
          <span className="scheduleTxt">
            New Update Available. Please update as soon as possible to maximize
            your fleet’s operational capabilities and efficiency.
          </span>
        </div>
        <div
          className="releaseNotesTxt pointer"
          onClick={() => setSeeAll(true)}
        >
          Release Notes
        </div>
      </div>
    </div>
  );
};

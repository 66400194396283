import React, { useState, useRef, useEffect, useContext } from 'react';
import { Card, Row, Space, Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons';
import moment from 'moment';

import { getDateTime, sortCameraViews } from '../../../constants/Common';
import { getVideoURL } from '../../../constants/Api';
import { ApplicationContext } from '../../../context/AppContext';
import RoutesConstants from '../../../routes/RoutesConstant';
import { AnalyticsContext } from '../AnalyticsContext';
import CameraGrid4 from '../../map/CameraGrid4';
import AppLoader from '../../common/AppLoader';
import { CamTypes } from '../../../constants/types';
import BarHandler from '../../common/BarHandler';
import PlayBackSpeed from '../../common/PlayBackSpeed';

import './style.css';
interface Props {
  UpdatedTime: any;
}
const VideoGrid: React.FC<Props> = ({ UpdatedTime }: Props) => {
  const { analyticReducer } = useContext(AnalyticsContext);
  const [state] = analyticReducer;
  const { JAG } = state;
  const { userDetails } = useContext(ApplicationContext);
  const ini = useRef(false);
  const iv = useRef<any>();
  const [updatedTime, setUpdatedTime] = useState<number>(
    UpdatedTime ? UpdatedTime : 0,
  );
  const [videos, setVideos] = useState<any>([]);
  const [playState, setPlayState] = useState(true);
  const [duration, setDuration] = useState<any>();
  const [vLength, setVLength] = useState<number>(300);
  const [tractorName, setTractorName] = useState<string>('');
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [current, setCurrent] = useState('1');
  const [cameras, setCameras] = useState<string[]>([]);

  useEffect(() => {
    return () => {
      setUpdatedTime(0);
      setVideos([]);
      setPlayState(true);
      setDuration([0, 0]);
      setVLength(0);
      setRefresh(false);
      setShowLoader(false);
      clearInterval(iv.current);
    };
  }, []);

  const errorRef = useRef<any>({
    front: false,
    rear: false,
    pto: false,
    front_left: false,
    front_right: false,
  });

  const videoRef = useRef<CamTypes>({
    front: undefined,
    rear: undefined,
    pto: undefined,
    front_left: undefined,
    front_right: undefined,
  });

  const checkVideos = () => {
    return Object.values(videoRef.current).map((item) => item !== undefined);
  };

  const loadDuration = () => {
    if (checkVideos().includes(true) && ini.current) {
      const durations = Object.values(videoRef.current)
        .filter((item) => item)
        .map((item: any) => {
          const { player } = item?.getState();
          return Math.round(player.duration);
        });
      setVLength(Math.max(...durations));
    }
  };

  const play = () => {
    setPlayState(false);
    Object.values(videoRef.current).map((item: any) => {
      if (item) {
        item.play();
      }
    });
  };

  const pause = () => {
    setPlayState(true);
    Object.values(videoRef.current).map((item: any) => {
      if (item) {
        item.pause();
      }
    });
  };

  const playbackRate = (steps: number) => {
    setPlayState(true);
    Object.values(videoRef.current).map((item: any) => {
      if (item) {
        item.video.video.playbackRate = steps;
        // const { player } = item.getState();
        // player.playbackRate = player.playbackRate + steps;
        item.pause();
      }
    });
  };

  const delay = (ms: number) =>
    new Promise<void>((resolve) => setTimeout(() => resolve(), ms));

  const seek = async (seconds: number) => {
    setPlayState(true);
    Object.values(videoRef.current).map((item: any) => {
      if (item) {
        item.seek(seconds);
        item.pause();
      }
    });
    await delay(3000);
    play();
  };

  const rightArrowClick = () => {
    if (duration[1] >= moment(updatedTime).add(5, 'minutes').valueOf()) {
      setUpdatedTime((prev: any) => {
        return moment(prev).add(5, 'minutes').valueOf();
      });
    }
  };

  const leftArrowClick = () => {
    if (duration[0] <= moment(updatedTime).subtract(5, 'minutes').valueOf()) {
      setUpdatedTime((prev: any) => {
        return moment(prev).subtract(5, 'minutes').valueOf();
      });
    }
  };

  const processDates = (dates: any[]) =>
    dates.map((date: any) => {
      date = new Date(date);
      date.setSeconds(0);
      date.setMilliseconds(0);
      return date.getTime();
    });

  const getVideoData = async () => {
    try {
      pause();
      ini.current = false;
      setShowLoader(true);
      let response = await getVideoURL(
        userDetails.organization.api_url,
        userDetails.organization.farm.id,
        JAG.tractor.id,
        updatedTime,
      );
      response = sortCameraViews(response, 'direction', 'videos');
      const cams = response.reduce(
        (a: any, data: any) => [...a, data.direction],
        [],
      );
      setCameras(cams);
      response = response
        .map((item: any) => {
          item.direction = item.direction.replace('-', '_');
          return item;
        })
        .filter((item: any) =>
          ['front', 'rear', 'pto', 'front_left', 'front_right'].includes(
            item.direction,
          ),
        );
      setVideos(response);
    } finally {
      setShowLoader(false);
      setRefresh(!refresh);
    }
  };

  const load = () => {
    Object.values(videoRef.current).map((item: any) => {
      if (item) {
        item.load();
      }
    });
  };

  const forward = (seconds: number) => {
    Object.values(videoRef.current).map((item: any) => {
      if (item) {
        item.video.video.currentTime = item.video.video.currentTime + seconds;
        item.seek(item.video.video.currentTime);
      }
    });
  };

  const openFullscreen = (videoUrl: any) => {
    videoUrl &&
      window.open(
        `${RoutesConstants.VideoPop}?videoUrl=${videoUrl}`,
        `Video URL`,
        'max-width: 100%',
      );
  };

  // const onError = (face: string) => {
  //   if (videoRef.current[face]) {
  //     const { player } = videoRef.current[face]?.getState();
  //     errorRef.current[face] = player.error ? true : false;
  //   }
  // };

  // const setMuted = (muted) => {
  //   Object.values(videoRef.current).map((item) => {
  //     if (item instanceof Player) {
  //       item.muted = muted;
  //     }
  //   });
  // };

  // const changeVolume = (steps:) => {
  //   Object.values(videoRef.current).map((item: Player) => {
  //     if (item instanceof Player) {
  //       item.volume = item.volume + steps;
  //     }
  //   });
  // };

  useEffect(() => {
    if (JAG) {
      setTractorName(JAG['tractor']['name']);
      const startDate: any = JAG['drive_action_start_date_time'];
      const endDate: any = JAG['drive_action_finish_date_time'];
      const dates = processDates([startDate, endDate]);
      setDuration(dates);
    } else {
      setVideos([]);
    }
  }, [JAG]);

  useEffect(() => {
    const { id: tractorId = 0 } = JAG.tractor;
    if (userDetails && userDetails.organization && updatedTime && tractorId) {
      setVideos([]);
      getVideoData();
    }
  }, [updatedTime]);

  useEffect(() => {
    const checkReadyState = () => {
      ini.current = false;
      if (!checkVideos().includes(true)) return;
      const readyStates = Object.values(videoRef.current)
        .filter((item) => item)
        .every((item: any) => {
          if (item?.getState()) {
            const { player } = item?.getState();
            errorRef.current[item.direction] = Boolean(player?.error);
            return player.readyState === 4;
          }
          return false;
        });
      if (readyStates) {
        ini.current = true;
        setRefresh(!refresh);
        clearInterval(iv.current);
      }
    };

    if (videos.length)
      iv.current = setInterval(() => {
        checkReadyState();
      }, 1000);

    return () => clearInterval(iv.current);
  }, [videos]);

  useEffect(() => {
    loadDuration();
    return () => {
      setRefresh(false);
    };
  }, [refresh]);

  const selectSpeed = (speed: number, e: any) => {
    setCurrent(e.key);
    playbackRate(speed);
  };

  return (
    <>
      <Content className="videoGrid">
        <Row>
          <Card
            title={tractorName}
            bordered={true}
            className="dbWidget videoDbCon"
            extra={
              <Space
                className="mt0"
                style={{ marginBottom: 0, position: 'relative' }}
              >
                <p style={{ fontFamily: 'Montserrat-Medium' }}>
                  <a style={{ fontWeight: 'bold' }} onClick={leftArrowClick}>
                    <LeftOutlined />
                  </a>{' '}
                  <span>{updatedTime && getDateTime(updatedTime)}</span>
                  <a style={{ fontWeight: 'bold' }} onClick={rightArrowClick}>
                    {' '}
                    <RightOutlined />
                  </a>
                </p>
              </Space>
            }
          >
            <CameraGrid4
              videos={videos}
              errorRef={errorRef}
              videoRef={videoRef}
              showLoader={showLoader}
              cameras={cameras}
              playState={playState}
              play={play}
            />
            {videos.length > 0 && (
              <div className="barDisplay">
                <Button
                  style={{ display: 'block' }}
                  className="okBtn"
                  onClick={() => (playState ? play() : pause())}
                >
                  {playState ? <CaretRightOutlined /> : <PauseOutlined />}
                </Button>
                <div className="video_progress">
                  <div className="video_progress__filled"></div>
                </div>
                <div
                  style={{
                    width: '140px',
                    fontFamily: 'Montserrat-SemiBold',
                  }}
                >
                  {document.getElementsByClassName('video_progress').length && (
                    <BarHandler
                      videoRef={videoRef}
                      seek={seek}
                      vLength={vLength}
                    />
                  )}
                </div>
                <PlayBackSpeed selectSpeed={selectSpeed} speed={current} />
              </div>
            )}
            <AppLoader loader={showLoader} />
          </Card>
        </Row>
      </Content>
    </>
  );
};

export default VideoGrid;

/* eslint-disable no-console */
// Import necessary packages and styles
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import '@amcharts/amcharts4/core.js';
import '@amcharts/amcharts4/charts.js';
import moment from 'moment';
import { selectedPath } from '../operationalAnalytics/common';
interface Props {
  data: any;
  chartId: string;
  selectedDate: any;
  onClick: (state: selectedPath) => void;
  loader: boolean;
  setHasZoomed: (state: boolean) => void;
  driveActionAndTime: selectedPath;
}
// Apply amCharts themes
am4core.useTheme(am4themes_animated);

const ProgressChart: React.FC<Props> = ({
  data,
  chartId,
  selectedDate,
  onClick,
  loader,
  setHasZoomed,
  driveActionAndTime,
}) => {
  const [hasZoomed1, setHasZoomed1] = useState(false);

  const chartRef = useRef<any>(null);
  const xAxisRef = useRef<any>(null);
  useEffect(() => {
    setHasZoomed(false);
    // Create a chart instance
    const chart = am4core.create(chartId, am4charts.XYChart);
    chart.logo.disabled = true;
    chartRef.current = chart;

    chart.data = data;

    const yAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    yAxis.dataFields.category = 'category';

    // Set margins and paddings to zero
    chart.paddingTop = 0;
    chart.paddingBottom = 0;
    chart.strokeWidth = 0;
    // Set margins to zero (outer margin)
    chart.marginTop = 0;
    chart.marginBottom = 0;

    yAxis.renderer.grid.template.disabled = true;
    yAxis.renderer.labels.template.disabled = true;

    const xAxis = chart.xAxes.push(new am4charts.DateAxis());
    xAxis.renderer.grid.template.disabled = true;
    xAxis.renderer.grid.template.disabled = true;
    xAxis.renderer.labels.template.disabled = true;
    // if (!loader && data?.length > 0) {
    //   xAxis.skipEmptyPeriods = true;
    // }
    xAxis.baseInterval = {
      timeUnit: 'minute',
      count: 1,
    };
    chart.seriesContainer.draggable = false;
    chart.seriesContainer.resizable = false;

    // Create series
    const series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.dateX = 'to';
    series.dataFields.openDateX = 'from';
    series.dataFields.categoryY = 'category';
    series.columns.template.propertyFields.fill = 'color';
    series.columns.template.strokeOpacity = 0;
    series.columns.template.height = 6;
    series.columns.template.paddingTop = 12;
    // series.columns.template.zIndex = 5;

    series.columns.template.marginBottom = 0;
    series.columns.template.column.cornerRadiusTopLeft = 5;
    series.columns.template.column.cornerRadiusTopRight = 5;
    series.columns.template.column.cornerRadiusBottomLeft = 5;
    series.columns.template.column.cornerRadiusBottomRight = 5;

    series.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    if (data?.length > 0) {
      // Ranges/labels
      chart.events.on('beforedatavalidated', function (ev) {
        const data = chart?.data;
        for (let i = 0; i < data?.length; i++) {
          const range = xAxis.axisRanges.create();
          range.date = data[i].from;
          range.endDate = data[i].to;
          const fromTime = chart.dateFormatter.format(data[i].from, 'hh:mm a');
          const toTime = chart.dateFormatter.format(data[i].to, 'hh:mm a');
          if (i == 0) {
            range.label.text = chart.dateFormatter.format(
              data[i].from,
              'hh:mm a',
            );
          }

          if (i == data.length - 1) {
            range.label.text = chart.dateFormatter.format(
              data[i].to,
              'hh:mm a',
            );
          }

          (range.label.fontFamily = 'Montserrat'),
            (range.label.fontWeight = '400');
          // range.axisFill.fill = am4core.color('#000').lighten(0.5);
          // range.axisFill.fillOpacity = 0.2;
          range.label.horizontalCenter = 'left';
          range.label.paddingLeft = 0;
          range.label.paddingTop = 0;
          range.label.paddingBottom = 0;
          range.label.marginTop = 0;
          range.label.marginBottom = 0;
          range.label.fontSize = 8;
          range.label.fill = am4core.color('#CFCFCF');
          const bullet = new am4charts.AxisBullet();
          // Highlight range on hover
          let hoverLabel: any;
          bullet.events.on('over', function (event: any) {
            // const dataItem = event.target.dataItem;
            // const range = xAxis.axisRanges.create();
            // range.date = dataItem.openDateX;
            // range.endDate = dataItem.dateX;
            // range.axisFill.fill = ;
            // range.axisFill.fillOpacity = 0.2;
            // bullet.range = range;
            const dataContext = event.target.dataItem.dataContext;
            // console.log('dataContext', event.target.dataItem);
            // Find the specific series data item
            series.dataItems.each(function (seriesDataItem) {
              // console.log(
              //   ' onetext',
              //   seriesDataItem.dataContext === dataContext,
              // );
              if (seriesDataItem.dataContext === dataContext) {
                // console.log('seriesDataItem', seriesDataItem);
                const column = seriesDataItem.column;
                if (column) {
                  column.fillOpacity = 0; // Set the opacity
                }
              }
            });
            // Add "click to expand" label
            hoverLabel = chart.createChild(am4core.Label);
            hoverLabel.text = 'Click to expand';
            hoverLabel.fill = am4core.color('#B5B5B6');
            hoverLabel.align = 'center';
            hoverLabel.isMeasured = false;
            hoverLabel.y = am4core.percent(100);
            hoverLabel.verticalCenter = 'bottom';
          });

          bullet.events.on('out', function (event: any) {
            if (hoverLabel) {
              hoverLabel.dispose();
              hoverLabel = null;
            }
            // range.axisFill.fill = am4core.color('#ffffff');
            const dataContext = event.target.dataItem.dataContext;

            // Find the specific series data item
            series.dataItems.each(function (seriesDataItem) {
              if (seriesDataItem.dataContext === dataContext) {
                const column = seriesDataItem.column;
                if (column) {
                  column.fillOpacity = 0.3; // Set the opacity
                }
              }
            });
          });
          const tooltip = bullet.createChild(am4core.Tooltip);
          bullet.tooltip = tooltip;
          bullet.tooltip.zIndex = 999;
          bullet.tooltip.getFillFromObject = false;
          bullet.tooltip.background.fill = am4core.color('#ffffff');
          bullet.tooltip.background.cornerRadius = 10;
          bullet.tooltip.background.strokeWidth = 0;
          bullet.tooltip.pointerOrientation = 'up';
          bullet.tooltip.label.fill = am4core.color('#000');
          bullet.tooltip.adapter.add('y', function (y, target) {
            if (y) {
              return (y = 20);
            }
          });
          bullet.tooltip.background.filters.clear();
          const shadow = new am4core.DropShadowFilter();
          shadow.blur = 6;
          shadow.dy = 4;
          shadow.dx = 0;
          shadow.opacity = 0.1;
          bullet.tooltip.background.filters.push(shadow);

          bullet.tooltipHTML = `<div class="bulletTooltip">
            <p class="tooltipDrive">Drive session</p>
            <p class="tooltipUser"> ${data[i].opName}</p>
            <p class="tooltipTime">${fromTime} - ${toTime}</p>
        </div>`;
          bullet.location = 0;
          range.bullet = bullet;
          const circle = bullet.createChild(am4core.Circle);
          const hs = circle.states.create('hover');
          hs.properties.stroke = am4core.color(data[i].color);
          hs.properties.strokeWidth = 1;
          circle.radius = 10;
          circle.fill = am4core.color('#EEEEEE');
          circle.strokeWidth = 0;
          range.grid.disabled = true;
          circle.horizontalCenter = 'middle';
          bullet.dy = -25;
          bullet.dx = 0;
          bullet.align = 'center';
          bullet.valign = 'middle';
          const label = bullet.createChild(am4core.Label);
          label.fill = am4core.color('#366376');
          label.text = data[i]?.operator;
          label.fontSize = 8;
          label.fontFamily = 'Montserrat';
          label.fontWeight = '600';
          label.horizontalCenter = 'middle';
          label.verticalCenter = 'middle';
        }
      });
    }

    let toolValue: any = '';
    if (data?.length > 0) {
      xAxis.adapter.add('getTooltipText', function (text: any, target) {
        toolValue = text;
        return text;
      });
      const cursor = new am4charts.XYCursor();
      chart.cursor = cursor;
      const axisTooltip = xAxis.tooltip;
      if (axisTooltip) {
        axisTooltip.background.fill = am4core.color('rgba(0, 122, 255, 0.4)');
        axisTooltip.padding(1, 4, 1, 4);
        axisTooltip.height = 12;
        axisTooltip.width = 40;
        xAxis.tooltipDateFormat = 'hh:mm a';
        axisTooltip.label.fill = am4core.color('#FFFFFF');
        axisTooltip.label.fontFamily = 'Montserrat';
        axisTooltip.label.fontSize = 8;
        axisTooltip.label.fontWeight = '500';
        axisTooltip.background.strokeWidth = 0;
        axisTooltip.background.cornerRadius = 4;
        axisTooltip.background.pointerLength = 0;
        axisTooltip.dy = -3;
      }

      cursor.xAxis = xAxis;
      cursor.yAxis = yAxis;
      cursor.lineY.disabled = true;
    }

    if (data?.length > 0) {
      series.columns.template.events.on('hit', function (ev: any) {
        const momentObj = moment(selectedDate).startOf('day');
        const amPm = toolValue?.split(' ')[1];
        const hour = Number(toolValue?.split(':')[0]);
        const minAndAmPm = toolValue?.split(':')[1];
        const minutes = Number(minAndAmPm?.split(' ')[0]);
        momentObj.hours(
          amPm && amPm?.toLowerCase() === 'pm' ? hour + 12 : hour,
        );
        momentObj.minutes(minutes);
        const epochTimeInMillis = momentObj.valueOf();
        onClick({
          fromClick: 'chart',
          created_date_time: epochTimeInMillis,
          drive_action_uuid:
            ev?.target?.dataItem?.dataContext?.drive_action_uuid,
        });
        if (hasZoomed1) return;
        // Zoom to the clicked time range
        const startTime = new Date(ev?.target?.dataItem.dataContext.from);
        const endTime = new Date(ev?.target?.dataItem.dataContext.to);
        xAxis.zoomToDates(startTime, endTime);
        setHasZoomed1(true);
      });
    }
    xAxisRef.current = xAxis;
    type DebouncedFunction = (...args: any[]) => void;

    function debounce(
      func: DebouncedFunction,
      delay: number,
    ): DebouncedFunction {
      let debounceTimer: ReturnType<typeof setTimeout>;
      return function (...args: any[]) {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func(...args), delay);
      };
    }

    xAxis.events.on(
      'selectionextremeschanged',
      debounce((ev: any) => {
        if (ev.target.start === 0 && ev.target.end === 1) {
          // console.log('Zoomed out');
          setHasZoomed(false);
        } else {
          // console.log(
          //   'Zoomed to: ',
          //   new Date(ev.target.minZoomed),
          //   new Date(ev.target.maxZoomed),
          // );
          setHasZoomed(true);
        }
      }, 100),
    );

    if (chart.zoomOutButton.label) {
      chart.zoomOutButton.label.text = 'Click to collapse view';
      chart.zoomOutButton.background.fill = am4core.color('transparent');
      chart.zoomOutButton.background.strokeWidth = 0;
      chart.zoomOutButton.background.paddingBottom = 0;
      chart.zoomOutButton.cursorOverStyle = am4core.MouseCursorStyle.pointer;
      const hoverkey = chart.zoomOutButton.background.states.getKey('hover');
      if (hoverkey) {
        hoverkey.properties.fill = am4core.color('white');
        hoverkey.properties.fillOpacity = 0;
        chart.zoomOutButton.label.fill = am4core.color('#99999C');
        chart.zoomOutButton.label.fontWeight = '600';
        chart.zoomOutButton.label.fontSize = '10';
      }
      // Positioning buttons
      chart.zoomOutButton.marginRight = 0;
      chart.zoomOutButton.marginTop = -8;
      chart.zoomOutButton.zIndex = 1;
      chart.zoomOutButton.marginBottom = 50;

      const downkey = chart.zoomOutButton.background.states.getKey('down');
      if (downkey) {
        downkey.properties.fill = am4core.color('white');
        downkey.properties.fillOpacity = 0;
      }
      chart.zoomOutButton.background.fillOpacity = 0; // Set opacity to 0
      chart.zoomOutButton.background.strokeOpacity = 0; // Set stroke opacity to 0
    }

    // Clean up when the component is unmounted
    return () => {
      chart.dispose();
    };
  }, []); // Ensure this effect runs only once on mount
  useEffect(() => {
    xAxisRef &&
      xAxisRef.current &&
      driveActionAndTime &&
      driveActionAndTime.fromClick &&
      driveActionAndTime.fromClick === 'map' &&
      data &&
      data.map((inSideData: any) => {
        if (
          inSideData.drive_action_uuid === driveActionAndTime.drive_action_uuid
        ) {
          xAxisRef.current.zoomToDates(inSideData.from, inSideData.to);
          setHasZoomed1(true);
        }
      });
  }, [driveActionAndTime]);
  useEffect(() => {
    // Update chart data when the data prop changes
    if (chartRef.current) {
      chartRef.current.data = data;
    }
  }, [data]);

  return (
    <div
      id={chartId}
      className="progressC"
      style={{
        width: '100%',
        height: '50px',
        // zIndex: 2,
      }}
    />
  );
};

export default ProgressChart;

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Row, Space, notification } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import translate from '../../../locale/en_translate.json';
import InfiniteScrollTable from '../../common/InfiniteScrollTable';
import usePaginate from '../../../hooks/usePaginate';
import { ApplicationContext } from '../../../context/AppContext';
import { initScroller } from '../../../constants/Common';
import { getAllTractors, getOrgDropdown } from '../api';
import { useTranslation } from 'react-i18next';
import { mapTractorsData } from '../Common';
// import './style.css';
import CustomMultiSelect from '../../common/CustomMultiSelect';

const RemoteAccess = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loader, setLoader] = useState<boolean>(false);
  const searchString = useRef<string>('');
  const [selectedOrgId, setSelectedOrgId] = useState<string[]>([]);
  const [organizations, setOrganizations] = useState<string[]>([]);
  const [search, setSearch] = useState<string>('');
  const [total, setTotal] = useState<number>(0);
  const [pageSize] = useState<any>(25);
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();
  const wrapResult = async (
    pageNumber: number,
    pageSize: number,
    search: string,
  ) => {
    const { organization } = userDetails;
    const result = await getAllTractors(
      organization.api_url,
      selectedOrgId,
      pageNumber,
      pageSize,
      search,
      //   sortInfo,
    );
    return {
      result,
      searchKey: search,
    };
  };

  const getAllOrganizations = async () => {
    try {
      const { api_url } = userDetails?.organization;
      const result = await getOrgDropdown(api_url);
      const orgList = result.map((data: any) => ({
        title: <span className="ellipsis">{data.name}</span>,
        value: `${data.id}`,
      }));
      const defaultSelectedIds = result?.map((item: any) => String(item.id));
      setSelectedOrgId(defaultSelectedIds);
      setOrganizations(orgList);
    } catch (error: any) {
      notification.error({ message: error.message });
    }
  };

  const getTractorsList = async () => {
    try {
      if (checkActivity()) initScroller();
      setLoader(true);
      const { result, searchKey } = await wrapResult(
        pageNumber,
        pageSize,
        search,
      );
      if (searchString.current !== searchKey) return;
      // eslint-disable-next-line no-underscore-dangle
      setTotal(result._metadata.total_records_count);
      const { records } = result;
      const data = mapTractorsData(records);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      //   if (!init.current) init.current = true;
      setLoader(false);
    }
  };

  const loadTractors = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      // eslint-disable-next-line no-unused-expressions
      pageNumber !== 1 ? setPageNumber(1) : getTractorsList();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getTractorsList();
    }
  };

  const handleOrg = (ids: any) => {
    activity.current = 'filter';
    setSelectedOrgId(ids);
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const handleSearch = (e: any) => {
    activity.current = 'search';
    const value = e.target.value.trim();
    if (value.length <= 1) {
      searchString.current = '';
      setSearch('');
    } else {
      searchString.current = value;
      setSearch(value);
    }
  };

  useEffect(() => {
    if (selectedOrgId?.length === 0) {
      setData([]);
    } else if (selectedOrgId.length) {
      loadTractors();
    }
  }, [userDetails, selectedOrgId, pageNumber, pageSize, search]);

  useEffect(() => {
    if (userDetails.organization) getAllOrganizations();
  }, [userDetails]);

  const columnsData = [
    {
      title: `${t(translate.tractors.name)}`,
      dataIndex: 'name',
      key: 'name',
      render: (tractorName: string) => <span>{tractorName}</span>,
    },
    {
      title: `${t(translate.tractors.serialNumber)}`,
      dataIndex: 'serial_number',
      key: 'serial_number',
      render: (serialNumber: string) => <span>{serialNumber}</span>,
    },
    {
      title: `${t(translate.tractors.tractorPin)}`,
      dataIndex: 'tractor_pin',
      key: 'tractor_pin',
      render: (tractor_pin: any) => (
        <span className="emailAlign hoverWrapper">
          <span className="tractorPinStyle">{tractor_pin}</span>
        </span>
      ),
    },
    {
      title: `${t(translate.tractors.connection)}`,
      dataIndex: 'connection',
      key: 'connection',
    },
    {
      title: `${t(translate.tractors.accessStatus)}`,
      dataIndex: 'remote_access_status',
      key: 'remote_access_status',
    },
    {
      title: `${t(translate.updateManager.remoteAccessGrant)}`,
      dataIndex: 'connection',
      key: 'connection',
      width: '200px',
      render: (connection: any, record: any) => (
        <>
          <div style={{ display: 'flex' }}>
            <Button
              disabled={record.connection === 'offline'}
              key="submit"
              className={
                record.connection === 'offline'
                  ? 'btnteamSave-1 Button disabled'
                  : 'btnteamSave-1 Button'
              }
            >
              {record?.connection === 'offline'
                ? 'Offline'
                : record?.remote_access_status === 'Enabled'
                ? 'Revoke'
                : 'Request'}
            </Button>
          </div>
        </>
      ),
    },
  ];

  return (
    <Layout className="posRel">
      <Content>
        <div className="tblContainer viewportContainer">
          <Row>
            <div className="common_wrapper wWidget_wrapper">
              <div className="filters_card spaceBtnAlignCenter personnelCard ad_filters_card">
                <div className="displayFlex">
                  <Space size="large">
                    {/* <Input
                      data-testid="searchDirectoryInputField-Employees"
                      addonBefore={<SearchOutlined />}
                      placeholder="Search Directory"
                      className="common_search"
                      onChange={(e) => handleSearch(e)}
                      autoComplete="off"
                    /> */}
                    <CustomMultiSelect
                      handleTreeSelect={handleOrg}
                      selectedId={selectedOrgId}
                      list={organizations}
                      label="Organizations"
                    />
                  </Space>
                </div>
              </div>
              <div className="common_table">
                <InfiniteScrollTable
                  columns={columnsData}
                  loading={loader}
                  hasMore={hasMore}
                  filterData={filterData}
                  totalcount={total}
                  handleLoadMore={handleLoadMore}
                  filename="Tractors"
                  //   onRowClick={(record) => getRow(record)}
                  //   onChange={handleChange}
                />
              </div>
            </div>
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

export default RemoteAccess;

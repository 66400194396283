/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from 'react';
import co2_saved from './../../../assets/images/co2_saved.svg';
import driving_hours from './../../../assets/images/driving_hours.svg';
import energy_used from './../../../assets/images/energy_used.svg';
import imp_close_cross from './../../../assets/images/imp_close_cross.svg';
import km_driven from './../../../assets/images/km_driven.svg';
import refresh from './../../../assets/images/t_refresh.svg';

import './ADashboard.css';

import { notification } from 'antd';
import { ApplicationContext } from '../../../context/AppContext';
import AdminLoader from '../AdminLoader';
import {
  adminAlignItems,
  adminTractorConst,
  extractHours,
  getAdminNameFromList,
  getAdminOperationStatus,
  getAdminStatusClass,
  getAdminStatusForTractor,
  getFirstChars,
  restrictDecimal,
} from '../Common';
import { getDashBoardInfo, getTractors } from '../api';
import {
  AdminHeartBeat,
  AdminTractor,
  DashBoardInfo,
  OrgTractor,
} from '../types';

interface Props {
  orgList: OrgTractor[];
  selectedOrg: number;
  setSelectedTractorId: (tractorId: number) => void;
  setSelectedOrg: (org: number) => void;
  setRecenterOrgId: (org: number) => void;
  setRecenterTractorId: (tractorId: number) => void;
}
const ImpactDashboard: React.FC<Props> = ({
  orgList,
  selectedOrg,
  setSelectedTractorId,
  setSelectedOrg,
  setRecenterOrgId,
  setRecenterTractorId,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const [tractorsList, setTractorsList] = useState<AdminTractor[]>([]);
  const [dashBoardInfo, setDashBoardInfo] = useState<DashBoardInfo>();
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);

  useEffect(() => {
    if (selectedOrg === 0) {
      setTractorsList([]);
    }
  }, [selectedOrg]);

  const getTractorsList = async () => {
    try {
      setLoader(true);
      const { organization } = userDetails;
      const records = await getTractors(organization.api_url, selectedOrg);
      let list = records && records.length && records.length > 0 ? records : [];
      list.forEach((element: AdminTractor) => {
        (element.housemeter = element.heartbeat
          ? element.heartbeat.hour_meter &&
            `${(Number(element.heartbeat?.hour_meter) / 3600).toFixed()} hrs`
          : ''),
          ((element.isAvailable = getAdminOperationStatus(
            element?.heartbeat as AdminHeartBeat,
          )),
          (element.isAvailable =
            element.connection === 'offline'
              ? adminTractorConst.off.key
              : getAdminOperationStatus(element?.heartbeat as AdminHeartBeat)));
      });
      list = adminAlignItems(list, userDetails.id);
      setTractorsList(list);
    } catch (error: any) {
      notification.error({
        message: error.message,
        duration: 1,
      });
    } finally {
      setLoader(false);
    }
  };

  const getDashBoardInfoHandle = async () => {
    try {
      setLoader1(true);
      const { organization } = userDetails;
      const resp = await getDashBoardInfo(organization.api_url, selectedOrg);
      setDashBoardInfo(resp);
    } catch (error: any) {
      notification.error({
        message: error.message,
        duration: 1,
      });
    } finally {
      setLoader1(false);
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.organization && selectedOrg) {
      getTractorsList();
      getDashBoardInfoHandle();
    }
  }, [userDetails, selectedOrg]);

  return (
    <div className="ad_RightSec" style={{ zIndex: 9 }}>
      <div className="ad_posrel">
        <div className="impact_side_head">
          {getAdminNameFromList(orgList, selectedOrg)}
        </div>

        <div className="imp_close_cross">
          <img
            src={imp_close_cross}
            alt=""
            onClick={() => {
              setSelectedOrg(0);
              setRecenterOrgId(0);
            }}
          />
        </div>
        <div className="imp_scroll">
          <AdminLoader loader={loader1} />
          <div className="imp_block_one imp_bdr">
            <div className="impact_cards_sec mb20">
              <div className="card">
                <div className="imp_card_row">
                  <div className="card_name">Kms Driven</div>
                  <div>
                    <img src={km_driven} alt="" />
                  </div>
                </div>
                <div className="imp_card_row">
                  <h2 className="card_value">
                    {restrictDecimal(dashBoardInfo?.distance as number) || '-'}
                  </h2>
                  (Kms)
                </div>
              </div>
              <div className="card">
                <div className="imp_card_row">
                  <div className="card_name">Co2 Saved</div>
                  <div>
                    <img src={co2_saved} alt="" />
                  </div>
                </div>
                <div className="imp_card_row">
                  <h2 className="card_value">
                    {restrictDecimal(
                      dashBoardInfo?.calculated_co2_saved as number,
                    ) || '-'}
                  </h2>
                  (Tons)
                </div>
              </div>
              <div className="card">
                <div className="imp_card_row">
                  <div className="card_name">Energy Used</div>
                  <div>
                    <img src={energy_used} alt="" />
                  </div>
                </div>
                <div className="imp_card_row">
                  <h2 className="card_value">
                    {restrictDecimal(dashBoardInfo?.energy_used as number) ||
                      '-'}
                  </h2>
                  (kWH)
                </div>
              </div>
              <div className="card">
                <div className="imp_card_row">
                  <div className="card_name">Driving Hours</div>
                  <div>
                    <img src={driving_hours} alt="" />
                  </div>
                </div>
                <div className="imp_card_row">
                  <h2 className="card_value">
                    {extractHours(dashBoardInfo?.driving_hours_str as string) ||
                      '-'}
                  </h2>
                  (Hrs)
                </div>
              </div>
            </div>
            {/* <div className="imp_block mb20">
              <h2 className="imp_block_head">Driving Hours</h2>
              <div className="imp_graph">
                <img src={imp_graph} alt="" />
              </div>
            </div> */}
          </div>
          <div className="imp_block_two">
            <div className="t_dflex">
              <h2 className="imp_block_head">Tractors</h2>
              <div className="t_icon_box">
                <img
                  src={refresh}
                  alt=""
                  width={18}
                  className="refreshAlign"
                  onClick={() => getTractorsList()}
                />
              </div>
            </div>

            <AdminLoader loader={loader} />
            <div className="imp_tractor_card_block">
              {tractorsList &&
                tractorsList.map((tractor: AdminTractor) => (
                  <>
                    <div
                      className="imp_tractor_card"
                      onClick={() => {
                        setRecenterTractorId(0);
                        setSelectedTractorId(0);
                        setTimeout(() => {
                          setRecenterTractorId(tractor.id);
                          setSelectedTractorId(tractor.id);
                        }, 100);
                        0;
                      }}
                      key={tractor.id}
                    >
                      <div className="imp_t_name_buble">
                        {getFirstChars(tractor.name)}
                      </div>
                      <div className="imp_t_name_desc">
                        <h2 className="imp_tname">{tractor.name}</h2>
                        <div className="imp_toperator">
                          {
                            tractor?.heartbeat?.drive_action_details
                              ?.operator_firstname
                          }{' '}
                          {
                            tractor.heartbeat?.drive_action_details
                              ?.operator_lastname
                          }
                        </div>
                      </div>
                      <div
                        className={`imp_t_status bg_manual ${getAdminStatusClass(
                          tractor?.isAvailable as string,
                          tractor?.heartbeat?.drive_action_details
                            ?.drive_action_created_at,
                        )}`}
                      >
                        {getAdminStatusForTractor(
                          tractor?.isAvailable as string,
                          tractor?.heartbeat?.drive_action_details
                            .drive_action_created_at,
                        )}
                      </div>
                    </div>
                  </>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImpactDashboard;

/* eslint-disable no-console */
import {
  Button,
  Input,
  InputNumber,
  Modal,
  notification,
  Select,
  Spin,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import {
  getPreviewSaveAbLineFromSurveyData,
  getPreviewSaveVineRowsFromSurveyData,
  saveAbLinesFromSurveyData,
  saveVineRowsFromJumpAndGoData,
  saveVineRowsFromSurveyData,
} from '../../constants/Api';
import { GenerateItem, PolygonDropDown } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import translate from '../../locale/en_translate.json';

import { useTranslation } from 'react-i18next';
import closeGreen from '../../assets/images/closeCross_rightbox.svg';
import { AB_LINE, VINE_ROW } from '../../constants/constant';

interface Props {
  showModal: boolean;
  handleCancel: (reload?: boolean) => void;
  polygonsListForPop: PolygonDropDown[];
  selectedVineRowsFromJumpAndGo: any[];
  generateFromRowOrAbLane: GenerateItem['type'];
}
export const VineRowModelWithJumpAndGo: React.FC<Props> = ({
  showModal,
  handleCancel,
  polygonsListForPop,
  selectedVineRowsFromJumpAndGo,
  generateFromRowOrAbLane,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [vineBlockName, setVineBlockName] = useState<string>('');
  const [numberOfRows, setNumberOfRows] = useState<number>(2);
  const [rowWidth, setRowWidth] = useState<string>('');
  const [selectedPolygonUuid, setSelectedPolygonUuid] = useState('');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [rows, setRows] = useState<any>([]);

  useEffect(() => {
    selectedVineRowsFromJumpAndGo &&
      selectedVineRowsFromJumpAndGo.length &&
      selectedVineRowsFromJumpAndGo.length > 0 &&
      setRows([
        selectedVineRowsFromJumpAndGo[0].data,
        selectedVineRowsFromJumpAndGo[selectedVineRowsFromJumpAndGo.length - 1]
          .data,
      ]);
  }, [selectedVineRowsFromJumpAndGo]);
  const saveVineRows = async () => {
    setLoader(true);
    let payload: any;

    if (
      selectedVineRowsFromJumpAndGo &&
      selectedVineRowsFromJumpAndGo.length &&
      selectedVineRowsFromJumpAndGo[0].type === 1
    ) {
      payload = getSurveyVineRowPayload();
    } else {
      payload = {
        vine_block_name: vineBlockName.trim(),
        polygon_uuid: selectedPolygonUuid,
        num_lanes: numberOfRows,
        vine_row_1: rows[0],
        vine_row_2: rows[1],
      };
    }
    setVineBlockName(vineBlockName.trim());

    try {
      const { organization } = userDetails;
      const response =
        selectedVineRowsFromJumpAndGo &&
        selectedVineRowsFromJumpAndGo.length &&
        selectedVineRowsFromJumpAndGo[0].type === 1
          ? generateFromRowOrAbLane === AB_LINE
            ? await saveAbLinesFromSurveyData(
                organization.api_url,
                organization.farm.id,
                payload,
              )
            : await saveVineRowsFromSurveyData(
                organization.api_url,
                organization.farm.id,
                payload,
              )
          : await saveVineRowsFromJumpAndGoData(
              organization.api_url,
              organization.farm.id,
              payload,
            );
      handleCancel(true);
      setLoader(false);
      notification.success({
        message: response.msg,
      });
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      selectedPolygonUuid &&
      selectedPolygonUuid.trim().length > 0 &&
      vineBlockName &&
      vineBlockName.trim().length > 0 &&
      numberOfRows &&
      numberOfRows > 0
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [userDetails, selectedPolygonUuid, vineBlockName, numberOfRows]);

  const onHandleChangeNumeric = (e: any) => {
    const value = e.target.value;
    if (Number(value) || value.trim() === '') {
      setRowWidth(value.trim());
    }
  };
  const getSurveyVineRowPayload = () => {
    return generateFromRowOrAbLane === VINE_ROW
      ? {
          vine_block_name: vineBlockName.trim(),
          polygon_uuid: selectedPolygonUuid,
          num_lanes: numberOfRows,
          start_survey_vine_row: rows[0],
          end_survey_vine_row: rows[1],
          row_width: rowWidth.trim() === '' ? null : Number(rowWidth),
        }
      : {
          ab_block_name: vineBlockName.trim(),
          polygon_uuid: selectedPolygonUuid,
          num_ab_lines: numberOfRows,
          start_survey_vine_row: rows[0],
          end_survey_vine_row: rows[1],
          row_width: rowWidth.trim() === '' ? null : Number(rowWidth),
        };
  };
  const getPreview = async () => {
    try {
      setLoader(true);
      const payload = getSurveyVineRowPayload();
      const { organization } = userDetails;
      const resp =
        generateFromRowOrAbLane === VINE_ROW
          ? await getPreviewSaveVineRowsFromSurveyData(
              organization.api_url,
              organization.farm.id,
              payload,
            )
          : await getPreviewSaveAbLineFromSurveyData(
              organization.api_url,
              organization.farm.id,
              payload,
            );

      notification.success({
        message: resp.msg,
      });
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  return (
    // <Modal
    //   className="commonPopup addPolygon vinrowAuto vinrowAutoJumpAndGo"
    //   title={`Generate ${t(translate.map.crop_rows)}`}
    //   centered
    //   width={'60vw'}
    //   visible={showModal}
    //   onCancel={() => handleCancel()}
    //   okText="Save"
    //   onOk={saveVineRows}
    //   okButtonProps={{
    //     className: 'btnSave ',
    //     disabled: isDisabled,
    //   }}
    //   cancelButtonProps={{
    //     className: 'btnCancel',
    //   }}
    // >
    <div
      className={
        'rightSideWidegtMapMaker ' +
        (showModal ? 'expandMapMaker' : 'hideMapMaker')
      }
    >
      <div className="closeButton profileClose">
        <img
          src={closeGreen}
          height="12px"
          alt="close icon"
          onClick={() => handleCancel()}
        />
        <span className="mapMakerTitle">
          {`Generate ${
            generateFromRowOrAbLane === VINE_ROW
              ? t(translate.map.crop_rows)
              : 'Ab Lane'
          }`}
        </span>
      </div>
      <div className="mapMakerContainer">
        <div className="formRow">
          <div className="formColW">
            <label className="formLabel">
              {generateFromRowOrAbLane === VINE_ROW
                ? t(translate.map.crop_block)
                : 'Abblock'}{' '}
              Name <span style={{ color: 'red' }}> *</span>
            </label>
            <Input
              className="commInput"
              onChange={(e) => setVineBlockName(e.target.value)}
              value={vineBlockName}
            />
          </div>
        </div>
        <div className="formRow">
          <div className="formColW">
            <label className="formLabel">
              {t(translate.map.boundary)}{' '}
              <span style={{ color: 'red' }}> *</span>
            </label>
            <Select
              style={{ width: '100%' }}
              onSelect={(value) => {
                setSelectedPolygonUuid(value);
              }}
              value={selectedPolygonUuid}
            >
              {polygonsListForPop.map((data: any) => {
                if (data.name !== '') {
                  return (
                    <option value={data.polygon_uuid} key={data.polygon_uuid}>
                      {data.name} ({data.color})
                    </option>
                  );
                }
              })}
            </Select>
          </div>
        </div>
        <div className="formRow">
          <div className="formColW ">
            <label className="formLabel">
              No of{' '}
              {generateFromRowOrAbLane === VINE_ROW
                ? t(translate.map.crop_lanes)
                : 'Ab lanes'}{' '}
              <span style={{ color: 'red' }}> *</span>
            </label>
            <InputNumber
              min={1}
              type="number"
              max={99999}
              className="commInput w100"
              value={numberOfRows}
              onChange={(value) => setNumberOfRows(value)}
            />
          </div>
        </div>
        <div className="formRow">
          <div className="formColW ">
            <label className="formLabel">
              Row Width
              {/* <span style={{ color: 'red' }}> *</span> */}
            </label>
            <Input
              className="commInput w100"
              value={rowWidth}
              onChange={(e) => onHandleChangeNumeric(e)}
            />
            {/* <InputNumber
            min={1}
            type="number"
            max={99999}
            className="commInput w100"
            value={rowWidth}
            onChange={(value) => setRowWidth(value)}
          /> */}
          </div>
        </div>
        <div className="formRowBtns">
          <div className="">
            {selectedVineRowsFromJumpAndGo &&
              selectedVineRowsFromJumpAndGo.length &&
              selectedVineRowsFromJumpAndGo[0].type === 1 && (
                <button
                  className="mapMakerActiveBtn"
                  onClick={getPreview}
                  disabled={isDisabled}
                  style={{ float: 'right' }}
                >
                  Show Preview
                </button>
              )}
          </div>
          <div className="">
            <button
              className="mapMakerActiveBtn"
              onClick={() => {
                const list = rows.reverse();
                setRows([...list]);
              }}
              style={{ float: 'right' }}
            >
              Swap Start and End
            </button>
          </div>
        </div>
        <div className="formRow">
          <div className="formColW ">
            <label className="formLabel lblHeads">
              Start{' '}
              {generateFromRowOrAbLane === VINE_ROW
                ? t(translate.map.crop_row)
                : 'Ab lane'}
            </label>
            <div className="pl15">
              {rows && rows.length == 2 && (
                <>{rows[0][0]?.row_name || rows[0][0]?.name}</>
              )}
            </div>
          </div>
          <div className="formColW">
            <label className="formLabel lblHeads">
              End{' '}
              {generateFromRowOrAbLane === VINE_ROW
                ? t(translate.map.crop_row)
                : 'Ab lane'}
            </label>
            <div className="pl15">
              {rows && rows.length == 2 && (
                <>{rows[1][0]?.row_name || rows[1][0]?.name}</>
              )}
            </div>
          </div>
        </div>
        <div className="formRow">
          <Button className="btnCancel" onClick={() => handleCancel()}>
            Cancel
          </Button>
          <Button
            className="btnSave"
            disabled={isDisabled}
            onClick={() => saveVineRows()}
          >
            Save
          </Button>
        </div>
        <div className="edit-loader">
          {loader && (
            <div className="loader">
              <Spin size="large" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default VineRowModelWithJumpAndGo;
